import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { map } from 'rxjs/operators';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class debitcancelService {
  claimData: any = null;
  claimData$: EventEmitter<any> = new EventEmitter<any>();
  math_min_param_1: any;
  constructor(protected httpClient: HttpClient) {}

 

  add(payload: any) {
      return this.httpClient.post(`/debitcanceladd`, payload).pipe(
        map((body: any) => {
          return body;
        })
      );
  }

  update(claimId:string,payload: any) {
    return this.httpClient.post(`/debitcancelupdate/${claimId}`, payload).pipe(
      map((body: any) => {
        return body;
      })
    );
}

 updateCal(claimId:string,payload: any) {
    return this.httpClient.post(`/debitcancelcalc/${claimId}`, payload).pipe(
      map((body: any) => {
        return body;
      })
    );
}

getByFilter(payload: any, customerId?: String) {
  let params = customerId
    ? new HttpParams().set('customerId', `${customerId}`)
    : new HttpParams();
  params = payload.claimDate_from
    ? params.append('claimDate_from', `${payload.claimDate_from}`)
    : params;
  params = payload.claimDate_to
    ? params.append('claimDate_to', `${payload.claimDate_to}`)
    : params;
  params = payload.addendumNumber
    ? params.append('addendumNumber', `${payload.addendumNumber}`)
    : params;
  params = payload.sequence
    ? params.append('sequence', `${payload.sequence}`)
    : params;
  params = payload.status
    ? params.append('status', `${payload.status}`)
    : params;
  params = payload.debitorName
    ? params.append('debitorName', `${payload.debitorName}`)
    : params;
  params = payload.debitorEmail
    ? params.append('debitorEmail', `${payload.debitorEmail}`)
    : params;
  params = payload.dealer
    ? params.append('dealer', `${payload.dealer}`)
    : params;
  params = payload.sortFieldFilter
    ? params.append('sortFieldFilter', `${payload.sortFieldFilter}`)
    : params;
  params = payload.sortByFilter
    ? params.append('sortByFilter', `${payload.sortByFilter}`)
    : params;
  params = params.append('currentPage', `${payload.currentPage}`);
  params = params.append('perPage', `${payload.perPage}`);

  return this.httpClient.get(`/debitcancelclaims`, { params }).pipe(
    map((body: any) => {
      return body;
    })
  );
}

getById(claimId: string) {
  return this.httpClient.get(`/debitcancel/${claimId}`).pipe(
    map((body: any) => {
      return body;
    })
  );
}

acceptRequest(claimId: string, payload: any) {
  return this.httpClient.put(`/debitcancel/${claimId}/acceptrequest`, payload).pipe(
    map((body: any) => {
      return body;
    })
  );
}

rejectRequest(claimId: string, payload: any, type: any) {
  return this.httpClient.put(`/debitcancel/${claimId}/${type}request`, payload).pipe(
    map((body: any) => {
      return body;
    })
  );
}

changeStatus(claimId: string, payload: any) {
  return this.httpClient.put(`/debitcancel/${claimId}/changestatus`, payload).pipe(
    map((body: any) => {
      return body;
    })
  );
}

deleteClaim(claimId: String) {
  return this.httpClient.delete(`/debitcancel/${claimId}`).pipe(
    map((body: any) => {
      return body;
    })
  );
}

getAnalytics(payload: any) {
  let params = null;
  if (payload) {
    params = new HttpParams().set('from', `${payload.from}`);
    params = params.append('to', `${payload.to}`);
  }
  if (payload.dealer && payload.dealer !== '') {
    params = params.append('dealer', `${payload.dealer}`);
  }

  return this.httpClient.get(`/debitcancelanalytics`, { params }).pipe(
    map((body: any) => {
      return body;
    })
  );
}

DebtCalculation(params: any) {
  const adjusted_amount = 0;
  //new calculation - begin
  if (params.frequencyOfPayments != null) {
    params.numberofPaymentsperYear = params.frequencyOfPayments;
  } else {
    params.numberofPaymentsperYear = 0;
  }
  if (params.dateOfFirstDue != null) {
    const date1 = new Date(params.claimDate);
    const date2 = new Date(DateTime.fromObject(params.dateOfFirstDue).toISO());
    const Difference_In_Days = Math.round(
      (date1.getTime() - date2.getTime()) / (1000 * 3600 * 24)
    );
    params.numberofPaymentsElapsed = Math.max(
      Math.floor(
        (Difference_In_Days / 365) * params.numberofPaymentsperYear
      ) + 1,
      0
    );
  } else {
  }

 
  if (params.numberOfPayments)
    params.realizedAPR = (
      parseFloat(
        (
          this.RATE(
            params.numberOfPayments,
            params.paymentsPerPeriod,
            -params.amountFinanced,
            params.balloonPayment,
            0,
            undefined
          ) * params.numberofPaymentsperYear
        ).toFixed(5)
      ) * 100
    ).toFixed(4);

  const fv_param_1 =
    params.realizedAPR / 100 / params.numberofPaymentsperYear;

  this.math_min_param_1 = this.FV(
    fv_param_1,
    params.numberofPaymentsElapsed,
    params.paymentsPerPeriod,
    -params.amountFinanced,
    0
  );
  params.projectedPrincipalBalance = Math.min(
    this.math_min_param_1,
    params.amountFinanced
  );
  params.estimatedPrincipalPaid =
    params.amountFinanced - params.projectedPrincipalBalance;

  params.lesserAdjustedAmount = parseFloat(
    Math.min(
      params.projectedPrincipalBalance,
      params.actualPayoffAmount
    ).toFixed(2)
  );

  let salvageValue = 0;
  if (params.includeSalvage == 'Yes') {
    salvageValue = parseFloat((params.vehiclePurchasePrice * 0.2).toFixed(2));
  } 
  
  params.salvageIncludeValue = salvageValue;
  
  params.totalDeduction = parseFloat(
    params.liabilityInsuranceSettlement +
      params.comprehensiveInsuranceSettlement +
      params.Dedectibles +
      params.accessories +
      params.serviceContract +
      params.closingFee +
      params.titleFee +
      params.salesTax +
      params.license +
      params.conditionAdjustmentA +
      params.conditionAdjustmentB +
      params.conditionAdjustmentC +
      salvageValue
    ).toFixed(2);

  params.settlementBeforeSalvage = parseFloat(
    (
      params.lesserAdjustedAmount -
      params.totalDeduction 
    ).toFixed(2)
  );

  params.lesserPayofforAmortized= (Math.min(
      params.maximunBenefitLimit,
      params.settlementBeforeSalvage
    )).toFixed(2);

  params.gapsettlement = parseFloat(params.lesserPayofforAmortized).toFixed(2);
  
  return params;
}

RATE(periods:any, payment:any, present:any, future:any, type:any, guess:any) {
  guess = guess === undefined ? 0.01 : guess
  future = future === undefined ? 0 : future
  type = type === undefined ? 0 : type

  periods = this.parseNumber(periods)
  payment = this.parseNumber(payment)
  present = this.parseNumber(present)
  future = this.parseNumber(future)
  type = this.parseNumber(type)
  guess = this.parseNumber(guess)

  
  const epsMax = 1e-10
  const iterMax = 20
  let rate = guess

  type = type ? 1 : 0

  for (let i = 0; i < iterMax; i++) {
    if (rate <= -1) {
      return true;
    }

    let y, f

    if (Math.abs(rate) < epsMax) {
      y = present * (1 + periods * rate) + payment * (1 + rate * type) * periods + future
    } else {
      f = Math.pow(1 + rate, periods)
      y = present * f + payment * (1 / rate + type) * (f - 1) + future
    }

    if (Math.abs(y) < epsMax) {
      return rate
    }

    let dy

    if (Math.abs(rate) < epsMax) {
      dy = present * periods + payment * type * periods
    } else {
      f = Math.pow(1 + rate, periods)
      const df = periods * Math.pow(1 + rate, periods - 1)
      dy = present * df + payment * (1 / rate + type) * df + payment * (-1 / (rate * rate)) * (f - 1)
    }

    rate -= y / dy
  }

  return rate
};

FV(rate: any, nper: any, pmt: any, pv: any, type: any) {
  const pow = Math.pow(1 + rate, nper);
  let fv;
  if (rate) {
    fv = (pmt * (1 + rate * type) * (1 - pow)) / rate - pv * pow;
  } else {
    fv = -1 * (pv + pmt * nper);
  }
  return fv.toFixed(2);
}

parseNumber(string:any) {
  if (string instanceof Error) {
    return string
  }

  if (string === undefined || string === null || string === '') {
    return 0
  }

  if (typeof string === 'boolean') {
    string = +string
  }

  if (!isNaN(string)) {
    return parseFloat(string)
  }

  return string
}

}
