import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { IpDetailComponent } from '@app/shared/modals/ip-detail/ip-detail.component';
import { UtilService, LOCAL_VAR } from '@app/core';
import { CustomerService } from '@app/core/service/customer.service';
import { finalize } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { SupportHelpComponent } from '@app/shared/modals/support-help/support-help.component';
import { ClaimHelpComponent } from '@app/shared/modals/claim-help/claim-help.component';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  @ViewChild('goto') nameInput: ElementRef;
  helpList: any;
  isLoading = false;
  closeResult: string;
  isFileterCollapse = true;
  fiterForm: FormGroup;
  customerList: any;
  sortDir = -1;
  perPage = 10;
  currentPage = 1;
  sortBy = 'updateTime';
  loggedInUserType: any = null;
  user: Authentication.User;
  pager = {
    perPage: 10,
    currentPage: 1,
    Totalrecords: 0,
    TotalPages: 0
  };
  PageList: any;
  selectedCustomer: any = null;

  constructor(
    public router: Router,
    private modalService: NgbModal,
    public utilService: UtilService,
    private customerService: CustomerService,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService
  ) {
    this.user = this.authenticationService.credentials.user;
    this.loggedInUserType = this.user.userRoleId.slug;
    localStorage.setItem(LOCAL_VAR.selectedCustomer, null);
    this.createForm();
  }

  ngOnInit() {
    if (this.loggedInUserType !== 'dowcsuperadmin') {
      this.router.navigate(['/products']);
    }
  }

  ngAfterViewInit() {
    this.utilService.updateDomainCss();
  }

  createForm() {
    this.isLoading = true;
    this.fiterForm = this.formBuilder.group({
      sequence: [''],
      request:[''],
      message: [''],
      email: [''],
      status: [''],
      date_from: [''],
      date_to: [''],
      sortFieldFilter: [''],
      sortByFilter: [''],
      perPage: 10
    });

    this.fiterForm.valueChanges.subscribe(values => {
      this.pager.currentPage = 1;
      this.getAllHelpByFilter();
    });
    this.customerService
      .getAllCustomers()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response: any) => {
        this.customerList = response.records;
      });

    this.getHelp(this.pager.currentPage, {});
  }

  async sort(sortByVal: string) {
    if (this.sortBy === sortByVal) {
      this.sortDir = this.sortDir === 1 ? -1 : 1;
    } else {
      this.sortDir = 1;
    }
    this.fiterForm.value.sortFieldFilter = sortByVal;
    this.fiterForm.value.sortByFilter = this.sortDir;
    this.sortBy = sortByVal;
    this.getAllHelpByFilter();
  }

  getAllHelpByFilter() {
    let payload = this.fiterForm.value;
    payload = this.utilService.removeWhiteSpace(payload);
    if (payload.date_from) {
      payload.datetime_from = DateTime.fromObject(
        payload.date_from
      ).toISO();
    }
    if (payload.date_to) {
      payload.datetime_to = DateTime.fromObject(payload.date_to).toISO();
    }
    this.pager.perPage = payload.perPage;
    delete payload.perPage
    this.getHelp(this.pager.currentPage, payload);
  }

  getHelp(page: number, payload: any) {
    payload.currentPage = page;
    payload.perPage = this.pager.perPage;
    //payload = this.utilService.removeWhiteSpace(payload);
    this.isLoading = true;
    this.customerService
      .getAllHelpByFilter(payload)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response: any) => {
        this.helpList = response.records;
        this.renderPagination(response.page, response.total, response.totalPage);
      });
  }

  goPage() {
    let goto = this.nameInput.nativeElement.value;
    this.setPage(goto);
    this.nameInput.nativeElement.value = '';
  }

  setPage(page: any) {
    if (0 < page && page <= this.pager.TotalPages && page != this.pager.currentPage) {
      this.pager.currentPage = page;
      this.getAllHelpByFilter();
    }
  }

  renderPagination(page: number, total: number, totalPage: number) {
    this.pager.currentPage = page;
    this.pager.Totalrecords = total;
    this.pager.TotalPages = totalPage;
    this.PageList = this.utilService.getPageList(this.pager.TotalPages, this.pager.currentPage);
  }

  ipDetails(ip: any) {

    this.customerService.
      getIpdetails(ip)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response: any) => {
        this.showIpdetails(ip, response);
      });


  }

  async showIpdetails(ip: any, details: any) {
    const modalRef = await this.modalService.open(IpDetailComponent);
    modalRef.componentInstance.ip = ip;
    if (details.status == "success") {
      modalRef.componentInstance.country = details.country;
      modalRef.componentInstance.countryCode = details.countryCode;
      modalRef.componentInstance.regionName = details.regionName;
      modalRef.componentInstance.region = details.region;
      modalRef.componentInstance.city = details.city;
    } else if (details.message) {
      modalRef.componentInstance.message = details.message;
    }


    const result: any = await modalRef.result.catch(e => { return e });
  }

  omit_special_char(event: any) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k <= 122) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  omit_special_Mailchar(event: any) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k <= 122) || k === 46 || k === 64 || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  async openResponsePopup(data:any) {
    const modalRef = await this.modalService.open(SupportHelpComponent);
    modalRef.componentInstance.helpId = data._id;
    modalRef.componentInstance.ticket = data.sequence;
    modalRef.componentInstance.updateTime = data.updateTime;
    modalRef.componentInstance.message = data.message;
    modalRef.componentInstance.userName = data.firstName+' '+data.lastName;
    modalRef.componentInstance.userEmail = data.email;
    modalRef.componentInstance.userPhone = data.phone;
    modalRef.componentInstance.status = data.status;
    modalRef.componentInstance.notes = data.notes;
    const result: any = await modalRef.result.catch(e => console.log(e));
    if (result) {
      this.getAllHelpByFilter();
    }
    
  }

  async openHelpPopup() {
    const modalRef = await this.modalService.open(ClaimHelpComponent);
  }
}

