import 'rxjs/add/operator/take';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LOCAL_VAR, STATES } from '@app/core/constants';
import { DateTime } from 'luxon';
import { ClaimService } from '@app/core/service/claim.service';
import { environment } from '../../../environments/environment';
import { finalize, tap, map, switchMap, distinctUntilChanged, debounceTime, catchError, filter } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { UtilService } from '@app/core';
import { of as observableOf, Observable, Subject, merge, of } from 'rxjs';
import {NgbTypeaheadConfig} from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { matchValidation } from '@app/core';

@Component({
  selector: 'app-claim-find',
  templateUrl: './claim-find.component.html',
  styleUrls: ['./claim-find.component.scss']
})
export class ClaimFindComponent implements OnInit {
  findForm: FormGroup;
  isLoading = false;
  queryParams: any;
  appname=environment.name;
  applogo=environment.logo;
  serverUrl=window.location.origin;
  logo=environment.logo;
  fileClaim = environment.fileClaim;
  isFind = true;
  isValidUrl = false;
  claims:any;
  
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private claimService: ClaimService,
    private toastrService: ToastrService,
    public utilService: UtilService,
    private authenticationService: AuthenticationService,
    config: NgbTypeaheadConfig,
  ) {
    this.queryParams = this.route.snapshot.params;

    console.log(this.queryParams);
    console.log(Object.keys(this.queryParams).length);
 
    this.createForm();
    var domainconfig = JSON.parse(localStorage.getItem(LOCAL_VAR.domainconfig));
    this.applogo = "assets/images/dowc-logo-green-bg.jpg";
    this.logo = "assets/images/dowc-logo.png";
    if (domainconfig) {
      if (domainconfig.appLogo) {
        this.applogo = domainconfig.appLogo;
      }
      if (domainconfig.appLogo) {
        this.logo = domainconfig.appLogo;
      }
    }
  }

  ngOnInit() { 
    if(this.applogo==='dowc'){
      $('#wrapper').css('background-image', 'url(assets/images/background/dowcclaims.jpg)');
    } else if(this.applogo==='aps'){
      $('#wrapper').css('background-image', 'url(assets/images/background/dowcclaims.jpg)');
    }
  }

  ngAfterViewInit() {
    var domainconfig = JSON.parse(localStorage.getItem(LOCAL_VAR.domainconfig));
    if(domainconfig){
      if(domainconfig.primaryColor){
        document.documentElement.style.setProperty('--primary-color', domainconfig.primaryColor);
      }
      if(domainconfig.primaryColor){
        document.documentElement.style.setProperty('--secondary-color', domainconfig.secondaryColor);
      }
    } else {
      document.documentElement.style.setProperty('--primary-color', '#009B4C');
    }
  }

 /*  signup() {
    this.isLoading = true;
    if (!this.queryParams.invitationCode) {
      this.findForm.removeControl('invitationCode');
    }
  } */

  private createForm() {
    this.findForm = this.formBuilder.group({
      contractNumber: ['', [Validators.required]],
      vinNumber: ['', [Validators.required]],
      sequence: ['', [Validators.required]]});

      this.checkURL();
  }

  checkURL(){
    if(Object.keys(this.queryParams).length>2){
      console.log("Find");
      this.isLoading = true;
    this.claimService
      .findClaim(this.queryParams)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response: any) => {
        if(response.data)
          this.claims = response.data;
        else {
          console.log("search"); 
          this.resetData();
          this.router.navigate(['/find']);
        } 
      });
    } else {
      console.log("search");
      this.router.navigate(['/find']);
    }
  }

  resetData(){
    this.claims = null;
    //this.router.navigate(['/find']);
  }


  find() {
    this.isLoading = true;
    const payload = this.findForm.value;
      console.log(this.findForm);
    //this.queryParams = payload;
     // this.checkURL();
     this.router.navigate(['/track/'+payload.contractNumber+'/'+payload.vinNumber+'/'+payload.sequence]);    
    
  }
}
