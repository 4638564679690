import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-theft-analytics',
  templateUrl: './theft-analytics.component.html',
  styleUrls: ['./theft-analytics.component.scss']
})
export class TheftAnalyticsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
