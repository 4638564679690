<div class="row agent-container">
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        Reports
        <div class="card-actions">
          <button class="btn btn-white" (click)="exportReport()">
            Export
          </button>
        </div>
      </div>
      <div class="card-body p-0">
        <div class="card mb-0">
          <div class="card-body pl-0 pr-0 pb-0">
            <form [formGroup]="fiterForm">
              <a class="cursor-pointer hidden-xl-up pl-2"
                (click)="utilService.toggleDivClass('filter-reports','hidden-lg-down')">
                <i class="fa fa-plus mr-2"></i><i class="fa fa-minus mr-2 d-none"></i>Filter
              </a>
              <div class="row hidden-lg-down" id="filter-reports">
                <div class="col-12 d-xl-flex align-items-start">
                  <div class="col-12 col-xl-2">
                    <div class="form-group mb-0">
                      <label class="d-block small">Contract No</label>
                      <input
                        type="text"
                        name="addendumNumber"
                        class="form-control"
                        formControlName="addendumNumber"
                        addendumNumber
                        (keypress)="omit_special_char($event)"
                       />
                    </div>
                  </div>
                  <div class="col-12 col-xl-2">
                    <div class="form-group mb-0">
                      <label class="d-block small">Name</label>
                      <input type="text" name="customerNameFilter" class="form-control" formControlName="customerName"
                        (keypress)="omit_special_char($event)" />
                    </div>
                  </div>
                  <div class="col-12 col-xl-2">
                    <div class="form-group mb-0">
                      <label class="d-block small">Email</label>
                      <input type="text" name="customerEmailFilter" class="form-control" formControlName="email"
                        (keypress)="omit_special_Mailchar($event)" />
                    </div>
                  </div>
                  <div class="col-12 col-xl-1">
                    <div class="form-group mb-0 pos-rel">
                      <label class="d-block small">Date From</label>
                      <input class="form-control small-date" type="text" name="dateOfLossFilter" ngbDatepicker
                        #date_from="ngbDatepicker" (click)="date_from.toggle()" formControlName="date_from"
                        [readonly]="true" [minDate]="{ year: 1900, month: 1, day: 1 }" [maxDate]="{
                          year: utilService.getCurrentYear,
                          month: utilService.getCurrentMonth,
                          day: utilService.getCurrentDay
                        }" />

                    </div>
                  </div>
                  <div class="col-12 col-xl-1">
                    <div class="form-group mb-0 pos-rel">
                      <label class="d-block small">Date To</label>
                      <input class="form-control small-date" type="text" name="dateOfLossFilter" ngbDatepicker
                        #date_to="ngbDatepicker" (click)="date_to.toggle()" formControlName="date_to" [readonly]="true"
                        [minDate]="{ year: 1900, month: 1, day: 1 }" [maxDate]="{
                          year: utilService.getCurrentYear,
                          month: utilService.getCurrentMonth,
                          day: utilService.getCurrentDay
                        }" />

                    </div>
                  </div>
                  <div class="col-12 col-xl-2">
                    <div class="form-group mb-0">
                      <label class="d-block small">Status</label>
                      <select class="form-control custom-select" name="status" formControlName="status">
                        <option value="">All</option>
                        <option value="in progress">In Progress</option>
                        <option value="in review">In Review</option>
                        <option value="completed">Completed</option>
                        <option value="rejected">Rejected</option>
                        <option value="approved">Approved</option>
                        <option value="voided">Voided</option>
                      </select>
                    </div>
                  </div>
                  <div
                    *ngIf="(loggedInUserType == 'superadmin' || loggedInUserType == 'dowcsuperadmin' ||  loggedInUserType == 'dowcadmin')"
                    class="col-12 col-xl-2">
                    <div class="form-group mb-0"
                      *ngIf="loggedInUserType=='dowcadmin' || loggedInUserType=='dowcsuperadmin' || user.isGroupDealer">
                      <label class="d-block small">Dealer</label>
                      <app-form-error-wrapper [control]="fiterForm.controls['dealer']" [controlName]="'Dealer'"
                        [apiErrorType]="'dealer'">
                        <ng-template #rt let-r="result" let-t="term">
                          {{ r.name }}
                        </ng-template>
                        <input class="form-control" type="text" [ngbTypeahead]="dealers" [inputFormatter]="formatter"
                          formControlName="dealer" [resultTemplate]="rt" autocomplete="off"
                          placeholder="Search Dealer" />
                        <a class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer pos-abs datecancel-r15 top-73"
                          (click)="
                          utilService.removeDate(
                            fiterForm,
                            'dealer'
                          )
                        " placement="left" ngbTooltip="Remove" *ngIf="fiterForm.controls['dealer'].value">
                          <i class="ti-close text-danger m-r-10"></i>
                        </a>
                      </app-form-error-wrapper>


                    </div>
                  </div>
                  <div class="none">
                    <input type="hidden" name="sortFieldFilter" formControlName="sortFieldFilter" #sortFieldFilter />
                    <input type="hidden" name="sortByFilter" formControlName="sortByFilter" #sortByFilter />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="table table-responsive smart-table" *ngIf="claims && claims.length > 0">
          <table class="w-100">
            <thead>
              <tr>
                <th class="pointer">
                  <div [ngClass]="{
                      sort: sortBy === 'sequence',
                      asc: sortDir == 1,
                      desc: sortDir == -1
                    }" (click)="sort('sequence')">
                    Claim No
                  </div>
                <th>Contract No</th>
                <th class="pointer">
                  <div [ngClass]="{
                      sort: sortBy === 'customerName',
                      asc: sortDir == 1,
                      desc: sortDir == -1
                    }" (click)="sort('customerName')">
                    Name
                  </div>
                </th>
                <!-- <th>Phone No</th> -->
                <th>Email</th>
                <th class="pointer">
                  <div [ngClass]="{
                      sort: sortBy === 'dateOfLoss_at',
                      asc: sortDir == 1,
                      desc: sortDir == -1
                    }" (click)="sort('dateOfLoss_at')">
                    Date of Loss
                  </div>
                </th>
                <th class="pointer">
                  VIN Number
                </th>
                <th>Dealer</th>
                <th>Status</th>
                <th class="pointer claim-status">
                  <div [ngClass]="{
                      sort: sortBy === 'GapAmountAftercancel',
                      asc: sortDir == 1,
                      desc: sortDir == -1
                    }" (click)="sort('GapAmountAftercancel')">
                    GAP Amount
                  </div>
                </th>

              </tr>
            </thead>
            <tbody>
              <tr *ngFor="
                  let claim of claims">
                <td>{{ claim.sequence }}</td>
                <td>{{ claim.contractNumber }}</td>
                <td>{{ claim.customerName }}</td>
                <!-- <td>{{ claim.phoneNumber | phone }}</td> -->
                <td>{{ claim.email | lowercase }}</td>
                <td>{{ claim.dateOfLoss_at | date: 'MM/dd/yyyy' }}</td>
                <td>{{ claim.vinNumber }}</td>
                <td class="list-dealer-td">
                  <span *ngIf="claim.dealer">{{ claim?.dealer?.name }}</span>
                </td>
                <td class="text-capitalize">{{ claim.status }}</td>
                <td *ngIf="!claim.dealer"></td>
                <td>{{
                  claim?.gapsettlement < 1 ? '$0.00' : (claim?.gapsettlement | currency: 'USD' :'symbol':'1.2-2')
                    }}</td>

              </tr>
            </tbody>
          </table>
        </div>
        <app-no-data-msg [message]="'No Claims Found'" *ngIf="claims && claims.length === 0 && !isLoading">
        </app-no-data-msg>
        <app-table-loader [isLoading]="isLoading"></app-table-loader>
      </div>
      <div class="card-footer" *ngIf="claims && claims.length > 0">
        <div class="row">
          <div class="col-md">
            <span>{{
              utilService.showPaginationInfo(pager.Totalrecords, pager.perPage, pager.currentPage)
              }}</span>
          </div>
          <div class="col-sm">
            <form [formGroup]="fiterForm">
              <div class="form-group mb-0 pull-right">
                <small>Rows:</small>
                <select class="form-control custom-select rows-button" name="perPage" formControlName="perPage">
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                <input *ngIf="pager.TotalPages && pager.TotalPages>1" type="number" #goto name="goto"
                  class="form-control goto-input" placeholder="Page No" min="1" max="{{pager.TotalPages}}" />
                <span *ngIf="pager.TotalPages && pager.TotalPages>1" class="btn btn-link page-goto goto-btn"
                  (click)="goPage()">
                  Go
                </span>
              </div>
            </form>
          </div>
          <div class="col-md" *ngIf="pager.TotalPages && pager.TotalPages>1">
            <div class="">
              <ul class="pagination pull-right">
                <li>
                  <a (click)="setPage(pager.currentPage - 1)"><i class="icon-arrow-left"></i></a>
                </li>
                <li *ngFor="let page of PageList" [ngClass]="{active:pager.currentPage === page.page}">
                  <a (click)="setPage(page.page)">{{page.label}}</a>
                </li>
                <li>
                  <a (click)="setPage(pager.currentPage + 1)"><i class="icon-arrow-right"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="help-container">
        <div class="help-section">
          <p class="help-text" (click)="openHelpPopup()">
            <i class="hellp-popup-icons"></i>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>