import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationComponent } from '@app/shared/modals/confirmation';
import { STATES } from '@app/core/constants';
import { DateTime } from 'luxon';
import { CustomerService } from '@app/core/service/customer.service';
import {
  finalize,
  tap,
  map,
  switchMap,
  distinctUntilChanged,
  debounceTime,
  catchError,
  filter
} from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { UtilService, CONFIRMATION_MSGS, LOCAL_VAR } from '@app/core';
import { of as observableOf, Observable, Subject, merge, of } from 'rxjs';
import { NgbTypeaheadConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { ClaimHelpComponent } from '@app/shared/modals/claim-help/claim-help.component';
import { ClaimService } from '@app/core/service/claim.service';
import { environment } from '@env/environment.local';

@Component({
  selector: 'app-dealergroup-create',
  templateUrl: './dealergroup-create.component.html',
  styleUrls: ['./dealergroup-create.component.scss']
})
export class DealergroupCreateComponent implements OnInit {
  dealerGroupForm: FormGroup;
  vehiclePhotos: FormArray;
  isLoading = false;
  queryParams: any;
  roles: string[];
  dealersList: [{ name: 'DOWC'; _id: '0' }]; //selectedPersons: Person[] = <any>[{ name: 'Karyn Wright' }, { name: 'Other' }];
  user: any;
  loggedInUserType: string;
  loggedinuserdetail: any;
  dealerGroup: any;
  isreivewMode = false;
  isEditMode = false;
  dealerGroupId: any = null;
  baseUrl = '';
  selectedCustomer: any = null;
  dealerGroupList: any = [];
  invalidDealer = true;

  @ViewChild('instance') instance: any;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  //formatter = (result: string) => result.toUpperCase();
  dealers = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term =>
        term.length < 3
          ? []
          : this.dealersList
            .filter(
              v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1
            )
            .slice(0, 10)
      )
    );
  formatter = (x: { name: string }) => x.name;
  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private customerService: CustomerService,
    private toastrService: ToastrService,
    public utilService: UtilService,
    private Claims: ClaimService,
    private authenticationService: AuthenticationService,
  ) {
    this.user = this.authenticationService.credentials.user;
    this.loggedInUserType = this.user.userRoleId.slug;
    this.baseUrl = environment.s3Url;
    this.selectedCustomer = localStorage.getItem(LOCAL_VAR.selectedCustomer);
    this.selectedCustomer = JSON.parse(this.selectedCustomer);
    if (this.router.url.indexOf('review') !== -1) {
      this.isreivewMode = true;
    } else {
      this.isreivewMode = false;
    }
    if (this.router.url.indexOf('edit') !== -1) {
      this.isEditMode = true;
    } else {
      this.isEditMode = false;
    }

    this.route.queryParams.pipe().subscribe(params => {
      this.queryParams = params;
    });

    if (this.isEditMode && this.route.snapshot.params['dealerGroupId']) {
      this.dealerGroupId = this.route.snapshot.params['dealerGroupId'];
    }
    if (this.dealerGroupId) {
      this.getDealerGroup(this.dealerGroupId);
    }
    this.createForm();
    this.getDealers();
  }

  ngOnInit() {
    if (this.loggedInUserType === 'dowcview' || this.loggedInUserType === 'adminview') {
      this.router.navigate(['/products'], { replaceUrl: true });
    }
  }

  private createForm() {

    this.dealerGroupForm = this.formBuilder.group({
      name: [
        this.dealerGroup ? this.dealerGroup.name : '',
        [Validators.required]
      ],
      dealers: [
        this.dealerGroup ? this.dealerGroup.dealers : '',
        [Validators.required]
      ],
      website: [this.dealerGroup ? this.dealerGroup.website : '' ],
      appName: [this.dealerGroup ? this.dealerGroup.appName : '' ],
      domain: [this.dealerGroup ? this.dealerGroup.domain : '' ],
      baseurl: [this.dealerGroup ? this.dealerGroup.baseurl : '' ],
      portal: [this.dealerGroup ? this.dealerGroup.portal : '' ],
      appLogo: [this.dealerGroup ? this.dealerGroup.appLogo : '' ],
      logo: [this.dealerGroup ? this.dealerGroup.logo : '' ],
      emailer: [this.dealerGroup ? this.dealerGroup.emailer : '' ],
      mailerKey: [this.dealerGroup ? this.dealerGroup.mailerKey : '' ],
      senderMail: [this.dealerGroup ? this.dealerGroup.senderMail : '' ],
      notificationSenderMail: [this.dealerGroup ? this.dealerGroup.notificationSenderMail : '' ],
      supportMail: [this.dealerGroup ? this.dealerGroup.supportMail : '' ],
      HelpMail: [this.dealerGroup ? this.dealerGroup.HelpMail : '' ],
      notifyClaimMail: [this.dealerGroup ? this.dealerGroup.notifyClaimMail : '' ],
      dealersNotifyMail: [this.dealerGroup ? this.dealerGroup.dealersNotifyMail : '' ],
      notifyToDefault: [(this.dealerGroup  && this.dealerGroup.notifyToDefault === "enable") ? true : false],
      defaultMail: [this.dealerGroup ? this.dealerGroup.defaultMail : '' ],
      MAIL_FLAG: [(this.dealerGroup && this.dealerGroup.MAIL_FLAG === "enable") ? true : false],
      MailFooter: [this.dealerGroup ? this.dealerGroup.MailFooter : '' ],
      stoneEagleAPI: [this.dealerGroup ? this.dealerGroup.stoneEagleAPI : '' ],
      primaryColor: [this.dealerGroup ? this.dealerGroup.primaryColor : '' ],
      secondaryColor: [this.dealerGroup ? this.dealerGroup.secondaryColor : '' ],
      status: [this.dealerGroup ? this.dealerGroup.status : 'active',
        [Validators.required]
      ],
      config: [this.dealerGroup ? this.dealerGroup.config : 'inactive',
        [Validators.required]
      ],
      dealer: [''],
    });
    //this.formControlsValueChanges();
    if (this.dealerGroup) {
      this.getDealersList();
    }

    this.dealerGroupForm.get('dealer').valueChanges.subscribe(values => {
      this.checkDealer();
    });

  }

  ngAfterViewInit() {
    $("app-required-indication").each(function (index, element) {
      var text = element.innerHTML;
      element.innerHTML = text.replace('\n<!--', '<!--');
    });
    this.utilService.updateDomainCss();
  }

  formControlsValueChanges() {
  }

  submitData() {
    // this.onSubmitStepChange.emit();
    // if (this.selectedCustomer) {
    // this.isLoading = true;
    let payload = this.dealerGroupForm.value;
    payload = this.utilService.removeEmpty(payload);
    payload.MAIL_FLAG = (payload.MAIL_FLAG) ? 'enable' : 'disable';
    payload.notifyToDefault = (payload.notifyToDefault) ? 'enable' : 'disable';
    delete payload.dealer;

    if (this.dealerGroup) {
      this.customerService
        .updateDealerGroup(this.dealerGroupId, payload)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe((response: any) => {
          this.toastrService.info('Dealer Group Created.');
          this.router.navigate(['/dealergroup']);
        });
    } else {
      this.customerService
        .createDealerGroup(payload)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe((response: any) => {
          this.toastrService.info('Dealer Group updated');
          this.router.navigate(['/dealergroup']);
        });
    }
  }

  getDealers() {
    this.customerService
      .getallDealers()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.records) {
          this.dealersList = response.records;
          this.getDealersList();
        }
      });
  }

  getDealerGroup(dealerGroupId: string) {
    this.customerService
      .getDealerGroupDetail(dealerGroupId)
      .subscribe((response: any) => {
        if (response.records) {
          this.dealerGroup = response.records;
          this.createForm();
          this.getDealersList();
        } else if (this.isEditMode) {
          this.router.navigate(['/dealergroup']);
        }
      });
  }


  cancelClick() {
    this.router.navigate(['/dealergroup']);
  }

  async openHelpPopup() {
    const modalRef = await this.modalService.open(ClaimHelpComponent);
  }

  omit_special_char(event: any) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k <= 122) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }


  omit_special_Mailchar(event: any) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k <= 122) || k === 46 || k === 64 || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }


  checkDealer() {
    if (typeof this.dealerGroupForm.controls['dealer'].value !== 'object') {
      this.invalidDealer = true;
    } else {
      this.invalidDealer = false;
    }
  }

  addDealer() {
    if (typeof this.dealerGroupForm.controls['dealer'].value === 'object') {
      let dealer:any = this.dealerGroupForm.controls['dealer'].value;
      this.customerService
      .checkDealerGroupByDealer(dealer._id)
      .subscribe((response: any) => {
      //  console.log(response)
       if(response && response.data){
        this.toastrService.warning(dealer.name +' already added in '+response.data.name+' Dealer');
        this.dealerGroupForm.controls.dealer.setValue('');
       } else {
        this.addAfterVerify();
       }
      });
    }   
  }
  
  addAfterVerify(){
    if (typeof this.dealerGroupForm.controls['dealer'].value === 'object') {
      let dealer:any = this.dealerGroupForm.controls['dealer'].value;
      let avail = true;
      this.dealerGroupList.forEach((val: any, index: number) => {
        if (dealer._id.toString() === val._id.toString()) {
          avail = false;
        }
      });
      if (avail) {
        this.dealerGroupList.push(this.dealerGroupForm.controls['dealer'].value);
      }      
      this.dealerGroupForm.controls.dealer.setValue('');
    }
    let dealers: any = [];
    this.dealerGroupList.forEach((val: any, index: number) => {
      dealers.push({ dealerId: val._id });
    });
    this.dealerGroupForm.controls.dealers.setValue(dealers);
  }

  removeDealer(index:string) {
    this.dealerGroupForm.markAsDirty();
    this.dealerGroupList.splice(index, 1);;
    let dealers: any = [];
    this.dealerGroupList.forEach((val: any, index: number) => {
      dealers.push({ dealerId: val._id });
    });
    this.dealerGroupForm.controls.dealers.setValue(dealers);
  }

  getDealersList() {
    if (
      this.isEditMode &&
      this.dealerGroup &&
      this.dealerGroup.dealers &&
      this.dealerGroup.dealers.length > 0 && this.dealerGroupList.length === 0 && this.dealersList
    ) {
      let list = this.dealersList;
      let dealers: any = [];
      this.dealerGroup.dealers.forEach((val: any, index: number) => {
        list.forEach((dealer: any, index: number) => {
          if (dealer._id && val.dealerId && (dealer._id.toString() === val.dealerId.toString())) {
            dealers.push(dealer);
          }
        });
      });
      this.dealerGroupList = dealers;
    }
  }


}
