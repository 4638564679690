import { Component, OnInit, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { ClaimService } from '@app/core/service/claim.service';
import { environment } from '../../../environments/environment';
import { UtilService, LOCAL_VAR } from '@app/core';
import { ClaimHelpComponent } from '@app/shared/modals/claim-help/claim-help.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  isLoading = false;
  queryParams: any;
  error: any;
  appname = environment.name;
  applogo = environment.logo;
  productname = '';
  user: any;
  loggedInUserType = '';
  claims = { gap: 0, lease: 0, keyfetch: 0, shield: 0, debitcancel: 0, reimburse: 0, theft: 0, gap_void: 0, lease_void: 0, keyfetch_void: 0, shield_void: 0, debitcancel_void: 0, reimburse_void: 0, theft_void: 0 };
  public credentials$ = new EventEmitter<Authentication.Credentials>();
  constructor(
    public router: Router,
    private modalService: NgbModal,
    private authenticationService: AuthenticationService,
    private claimService: ClaimService,
    public utilService: UtilService
  ) {

    this.user = this.authenticationService.credentials.user;
    this.loggedInUserType = this.user.userRoleId.slug;


  }

  ngOnInit() {
   // if (this.loggedInUserType === 'customer') {
      this.getProdutsList();
    //}
  }

  ngAfterViewInit() {
    var domainconfig = JSON.parse(localStorage.getItem(LOCAL_VAR.domainconfig));
    if(domainconfig){
      if(domainconfig.secondaryColor){
       this.utilService.changeCssAttribute('card-body','background-color',domainconfig.primaryColor);
      }
    } 
  }

  selectProduct(product: string) {
    switch (product) {
      case 'gap':
        localStorage.setItem(LOCAL_VAR.selectedProduct, 'gap');
        if (this.loggedInUserType !== 'customer') {
          this.router.navigate(['/dashboard'], {
            replaceUrl: true
          });
        } else {
          this.router.navigate([(this.claims.gap != 0 || this.claims.gap_void != 0) ? '/claim':'/claim/create'], {
            replaceUrl: true
          });
        }
        break;
      case 'lease':
        localStorage.setItem(LOCAL_VAR.selectedProduct, 'lease');
        this.router.navigate([(this.claims.lease != 0 || this.claims.lease_void != 0) ? '/lease-list':'/lease-create'], {
          replaceUrl: true
        });
        break;
      case 'keyfetch':
        localStorage.setItem(LOCAL_VAR.selectedProduct, 'keyfetch');
        this.router.navigate([(this.claims.keyfetch != 0 || this.claims.keyfetch_void != 0) ? '/keyprotection-list':'/keyprotection-create'], {
          replaceUrl: true
        });
        break;
      case 'shield':
        localStorage.setItem(LOCAL_VAR.selectedProduct, 'shield');
        this.router.navigate([(this.claims.shield != 0 || this.claims.shield_void != 0) ? '/360shield-list':'/360shield-create'], {
          replaceUrl: true
        });
        break;
      case 'debitcancellation':
          localStorage.setItem(LOCAL_VAR.selectedProduct, 'debitcancellation');
          this.router.navigate([(this.claims.debitcancel != 0 || this.claims.debitcancel_void != 0) ? '/debitcancellation-list':'/debitcancellation-create'], {
            replaceUrl: true
          });
       break;
      case 'reimburse':
          localStorage.setItem(LOCAL_VAR.selectedProduct, 'reimburse');
          this.router.navigate([(this.claims.reimburse != 0 || this.claims.reimburse_void != 0) ? '/reimburse-list':'/reimburse-create'], {
            replaceUrl: true
          });
       break;
      case 'theft':
          localStorage.setItem(LOCAL_VAR.selectedProduct, 'theft');
          this.router.navigate([(this.claims.theft != 0 || this.claims.theft_void != 0) ? '/theft-list':'/theft-create'], {
            replaceUrl: true
          });
       break;
    }

  }

  getProdutsList() {
    this.isLoading = true;
    this.claimService
      .getProducts()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response: any) => {
        if (response) {
          this.claims = response;
        }
      });

  }

  async openHelpPopup() {
    const modalRef = await this.modalService.open(ClaimHelpComponent);
  }

}
