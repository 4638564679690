var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { takeUntil, finalize } from 'rxjs/operators';
import { ClaimService } from '@app/core/service/claim.service';
import { ReimburseService } from '@app/core/service/reimburse.service';
import { UtilService, LOCAL_VAR } from '@app/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { FormBuilder } from '@angular/forms';
import { ClaimHelpComponent } from '@app/shared/modals/claim-help/claim-help.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
var ReimburseViewComponent = /** @class */ (function () {
    function ReimburseViewComponent(modalService, toastrService, router, route, utilService, authenticationService, claimService, ReimburseService, formBuilder) {
        var _this = this;
        this.modalService = modalService;
        this.toastrService = toastrService;
        this.router = router;
        this.route = route;
        this.utilService = utilService;
        this.authenticationService = authenticationService;
        this.claimService = claimService;
        this.ReimburseService = ReimburseService;
        this.formBuilder = formBuilder;
        this.unsubscribe = new Subject();
        this.isLoading = false;
        this.loggedInUserType = null;
        this.claimId = null;
        this.selectedCustomer = null;
        this.claims = [];
        this.history = [];
        this.rejectEdit = '';
        this.baseUrl = '';
        this.appname = environment.name;
        this.user = this.authenticationService.credentials.user;
        this.authenticationService.credentials$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(function (credentials) {
            _this.user = credentials.user;
        });
        this.loggedInUserType = this.user.userRoleId.slug;
        this.claimId = this.route.snapshot.params['claimId'];
        this.getClaim();
        this.selectedCustomer = localStorage.getItem(LOCAL_VAR.selectedCustomer);
        this.selectedCustomer = JSON.parse(this.selectedCustomer);
        this.baseUrl = environment.s3Url;
    }
    ReimburseViewComponent.prototype.ngOnInit = function () { };
    ReimburseViewComponent.prototype.ngAfterViewInit = function () {
        this.utilService.updateDomainCss();
    };
    ReimburseViewComponent.prototype.getClaim = function () {
        var _this = this;
        this.isLoading = true;
        this.ReimburseService
            .getById(this.claimId)
            .pipe(finalize(function () {
            _this.isLoading = false;
        }))
            .subscribe(function (response) {
            if (response) {
                if (response.records) {
                    _this.claims = response.records;
                    _this.history = response.history ? response.history : null;
                    if (_this.claims.rejectEdit === true && (_this.loggedInUserType === 'superadmin' || _this.loggedInUserType === 'dowcsuperadmin')) {
                        _this.rejectEdit = 'allow';
                    }
                    _this.getClaimDomainInfo();
                }
                else {
                    localStorage.setItem(LOCAL_VAR.selectedProduct, 'reimburse');
                    _this.router.navigate(['/reimburse-list']);
                }
            }
        });
        this.statusChange = this.formBuilder.group({
            status: ['']
        });
    };
    ReimburseViewComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    };
    ReimburseViewComponent.prototype.downloadDocuments = function (claimId) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.isLoading = true;
                this.claimService.downloadDocuments(claimId).subscribe(function (respData) {
                    _this.downLoadFile(respData.records);
                    _this.toastrService.info('File has been downloaded.');
                    _this.isLoading = false;
                }, function (error) {
                    _this.toastrService.warning('Sorry! Documents not found.');
                    _this.isLoading = false;
                });
                return [2 /*return*/];
            });
        });
    };
    ReimburseViewComponent.prototype.downLoadFile = function (data) {
        var aTag = document.createElement('a');
        document.body.appendChild(aTag);
        aTag.style.display = 'none';
        var url = environment.serverUrl.slice(0, -3) + '/downloads/' + data;
        aTag.href = url;
        aTag.setAttribute('target', '_blank');
        aTag.click();
        document.body.removeChild(aTag);
    };
    ReimburseViewComponent.prototype.changeStatus = function (claimId) {
        var _this = this;
        var payload = {
            status: this.statusChange.value.status
        };
        this.ReimburseService
            .changeStatus(claimId, payload)
            .subscribe(function (response) {
            if (response) {
                _this.toastrService.info('Claim Status Updated');
                setTimeout(function () {
                    _this.router.navigate(['/reimburse-list']);
                }, 1000);
            }
            else {
                _this.toastrService.warning('something went Wrong');
            }
        });
    };
    ReimburseViewComponent.prototype.openHelpPopup = function () {
        return __awaiter(this, void 0, void 0, function () {
            var modalRef;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalService.open(ClaimHelpComponent)];
                    case 1:
                        modalRef = _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ReimburseViewComponent.prototype.getClaimDomainInfo = function () {
        var _this = this;
        if (this.claims.domain) {
            var hostname = window.location.hostname;
            if (hostname != this.claims.domain) {
                this.authenticationService.getconfig(this.claims.domain)
                    .subscribe(function (response) {
                    if (response && response.records) {
                        _this.claimDomain = response.records;
                        setTimeout(function () {
                            _this.utilService.changeCssAttribute('domain-logo', 'background-color', _this.claimDomain.primaryColor);
                            _this.utilService.changeCssAttribute('domain-logo', 'color', _this.claimDomain.secondaryColor);
                            _this.utilService.changeCssAttribute('domain-bg', 'background-color', _this.claimDomain.primaryColor);
                            _this.utilService.changeCssAttribute('domain-bg', 'color', _this.claimDomain.secondaryColor);
                        }, 200);
                    }
                });
            }
        }
    };
    return ReimburseViewComponent;
}());
export { ReimburseViewComponent };
