/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./home.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../shared/table-loader/table-loader.component.ngfactory";
import * as i4 from "../../shared/table-loader/table-loader.component";
import * as i5 from "./home.component";
import * as i6 from "@angular/router";
import * as i7 from "@ng-bootstrap/ng-bootstrap";
import * as i8 from "../../core/authentication/authentication.service";
import * as i9 from "../../core/service/claim.service";
import * as i10 from "../../core/service/util.service";
var styles_HomeComponent = [i0.styles];
var RenderType_HomeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HomeComponent, data: {} });
export { RenderType_HomeComponent as RenderType_HomeComponent };
function View_HomeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "card-text text-right"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["Claims: ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.claims.gap; _ck(_v, 1, 0, currVal_0); }); }
function View_HomeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "card-text text-right"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["Claims: ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.claims.lease; _ck(_v, 1, 0, currVal_0); }); }
function View_HomeComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "card-text text-right"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["Claims: ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.claims.keyfetch; _ck(_v, 1, 0, currVal_0); }); }
function View_HomeComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "card-text text-right"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["Claims: ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.claims.shield; _ck(_v, 1, 0, currVal_0); }); }
function View_HomeComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "card-text text-right"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["Claims: ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.claims.debitcancel; _ck(_v, 1, 0, currVal_0); }); }
function View_HomeComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "card-text text-right"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["Claims: ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.claims.reimburse; _ck(_v, 1, 0, currVal_0); }); }
function View_HomeComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "card-text text-right"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["Claims: ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.claims.theft; _ck(_v, 1, 0, currVal_0); }); }
export function View_HomeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 59, "div", [["class", "row agent-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 58, "div", [["class", "column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 49, "div", [["class", "card-group m-b-30 p-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "card m-2 curr-pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectProduct("gap") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", "Gap Claim"], ["class", "card-img-top"], ["src", "../assets/images/products/gap.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "card-title1 text-center mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["GAP Claim"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomeComponent_1)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 6, "div", [["class", "card m-2 curr-pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectProduct("lease") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "img", [["alt", "Lease Claim"], ["class", "card-img-top"], ["src", "../assets/images/products/lewt.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 4, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "card-title1 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Lease Excess Wear & Tear Claim"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomeComponent_2)), i1.ɵdid(16, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 6, "div", [["class", "card m-2 curr-pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectProduct("keyfetch") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 0, "img", [["alt", "Key Protection Claim"], ["class", "card-img-top"], ["src", "../assets/images/products/key-protection.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 4, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 1, "div", [["class", "card-title1 text-center mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Key Protection Claim"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomeComponent_3)), i1.ɵdid(23, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(24, 0, null, null, 6, "div", [["class", "card m-2 curr-pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectProduct("shield") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 0, "img", [["alt", "360 Shield Claim"], ["class", "card-img-top"], ["src", "../assets/images/products/360shield.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 4, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 1, "div", [["class", "card-title1 text-center mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["360 Shield Claim"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomeComponent_4)), i1.ɵdid(30, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(31, 0, null, null, 6, "div", [["class", "card m-2 curr-pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectProduct("debitcancellation") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(32, 0, null, null, 0, "img", [["alt", "Debt cancellation"], ["class", "card-img-top p-4 mt-4"], ["src", "../assets/images/products/debt-cancel.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(33, 0, null, null, 4, "div", [["class", "card-body mt-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(34, 0, null, null, 1, "div", [["class", "card-title1 text-center mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Debt Cancellation"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomeComponent_5)), i1.ɵdid(37, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(38, 0, null, null, 6, "div", [["class", "card m-2 curr-pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectProduct("reimburse") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(39, 0, null, null, 0, "img", [["alt", "Debt cancellation"], ["class", "card-img-top p-4"], ["src", "../assets/images/products/reimbursement.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(40, 0, null, null, 4, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(41, 0, null, null, 1, "div", [["class", "card-title1 text-center mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Reimbursement"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomeComponent_6)), i1.ɵdid(44, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(45, 0, null, null, 6, "div", [["class", "card m-2 curr-pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectProduct("theft") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(46, 0, null, null, 0, "img", [["alt", "Theft"], ["class", "card-img-top p-4"], ["src", "../assets/images/products/theft.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(47, 0, null, null, 4, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(48, 0, null, null, 1, "div", [["class", "card-title1 text-center mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Theft Claim"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomeComponent_7)), i1.ɵdid(51, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(52, 0, null, null, 5, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(53, 0, null, null, 4, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(54, 0, null, null, 3, "div", [["class", "help-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(55, 0, null, null, 2, "div", [["class", "help-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(56, 0, null, null, 1, "p", [["class", "help-text"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openHelpPopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(57, 0, null, null, 0, "i", [["class", "hellp-popup-icons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(58, 0, null, null, 1, "app-table-loader", [], null, null, null, i3.View_TableLoaderComponent_0, i3.RenderType_TableLoaderComponent)), i1.ɵdid(59, 114688, null, 0, i4.TableLoaderComponent, [], { isLoading: [0, "isLoading"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.loggedInUserType === "customer"); _ck(_v, 9, 0, currVal_0); var currVal_1 = (_co.loggedInUserType === "customer"); _ck(_v, 16, 0, currVal_1); var currVal_2 = (_co.loggedInUserType === "customer"); _ck(_v, 23, 0, currVal_2); var currVal_3 = (_co.loggedInUserType === "customer"); _ck(_v, 30, 0, currVal_3); var currVal_4 = (_co.loggedInUserType === "customer"); _ck(_v, 37, 0, currVal_4); var currVal_5 = (_co.loggedInUserType === "customer"); _ck(_v, 44, 0, currVal_5); var currVal_6 = (_co.loggedInUserType === "customer"); _ck(_v, 51, 0, currVal_6); var currVal_7 = _co.isLoading; _ck(_v, 59, 0, currVal_7); }, null); }
export function View_HomeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-home", [], null, null, null, View_HomeComponent_0, RenderType_HomeComponent)), i1.ɵdid(1, 4308992, null, 0, i5.HomeComponent, [i6.Router, i7.NgbModal, i8.AuthenticationService, i9.ClaimService, i10.UtilService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomeComponentNgFactory = i1.ɵccf("app-home", i5.HomeComponent, View_HomeComponent_Host_0, {}, {}, []);
export { HomeComponentNgFactory as HomeComponentNgFactory };
