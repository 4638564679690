var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, ElementRef } from '@angular/core';
import { IpDetailComponent } from '@app/shared/modals/ip-detail/ip-detail.component';
import { UtilService, LOCAL_VAR } from '@app/core';
import { CustomerService } from '@app/core/service/customer.service';
import { finalize } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
var AuditComponent = /** @class */ (function () {
    function AuditComponent(router, modalService, utilService, customerService, formBuilder, authenticationService) {
        this.router = router;
        this.modalService = modalService;
        this.utilService = utilService;
        this.customerService = customerService;
        this.formBuilder = formBuilder;
        this.authenticationService = authenticationService;
        this.isLoading = false;
        this.isFileterCollapse = true;
        this.actionList = ["login", "create-claim", "update-claim-step-1", "update-claim-step-2", "update-claim-step-3", "update-claim-step-4", "update-claim-step-5", "update-claim-step-6", "update-claim-step-7", "forced-status-change", "claim-deleted", "claim-rejected", "register", "claim-approved", "user-delete", "account-activation"];
        this.sortDir = -1;
        this.perPage = 10;
        this.currentPage = 1;
        this.sortBy = 'updateTime';
        this.loggedInUserType = null;
        this.pager = {
            perPage: 10,
            currentPage: 1,
            Totalrecords: 0,
            TotalPages: 0
        };
        this.selectedCustomer = null;
        this.user = this.authenticationService.credentials.user;
        this.loggedInUserType = this.user.userRoleId.slug;
        localStorage.setItem(LOCAL_VAR.selectedCustomer, null);
        localStorage.setItem(LOCAL_VAR.selectedProduct, null);
        this.createForm();
        this.getActions();
    }
    AuditComponent.prototype.ngOnInit = function () {
        if (this.loggedInUserType !== 'dowcsuperadmin' && this.user.audit != 1) {
            this.router.navigate(['/products']);
        }
    };
    AuditComponent.prototype.ngAfterViewInit = function () {
        this.utilService.updateDomainCss();
    };
    AuditComponent.prototype.createForm = function () {
        var _this = this;
        this.isLoading = true;
        this.fiterForm = this.formBuilder.group({
            cid: [''],
            rid: [''],
            name: [''],
            email: [''],
            type: [''],
            date_from: [''],
            date_to: [''],
            sortFieldFilter: [''],
            sortByFilter: [''],
            perPage: 10,
            domain: ['']
        });
        //this.actionList = ["create-claim", "update-claim-step-1", "update-claim-step-2", "update-claim-step-3", "update-claim-step-4", "update-claim-step-5", "update-claim-step-6", "update-claim-step-7", "claim-approved", "forced-status-change", "claim-deleted", "claim-exception-accept", "register", "account-activation", "user-delete", "login", "profile-update", "password-update", "create-dealer", "update-dealer"];
        this.fiterForm.valueChanges.subscribe(function (values) {
            _this.pager.currentPage = 1;
            _this.getAllAuditByFilter();
        });
        // this.customerService
        //   .getAllCustomers()
        //   .pipe(
        //     finalize(() => {
        //       this.isLoading = false;
        //     })
        //   )
        //   .subscribe((response: any) => {
        //     this.customerList = response.records;
        //   });
        this.getAudit(this.pager.currentPage, {});
    };
    AuditComponent.prototype.sort = function (sortByVal) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (this.sortBy === sortByVal) {
                    this.sortDir = this.sortDir === 1 ? -1 : 1;
                }
                else {
                    this.sortDir = 1;
                }
                this.fiterForm.value.sortFieldFilter = sortByVal;
                this.fiterForm.value.sortByFilter = this.sortDir;
                this.sortBy = sortByVal;
                this.getAllAuditByFilter();
                return [2 /*return*/];
            });
        });
    };
    AuditComponent.prototype.getActions = function () {
        var _this = this;
        this.customerService
            .getAuditActions()
            .subscribe(function (response) {
            _this.actionList = response;
        });
    };
    AuditComponent.prototype.getAllAuditByFilter = function () {
        var payload = this.fiterForm.value;
        payload = this.utilService.removeWhiteSpace(payload);
        if (payload.date_from) {
            payload.datetime_from = DateTime.fromObject(payload.date_from).toISO();
        }
        if (payload.date_to) {
            payload.datetime_to = DateTime.fromObject(payload.date_to).toISO();
        }
        this.pager.perPage = payload.perPage;
        delete payload.perPage;
        this.getAudit(this.pager.currentPage, payload);
    };
    AuditComponent.prototype.getAudit = function (page, payload) {
        var _this = this;
        payload.currentPage = page;
        payload.perPage = this.pager.perPage;
        //payload = this.utilService.removeWhiteSpace(payload);
        this.isLoading = true;
        this.customerService
            .getAllAuditsByFilter(payload)
            .pipe(finalize(function () {
            _this.isLoading = false;
        }))
            .subscribe(function (response) {
            var list = [];
            response.records.forEach(function (val) {
                var type = val.type;
                var url = '';
                if (type.match(/dealergroup/g)) {
                    url = "dealergroup-edit/" + val.rId;
                }
                else if (type.match(/debit/g)) {
                    url = 'debitcancellationview/' + val.rId;
                }
                else if (type.match(/360shield/g)) {
                    url = '360shieldview/' + val.rId;
                }
                else if (type.match(/lease/g)) {
                    url = 'leaseview/' + val.rId;
                }
                else if (type.match(/production/g) || type.match(/protection/g)) {
                    url = 'keyprotectionview/' + val.rId;
                }
                else if (type.match(/customer/g) || type.match(/user/g) || type.match(/send-code/g) || type.match(/send-code/g)) {
                    url = 'user/edit/' + val.rId;
                }
                else if (type.match(/dealer/g)) {
                    //url='/user/edit/'+val.rId;
                }
                else if (type.match(/claim/g) || type.match(/forced-status-change/g)) {
                    url = 'claim/preview/' + val.rId;
                }
                val.url = url;
                list.push(val);
            });
            _this.auditList = list;
            _this.renderPagination(response.page, response.total, response.totalPage);
        });
    };
    AuditComponent.prototype.goPage = function () {
        var goto = this.nameInput.nativeElement.value;
        this.setPage(goto);
        this.nameInput.nativeElement.value = '';
    };
    AuditComponent.prototype.setPage = function (page) {
        if (0 < page && page <= this.pager.TotalPages && page != this.pager.currentPage) {
            this.pager.currentPage = page;
            this.getAllAuditByFilter();
        }
    };
    AuditComponent.prototype.renderPagination = function (page, total, totalPage) {
        this.pager.currentPage = page;
        this.pager.Totalrecords = total;
        this.pager.TotalPages = totalPage;
        this.PageList = this.utilService.getPageList(this.pager.TotalPages, this.pager.currentPage);
    };
    AuditComponent.prototype.ipDetails = function (ip) {
        var _this = this;
        this.customerService.
            getIpdetails(ip)
            .pipe(finalize(function () {
            _this.isLoading = false;
        }))
            .subscribe(function (response) {
            _this.showIpdetails(ip, response);
        });
    };
    AuditComponent.prototype.showIpdetails = function (ip, details) {
        return __awaiter(this, void 0, void 0, function () {
            var modalRef, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalService.open(IpDetailComponent)];
                    case 1:
                        modalRef = _a.sent();
                        modalRef.componentInstance.ip = ip;
                        if (details.status == "success") {
                            modalRef.componentInstance.country = details.country;
                            modalRef.componentInstance.countryCode = details.countryCode;
                            modalRef.componentInstance.regionName = details.regionName;
                            modalRef.componentInstance.region = details.region;
                            modalRef.componentInstance.city = details.city;
                        }
                        else if (details.message) {
                            modalRef.componentInstance.message = details.message;
                        }
                        return [4 /*yield*/, modalRef.result.catch(function (e) { return e; })];
                    case 2:
                        result = _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AuditComponent.prototype.omit_special_char = function (event) {
        var k;
        k = event.charCode; //         k = event.keyCode;  (Both can be used)
        return ((k > 64 && k < 91) || (k > 96 && k <= 122) || k == 8 || k == 32 || (k >= 48 && k <= 57));
    };
    AuditComponent.prototype.omit_special_Mailchar = function (event) {
        var k;
        k = event.charCode; //         k = event.keyCode;  (Both can be used)
        return ((k > 64 && k < 91) || (k > 96 && k <= 122) || k === 46 || k === 64 || k == 8 || k == 32 || (k >= 48 && k <= 57));
    };
    return AuditComponent;
}());
export { AuditComponent };
