<div class="row agent-container">
  <div class="column">
    <div class="card-group m-b-30 p-5">
      <div class="card m-2 curr-pointer" (click)="selectProduct('gap')">
        <img class="card-img-top" src="../assets/images/products/gap.png" alt="Gap Claim">
        <div class="card-body">
          <div class="card-title1 text-center mt-2">GAP Claim</div>
          <p class="card-text text-right" *ngIf="loggedInUserType==='customer'">Claims: {{claims.gap}}</p>
        </div>
      </div>
      <div class="card m-2 curr-pointer" (click)="selectProduct('lease')">
        <img class="card-img-top" src="../assets/images/products/lewt.png" alt="Lease Claim">
        <div class="card-body">
          <div class="card-title1 text-center">Lease Excess Wear & Tear Claim</div>
          <p class="card-text text-right" *ngIf="loggedInUserType==='customer'">Claims: {{claims.lease}}</p>
        </div>
      </div>
      <div class="card m-2 curr-pointer" (click)="selectProduct('keyfetch')">
        <img class="card-img-top" src="../assets/images/products/key-protection.png" alt="Key Protection Claim">
        <div class="card-body">
          <div class="card-title1 text-center mt-2">Key Protection Claim</div>
          <p class="card-text text-right" *ngIf="loggedInUserType==='customer'">Claims: {{claims.keyfetch}}</p>
        </div>
      </div>
      <div class="card m-2 curr-pointer" (click)="selectProduct('shield')">
        <img class="card-img-top" src="../assets/images/products/360shield.png" alt="360 Shield Claim">
        <div class="card-body">
          <div class="card-title1 text-center mt-2">360 Shield Claim</div>
          <p class="card-text text-right" *ngIf="loggedInUserType==='customer'">Claims: {{claims.shield}}</p>
        </div>
      </div>
      <div class="card m-2 curr-pointer" (click)="selectProduct('debitcancellation')">
        <img class="card-img-top p-4 mt-4" src="../assets/images/products/debt-cancel.png" alt="Debt cancellation">
        <div class="card-body mt-1">
          <div class="card-title1 text-center mt-2">Debt Cancellation</div>
          <p class="card-text text-right" *ngIf="loggedInUserType==='customer'">Claims: {{claims.debitcancel}}</p>
        </div>
      </div>
      <div class="card m-2 curr-pointer" (click)="selectProduct('reimburse')">
        <img class="card-img-top p-4" src="../assets/images/products/reimbursement.png" alt="Debt cancellation">
        <div class="card-body">
          <div class="card-title1 text-center mt-2">Reimbursement</div>
          <p class="card-text text-right" *ngIf="loggedInUserType==='customer'">Claims: {{claims.reimburse}}</p>
        </div>
      </div>
      <div class="card m-2 curr-pointer" (click)="selectProduct('theft')">
        <img class="card-img-top p-4" src="../assets/images/products/theft.png" alt="Theft">
        <div class="card-body">
          <div class="card-title1 text-center mt-2">Theft Claim</div>
          <p class="card-text text-right" *ngIf="loggedInUserType==='customer'">Claims: {{claims.theft}}</p>
        </div>
      </div>
      <!-- <div class="card">
      <div class="card-header">
        Products
      </div>
      <div class="container">
        <div class="vertical-center">
          <h3 class="text-center">Choose Product</h3>
          <div class="row  text-center">
            <a class="btn btn-primary p-5 mr-1 p-5 pb-0 mb-0 w-400" routerLink="/" (click)="selectProduct('gap')">
              GAP Claims
              <small *ngIf="loggedInUserType==='customer'" class="bg-seconday float-right"> <br />
                <br />Claims: {{claims.gap}}</small>
            </a>
            <a class="btn btn-primary p-5 ml-1 w-400" routerLink="/" (click)="selectProduct('lease')">
              Lease Excess Wear & Tear Claim
              <small *ngIf="loggedInUserType==='customer'" class="bg-seconday float-right"> <br />
                <br />Claims: {{claims.lease}}</small>
            </a>
          </div>
        </div>
      </div>
    </div> -->
    </div>
    <div class="col-12">
      <div class="card">
        <div class="help-container">
          <div class="help-section">
            <p class="help-text" (click)="openHelpPopup()">
              <i class="hellp-popup-icons"></i>
            </p>
          </div>
        </div>
      </div>
    </div>    
    <app-table-loader [isLoading]="isLoading"></app-table-loader>
  </div>