import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter } from '@angular/core';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var TheftService = /** @class */ (function () {
    function TheftService(httpClient) {
        this.httpClient = httpClient;
        this.claimData = null;
        this.claimData$ = new EventEmitter();
    }
    TheftService.prototype.add = function (payload) {
        return this.httpClient.post("/theftadd", payload).pipe(map(function (body) {
            return body;
        }));
    };
    TheftService.prototype.update = function (claimId, payload) {
        return this.httpClient.post("/theftupdate/" + claimId, payload).pipe(map(function (body) {
            return body;
        }));
    };
    TheftService.prototype.getByFilter = function (payload, customerId) {
        var params = customerId
            ? new HttpParams().set('customerId', "" + customerId)
            : new HttpParams();
        params = payload.claimDate_from
            ? params.append('claimDate_from', "" + payload.claimDate_from)
            : params;
        params = payload.claimDate_to
            ? params.append('claimDate_to', "" + payload.claimDate_to)
            : params;
        params = payload.email
            ? params.append('email', "" + payload.email)
            : params;
        params = payload.status
            ? params.append('status', "" + payload.status)
            : params;
        params = payload.customerName
            ? params.append('customerName', "" + payload.customerName)
            : params;
        params = payload.sortFieldFilter
            ? params.append('sortFieldFilter', "" + payload.sortFieldFilter)
            : params;
        params = payload.sortByFilter
            ? params.append('sortByFilter', "" + payload.sortByFilter)
            : params;
        params = payload.claimNumber
            ? params.append('claimNumber', "" + payload.claimNumber)
            : params;
        params = payload.sequence
            ? params.append('sequence', "" + payload.sequence)
            : params;
        params = payload.dealer
            ? params.append('dealer', "" + payload.dealer)
            : params;
        params = params.append('currentPage', "" + payload.currentPage);
        params = params.append('perPage', "" + payload.perPage);
        return this.httpClient.get("/theftclaims", { params: params }).pipe(map(function (body) {
            return body;
        }));
    };
    TheftService.prototype.getById = function (claimId) {
        return this.httpClient.get("/theft/" + claimId).pipe(map(function (body) {
            return body;
        }));
    };
    TheftService.prototype.acceptRequest = function (claimId, payload) {
        return this.httpClient.put("/theft/" + claimId + "/acceptrequest", payload).pipe(map(function (body) {
            return body;
        }));
    };
    TheftService.prototype.rejectRequest = function (claimId, payload, type) {
        return this.httpClient.put("/theft/" + claimId + "/" + type + "request", payload).pipe(map(function (body) {
            return body;
        }));
    };
    TheftService.prototype.changeStatus = function (claimId, payload) {
        return this.httpClient.put("/theft/" + claimId + "/changestatus", payload).pipe(map(function (body) {
            return body;
        }));
    };
    TheftService.prototype.deleteClaim = function (claimId) {
        return this.httpClient.delete("/theft/" + claimId).pipe(map(function (body) {
            return body;
        }));
    };
    TheftService.prototype.getAnalytics = function (payload) {
        var params = null;
        if (payload) {
            params = new HttpParams().set('from', "" + payload.from);
            params = params.append('to', "" + payload.to);
        }
        if (payload.dealer && payload.dealer !== '') {
            params = params.append('dealer', "" + payload.dealer);
        }
        return this.httpClient.get("/theftanalytics", { params: params }).pipe(map(function (body) {
            return body;
        }));
    };
    TheftService.ngInjectableDef = i0.defineInjectable({ factory: function TheftService_Factory() { return new TheftService(i0.inject(i1.HttpClient)); }, token: TheftService, providedIn: "root" });
    return TheftService;
}());
export { TheftService };
