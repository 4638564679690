var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationComponent } from '@app/shared/modals/confirmation';
import { STATES } from '@app/core/constants';
import { DateTime } from 'luxon';
import { CustomerService } from '@app/core/service/customer.service';
import { finalize, map, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { UtilService, CONFIRMATION_MSGS, LOCAL_VAR } from '@app/core';
import { Observable, Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { ClaimHelpComponent } from '@app/shared/modals/claim-help/claim-help.component';
import { TheftService } from '@app/core/service/theft.service';
import { ClaimService } from '@app/core/service/claim.service';
import { environment } from '@env/environment.local';
var TheftCreateComponent = /** @class */ (function () {
    function TheftCreateComponent(modalService, formBuilder, route, router, customerService, toastrService, utilService, claimService, Claims, authenticationService) {
        var _this = this;
        this.modalService = modalService;
        this.formBuilder = formBuilder;
        this.route = route;
        this.router = router;
        this.customerService = customerService;
        this.toastrService = toastrService;
        this.utilService = utilService;
        this.claimService = claimService;
        this.Claims = Claims;
        this.authenticationService = authenticationService;
        this.isLoading = false;
        this.states = STATES;
        this.isreivewMode = false;
        this.isEditMode = false;
        this.claimId = null;
        this.baseUrl = '';
        this.uploadedImg = {
            copyOfLimitedWarranty: null,
            profOfInstallation: null,
            copyOfOrinalBuyer: null,
            copyOfPoliceReport: null,
            copyOfPrimaryCarrierStatement: null,
            additionalReasonableDocument: null,
            profOfPaymentForExtend: null,
            profOfRentalReceipt: null
        };
        this.selectedCustomer = null;
        this.moreDoc = [];
        this.showmoreDocs = [];
        this.isCustomer = true;
        this.isRepairFacility = false;
        this.show_claims = false;
        this.getcontract_loading = false;
        this.enterBy = 'selling_dealer';
        this.onlyCustomer = false;
        this.enableForm = true;
        this.contractPhone = '';
        this.focus$ = new Subject();
        this.click$ = new Subject();
        //formatter = (result: string) => result.toUpperCase();
        this.dealers = function (text$) {
            return text$.pipe(debounceTime(200), distinctUntilChanged(), map(function (term) {
                return term.length < 3
                    ? []
                    : _this.dealersList
                        .filter(function (v) { return v.name.toLowerCase().indexOf(term.toLowerCase()) > -1; })
                        .slice(0, 10);
            }));
        };
        this.formatter = function (x) { return x.name; };
        this.user = this.authenticationService.credentials.user;
        this.loggedInUserType = this.user.userRoleId.slug;
        this.baseUrl = environment.s3Url;
        this.selectedCustomer = localStorage.getItem(LOCAL_VAR.selectedCustomer);
        this.selectedCustomer = JSON.parse(this.selectedCustomer);
        if (this.user.usertype) {
            this.enterBy = this.user.usertype;
        }
        else if (this.loggedInUserType == 'customer') {
            this.enterBy = 'contract_holder';
        }
        if (this.router.url.indexOf('review') !== -1) {
            this.isreivewMode = true;
        }
        else {
            this.isreivewMode = false;
        }
        if (this.router.url.indexOf('edit') !== -1) {
            this.isEditMode = true;
        }
        else {
            this.isEditMode = false;
        }
        this.enableForm = (this.enterBy == 'contract_holder' && this.isEditMode == false) ? false : true;
        this.route.queryParams.pipe().subscribe(function (params) {
            _this.queryParams = params;
        });
        if (this.isEditMode && this.route.snapshot.params['claimId']) {
            this.claimId = this.route.snapshot.params['claimId'];
        }
        if (this.claimId) {
            this.getLeaseDealbyId(this.claimId);
        }
        this.createForm();
        // this.getDealers();
    }
    TheftCreateComponent.prototype.ngOnInit = function () {
        if (this.loggedInUserType === 'dowcview' || this.loggedInUserType === 'adminview') {
            this.router.navigate(['/products'], { replaceUrl: true });
        }
    };
    TheftCreateComponent.prototype.createForm = function () {
        var _this = this;
        if (this.claimData) {
            this.uploadedImg.copyOfLimitedWarranty = this.claimData
                .copyOfLimitedWarranty
                ? this.claimData.copyOfLimitedWarranty
                : null;
            this.uploadedImg.profOfInstallation = this.claimData
                .profOfInstallation
                ? this.claimData.profOfInstallation
                : null;
            this.uploadedImg.copyOfOrinalBuyer = this.claimData
                .copyOfOrinalBuyer
                ? this.claimData.copyOfOrinalBuyer
                : null;
            this.uploadedImg.copyOfPoliceReport = this.claimData
                .copyOfPoliceReport
                ? this.claimData.copyOfPoliceReport
                : null;
            this.uploadedImg.copyOfPrimaryCarrierStatement = this.claimData
                .copyOfPrimaryCarrierStatement
                ? this.claimData.copyOfPrimaryCarrierStatement
                : null;
            this.uploadedImg.additionalReasonableDocument = this.claimData
                .additionalReasonableDocument
                ? this.claimData.additionalReasonableDocument
                : null;
            this.uploadedImg.profOfPaymentForExtend = this.claimData
                .profOfPaymentForExtend
                ? this.claimData.profOfPaymentForExtend
                : null;
            this.uploadedImg.profOfRentalReceipt = this.claimData
                .profOfRentalReceipt
                ? this.claimData.profOfRentalReceipt
                : null;
        }
        this.theftForm = this.formBuilder.group({
            contractNumber: [
                this.claimData ? this.claimData.contractNumber : '',
                [Validators.required]
            ],
            claimDate: [
                this.claimData
                    ? this.claimData.claimDate
                        ? DateTime.fromISO(this.claimData.claimDate).toObject()
                        : null
                    : '',
                [Validators.required]
            ],
            claimNumber: [
                this.claimData ? this.claimData.claimNumber : '',
                [Validators.required]
            ],
            customerName: [
                this.claimData ? this.claimData.customerName : '',
                [Validators.required]
            ],
            email: [
                this.claimData ? this.claimData.email : this.user.email,
                [Validators.required, Validators.pattern(/^\d{10}|\w+([\.+-]?\w+)*@\w+([\.-]?\w+)$/)]
            ],
            streetAddress: [
                this.claimData ? this.claimData.streetAddress : ''
            ],
            city: [this.claimData ? this.claimData.city : ''],
            state: [this.claimData ? this.claimData.state : ''],
            zipCode: [
                this.claimData ? this.claimData.zipCode : '',
                Validators.compose([
                    Validators.minLength(5),
                    Validators.maxLength(5)
                ])
            ],
            phoneNumber: [
                this.claimData ? this.claimData.phoneNumber : '',
                Validators.compose([
                    Validators.required,
                    Validators.minLength(10),
                    Validators.maxLength(12)
                ])
            ],
            // dealer: [
            //   this.claimData
            //     ? this.claimData.dealer == null
            //       ? ''
            //       : {
            //         _id: this.claimData.dealer._id,
            //         name: this.claimData.dealer.name
            //       }
            //     : '',
            //   /* this.claimData ? this.claimData.dealer : '', */
            //   [Validators.required]
            // ],
            amount: [
                this.claimData && this.claimData.amount
                    ? this.claimData.amount
                    : null
            ],
            year: [
                this.claimData && this.claimData.year ? this.claimData.year : null,
                Validators.compose([
                    Validators.required,
                    Validators.minLength(4),
                    Validators.maxLength(4)
                ])
            ],
            make: [
                this.claimData && this.claimData.make ? this.claimData.make : null,
                Validators.required
            ],
            model: [
                this.claimData && this.claimData.model ? this.claimData.model : null,
                Validators.required
            ],
            currentMileage: [
                this.claimData && this.claimData.currentMileage
                    ? this.claimData.currentMileage
                    : null,
                Validators.required
            ],
            vinNumber: [
                this.claimData && this.claimData.vinNumber
                    ? this.claimData.vinNumber
                    : null,
                Validators.compose([Validators.required, Validators.minLength(17)])
            ],
            copyOfLimitedWarranty: [
                this.claimData ? this.claimData.copyOfLimitedWarranty : '',
                [Validators.required]
            ],
            profOfInstallation: [
                this.claimData ? this.claimData.profOfInstallation : '',
                [Validators.required]
            ],
            copyOfOrinalBuyer: [
                this.claimData ? this.claimData.copyOfOrinalBuyer : '',
                [Validators.required]
            ],
            copyOfPoliceReport: [
                this.claimData ? this.claimData.copyOfPoliceReport : '',
                [Validators.required]
            ],
            copyOfPrimaryCarrierStatement: [
                this.claimData ? this.claimData.copyOfPrimaryCarrierStatement : '',
                [Validators.required]
            ],
            additionalReasonableDocument: [
                this.claimData ? this.claimData.additionalReasonableDocument : '',
                [Validators.required]
            ],
            onVacation: [
                this.claimData ? this.claimData.onVacation : '',
                [Validators.required]
            ],
            profOfPaymentForExtend: [
                this.claimData ? this.claimData.profOfPaymentForExtend : ''
            ],
            isCarRent: [
                this.claimData ? this.claimData.isCarRent : '',
                [Validators.required]
            ],
            profOfRentalReceipt: [
                this.claimData ? this.claimData.profOfRentalReceipt : ''
            ],
            reasonForReplace: [
                this.claimData ? this.claimData.reasonForReplace : '',
                [Validators.required]
            ],
            moreDocs: this.formBuilder.array([]),
            filter1select: [''],
            filter1: [],
            filter2select: [''],
            filter2: []
        });
        this.formControlsValueChanges();
        this.moreDocs = this.theftForm.get('moreDocs');
        if (this.isEditMode &&
            this.claimData &&
            this.claimData.moreDocs &&
            this.claimData.moreDocs.length > 0) {
            this.claimData.moreDocs.forEach(function (addition, index) {
                _this.moreDocs.push(_this.createmoreDocsForm(addition));
                _this.moreDoc.push(addition.url);
                _this.showmoreDocs.push(addition.url ? true : false);
            });
        }
        else {
            // this.moreDocs.push(this.createmoreDocsForm());
        }
        // this.theftForm.get('dealer').valueChanges.subscribe(values => {
        //   this.checkDealer();
        // });
    };
    TheftCreateComponent.prototype.ngAfterViewInit = function () {
        $("app-required-indication").each(function (index, element) {
            var text = element.innerHTML;
            element.innerHTML = text.replace('\n<!--', '<!--');
        });
        var domainconfig = JSON.parse(localStorage.getItem(LOCAL_VAR.domainconfig));
        if (domainconfig) {
            if (domainconfig.secondaryColor) {
                this.utilService.changeCssAttribute('card-header', 'background-color', domainconfig.secondaryColor);
            }
        }
    };
    TheftCreateComponent.prototype.formControlsValueChanges = function () {
        var _this = this;
        this.theftForm.get('onVacation').valueChanges.subscribe(function (value) {
            if (value === 'Yes') {
                _this.theftForm
                    .get('profOfPaymentForExtend')
                    .setValidators([Validators.required]);
            }
            else {
                _this.theftForm
                    .get('profOfPaymentForExtend')
                    .clearValidators();
            }
            _this.theftForm
                .get('profOfPaymentForExtend')
                .updateValueAndValidity();
        });
        this.theftForm.get('isCarRent').valueChanges.subscribe(function (value) {
            if (value === 'Yes') {
                _this.theftForm
                    .get('profOfRentalReceipt')
                    .setValidators([Validators.required]);
            }
            else {
                _this.theftForm
                    .get('profOfRentalReceipt')
                    .clearValidators();
            }
            _this.theftForm
                .get('profOfRentalReceipt')
                .updateValueAndValidity();
        });
    };
    TheftCreateComponent.prototype.submitData = function () {
        var _this = this;
        // this.onSubmitStepChange.emit();
        // if (this.selectedCustomer) {
        this.isLoading = true;
        var payload = this.theftForm.value;
        payload = this.utilService.removeEmpty(payload);
        payload.userId = this.selectedCustomer
            ? this.selectedCustomer._id
            : this.user._id
                ? this.user._id
                : null;
        if (payload.claimDate) {
            payload.claimDate = DateTime.fromObject(payload.claimDate).toISO();
        }
        if (payload.leaseExpireDate) {
            payload.leaseExpireDate = DateTime.fromObject(payload.leaseExpireDate).toISO();
        }
        var docs = this.moreDocs.controls.filter(function (a) { return a.value; });
        // console.log(docs);
        if (docs && docs.length > 0) {
            payload.moreDocs.map(function (docs, index) {
                _this.showmoreDocs[index] = true;
            });
        }
        if (payload.onVacation != 'Yes')
            payload.profOfPaymentForExtend = '';
        if (payload.isCarRent != 'Yes')
            payload.profOfRentalReceipt = '';
        // console.log(payload.moreDocs);
        // return true;
        if (this.claimData) {
            this.claimService
                .update(this.claimId, payload)
                .pipe(finalize(function () {
                _this.isLoading = false;
            }))
                .subscribe(function (response) {
                _this.toastrService.info('Thank you for submitting your Theft Claim.  One of our Claims Specialists will contact you shortly.');
                _this.router.navigate(['/theft-list']);
            });
        }
        else {
            this.claimService
                .add(payload)
                .pipe(finalize(function () {
                _this.isLoading = false;
            }))
                .subscribe(function (response) {
                _this.toastrService.info('Thank you for submitting your Theft Claim.  One of our Claims Specialists will contact you shortly.');
                _this.router.navigate(['/theft-list']);
            });
        }
    };
    TheftCreateComponent.prototype.getDealers = function () {
        var _this = this;
        this.customerService
            .getDealers()
            .pipe(finalize(function () {
            _this.isLoading = false;
        }))
            .subscribe(function (response) {
            if (response && response.records) {
                _this.dealersList = response.records;
            }
        });
    };
    TheftCreateComponent.prototype.getLeaseDealbyId = function (DealId) {
        var _this = this;
        this.claimService
            .getById(DealId)
            .subscribe(function (response) {
            if (response.records) {
                _this.claimData = response.records;
                if (_this.claimData.status == 'voided') {
                    localStorage.setItem(LOCAL_VAR.selectedProduct, 'theft');
                    _this.router.navigate(['/theft-list']);
                }
                if (_this.claimData.enterBy) {
                    _this.enterBy = _this.claimData.enterBy;
                }
                _this.createForm();
            }
            else if (_this.isEditMode) {
                localStorage.setItem(LOCAL_VAR.selectedProduct, 'theft');
                _this.router.navigate(['/theft-list']);
            }
        });
    };
    TheftCreateComponent.prototype.onSelectFile = function (event, type) {
        var _this = this;
        this.isLoading = true;
        if (event.target.files && event.target.files.length) {
            var formData = new FormData();
            formData.append('file', event.target.files[0]);
            if (this.claimData)
                formData.append('claimId', this.claimData._id);
            formData.append('fieldName', type);
            formData.append('prod', 'theft');
            this.authenticationService.uploadPhoto(formData).subscribe(function (res) {
                _this.isLoading = false;
                if (res.path) {
                    _this.theftForm.controls[type].setValue(res.path);
                    _this.theftForm.controls[type].markAsDirty();
                    //this.theftForm.controls[type].setValue(null);
                    //TODO - change ui
                    _this.uploadedImg[type] = res.path;
                }
                else {
                    _this.toastrService.error('Invalid file');
                }
            }, function (error) {
                _this.isLoading = false;
                console.log(error);
                _this.toastrService.warning('Invalid file');
            });
        }
    };
    TheftCreateComponent.prototype.removeImage = function (type) {
        return __awaiter(this, void 0, void 0, function () {
            var modalRef, payload, filename, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        modalRef = this.modalService.open(ConfirmationComponent);
                        modalRef.componentInstance.title = this.utilService.objectResolver('files.deleteFiles.title', CONFIRMATION_MSGS);
                        modalRef.componentInstance.body = this.utilService.objectResolver('files.deleteFiles.content', CONFIRMATION_MSGS);
                        modalRef.componentInstance.okBtn = this.utilService.objectResolver('files.deleteFiles.okBtn', CONFIRMATION_MSGS);
                        modalRef.componentInstance.cancelBtn = this.utilService.objectResolver('files.deleteFiles.cancelBtn', CONFIRMATION_MSGS);
                        modalRef.componentInstance.service = this.utilService.objectResolver('files.deleteFiles.service', CONFIRMATION_MSGS);
                        modalRef.componentInstance.method = this.utilService.objectResolver('files.deleteFiles.method', CONFIRMATION_MSGS);
                        if (this.claimData) {
                            payload = { claimId: this.claimData._id, fieldName: type, prod: 'theft' };
                        }
                        else {
                            filename = this.theftForm.controls[type].value;
                            payload = { fieldName: filename, prod: 'theft' };
                        }
                        modalRef.componentInstance.payload = [payload];
                        return [4 /*yield*/, modalRef.result.catch(function (e) { return console.log(e); })];
                    case 1:
                        result = _a.sent();
                        if (result) {
                            this.toastrService.info('Document deleted Successfully!..');
                            this.isLoading = false;
                            this.uploadedImg[type] = null;
                            this.theftForm.controls[type].setValue(null);
                            this.theftForm.controls[type].markAsDirty();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    TheftCreateComponent.prototype.cancelClick = function () {
        this.router.navigate(['/theft-list']);
    };
    TheftCreateComponent.prototype.openHelpPopup = function () {
        return __awaiter(this, void 0, void 0, function () {
            var modalRef;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalService.open(ClaimHelpComponent)];
                    case 1:
                        modalRef = _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TheftCreateComponent.prototype.omit_special_char = function (event) {
        var k;
        k = event.charCode; //         k = event.keyCode;  (Both can be used)
        return ((k > 64 && k < 91) || (k > 96 && k <= 122) || k == 8 || k == 32 || (k >= 48 && k <= 57));
    };
    TheftCreateComponent.prototype.omit_special_Mailchar = function (event) {
        var k;
        k = event.charCode; //         k = event.keyCode;  (Both can be used)
        return ((k > 64 && k < 91) || (k > 96 && k <= 122) || k === 46 || k === 64 || k == 8 || k == 32 || (k >= 48 && k <= 57));
    };
    TheftCreateComponent.prototype.getMyContract = function () {
        var _this = this;
        this.getcontract_loading = true;
        this.filter1_name = this.theftForm.controls.filter1select.value;
        this.filter1_value = $.trim(this.theftForm.controls.filter1.value);
        this.filter2_name = this.theftForm.controls.filter2select.value;
        this.filter2_value = $.trim(this.theftForm.controls.filter2.value);
        // if(this.filter1_name == '' || this.filter1_value == '' || this.filter2_name == '' || this.filter2_value =='')
        // {
        //   this.toastrService.warning('please select all fields and values');
        //   return true;
        // }
        // console.log(this.filter1_name,'1',this.filter1_value,'2',this.filter2_name,'3',this.filter2_value,'4')
        // var data = { [filter1_name] : filter1_value, [filter2_name]: filter2_value };
        if (this.filter1_name && this.filter1_value && this.filter2_name && this.filter2_value) {
            var data = {
                "firstname": this.filter1_name == 'firstname' ? $.trim(this.filter1_value) : '',
                "lastname": this.filter1_name == 'lastname' ? $.trim(this.filter1_value) : '',
                "phone": this.filter1_name == 'phone' ? $.trim(this.filter1_value) : (this.filter2_name == 'phone' ? $.trim(this.filter2_value) : ''),
                "vin": this.filter1_name == 'vin' ? $.trim(this.filter1_value) : (this.filter2_name == 'vin' ? $.trim(this.filter2_value) : ''),
                "contract": this.filter2_name == 'contract' ? $.trim(this.filter2_value) : '',
                "ProductType": "",
                "ProductSubType": "",
                "Product": ""
            };
            this.Claims
                .getContractDetails(data)
                .subscribe(function (res) {
                _this.getcontract_loading = false;
                if (res.status === 1) {
                    _this.toastrService.info('The contract information provided is for reference only. Please edit or make any changes you deem necessary.', 'We have found a contract matching your search!');
                    if (res.data.length == 1) {
                        // this.show_claims = true;
                        _this.setContractData(res.data[0]);
                        // this.claims_data = res.data;
                    }
                    else {
                        _this.claims_data = res.data;
                        _this.show_claims = true;
                    }
                }
                else {
                    _this.show_claims = false;
                    _this.toastrService.warning("Try a different combination or fill in the details manually.", "Sorry! we couldn't find that contract!");
                    _this.resetContractData();
                }
            });
        }
        else {
            this.toastrService.warning('please select / fill all fields and values');
            this.show_claims = false;
            this.getcontract_loading = false;
            return true;
        }
    };
    TheftCreateComponent.prototype.getContract = function () {
        var _this = this;
        var contactNumber = $.trim(this.theftForm.controls.contractNumber.value);
        if (contactNumber !== '') {
            var data = { "contractNo": contactNumber, "productType": "ANC" };
            this.Claims
                .getContractInfo(data)
                .subscribe(function (res) {
                if (res.status === 1) {
                    _this.toastrService.info(res.msg);
                    _this.setContractData(res.data);
                }
                else {
                    _this.toastrService.warning(res.msg);
                    _this.resetContractData();
                }
            });
        }
        else {
            this.toastrService.warning('please fill Contract No');
        }
    };
    TheftCreateComponent.prototype.claim_select = function (event) {
        // console.log(event.target.value,'valuuuuu')
        this.setContractData(this.claims_data[event.target.value]);
        this.show_claims = false;
    };
    TheftCreateComponent.prototype.setContractData = function (data) {
        this.theftForm.controls['contractNumber'].setValue(data.ContractNumber);
        this.theftForm.controls['vinNumber'].setValue(data.VIN);
        this.theftForm.controls['customerName'].setValue(data.FullName ? data.FullName : '');
        // this.theftForm.controls['phoneNumber'].setValue(data.PhysicalPhone);
        this.contractPhone = data.PhysicalPhone;
        // let address = '';
        // if (data.PhysicalAddressLine1)
        //   address = data.PhysicalAddressLine1
        // if (data.PhysicalAddressLine2)
        //   address = address + data.PhysicalAddressLine1 ? ', ' : ' ' + data.PhysicalAddressLine2
        // this.theftForm.controls['streetAddress'].setValue(address);
        // this.theftForm.controls['city'].setValue(data.PhysicalCity);
        // this.theftForm.controls['state'].setValue(data.PhysicalState);
        // this.theftForm.controls['zipCode'].setValue(data.PhysicalZipCode);
        this.theftForm.controls['make'].setValue(data.VehicleMake);
        this.theftForm.controls['model'].setValue(data.VehicleModel);
        this.theftForm.controls['year'].setValue(data.VehicleYear);
        // this.theftForm.controls['dealer'].setValue(this.dealersList.find((user: any) => { return (user.name.toLowerCase() == data.DealerName.toLowerCase() /*||  user.dealerNo == data.dealerNo */) }));
    };
    TheftCreateComponent.prototype.resetContractData = function () {
        this.contractPhone = '';
        this.theftForm.controls['contractNumber'].setValue('');
        this.theftForm.controls['vinNumber'].setValue('');
        this.theftForm.controls['customerName'].setValue('');
        // this.theftForm.controls['phoneNumber'].setValue('');
        // this.theftForm.controls['streetAddress'].setValue('');
        // this.theftForm.controls['city'].setValue('');
        // this.theftForm.controls['state'].setValue('');
        // this.theftForm.controls['zipCode'].setValue('');
        this.theftForm.controls['make'].setValue('');
        this.theftForm.controls['model'].setValue('');
        this.theftForm.controls['year'].setValue('');
    };
    TheftCreateComponent.prototype.createmoreDocsForm = function (data) {
        return this.formBuilder.group({
            name: [
                (this.isEditMode && data && data.name)
                    ? data.name
                    : ''
            ],
            url: [
                (this.isEditMode && data && data.url)
                    ? data.url
                    : '',
                [Validators.required]
            ]
        });
    };
    TheftCreateComponent.prototype.addDoc = function () {
        this.moreDocs = this.theftForm.get('moreDocs');
        this.moreDocs.push(this.createmoreDocsForm());
    };
    TheftCreateComponent.prototype.removeDoc = function (index) {
        this.theftForm.markAsDirty();
        this.moreDoc[index] = '';
        this.showmoreDocs[index] = false;
        this.moreDocs.removeAt(index);
    };
    TheftCreateComponent.prototype.onChangeFile = function (event, index) {
        var _this = this;
        this.isLoading = true;
        if (event.target.files && event.target.files.length) {
            var phots = this.moreDocs.controls[index];
            var formData = new FormData();
            formData.append('file', event.target.files[0]);
            // if (this.claimData)
            //   formData.append('claimId', this.claimData._id);
            formData.append('fieldName', 'moreDocs');
            formData.append('prod', 'theft');
            this.authenticationService.uploadPhoto(formData).subscribe(function (res) {
                _this.moreDocs.markAsDirty();
                _this.isLoading = false;
                _this.moreDoc[index] = res.path;
                _this.moreDocs.controls[index].get('url').setValue(res.path);
                _this.showmoreDocs[index] = true;
            }, function (error) {
                _this.isLoading = false;
                console.log(error);
                _this.toastrService.warning('Invalid file');
            });
        }
    };
    TheftCreateComponent.prototype.changeImage = function (index) {
        this.moreDocs.markAsDirty();
        this.moreDoc[index] = '';
        this.showmoreDocs[index] = false;
        this.moreDocs.controls[index].get('url').setValue(null);
    };
    TheftCreateComponent.prototype.checkDealer = function () {
        if (typeof this.theftForm.controls['dealer'].value !== 'object') {
            this.theftForm.controls.dealer.setErrors({ 'incorrect': true });
        }
    };
    TheftCreateComponent.prototype.omit_all_char = function (event) {
        var k;
        k = event.charCode; //         k = event.keyCode;  (Both can be used)
        return ((k >= 48 && k <= 57));
    };
    return TheftCreateComponent;
}());
export { TheftCreateComponent };
