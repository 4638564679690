<div class="row agent-container">
  <div class="col-12">
    <div class="card">
      <form [formGroup]="debitForm" (ngSubmit)="submitData()" class="form-horizontal form-material" id="debitForm"
        novalidate>
        <div class="card-header">
          Debt Cancellation Calculator / Checklist
        </div>
        <div class="card-body">
          <div class="row" *ngIf="loggedInUserType == 'customer'">
            <h3 class="title-case">Find my contract</h3>
            <div class="col-12 pl-0">             
              <div class="form-group row ml-0 mr-0">
                <div class="form-group col-12 col-xl-6 d-xl-flex">
                  <div class="form-group col-12 col-xl-6 d-xl-flex mb-xl-0">
                        <select class="custom-select form-control mr-2 mb-2 mb-xl-0" name="filter1select"
                          formControlName="filter1select">
                          <option value="">Select Field</option>
                          <option value="firstname">First Name</option>
                          <option value="lastname">Last Name</option>
                          <option value="phone" [disabled]="debitForm.get('filter2select').value == 'phone' ? true : false">Phone</option>
                          <option value="vin" [disabled]="debitForm.get('filter2select').value == 'vin' ? true : false">VIN(Last 8 Digits)</option> 
                        </select>           
                        <input type="text" name="filter1" class="form-control" placeholder="value" formControlName="filter1" />   
                        <!-- 1111111111{{filter2_name}}--asdfasdfasd-         -->
                  </div>
                  <div class="form-group col-12 col-xl-6 d-xl-flex mb-xl-0">
                        <select class="custom-select form-control mr-2 mb-2 mb-xl-0" name="filter2select"
                          formControlName="filter2select">
                          <option value="" >Select Field</option>
                          <option value="contract">Contract No</option>
                          <option value="vin" [disabled]="debitForm.get('filter1select').value == 'vin' ? true : false">VIN(Last 8 Digits)</option>
                          <option value="phone" [disabled]="debitForm.get('filter1select').value == 'phone' ? true : false">Phone</option>
                        </select>                   
                        <input type="text" name="filter2" class="form-control" placeholder="value" formControlName="filter2" />   
                  </div>
                </div>
                <div class="form-group col-12 col-xl-6 d-xl-flex text-center">
                    <button
                      type="button"
                      (click)="getMyContract()"
                      class="btn btn-info primary-background mr-2 mb-0"
                    >
                    <app-button-loader [isLoading]="getcontract_loading"></app-button-loader>
                    Search & Fill
                    </button>                      
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-2" *ngIf="show_claims">
            <div class="col-12">
              <span class="pl-prefill pl-5">Please select contracts below to prefill</span>
              <div class="row">
                <div class="col-12 d-xl-flex pl-5 pr-5">
                  <div class="table table-responsive smart-table">
                    <table class="w-100">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Contract No</th>
                          <th>VIN</th>
                          <!--<th>Name</th>
                          <th>Phone No</th>
                          <th>Dealer</th> -->
                          <th>Product</th>
                          <th>Year</th>
                          <th>Make</th>
                          <th>Model</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="
                            let claim of claims_data; let i=index"
                        >
                          <td><input type="radio" value="{{i}}" (click)="claim_select($event)" /></td>
                          <td>{{ claim.ContractNumber }}</td>
                          <td>xxxxxxxxx{{ claim.VIN.substring(9,17) }}</td>
                          <!--<td>{{ claim.FirstName ? claim.FirstName : '' + ' ' + claim.LastName ? claim.LastName : '' }}</td>
                          <td>{{ claim.PhysicalPhone | phone }}</td>
                          <td>{{ claim.DealerName }}</td> -->
                          <td>{{ claim.Product }}</td>
                          <td>{{ claim.VehicleYear }}</td>
                          <td>{{ claim.VehicleMake }}</td>
                          <td>{{ claim.VehicleModel }}</td>
                        </tr>
                      </tbody>
                    </table>              
                  </div>
                </div>
              </div>
            </div>
          </div>        
          <hr class="mt-0" *ngIf="loggedInUserType == 'customer'"/>
          <div class="row">
            <h3 class="title-case">Contract Information</h3>
            <div class="col-12 p-xl-0">
              <div class="form-group row ml-0 mr-0">
                <div class="form-group m-xl-0 col-12 col-xl-5 d-xl-flex align-items-center p-0">
                  <label class="col-12 col-xl-5"><app-required-indication [control]="debitForm.get('contractNumber')"
                      [label]="'Contract Number'"></app-required-indication>
                      <!-- <i class="fa fa-question-circle" title="It helps you to pre-fill the basic contract details" ></i> -->
                  </label>
                  <div class="col-12 pl-0"
                  [ngClass]="{
                    'col-xl-7': loggedInUserType == 'customer',
                    'col-xl-6 pr-1': loggedInUserType != 'customer'
                  }">
                    <app-form-error-wrapper [control]="debitForm.get('contractNumber')"
                      [controlName]="'Contract Number'" [apiErrorType]="'contractNumber'">
                      <input type="text" name="contractNumber" class="form-control" formControlName="contractNumber" />
                    </app-form-error-wrapper>
                  </div>
                  <div class="col-12 col-xl-1"  *ngIf="loggedInUserType != 'customer'">
                    <button
                    type="button"
                    (click)="getContract()"
                    class="btn btn-info mr-2 primary-background"
                  >
                    Fill
                  </button>
                  </div>
                </div>
                <div class="form-group col-12 col-xl-4 d-xl-flex align-items-center p-0">
                  <label class="col-12 col-xl-4 align-items-center">
                    <app-required-indication [control]="debitForm.get('vinNumber')"
                    [label]="'VIN Number'"></app-required-indication>
                  </label>                  
                  <div class="col-12 col-xl-8">
                    <app-form-error-wrapper [control]="debitForm.get('vinNumber')" [controlName]="'VIN Number'"
                      [apiErrorType]="'vinNumber'">
                      <input type="text" name="vin-number" class="form-control" mask="AAAAAAAAAAAAAAAAA"
                        [formControl]="debitForm.get('vinNumber')" />
                    </app-form-error-wrapper>
                  </div>
                </div>
                <div class="form-group col-12 col-xl-3 d-xl-flex align-items-center">
                  <label class="col-12 col-xl-6 p-0">
                    <app-required-indication [control]="debitForm.get('claimDate')"
                      [label]="'Date of Loss'">
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-6 pos-rel">
                    <app-form-error-wrapper [control]="debitForm.get('claimDate')"
                      [controlName]="'Date Leased Vehicle Returned'" [apiErrorType]="'claimDate'">
                      <input type="text" name="dateLoss" formControlName="claimDate" placeholder="MM/DD/YYYY"
                        ngbDatepicker #dt="ngbDatepicker" class="form-control" (click)="dt.toggle()" [readonly]="true"
                        [minDate]="{ year: 1900, month: 1, day: 1 }" [maxDate]="{
                          year: utilService.getCurrentYear,
                          month: utilService.getCurrentMonth,
                          day: utilService.getCurrentDay
                        }" />
                    </app-form-error-wrapper>
                    <a class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer pos-abs datecancel-r15"
                      (click)="utilService.removeDate(debitForm, 'claimDate')" placement="left"
                      ngbTooltip="Remove" *ngIf="debitForm.controls['claimDate'].value">
                      <i class="ti-close text-danger m-r-10"></i>
                    </a>
                  </div>
                </div> 
              </div>
              <h3 class="title-case">Buyer/Debtor information </h3>
              <div class="col-12 p-0">
                <!-- <div class="form-group d-xl-flex align-items-center">
                  <div class="form-group col-12 col-xl-6 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-4 p-0">
                      <app-required-indication [control]="debitForm.get('contractNumber')"
                        [label]="'Contract Number'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-8 p-ls-xl-0">
                      <app-form-error-wrapper [control]="debitForm.get('contractNumber')"
                        [controlName]="'Contract Number'" [apiErrorType]="'contractNumber'">
                        <input type="text" name="contractNumber" class="form-control" formControlName="contractNumber" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                  <div class="form-group col-12 col-xl-4 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-6 p-0">
                      <app-required-indication [control]="debitForm.get('claimDate')"
                        [label]="'Claim Date'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-6 pos-rel">
                      <app-form-error-wrapper [control]="debitForm.get('claimDate')"
                        [controlName]="'Date Leased Vehicle Returned'" [apiErrorType]="'claimDate'">
                        <input type="text" name="dateLoss" formControlName="claimDate" placeholder="MM/DD/YYYY"
                          ngbDatepicker #dt="ngbDatepicker" class="form-control" (click)="dt.toggle()" [readonly]="true"
                          [minDate]="{ year: 1900, month: 1, day: 1 }" [maxDate]="{
                            year: utilService.getCurrentYear,
                            month: utilService.getCurrentMonth,
                            day: utilService.getCurrentDay
                          }" />
                      </app-form-error-wrapper>
                      <a class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer pos-abs datecancel-r15"
                        (click)="utilService.removeDate(debitForm, 'claimDate')" placement="left"
                        ngbTooltip="Remove" *ngIf="debitForm.controls['claimDate'].value">
                        <i class="ti-close text-danger m-r-10"></i>
                      </a>
                    </div>
                  </div>
                </div> -->
                <div class="form-group d-xl-flex align-items-center">
                  <div class="form-group col-12 col-xl-5 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-5 p-0">
                      <app-required-indication [control]="debitForm.get('debitorName')" [label]="'Name'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-7 p-0">
                      <app-form-error-wrapper [control]="debitForm.get('debitorName')" [controlName]="'Customer Name'"
                        [apiErrorType]="'debitorName'">
                        <input type="text" name="debitorName" class="form-control" formControlName="debitorName" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                  <div class="form-group col-12 col-xl-3 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-4">
                      <app-required-indication [control]="debitForm.get('debitorPhone')" [label]="'Phone'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-8 p-ls-xl-0">
                      <app-form-error-wrapper [control]="debitForm.get('debitorPhone')" [controlName]="'Phone'"
                        [apiErrorType]="'debitorPhone'">
                        <input type="tel" name="debitorPhone" mask="000-000-0000" class="form-control"
                          [formControl]="debitForm.controls['debitorPhone']" />
                      </app-form-error-wrapper>
                    </div>
                  </div>   
                  <div class="form-group col-12 col-xl-4 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-4 p-0">
                      <app-required-indication [control]="debitForm.get('debitorEmail')" [label]="'Email'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-8 p-0">
                      <app-form-error-wrapper [control]="debitForm.get('debitorEmail')" [controlName]="'Email'"
                        [apiErrorType]="'debitorEmail'">
                        <input type="debitorEmail" name="debitorEmail" class="form-control"
                          formControlName="debitorEmail" />
                      </app-form-error-wrapper>
                    </div>
                  </div>           
                </div>
                <div class="form-group d-xl-flex align-items-center">
                  <label class="col-12 col-xl-2">
                    <app-required-indication [control]="debitForm.get('debitorStreet')" [label]="'Street Address'">
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-10">
                    <app-form-error-wrapper [control]="debitForm.get('debitorStreet')"
                      [controlName]="'Street Address'" [apiErrorType]="'debitorStreet'">
                      <input type="text" name="street-address" class="form-control" formControlName="debitorStreet" />
                    </app-form-error-wrapper>
                  </div>
                </div>
                <div class="form-group d-xl-flex align-items-center">
                  <div class="form-group col-12 col-xl-4 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-6 p-0">
                      <app-required-indication [control]="debitForm.get('debitorCity')" [label]="'City'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-6 p-ls-xl-0">
                      <app-form-error-wrapper [control]="debitForm.get('debitorCity')" [controlName]="'City'"
                        [apiErrorType]="'debitorCity'">
                        <input type="text" class="form-control" formControlName="debitorCity" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                  <div class="form-group col-12 col-xl-4 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-3 p-0">
                      <app-required-indication [control]="debitForm.get('debitorState')" [label]="'State'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-9 p-0">
                      <app-form-error-wrapper [control]="debitForm.get('debitorState')" [controlName]="'State'"
                        [apiErrorType]="'debitorState'">
                        <select class="custom-select form-control" name="state" formControlName="debitorState">
                          <option value="">Select State </option>
                          <option *ngFor="let stat of states" value="{{ stat.code }}">{{ stat.name }}</option>
                        </select>
                      </app-form-error-wrapper>
                    </div>
                  </div>
                  <div class="form-group col-12 col-xl-4 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-4 p-0">
                      <app-required-indication [control]="debitForm.get('debitorZip')" [label]="'Zip Code'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-8 p-0">
                      <app-form-error-wrapper [control]="debitForm.get('debitorZip')" [controlName]="'Zip Code'"
                        [apiErrorType]="'debitorZip'">
                        <input type="tel" name="debitorZip" class="form-control"
                          [formControl]="debitForm.controls['debitorZip']" minlength="5" maxlength="5" mask="00000" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                </div>
              </div>
              <h3 class="title-case">Co-Buyer/Co-Debtor information </h3>
              <div>
                <div class="form-group d-xl-flex align-items-center">
                  <div class="form-group m-xl-0 col-12 col-xl-5 d-xl-flex align-items-center p-0">
                    <label class="col-12 col-xl-5">
                      <app-required-indication [control]="debitForm.get('codebitorName')"
                        [label]="'Name'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-7 p-0">
                      <app-form-error-wrapper [control]="debitForm.get('codebitorName')" [controlName]="'Contact Name'"
                        [apiErrorType]="'codebitorName'">
                        <input type="text" name="codebitorName" class="form-control" formControlName="codebitorName" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                  <div class="form-group m-xl-0 col-12 col-xl-3 d-xl-flex align-items-center p-0">
                    <label class="col-12 col-xl-4">
                      <app-required-indication [control]="debitForm.get('codebitorPhone')" [label]="'Phone'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-8">
                      <app-form-error-wrapper [control]="debitForm.get('codebitorPhone')"
                        [controlName]="'codebitorPhone'" [apiErrorType]="'codebitorPhone'">
                        <input type="tel" name="codebitorPhone" mask="000-000-0000" class="form-control"
                          [formControl]="debitForm.controls['codebitorPhone']" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                  <div class="form-group m-xl-0 col-12 col-xl-4 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-4 p-0">
                      <app-required-indication [control]="debitForm.get('codebitorEmail')" [label]="'Email'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-8 p-0">
                      <app-form-error-wrapper [control]="debitForm.get('codebitorEmail')" [controlName]="'Email'"
                        [apiErrorType]="'codebitorEmail'">
                        <input type="text" name="codebitorEmail" class="form-control"
                          formControlName="codebitorEmail" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                </div>

                <div class="form-group d-xl-flex align-items-center">
                  <label class="col-12 col-xl-2">
                    <app-required-indication [control]="debitForm.get('codebitorStreet')" [label]="'Street Address'">
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-10">
                    <app-form-error-wrapper [control]="debitForm.get('codebitorStreet')"
                      [controlName]="'Street Address'" [apiErrorType]="'codebitorStreet'">
                      <input type="text" name="street-address" class="form-control" formControlName="codebitorStreet" />
                    </app-form-error-wrapper>
                  </div>
                </div>
                <div class="form-group d-xl-flex align-items-center">
                  <div class="form-group col-12 col-xl-4 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-6 p-0">
                      <app-required-indication [control]="debitForm.get('codebitorCity')" [label]="'City'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-6 p-ls-xl-0">
                      <app-form-error-wrapper [control]="debitForm.get('codebitorCity')" [controlName]="'City'"
                        [apiErrorType]="'codebitorCity'">
                        <input type="text" class="form-control" formControlName="codebitorCity" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                  <div class="form-group col-12 col-xl-4 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-3 p-0">
                      <app-required-indication [control]="debitForm.get('codebitorState')" [label]="'State'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-9 p-0">
                      <app-form-error-wrapper [control]="debitForm.get('codebitorState')" [controlName]="'State'"
                        [apiErrorType]="'codebitorState'">
                        <select class="custom-select form-control" name="state" formControlName="codebitorState">
                          <option value="">Select State </option>
                          <option *ngFor="let stat of states" value="{{ stat.code }}">{{ stat.name }}</option>
                        </select>
                      </app-form-error-wrapper>
                    </div>
                  </div>
                  <div class="form-group col-12 col-xl-4 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-4 p-0">
                      <app-required-indication [control]="debitForm.get('codebitorZip')" [label]="'Zip Code'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-8 p-0">
                      <app-form-error-wrapper [control]="debitForm.get('codebitorZip')" [controlName]="'Zip Code'"
                        [apiErrorType]="'codebitorZip'">
                        <input type="tel" name="codebitorZip" class="form-control"
                          [formControl]="debitForm.controls['codebitorZip']" minlength="5" maxlength="5" mask="00000" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                </div>
              </div>
              <h3 class="title-case">Vehicle Information</h3>
              <div>
                <div class="form-group d-xl-flex align-items-center">
                  <!-- <div class="form-group col-12 col-xl-6 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-4 p-0">
                      <app-required-indication [control]="debitForm.get('vinNumber')" [label]="'VIN Number'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-8 p-ls-xl-0 pl-1">
                      <app-form-error-wrapper [control]="debitForm.get('vinNumber')" [controlName]="'VIN Number'"
                        [apiErrorType]="'vinNumber'">
                        <input type="text" name="vin-number" class="form-control" mask="AAAAAAAAAAAAAAAAA"
                          [formControl]="debitForm.get('vinNumber')" />
                      </app-form-error-wrapper>
                    </div>
                  </div> -->
                  <div class="form-group col-12 col-xl-4 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-6 p-0">
                      <app-required-indication [control]="debitForm.get('currentMileage')"
                        [label]="'Current Odometer reading'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-6 p-ls-xl-0">
                      <app-form-error-wrapper [control]="debitForm.get('currentMileage')"
                        [controlName]="'Current mileage'" [apiErrorType]="'currentMileage'">
                        <input type="text" name="currentMileage" class="form-control" mask="000000"
                          [formControl]="debitForm.get('currentMileage')" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                <!-- </div>
                <div class="form-group d-xl-flex align-items-center"> -->
                  <div class="form-group col-12 col-xl-2 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-4 p-0">
                      <app-required-indication [control]="debitForm.get('year')" [label]="'Year'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-8 p-ls-xl-0">
                      <app-form-error-wrapper [control]="debitForm.get('year')" [controlName]="'Year'"
                        [apiErrorType]="'year'">
                        <input type="text" name="year" mask="0000" class="form-control"
                          [formControl]="debitForm.get('year')" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                  <div class="form-group col-12 col-xl-3 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-3 p-0 text-xl-center">
                      <app-required-indication [control]="debitForm.get('make')" [label]="'Make'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-9 p-0">
                      <app-form-error-wrapper [control]="debitForm.get('make')" [controlName]="'Make'"
                        [apiErrorType]="'make'">
                        <input type="text" name="make" class="form-control" [formControl]="debitForm.get('make')" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                  <div class="form-group col-12 col-xl-3 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-3 p-0 text-xl-center">
                      <app-required-indication [control]="debitForm.get('model')" [label]="'Model'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-9 p-0">
                      <app-form-error-wrapper [control]="debitForm.get('model')" [controlName]="'Model'"
                        [apiErrorType]="'model'">
                        <input type="text" name="model" class="form-control" formControlName="model" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                </div>

                <div class="form-group d-xl-flex align-items-center">
                  <div class="form-group col-12 col-xl-4 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-6 p-0">
                      <app-required-indication [control]="debitForm.get('trim')" [label]="'Trim'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-6 p-ls-xl-0">
                      <app-form-error-wrapper [control]="debitForm.get('trim')" [controlName]="'trim'"
                        [apiErrorType]="'trim'">
                        <input type="text" name="year" class="form-control" [formControl]="debitForm.get('trim')" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                  <div class="col-12 col-xl-1">
                    <div class="custom-control custom-checkbox ">
                      <input id="checkbox-isNew" class="custom-control-input"
                        (click)="selectCheckbox('isNew', $event, 'vehicleType')" type="checkbox"
                        [checked]="this.debitForm.get('vehicleType').value.isNew" />
                      <label for="checkbox-isNew" class="custom-control-label">NEW</label>
                    </div>
                  </div>
                  <div class="col-12 col-xl-2">
                    <div class="custom-control custom-checkbox ">
                      <input id="checkbox-isPreOwned" class="custom-control-input"
                        (click)="selectCheckbox('isPreOwned', $event, 'vehicleType')" type="checkbox"
                        [checked]="this.debitForm.get('vehicleType').value.isPreOwned" />
                      <label for="checkbox-isPreOwned" class="custom-control-label">PRE-OWNED</label>
                    </div>
                  </div>
                  <div class="col-12 col-xl-3">
                    <div class="custom-control custom-checkbox ">
                      <input id="checkbox-isCertifiedPreOwned" class="custom-control-input"
                        (click)="selectCheckbox('isCertifiedPreOwned', $event, 'vehicleType')" type="checkbox"
                        [checked]="this.debitForm.get('vehicleType').value.isCertifiedPreOwned" />
                      <label for="checkbox-isCertifiedPreOwned" class="custom-control-label">CERTIFIED PRE-OWNED</label>
                    </div>
                  </div>
                </div>
              </div>
              <h3 class="title-case">DEALER/CREDITOR INFORMATION </h3>
              <div class="col-12 p-0">
                <div class="form-group d-xl-flex align-items-center">
                  <div class="form-group col-12 col-xl-6 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-4 p-0">
                      <app-required-indication [control]="debitForm.get('dealer')" [label]="'Dealer Name'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-8">
                      <app-form-error-wrapper [control]="debitForm.get('dealer')" [controlName]="'Dealer'"
                        [apiErrorType]="'dealer'">
                        <ng-template #rt let-r="result" let-t="term">
                          {{ r.name }}
                        </ng-template>
                        <input
                          class="form-control"
                          type="text"
                          [ngbTypeahead]="dealers"
                          [inputFormatter]="formatter"
                          formControlName="dealer"
                          [resultTemplate]="rt"
                          autocomplete="off"
                          placeholder="Search and select Dealer"
                          name="dealer"
                        />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                  <div class="form-group col-12 col-xl-6 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-4 text-xl-center">
                      <app-required-indication [control]="debitForm.get('dealerPhone')" [label]="'Phone Number'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-8 p-ls-xl-0">
                      <app-form-error-wrapper [control]="debitForm.get('dealerPhone')" [controlName]="'Phone Number'"
                        [apiErrorType]="'dealerPhone'">
                        <input type="tel" name="dealerPhone" mask="000-000-0000" class="form-control"
                          [formControl]="debitForm.controls['dealerPhone']" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                </div>
                <div class="form-group d-xl-flex align-items-center">
                  <label class="col-12 col-xl-2">
                    <app-required-indication [control]="debitForm.get('dealerStreet')" [label]="'Street Address'">
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-6">
                    <app-form-error-wrapper [control]="debitForm.get('dealerStreet')" [controlName]="'Street Address'"
                      [apiErrorType]="'dealerStreet'">
                      <input type="text" name="street-address" class="form-control" formControlName="dealerStreet" />
                    </app-form-error-wrapper>
                  </div>
                  <label class="col-12 col-xl-1">
                    <app-required-indication [control]="debitForm.get('dealerCity')" [label]="'City'">
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-3">
                    <app-form-error-wrapper [control]="debitForm.get('dealerCity')" [controlName]="'City'"
                      [apiErrorType]="'dealerCity'">
                      <input type="text" name="dealerCity" class="form-control" formControlName="dealerCity" />
                    </app-form-error-wrapper>
                  </div>
                </div>
                <div class="form-group d-xl-flex align-items-center">
                  <div class="form-group col-12 col-xl-5 d-xl-flex">
                    <label class="col-12 col-xl-5 p-0">
                      <app-required-indication [control]="debitForm.get('state')" [label]="'State'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-7 p-0">
                      <app-form-error-wrapper [control]="debitForm.get('dealerState')" [controlName]="'State'"
                        [apiErrorType]="'dealerState'">
                        <select class="custom-select form-control" name="dealerState" formControlName="dealerState">
                          <option value="">Select State</option>
                          <option *ngFor="let stat of states" value="{{ stat.code }}">{{ stat.name }}</option>
                        </select>
                      </app-form-error-wrapper>
                    </div>
                  </div>
                  <div class="form-group col-12 col-xl-3 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-4 p-0">
                      <app-required-indication [control]="debitForm.get('dealerZip')" [label]="'Zip Code'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-8 p-0">
                      <app-form-error-wrapper [control]="debitForm.get('dealerZip')" [controlName]="'Zip Code'"
                        [apiErrorType]="'dealerZip'">
                        <input type="tel" class="form-control" [formControl]="debitForm.controls['dealerZip']"
                          mask="00000" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                </div>
              </div>
              <h3 class="title-case">ASSIGNEE/LIENHOLDER INFORMATION </h3>
              <div class="col-12 p-0">
                <div class="form-group d-xl-flex align-items-center">
                  <div class="form-group col-12 col-xl-6 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-4 p-0">
                      <app-required-indication [control]="debitForm.get('lienholderName')" [label]="'Name'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-8 p-ls-xl-0">
                      <app-form-error-wrapper [control]="debitForm.get('lienholderName')"
                        [controlName]="'Customer Name'" [apiErrorType]="'lienholderName'">
                        <input type="text" name="lienholderName" class="form-control"
                          formControlName="lienholderName" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                  <div class="form-group col-12 col-xl-6 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-4 text-xl-center">
                      <app-required-indication [control]="debitForm.get('lienholderPhone')" [label]="'Phone Number'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-8 p-ls-xl-0">
                      <app-form-error-wrapper [control]="debitForm.get('lienholderPhone')"
                        [controlName]="'Phone Number'" [apiErrorType]="'lienholderPhone'">
                        <input type="tel" name="lienholderPhone" mask="000-000-0000" class="form-control"
                          [formControl]="debitForm.controls['lienholderPhone']" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                </div>
                <div class="form-group d-xl-flex align-items-center">
                  <label class="col-12 col-xl-2">
                    <app-required-indication [control]="debitForm.get('lienholderStreet')" [label]="'Street Address'">
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-6">
                    <app-form-error-wrapper [control]="debitForm.get('lienholderStreet')"
                      [controlName]="'Street Address'" [apiErrorType]="'lienholderStreet'">
                      <input type="text" name="street-address" class="form-control"
                        formControlName="lienholderStreet" />
                    </app-form-error-wrapper>
                  </div>
                  <label class="col-12 col-xl-1">
                    <app-required-indication [control]="debitForm.get('lienholderCity')" [label]="'City'">
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-3">
                    <app-form-error-wrapper [control]="debitForm.get('lienholderCity')" [controlName]="'City'"
                      [apiErrorType]="'lienholderCity'">
                      <input type="text" name="lienholderCity" class="form-control" formControlName="lienholderCity" />
                    </app-form-error-wrapper>
                  </div>
                </div>
                <div class="form-group d-xl-flex align-items-center">
                  <div class="form-group col-12 col-xl-5 d-xl-flex">
                    <label class="col-12 col-xl-5 p-0">
                      <app-required-indication [control]="debitForm.get('lienholderState')" [label]="'State'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-7 p-0">
                      <app-form-error-wrapper [control]="debitForm.get('lienholderState')" [controlName]="'State'"
                        [apiErrorType]="'lienholderState'">
                        <select class="custom-select form-control" name="lienholderState"
                          formControlName="lienholderState">
                          <option value="">Select State</option>
                          <option *ngFor="let stat of states" value="{{ stat.code }}">{{ stat.name }}</option>
                        </select>
                      </app-form-error-wrapper>
                    </div>
                  </div>
                  <div class="form-group col-12 col-xl-3 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-4 p-0">
                      <app-required-indication [control]="debitForm.get('lienholderZip')" [label]="'Zip Code'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-8 p-0">
                      <app-form-error-wrapper [control]="debitForm.get('lienholderZip')" [controlName]="'Zip Code'"
                        [apiErrorType]="'lienholderZip'">
                        <input type="tel" class="form-control" [formControl]="debitForm.controls['lienholderZip']"
                          mask="00000" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                </div>
              </div>
              <h3 class="title-case">Customer Documents Needed</h3>
              <div>
                <div class="form-group d-xl-flex align-items-center">
                  <label class="col-12 col-xl-8">
                    <app-required-indication
                      [control]="debitForm.get('liabilitySettlement')"
                      [label]="
                        'A complete copy of the Primary Carriers Settlement showing all additions and deductions for the liability settlement amount, including the valuation report'"
                    >
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-4">
                    <div *ngIf="debitForm.get('liabilitySettlement').value">
                      <label class="col-12 col-xl-9 float-left">{{
                        debitForm.get('liabilitySettlement').value.replace('uploads/', '').replace('debitcancellation/','')
                      }}</label>
                      <div class="col-12 col-xl-3 float-left">
                        <a
                          class="ng2-smart-action ng2-smart-action-edit-edit ml-2"
                          placement="left"
                          ngbTooltip="View Document"
                          href="{{ baseUrl }}{{
                            debitForm.get('liabilitySettlement').value
                          }}"
                          target="_blank"
                        >
                          <i class="ti-eye text-info m-r-10"></i>
                        </a>
                        <a
                          class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer ml-2"
                          (click)="removeImage('liabilitySettlement')"
                          placement="left"
                          ngbTooltip="Change Document"
                        >
                          <i class="ti-trash text-danger m-r-10"></i>
                        </a>
                      </div>
                    </div>
                    <div *ngIf="!debitForm.get('liabilitySettlement').value">
                      <input
                        type="file"
                        name="deductiblesSection"
                        (change)="onSelectFile($event, 'liabilitySettlement')"
                        class="file-upload-width"
                      />
                      <a
                        *ngIf="debitForm.get('liabilitySettlement').value"
                        class="ng2-smart-action ng2-smart-action-edit-edit ml-2 float-right"
                        placement="left"
                        ngbTooltip="View Document"
                        href="{{ baseUrl }}{{
                          debitForm.get('liabilitySettlement').value
                        }}"
                        target="_blank"
                      >
                        <i class="ti-eye text-info m-r-10"></i>
                      </a>
                    </div>
                  </div>
                 </div> 
                <div class="form-group d-xl-flex align-items-center">
                  <label class="col-12 col-xl-8">
                    <app-required-indication [control]="debitForm.get('comprehensiveOrCollisionSettlement')" [label]="
                        'A complete copy of the Primary Carriers Settlement showing all additions and deductions for the comprehensive or collision settlement amount, including the valuation report, if any'"
                    >
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-4">
                    <div *ngIf="debitForm.get('comprehensiveOrCollisionSettlement').value">
                      <label class="col-12 col-xl-9 float-left">{{debitForm.get('comprehensiveOrCollisionSettlement').value.replace('uploads/', '').replace('debitcancellation/','')}}</label>
                      <div class="col-12 col-xl-3 float-left">
                        <a class="ng2-smart-action ng2-smart-action-edit-edit ml-2" placement="left" ngbTooltip="View Document" href="{{ baseUrl }}{{debitForm.get('comprehensiveOrCollisionSettlement').value}}" target="_blank">
                          <i class="ti-eye text-info m-r-10"></i>
                        </a>
                        <a class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer ml-2" (click)="removeImage('comprehensiveOrCollisionSettlement')" placement="left" ngbTooltip="Change Document">
                          <i class="ti-trash text-danger m-r-10"></i>
                        </a>
                      </div>
                    </div>
                    <div *ngIf="!debitForm.get('comprehensiveOrCollisionSettlement').value">
                      <input type="file" name="deductiblesSection" (change)="onSelectFile($event, 'comprehensiveOrCollisionSettlement')" class="file-upload-width"/>
                      <a *ngIf="debitForm.get('comprehensiveOrCollisionSettlement').value" class="ng2-smart-action ng2-smart-action-edit-edit ml-2 float-right" placement="left" ngbTooltip="View Document" href="{{ baseUrl }}{{debitForm.get('comprehensiveOrCollisionSettlement').value}}" target="_blank">
                        <i class="ti-eye text-info m-r-10"></i>
                      </a>
                    </div>
                  </div>
                 </div>                 
                 <div class="form-group d-xl-flex align-items-center">
                  <label class="col-12 col-xl-8">
                    <app-required-indication [control]="debitForm.get('accidentOrPoliceReport')" [label]="
                        'A copy of the accident/police report'"
                    >
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-4">
                    <div *ngIf="debitForm.get('accidentOrPoliceReport').value">
                      <label class="col-12 col-xl-9 float-left">{{debitForm.get('accidentOrPoliceReport').value.replace('uploads/', '').replace('debitcancellation/','')}}</label>
                      <div class="col-12 col-xl-3 float-left">
                        <a class="ng2-smart-action ng2-smart-action-edit-edit ml-2" placement="left" ngbTooltip="View Document" href="{{ baseUrl }}{{debitForm.get('accidentOrPoliceReport').value}}" target="_blank">
                          <i class="ti-eye text-info m-r-10"></i>
                        </a>
                        <a class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer ml-2" (click)="removeImage('accidentOrPoliceReport')" placement="left" ngbTooltip="Change Document">
                          <i class="ti-trash text-danger m-r-10"></i>
                        </a>
                      </div>
                    </div>
                    <div *ngIf="!debitForm.get('accidentOrPoliceReport').value">
                      <input type="file" name="deductiblesSection" (change)="onSelectFile($event, 'accidentOrPoliceReport')" class="file-upload-width"/>
                      <a *ngIf="debitForm.get('accidentOrPoliceReport').value" class="ng2-smart-action ng2-smart-action-edit-edit ml-2 float-right" placement="left" ngbTooltip="View Document" href="{{ baseUrl }}{{debitForm.get('accidentOrPoliceReport').value}}" target="_blank">
                        <i class="ti-eye text-info m-r-10"></i>
                      </a>
                    </div>
                  </div>
                 </div>
                 <div class="form-group d-xl-flex align-items-center">
                  <label class="col-12 col-xl-8">
                    <app-required-indication [control]="debitForm.get('fireReport')" [label]="
                        'A copy of the fire report (if applicable)'"
                    >
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-4">
                    <div *ngIf="debitForm.get('fireReport').value">
                      <label class="col-12 col-xl-9 float-left">{{debitForm.get('fireReport').value.replace('uploads/', '').replace('debitcancellation/','')}}</label>
                      <div class="col-12 col-xl-3 float-left">
                        <a class="ng2-smart-action ng2-smart-action-edit-edit ml-2" placement="left" ngbTooltip="View Document" href="{{ baseUrl }}{{debitForm.get('fireReport').value}}" target="_blank">
                          <i class="ti-eye text-info m-r-10"></i>
                        </a>
                        <a class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer ml-2" (click)="removeImage('fireReport')" placement="left" ngbTooltip="Change Document">
                          <i class="ti-trash text-danger m-r-10"></i>
                        </a>
                      </div>
                    </div>
                    <div *ngIf="!debitForm.get('fireReport').value">
                      <input type="file" name="deductiblesSection" (change)="onSelectFile($event, 'fireReport')" class="file-upload-width"/>
                      <a *ngIf="debitForm.get('fireReport').value" class="ng2-smart-action ng2-smart-action-edit-edit ml-2 float-right" placement="left" ngbTooltip="View Document" href="{{ baseUrl }}{{debitForm.get('fireReport').value}}" target="_blank">
                        <i class="ti-eye text-info m-r-10"></i>
                      </a>
                    </div>
                  </div>
                 </div>
                <div class="form-group d-xl-flex align-items-center">
                  <label class="col-12 col-xl-8">
                    <app-required-indication [control]="debitForm.get('estimateDamageRepair')" [label]="
                        'A copy of the estimate of damage and repair'"
                    >
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-4">
                    <div *ngIf="debitForm.get('estimateDamageRepair').value">
                      <label class="col-12 col-xl-9 float-left">{{debitForm.get('estimateDamageRepair').value.replace('uploads/', '').replace('debitcancellation/','')}}</label>
                      <div class="col-12 col-xl-3 float-left">
                        <a class="ng2-smart-action ng2-smart-action-edit-edit ml-2" placement="left" ngbTooltip="View Document" href="{{ baseUrl }}{{debitForm.get('estimateDamageRepair').value}}" target="_blank">
                          <i class="ti-eye text-info m-r-10"></i>
                        </a>
                        <a class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer ml-2" (click)="removeImage('estimateDamageRepair')" placement="left" ngbTooltip="Change Document">
                          <i class="ti-trash text-danger m-r-10"></i>
                        </a>
                      </div>
                    </div>
                    <div *ngIf="!debitForm.get('estimateDamageRepair').value">
                      <input type="file" name="deductiblesSection" (change)="onSelectFile($event, 'estimateDamageRepair')" class="file-upload-width"/>
                      <a *ngIf="debitForm.get('estimateDamageRepair').value" class="ng2-smart-action ng2-smart-action-edit-edit ml-2 float-right" placement="left" ngbTooltip="View Document" href="{{ baseUrl }}{{debitForm.get('estimateDamageRepair').value}}" target="_blank">
                        <i class="ti-eye text-info m-r-10"></i>
                      </a>
                    </div>
                  </div>
                 </div>
                 <div class="form-group d-xl-flex align-items-center">
                  <label class="col-12 col-xl-8">
                    <app-required-indication [control]="debitForm.get('debtCancellationAgreement')" [label]="
                        'A copy of the debt cancellation agreement'"
                    >
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-4">
                    <div *ngIf="debitForm.get('debtCancellationAgreement').value">
                      <label class="col-12 col-xl-9 float-left">{{debitForm.get('debtCancellationAgreement').value.replace('uploads/', '').replace('debitcancellation/','')}}</label>
                      <div class="col-12 col-xl-3 float-left">
                        <a class="ng2-smart-action ng2-smart-action-edit-edit ml-2" placement="left" ngbTooltip="View Document" href="{{ baseUrl }}{{debitForm.get('debtCancellationAgreement').value}}" target="_blank">
                          <i class="ti-eye text-info m-r-10"></i>
                        </a>
                        <a class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer ml-2" (click)="removeImage('debtCancellationAgreement')" placement="left" ngbTooltip="Change Document">
                          <i class="ti-trash text-danger m-r-10"></i>
                        </a>
                      </div>
                    </div>
                    <div *ngIf="!debitForm.get('debtCancellationAgreement').value">
                      <input type="file" name="deductiblesSection" (change)="onSelectFile($event, 'debtCancellationAgreement')" class="file-upload-width"/>
                      <a *ngIf="debitForm.get('debtCancellationAgreement').value" class="ng2-smart-action ng2-smart-action-edit-edit ml-2 float-right" placement="left" ngbTooltip="View Document" href="{{ baseUrl }}{{debitForm.get('debtCancellationAgreement').value}}" target="_blank">
                        <i class="ti-eye text-info m-r-10"></i>
                      </a>
                    </div>
                  </div>
                 </div>
                 <div class="form-group d-xl-flex align-items-center">
                  <label class="col-12 col-xl-8">
                    <app-required-indication [control]="debitForm.get('vehiclesOriginalBill')" [label]="
                        'A copy of the Vehicles original bill of sale'"
                    >
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-4">
                    <div *ngIf="debitForm.get('vehiclesOriginalBill').value">
                      <label class="col-12 col-xl-9 float-left">{{debitForm.get('vehiclesOriginalBill').value.replace('uploads/', '').replace('debitcancellation/','')}}</label>
                      <div class="col-12 col-xl-3 float-left">
                        <a class="ng2-smart-action ng2-smart-action-edit-edit ml-2" placement="left" ngbTooltip="View Document" href="{{ baseUrl }}{{debitForm.get('vehiclesOriginalBill').value}}" target="_blank">
                          <i class="ti-eye text-info m-r-10"></i>
                        </a>
                        <a class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer ml-2" (click)="removeImage('vehiclesOriginalBill')" placement="left" ngbTooltip="Change Document">
                          <i class="ti-trash text-danger m-r-10"></i>
                        </a>
                      </div>
                    </div>
                    <div *ngIf="!debitForm.get('vehiclesOriginalBill').value">
                      <input type="file" name="deductiblesSection" (change)="onSelectFile($event, 'vehiclesOriginalBill')" class="file-upload-width"/>
                      <a *ngIf="debitForm.get('vehiclesOriginalBill').value" class="ng2-smart-action ng2-smart-action-edit-edit ml-2 float-right" placement="left" ngbTooltip="View Document" href="{{ baseUrl }}{{debitForm.get('vehiclesOriginalBill').value}}" target="_blank">
                        <i class="ti-eye text-info m-r-10"></i>
                      </a>
                    </div>
                  </div>
                 </div>
                 <div class="form-group d-xl-flex align-items-center">
                  <label class="col-12 col-xl-8">
                    <app-required-indication [control]="debitForm.get('historyOfFinanceContract')" [label]="
                        'A copy and history of Your Finance Contract, including the Dealer/Creditor\’s or Assignee/Lienholder\’s name, address, and account number, showing all payments made, late charges, and fees'"
                    >
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-4">
                    <div *ngIf="debitForm.get('historyOfFinanceContract').value">
                      <label class="col-12 col-xl-9 float-left">{{debitForm.get('historyOfFinanceContract').value.replace('uploads/', '').replace('debitcancellation/','')}}</label>
                      <div class="col-12 col-xl-3 float-left">
                        <a class="ng2-smart-action ng2-smart-action-edit-edit ml-2" placement="left" ngbTooltip="View Document" href="{{ baseUrl }}{{debitForm.get('historyOfFinanceContract').value}}" target="_blank">
                          <i class="ti-eye text-info m-r-10"></i>
                        </a>
                        <a class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer ml-2" (click)="removeImage('historyOfFinanceContract')" placement="left" ngbTooltip="Change Document">
                          <i class="ti-trash text-danger m-r-10"></i>
                        </a>
                      </div>
                    </div>
                    <div *ngIf="!debitForm.get('historyOfFinanceContract').value">
                      <input type="file" name="deductiblesSection" (change)="onSelectFile($event, 'historyOfFinanceContract')" class="file-upload-width"/>
                      <a *ngIf="debitForm.get('historyOfFinanceContract').value" class="ng2-smart-action ng2-smart-action-edit-edit ml-2 float-right" placement="left" ngbTooltip="View Document" href="{{ baseUrl }}{{debitForm.get('historyOfFinanceContract').value}}" target="_blank">
                        <i class="ti-eye text-info m-r-10"></i>
                      </a>
                    </div>
                  </div>
                 </div>
                 <div class="form-group d-xl-flex align-items-center">
                  <label class="col-12 col-xl-8">
                    <app-required-indication [control]="debitForm.get('payOffStatement')" [label]="
                        'A copy of the pay-off statement for Your Finance Contract as calculated by the Dealer/Creditor or Assignee/Lienholder as of the Date of Total Loss'"
                    >
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-4">
                    <div *ngIf="debitForm.get('payOffStatement').value">
                      <label class="col-12 col-xl-9 float-left">{{debitForm.get('payOffStatement').value.replace('uploads/', '').replace('debitcancellation/','')}}</label>
                      <div class="col-12 col-xl-3 float-left">
                        <a class="ng2-smart-action ng2-smart-action-edit-edit ml-2" placement="left" ngbTooltip="View Document" href="{{ baseUrl }}{{debitForm.get('payOffStatement').value}}" target="_blank">
                          <i class="ti-eye text-info m-r-10"></i>
                        </a>
                        <a class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer ml-2" (click)="removeImage('payOffStatement')" placement="left" ngbTooltip="Change Document">
                          <i class="ti-trash text-danger m-r-10"></i>
                        </a>
                      </div>
                    </div>
                    <div *ngIf="!debitForm.get('payOffStatement').value">
                      <input type="file" name="deductiblesSection" (change)="onSelectFile($event, 'payOffStatement')" class="file-upload-width"/>
                      <a *ngIf="debitForm.get('payOffStatement').value" class="ng2-smart-action ng2-smart-action-edit-edit ml-2 float-right" placement="left" ngbTooltip="View Document" href="{{ baseUrl }}{{debitForm.get('payOffStatement').value}}" target="_blank">
                        <i class="ti-eye text-info m-r-10"></i>
                      </a>
                    </div>
                  </div>
                 </div>
                 <div class="form-group d-xl-flex align-items-center">
                  <label class="col-12 col-xl-8">
                    <app-required-indication [control]="debitForm.get('refundChecksOrAuthorizedCancellation')" [label]="
                        'A copy of refund checks or authorized cancellation forms confirming the refund amount for any credit insurance, mechanical repair coverage, or other similar products that were purchased and included in the Finance Contract'"
                    >
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-4">
                    <div *ngIf="debitForm.get('refundChecksOrAuthorizedCancellation').value">
                      <label class="col-12 col-xl-9 float-left">{{debitForm.get('refundChecksOrAuthorizedCancellation').value.replace('uploads/', '').replace('debitcancellation/','')}}</label>
                      <div class="col-12 col-xl-3 float-left">
                        <a class="ng2-smart-action ng2-smart-action-edit-edit ml-2" placement="left" ngbTooltip="View Document" href="{{ baseUrl }}{{debitForm.get('refundChecksOrAuthorizedCancellation').value}}" target="_blank">
                          <i class="ti-eye text-info m-r-10"></i>
                        </a>
                        <a class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer ml-2" (click)="removeImage('refundChecksOrAuthorizedCancellation')" placement="left" ngbTooltip="Change Document">
                          <i class="ti-trash text-danger m-r-10"></i>
                        </a>
                      </div>
                    </div>
                    <div *ngIf="!debitForm.get('refundChecksOrAuthorizedCancellation').value">
                      <input type="file" name="deductiblesSection" (change)="onSelectFile($event, 'refundChecksOrAuthorizedCancellation')" class="file-upload-width"/>
                      <a *ngIf="debitForm.get('refundChecksOrAuthorizedCancellation').value" class="ng2-smart-action ng2-smart-action-edit-edit ml-2 float-right" placement="left" ngbTooltip="View Document" href="{{ baseUrl }}{{debitForm.get('refundChecksOrAuthorizedCancellation').value}}" target="_blank">
                        <i class="ti-eye text-info m-r-10"></i>
                      </a>
                    </div>
                  </div>
                 </div>
                <div class="form-group d-xl-flex align-items-center">
                  <label class="col-12 col-xl-8">
                    <app-required-indication [control]="debitForm.get('windowStickerOrMSRP')" [label]="
                        'If available, a copy of the window sticker or vehicle invoice showing the MSRP(For new vehicles only)'"
                    >
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-4">
                    <div *ngIf="debitForm.get('windowStickerOrMSRP').value">
                      <label class="col-12 col-xl-9 float-left">{{debitForm.get('windowStickerOrMSRP').value.replace('uploads/', '').replace('debitcancellation/','')}}</label>
                      <div class="col-12 col-xl-3 float-left">
                        <a class="ng2-smart-action ng2-smart-action-edit-edit ml-2" placement="left" ngbTooltip="View Document" href="{{ baseUrl }}{{debitForm.get('windowStickerOrMSRP').value}}" target="_blank">
                          <i class="ti-eye text-info m-r-10"></i>
                        </a>
                        <a class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer ml-2" (click)="removeImage('windowStickerOrMSRP')" placement="left" ngbTooltip="Change Document">
                          <i class="ti-trash text-danger m-r-10"></i>
                        </a>
                      </div>
                    </div>
                    <div *ngIf="!debitForm.get('windowStickerOrMSRP').value">
                      <input type="file" name="deductiblesSection" (change)="onSelectFile($event, 'windowStickerOrMSRP')" class="file-upload-width"/>
                      <a *ngIf="debitForm.get('windowStickerOrMSRP').value" class="ng2-smart-action ng2-smart-action-edit-edit ml-2 float-right" placement="left" ngbTooltip="View Document" 
                        href="{{ baseUrl }}{{debitForm.get('windowStickerOrMSRP').value}}" target="_blank">
                        <i class="ti-eye text-info m-r-10"></i>
                      </a>
                    </div>
                  </div>
                 </div>
                 <div class="form-group d-xl-flex align-items-center">
                  <label class="col-12 col-xl-8">
                    <app-required-indication [control]="debitForm.get('swornProofOfLossStatement')" [label]="
                        'Upon request, a sworn proof of loss statement and/or an examination under path'"
                    >
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-4">
                    <div *ngIf="debitForm.get('swornProofOfLossStatement').value">
                      <label class="col-12 col-xl-9 float-left">{{debitForm.get('swornProofOfLossStatement').value.replace('uploads/', '').replace('debitcancellation/','')}}</label>
                      <div class="col-12 col-xl-3 float-left">
                        <a class="ng2-smart-action ng2-smart-action-edit-edit ml-2" placement="left" ngbTooltip="View Document" href="{{ baseUrl }}{{debitForm.get('swornProofOfLossStatement').value}}" target="_blank">
                          <i class="ti-eye text-info m-r-10"></i>
                        </a>
                        <a class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer ml-2" (click)="removeImage('swornProofOfLossStatement')" placement="left" ngbTooltip="Change Document">
                          <i class="ti-trash text-danger m-r-10"></i>
                        </a>
                      </div>
                    </div>
                    <div *ngIf="!debitForm.get('swornProofOfLossStatement').value">
                      <input type="file" name="deductiblesSection" (change)="onSelectFile($event, 'swornProofOfLossStatement')" class="file-upload-width"/>
                      <a *ngIf="debitForm.get('swornProofOfLossStatement').value" class="ng2-smart-action ng2-smart-action-edit-edit ml-2 float-right" placement="left" ngbTooltip="View Document" 
                        href="{{ baseUrl }}{{debitForm.get('swornProofOfLossStatement').value}}" target="_blank">
                        <i class="ti-eye text-info m-r-10"></i>
                      </a>
                    </div>
                  </div>
                 </div>
              </div>
              <div formArrayName="additionDocs" *ngIf="additionDocs">
                <div class="form-group d-xl-flex align-items-center">
                  <label class="col-12 text-info">
                    <app-required-indication
                      [label]="
                        'Any additional reasonable documentation requested by the Administrator or Us'"
                    >
                    </app-required-indication>
                  </label>
                </div>
                <div *ngFor="let item of additionDocs.controls; let i = index">
                  <div [formGroupName]="i">
                    <div class="form-group d-xl-flex align-items-center">
                      <div
                        class="form-group m-xl-0 col-12 col-xl-7 d-xl-flex align-items-center p-0"
                      >
                        <label class="col-12 col-xl-2 text-xl-center">
                          <app-required-indication
                            [control]="item.get('url')"
                            [label]="'Document'"
                          ></app-required-indication>
                        </label>
                        <div class="col-12 col-xl-10">
                          <input
                            type="file"
                            *ngIf="!item.get('url').value"
                            (change)="onChangeFile($event, i)"
                            name="url"
                            value="item.get('url').value"
                          />
                          <div *ngIf="item.get('url').value">
                            <label class="m-r-10">{{
                              item.get('url').value &&
                                item.get('url').value.substr(
                                  item.get('url').value.lastIndexOf('/') + 1
                                )
                            }}</label>
                            <a
                              class="ng2-smart-action ng2-smart-action-edit-edit"
                              placement="right"
                              ngbTooltip="View Request"
                              href="{{ baseUrl }}{{
                                item.get('url').value
                              }}"
                              target="_blank"
                              ><i class="ti-eye text-info m-r-10"></i>
                            </a>
                            <a
                              class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer"
                              (click)="changeImage(i)"
                              placement="top"
                              ngbTooltip="Change Image"
                            >
                              <i class="ti-move text-danger m-r-10"></i>
                            </a>
                          </div>
                      </div>
                      </div>
                      <div
                        class="form-group m-xl-0 col-12 col-xl-4 d-xl-flex align-items-center p-0"
                      >
                        <label class="col-12 col-xl-3">
                          <app-required-indication
                            [control]="item.get('name')"
                            [label]="'Name'"
                          >
                          </app-required-indication>
                        </label>
                        <div class="d-flex col-12 col-xl-9 p-0">
                          <div class="col-10">
                            <app-form-error-wrapper
                              [control]="item.get('name')"
                              [controlName]="'Title'"
                              [apiErrorType]="'name'"
                            >
                              <input
                                type="text"
                                name="title-{{ i }}"
                                class="form-control"
                                formControlName="name"
                              />
                            </app-form-error-wrapper>
                          </div>
                          <div class="col-2 text-right">
                            <button
                              class="btn btn-danger"
                              (click)="removeDoc(i)"
                            >
                              <i class="mdi mdi-close"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group d-xl-flex align-items-center">
                  <label class="col-12 col-xl-1"></label>
                  <div class="col-12 col-xl-10 pl-5">
                    <button
                      type="button"
                      class="btn btn-primary primary-background"
                      (click)="addDoc()"
                    >
                      <i class="mdi mdi-plus"></i> Add More
                    </button>
                  </div>
                </div>
              </div>
              <!-- <h3 class="title-case" *ngIf="loggedInUserType !=='customer'">AGREEMENT AND FINANCE CONTRACT INFORMATION </h3>
              <div class="col-12 p-0" *ngIf="loggedInUserType !=='customer'">
                <div class="form-group d-xl-flex align-items-center">
                  <div class="form-group col-12 col-xl-4 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-6 p-0">
                      <app-required-indication [control]="debitForm.get('agreementPurchasePrice')"
                        [label]="'Agreement Purchase Price'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-6">
                      <app-form-error-wrapper [control]="debitForm.get('agreementPurchasePrice')"
                        [controlName]="'Deductible amount'" [apiErrorType]="'agreementPurchasePrice'">
                        <input type="text" name="agreementPurchasePrice" class="form-control" currencyMask
                          autocomplete="off" [formControl]="debitForm.controls['agreementPurchasePrice']" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                  <div class="form-group col-12 col-xl-4 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-7">
                      <app-required-indication [control]="debitForm.get('agreementPurchaseDate')"
                        [label]="'Agreement Purchase Date'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-5 pos-rel">
                      <app-form-error-wrapper [control]="debitForm.get('agreementPurchaseDate')"
                        [controlName]="'Date Leased Vehicle Returned'" [apiErrorType]="'agreementPurchaseDate'">
                        <input type="text" name="agreementPurchaseDate" formControlName="agreementPurchaseDate"
                          placeholder="MM/DD/YYYY" ngbDatepicker #d1="ngbDatepicker" class="form-control"
                          (click)="d1.toggle()" [readonly]="true" [minDate]="{ year: 1900, month: 1, day: 1 }"
                          [maxDate]="{
                            year: utilService.getCurrentYear,
                            month: utilService.getCurrentMonth,
                            day: utilService.getCurrentDay
                          }" />
                      </app-form-error-wrapper>
                      <a class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer pos-abs datecancel-r15"
                        (click)="utilService.removeDate(debitForm, 'agreementPurchaseDate')" placement="left"
                        ngbTooltip="Remove" *ngIf="debitForm.controls['agreementPurchaseDate'].value">
                        <i class="ti-close text-danger m-r-10"></i>
                      </a>
                    </div>
                  </div>
                  <div class="form-group col-12 col-xl-4 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-6 p-0">
                      <app-required-indication [control]="debitForm.get('maximunBenefitLimit')"
                        [label]="'Maximum benefit limit'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-6">
                      <app-form-error-wrapper [control]="debitForm.get('maximunBenefitLimit')"
                        [controlName]="'Deductible amount'" [apiErrorType]="'maximunBenefitLimit'">
                        <input type="text" name="maximunBenefitLimit" class="form-control" currencyMask
                          autocomplete="off" [formControl]="debitForm.controls['maximunBenefitLimit']"
                          (change)="GAPCalculation()" (keyup)="GAPCalculation()"  />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                </div>
                <div class="form-group d-xl-flex align-items-center">
                  <div class="form-group col-12 col-xl-4 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-6 p-0">
                      <app-required-indication [control]="debitForm.get('vehiclePurchasePrice')"
                        [label]="'Vehicle Purchase Price'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-6">
                      <app-form-error-wrapper [control]="debitForm.get('vehiclePurchasePrice')"
                        [controlName]="'Vehicle Purchase Price'" [apiErrorType]="'vehiclePurchasePrice'">
                        <input type="text" name="vehiclePurchasePrice" class="form-control" currencyMask
                          autocomplete="off" [formControl]="debitForm.controls['vehiclePurchasePrice']"
                           (change)="GAPCalculation()" (keyup)="GAPCalculation()"  />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                  <div class="form-group col-12 col-xl-4 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-7">
                      <app-required-indication [control]="debitForm.get('dateOfFirstPayment')"
                        [label]="'Date of First Payment'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-5 pos-rel">
                      <app-form-error-wrapper [control]="debitForm.get('dateOfFirstPayment')"
                        [controlName]="'Date of First Payment'" [apiErrorType]="'dateOfFirstPayment'">
                        <input type="text" name="dateOfFirstPayment" formControlName="dateOfFirstPayment"
                          placeholder="MM/DD/YYYY" ngbDatepicker #d="ngbDatepicker" class="form-control"
                          (click)="d.toggle()" [readonly]="true" [minDate]="{ year: 1900, month: 1, day: 1 }" [maxDate]="{
                            year: utilService.getCurrentYear,
                            month: utilService.getCurrentMonth,
                            day: utilService.getCurrentDay
                          }" />
                      </app-form-error-wrapper>
                      <a class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer pos-abs datecancel-r15"
                        (click)="utilService.removeDate(debitForm, 'dateOfFirstPayment')" placement="left"
                        ngbTooltip="Remove" *ngIf="debitForm.controls['dateOfFirstPayment'].value">
                        <i class="ti-close text-danger m-r-10"></i>
                      </a>
                    </div>
                  </div>
                  <div class="form-group col-12 col-xl-4 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-6 p-0">
                      <app-required-indication [control]="debitForm.get('APR')" [label]="'APR'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-6">
                      <app-form-error-wrapper [control]="debitForm.get('APR')" [controlName]="'APR'"
                        [apiErrorType]="'APR'">
                        <input type="text" name="APR" class="form-control"
                        maxlength="3" 
                        min="0" 
                        max="100" [formControl]="debitForm.controls['APR']" (keypress)="omit_char($event)" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                </div>
                <div class="form-group d-xl-flex align-items-center">
                  <div class="form-group col-12 col-xl-4 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-6 p-0">
                      <app-required-indication [control]="debitForm.get('monthlyPaymentAmount')"
                        [label]="'Monthly Payment Amount'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-6">
                      <app-form-error-wrapper [control]="debitForm.get('monthlyPaymentAmount')"
                        [controlName]="'Monthly Payment Amount'" [apiErrorType]="'monthlyPaymentAmount'">
                        <input type="text" name="monthlyPaymentAmount" class="form-control" currencyMask
                          autocomplete="off" [formControl]="debitForm.controls['monthlyPaymentAmount']"
                          (change)="GAPCalculation()" (keyup)="GAPCalculation()" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                  <div class="form-group col-12 col-xl-4 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-7">
                      <app-required-indication [control]="debitForm.get('amountFinanced')" [label]="'Amount Financed'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-5">
                      <app-form-error-wrapper [control]="debitForm.get('amountFinanced')"
                        [controlName]="'amountFinanced'" [apiErrorType]="'amountFinanced'">
                        <input type="text" name="amountFinanced" class="form-control" currencyMask autocomplete="off"
                          [formControl]="debitForm.controls['amountFinanced']" (change)="GAPCalculation()" (keyup)="GAPCalculation()" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                  <div class="form-group col-12 col-xl-4 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-6 p-0">
                      <app-required-indication [control]="debitForm.get('totalOfAllPayement')" [label]="'Total of All Payment'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-6">
                      <app-form-error-wrapper [control]="debitForm.get('totalOfAllPayement')"
                        [controlName]="'totalOfAllPayement'" [apiErrorType]="'totalOfAllPayement'">
                        <input type="text" name="totalOfAllPayement" class="form-control" currencyMask
                          autocomplete="off" [formControl]="debitForm.controls['totalOfAllPayement']"
                          (change)="GAPCalculation()" (keyup)="GAPCalculation()" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                </div>
                <div class="form-group d-xl-flex align-items-center">
                  <div class="form-group col-12 col-xl-12 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-2 p-0">
                      <app-required-indication [control]="debitForm.get('financingContractType')"
                        [label]="'Type of Financing Contract'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-4">
                      <div class="custom-control custom-checkbox ">
                        <div class="custom-control custom-checkbox ">
                          <input id="checkbox-retailInstallmentSales" class="custom-control-input"
                            (click)="selectCheckbox('retailInstallmentSales', $event, 'financingContractType')"
                            type="checkbox"
                            [checked]="this.debitForm.get('financingContractType').value.retailInstallmentSales" />
                          <label for="checkbox-retailInstallmentSales" class="custom-control-label">Retail Installment
                            Sales (CONVENTIONAL LOANS)</label>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-xl-2">
                      <div class="custom-control custom-checkbox ">
                        <input id="checkbox-lease" class="custom-control-input"
                          (click)="selectCheckbox('lease', $event, 'financingContractType')" type="checkbox"
                          [checked]="this.debitForm.get('financingContractType').value.lease" />
                        <label for="checkbox-lease" class="custom-control-label">LEASE</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              <!-- <h3 class="title-case"
                *ngIf="isEditMode && (loggedInUserType==='dowcsuperadmin' || loggedInUserType==='superadmin')">
                CACULATIONS </h3>
              <div class="col-12 p-0"
                *ngIf="isEditMode && (loggedInUserType==='dowcsuperadmin' || loggedInUserType==='superadmin')">
                <div class="form-group col-12 d-xl-flex align-items-center">
                  <label class="col-12 col-xl-8 p-0">
                    <app-required-indication [control]="debitForm.get('outstandingAmountFinanced')"
                      [label]="'Outstanding amount financed'">
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-4">
                    <app-form-error-wrapper [control]="debitForm.get('outstandingAmountFinanced')"
                      [controlName]="'outstandingAmountFinanced'" [apiErrorType]="'outstandingAmountFinanced'">
                      <input type="text" name="outstandingAmountFinanced" class="form-control" currencyMask
                        autocomplete="off" [formControl]="debitForm.controls['outstandingAmountFinanced']"
                        [readonly]="true" />
                    </app-form-error-wrapper>
                  </div>
                </div>
                <div class="form-group col-12 d-xl-flex align-items-center">
                  <label class="col-12 col-xl-8 p-0">
                    <app-required-indication [control]="debitForm.get('cancelledProducts')"
                      [label]="'Cancelled products'">
                    </app-required-indication><sup class="required">*</sup>
                  </label>
                  <div class="col-12 col-xl-4">
                    <app-form-error-wrapper [control]="debitForm.get('cancelledProducts')"
                      [controlName]="'cancelledProducts'" [apiErrorType]="'cancelledProducts'">
                      <input type="text" name="cancelledProducts" class="form-control" currencyMask autocomplete="off"
                        [formControl]="debitForm.controls['cancelledProducts']" (change)="GAPCalculation()"
                        (keyup)="GAPCalculation()" />
                    </app-form-error-wrapper>
                  </div>
                </div>
                <div class="form-group col-12 d-xl-flex align-items-center">
                  <label class="col-12 col-xl-8 p-0">
                    <app-required-indication [control]="debitForm.get('subTotal')" [label]="'Subtotal'">
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-4">
                    <app-form-error-wrapper [control]="debitForm.get('subTotal')" [controlName]="'subTotal'"
                      [apiErrorType]="'subTotal'">
                      <input type="text" name="subTotal" class="form-control" currencyMask autocomplete="off"
                        [formControl]="debitForm.controls['subTotal']" [readonly]="true" />
                    </app-form-error-wrapper>
                  </div>
                </div>
                <div class="form-group col-12 d-xl-flex align-items-center">
                  <label class="col-12 col-xl-8 p-0">
                    <app-required-indication [control]="debitForm.get('consumerLiability')"
                      [label]="'Consumer liability'">
                    </app-required-indication><sup class="required">*</sup>
                  </label>
                  <div class="col-12 col-xl-4">
                    <app-form-error-wrapper [control]="debitForm.get('consumerLiability')"
                      [controlName]="'consumerLiability'" [apiErrorType]="'consumerLiability'">
                      <input type="text" name="consumerLiability" class="form-control" currencyMask autocomplete="off"
                        [formControl]="debitForm.controls['consumerLiability']" (change)="GAPCalculation()"
                        (keyup)="GAPCalculation()" />
                    </app-form-error-wrapper>
                  </div>
                </div>
                <div class="form-group col-12 d-xl-flex align-items-center">
                  <label class="col-12 col-xl-8 p-0">
                    <app-required-indication [control]="debitForm.get('maximumDebtCancellation')"
                      [label]="'Maximum Debt cancellation amount'">
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-4">
                    <app-form-error-wrapper [control]="debitForm.get('maximumDebtCancellation')"
                      [controlName]="'maximumDebtCancellation'" [apiErrorType]="'maximumDebtCancellation'">
                      <input type="text" name="maximumDebtCancellation" class="form-control" currencyMask
                        autocomplete="off" [formControl]="debitForm.controls['maximumDebtCancellation']"
                        [readonly]="true" />
                    </app-form-error-wrapper>
                  </div>
                </div>
              </div> -->
              <!-- <h3 class="title-case"
                *ngIf="isEditMode && (loggedInUserType==='dowcsuperadmin' || loggedInUserType==='superadmin')">PAYOUT
              </h3>
              <div class="col-12 p-0"
                *ngIf="isEditMode && (loggedInUserType==='dowcsuperadmin' || loggedInUserType==='superadmin')">
                <div class="form-group col-12 d-xl-flex align-items-center">
                  <label class="col-12 col-xl-8 p-0">
                    <app-required-indication [control]="debitForm.get('truncatedAmount')" [label]="'Truncated amount'">
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-4">
                    <app-form-error-wrapper [control]="debitForm.get('truncatedAmount')"
                      [controlName]="'truncatedAmount'" [apiErrorType]="'truncatedAmount'">
                      <input type="text" name="truncatedAmount" class="form-control" currencyMask autocomplete="off"
                        [formControl]="debitForm.controls['truncatedAmount']" [readonly]="true" />
                    </app-form-error-wrapper>
                  </div>
                </div>
                <div class="form-group col-12 d-xl-flex align-items-center">
                  <label class="col-12 col-xl-8 p-0">
                    <app-required-indication [control]="debitForm.get('salvageCredit')"
                      [label]="'Salvage credit (10%)'">
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-4">
                    <app-form-error-wrapper [control]="debitForm.get('salvageCredit')" [controlName]="'salvageCredit'"
                      [apiErrorType]="'salvageCredit'">
                      <input type="text" name="salvageCredit" class="form-control" currencyMask autocomplete="off"
                        [formControl]="debitForm.controls['salvageCredit']" [readonly]="true" />
                    </app-form-error-wrapper>
                  </div>
                </div>
                <div class="form-group col-12 d-xl-flex align-items-center">
                  <label class="col-12 col-xl-8 p-0">
                    <app-required-indication [control]="debitForm.get('finalPayout')"
                      [label]="'Final Payout to Creditor'">
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-4">
                    <app-form-error-wrapper [control]="debitForm.get('finalPayout')" [controlName]="'finalPayout'"
                      [apiErrorType]="'finalPayout'">
                      <input type="text" name="finalPayout" class="form-control" currencyMask autocomplete="off"
                        [readonly]="true" [formControl]="debitForm.controls['finalPayout']" />
                    </app-form-error-wrapper>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-12 text-right">
              <button type="button" (click)="cancelClick()" class="btn btn-secondary mr-2">
                Cancel
              </button>
              <button *ngIf="isEditMode" class="btn btn-info primary-background" [disabled]="debitForm.invalid || isLoading">
                <app-button-loader [isLoading]="isLoading" [label]="isAdmin?'Calculations':'Update Claim'" (click)="checkValidfields()"></app-button-loader>
              </button>
              <button *ngIf="isEditMode===false" class="btn btn-info primary-background" [disabled]="debitForm.invalid || isLoading">
                <app-button-loader [isLoading]="isLoading" [label]="'Create Claim'"></app-button-loader>
              </button>
              <!-- <button class="btn btn-primary" routerLink="/agents">Create Agent</button> -->
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="help-container">
      <div class="help-section">
        <p class="help-text" (click)="openHelpPopup()">
          <i class="hellp-popup-icons"></i>
        </p>
      </div>
    </div>
  </div>
</div>