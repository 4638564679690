import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ClaimService } from '@app/core/service/claim.service';
import { KeyfechService } from '@app/core/service/keyfetch.service';
import { shieldService } from '@app/core/service/shield.service';
import { ToastrService } from 'ngx-toastr';
import { LOCAL_VAR } from './../../../core/constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-support-help',
  templateUrl: './support-help.component.html',
  styleUrls: ['./support-help.component.scss']
})
export class SupportHelpComponent implements OnInit {
  @Input()
  helpId?= '';
  message?= '';
  updateTime?='';
  ticket?= '';
  userData?= '';
  userEmail?= '';
  userPhone?= '';
  notes?= '';
  status?= 'open';

  selectedCustomer: any = null;
  helpSupport: FormGroup;
  isLoading = false;
  constructor(
    public activeModal: NgbActiveModal,
    public claimService: ClaimService,
    public KeyfechService: KeyfechService,
    public shieldService: shieldService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    public router: Router
  ) {
    this.selectedCustomer = localStorage.getItem(LOCAL_VAR.selectedCustomer);
    this.selectedCustomer = JSON.parse(this.selectedCustomer);
    this.createForm();
  }

  ngOnInit() {
    this.helpSupport.controls['notes'].setValue(this.notes);
    this.helpSupport.controls['status'].setValue(this.status);
  }

  createForm() {
    this.helpSupport = this.formBuilder.group({
      notes: [this.notes, [Validators.required]],
      status: [this.status, [Validators.required]]
    });
  }

  rejectRequest() {
      let helpFormdata=this.helpSupport.value;
      if (this.helpSupport.valid) {
         this.claimService
           .helpUpdate(this.helpId, helpFormdata)
           .subscribe((response: any) => {
             if (response) {
               this.toastrService.info('Updated Successfully..!');
               this.activeModal.close(response);
             } else {
               this.toastrService.warning('something went Wrong');
             }
           });
       }
  }
}
