import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  constructor(protected httpClient: HttpClient) {}
  getAllCustomers() {
    return this.httpClient.get(`/customer`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  getAllCustomersByFilter(payload: any) {
    let params = payload.status
      ? new HttpParams().set('status', `${payload.status}`)
      : new HttpParams();

    params = payload.firstName
      ? params.append('firstName', `${payload.firstName}`)
      : params;
    params = payload.lastName
      ? params.append('lastName', `${payload.lastName}`)
      : params;
    params = payload.email
      ? params.append('email', `${payload.email}`)
      : params;
    params = payload.role ? params.append('role', `${payload.role}`) : params;
    params = payload.dealer
      ? params.append('dealerId', `${payload.dealer}`)
      : params;
    params = payload.sortFieldFilter
      ? params.append('sortFieldFilter', `${payload.sortFieldFilter}`)
      : params;
    params = payload.sortByFilter
      ? params.append('sortByFilter', `${payload.sortByFilter}`)
      : params;
    params = params.append('currentPage', `${payload.currentPage}`);
    params = params.append('perPage', `${payload.perPage}`);

    return this.httpClient.get('/customer', { params }).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  getCustomerDetail(customerId: String) {
    return this.httpClient.get(`/customer/${customerId}`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  getCustomerRoles() {
    return this.httpClient.get(`/userroles`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  getConfig() {
    return this.httpClient.get(`/config`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  updateConfig(payload: any) {
    return this.httpClient.post(`/updateconfig`, payload).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  createCustomer(payload: any) {
    return this.httpClient.post(`/customer`, payload).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  updateCustomer(customerId: String, payload: any) {
    return this.httpClient.put(`/customer/${customerId}`, payload).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  sendActivationCode(customerId: String) {
    return this.httpClient.get(`/customer/${customerId}/sendcode`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  deleteCustomer(customerId: String) {
    return this.httpClient.delete(`/customer/${customerId}`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  getDealers() {
    return this.httpClient.get(`/dealerlist`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }
  
  getallDealers() {
    return this.httpClient.get(`/alldealerlist`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

//audit
getAuditActions() {
  return this.httpClient.get(`/auditactions`).pipe(
    map((body: any) => {
      return body;
    })
  );
}

getAllAudits(payload: any) {
  let params = new HttpParams();
  params = params.append('currentPage', `${payload.currentPage}`);
  params = params.append('perPage', `${payload.perPage}`);
  return this.httpClient.get(`/audit`, {params}).pipe(
    map((body: any) => {
      return body;
    })
  );
}

getAllAuditsByFilter(payload: any) {
    let params = payload.customerId
      ? new HttpParams().set('customerId', `${payload.customerId}`)
      : new HttpParams();
    params = payload.datetime_from
      ? params.append('datetime_from', `${payload.datetime_from}`)
      : params;
    params = payload.datetime_to
      ? params.append('datetime_to', `${payload.datetime_to}`)
      : params;
    params = payload.rid
      ? params.append('rId', `${payload.rid}`)
      : params;
    params = payload.type
      ? params.append('type', `${payload.type}`)
      : params;
    params = payload.cid
      ? params.append('cid', `${payload.cid}`)
      : params;
    params = payload.email
      ? params.append('email', `${payload.email}`)
      : params;
    params = payload.domain
      ? params.append('domain', `${payload.domain}`)
      : params;
    params = payload.name
      ? params.append('name', `${payload.name}`)
      : params;
    params = payload.sortFieldFilter
      ? params.append('sortFieldFilter', `${payload.sortFieldFilter}`)
      : params;
    params = payload.sortByFilter
      ? params.append('sortByFilter', `${payload.sortByFilter}`)
      : params;
    params = params.append('currentPage', `${payload.currentPage}`);
    params = params.append('perPage', `${payload.perPage}`);
    return this.httpClient.get('/audit', { params }).pipe(
    map((body: any) => {
      return body;
    })
  );
}

getAllDealers(payload: any) {
  let params = payload.status
    ? new HttpParams().set('status', `${payload.status}`)
    : new HttpParams();
  params = payload.dealername
    ? params.append('name', `${payload.dealername}`)
    : params;
  params = payload.sortFieldFilter
    ? params.append('sortFieldFilter', `${payload.sortFieldFilter}`)
    : params;
  params = payload.sortByFilter
    ? params.append('sortByFilter', `${payload.sortByFilter}`)
    : params;
  params = params.append('currentPage', `${payload.currentPage}`);
  params = params.append('perPage', `${payload.perPage}`);

  return this.httpClient.get('/dealer', { params }).pipe(
    map((body: any) => {
      return body;
    })
  );
}

createDealer(payload: any) {
  return this.httpClient.post(`/createdealer`, payload).pipe(
    map((body: any) => {
      return body;
    })
  );
}

updateDealer(payload: any) {
  return this.httpClient.post(`/updatedealer`, payload).pipe(
    map((body: any) => {
      return body;
    })
  );
}

getAllDealerGroup(payload: any) {
  let params = payload.status
    ? new HttpParams().set('status', `${payload.status}`)
    : new HttpParams();
  params = payload.dealername
    ? params.append('name', `${payload.dealername}`)
    : params;
  params = payload.domain
    ? params.append('domain', `${payload.domain}`)
    : params;
  params = payload.config
    ? params.append('config', `${payload.config}`)
    : params;
  params = payload.sortFieldFilter
    ? params.append('sortFieldFilter', `${payload.sortFieldFilter}`)
    : params;
  params = payload.sortByFilter
    ? params.append('sortByFilter', `${payload.sortByFilter}`)
    : params;
  params = params.append('currentPage', `${payload.currentPage}`);
  params = params.append('perPage', `${payload.perPage}`);

  return this.httpClient.get('/dealergroups', { params }).pipe(
    map((body: any) => {
      return body;
    })
  );
}

getDealerGroups() {
  return this.httpClient.get(`/dealergroup`).pipe(
    map((body: any) => {
      return body;
    })
  );
}

getDealerGroupDetail(dealerGroupId: String) {
  return this.httpClient.get(`/dealergroup/${dealerGroupId}`).pipe(
    map((body: any) => {
      return body;
    })
  );
}

checkDealerGroupByDealer(dealerId: String) {
  return this.httpClient.get(`/dealergroupcheck/${dealerId}`).pipe(
    map((body: any) => {
      return body;
    })
  );
}

createDealerGroup(payload: any) {
  return this.httpClient.post(`/createdealergroup`, payload).pipe(
    map((body: any) => {
      return body;
    })
  );
}


updateDealerGroup(dealerGroupId: String,payload: any) {
  return this.httpClient.post(`/updatedealergroup/${dealerGroupId}`, payload).pipe(
    map((body: any) => {
      return body;
    })
  );
}

getIpdetails(ip:any){
  return this.httpClient.get(`/ipdetails/${ip}`).pipe(
    map((body: any) => {
      return body;
    })
  );
}

  getAllHelpByFilter(payload: any) {
    let params = payload.customerId
      ? new HttpParams().set('customerId', `${payload.customerId}`)
      : new HttpParams();
    params = payload.datetime_from
      ? params.append('datetime_from', `${payload.datetime_from}`)
      : params;
    params = payload.datetime_to
      ? params.append('datetime_to', `${payload.datetime_to}`)
      : params;
    params = payload.sequence
      ? params.append('sequence', `${payload.sequence}`)
      : params;
    params = payload.message
      ? params.append('message', `${payload.message}`)
      : params;
    params = payload.request
      ? params.append('request', `${payload.request}`)
      : params;
    params = payload.status
      ? params.append('status', `${payload.status}`)
      : params;
    params = payload.email
      ? params.append('email', `${payload.email}`)
      : params;
    params = payload.sortFieldFilter
      ? params.append('sortFieldFilter', `${payload.sortFieldFilter}`)
      : params;
    params = payload.sortByFilter
      ? params.append('sortByFilter', `${payload.sortByFilter}`)
      : params;
    params = params.append('currentPage', `${payload.currentPage}`);
    params = params.append('perPage', `${payload.perPage}`);
    return this.httpClient.get('/help', { params }).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

}
