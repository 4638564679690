import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  constructor(protected httpClient: HttpClient) {}

  updateProfile(payload: any): Observable<any> {
    return this.httpClient.put(`/updateprofile`, payload).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  updatePassword(payload: any): Observable<Authentication.Credentials> {
    return this.httpClient.put('/updatepassword', payload).pipe(
      map((body: Authentication.Credentials) => {
        return body;
      })
    );
  }
}
