<div class="row agent-container">
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        <div class="fl-lt ">Theft Claim Preview</div>
      </div>

      <div class="card-group"  *ngIf="claimDomain">
        <div class="card-body pb-0">
          <div class="row">
            <div class="col-md-12 text-center p-1 domain-bg">
              <img class="domain-logo" style="width: 150px;" src={{claimDomain.appLogo}} alt={{claimDomain.appName}}> 
            </div>      
          </div>
        </div>
      </div>

      <div class="card-group" *ngIf="claims">
        <div class="card-body">
          <div class="row">
            <div class="col-4">
              <table class="w-100 preview-table">
                <tbody>
                  <tr class="table-title">
                    <td colspan="2">Claim Details</td>
                  </tr>
                  <tr>
                    <td width="60%">
                      <span>Theft Claim No</span>
                    </td>
                    <td width="40%">
                      {{ claims.sequence }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Claim Number
                    </td>
                    <td>
                      <span>{{ claims?.claimNumber }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Status</span>
                    </td>
                    <td>
                      <span class="label text-white"
                      [ngClass]="{
                        'bg-inprogress': claims.status === 'in progress',
                        'bg-inreview': claims.status === 'in review',
                        'bg-approved': claims.status === 'approved',
                        'bg-completed': claims.status === 'completed',
                        'bg-rejected': claims.status === 'rejected',
                        'bg-denied': claims.status === 'denied',
                        'bg-voided': claims.status === 'voided'
                      }">{{ claims.status | titlecase }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Created By</span>
                    </td>
                    <td>
                      <div *ngIf="claims?.userId?.firstName; else cCustomer">
                        {{ claims.userId?.firstName }} {{ claims.userId?.lastName }}
                      </div>
                      <ng-template #cCustomer>
                        {{ appname }} User
                      </ng-template>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Created On</span>
                    </td>
                    <td>
                      {{ claims.createdAt | date: 'MM/dd/yyyy' }}
                    </td>
                  </tr>
                  <tr *ngIf="claims.status === 'approved' || claims.status === 'rejected'">
                    <td>
                      <span>{{ claims.status | titlecase }} By</span>
                    </td>
                    <td>
                      <span *ngIf="claims?.adminId?.firstName; else dAdmin">
                        {{ claims.adminId?.firstName }}
                        {{ claims.adminId?.lastName }}
                      </span>
                      <ng-template #dAdmin> {{ appname }} Admin </ng-template>
                    </td>

                  </tr>
                  <tr *ngIf="claims.status === 'approved' || claims.status === 'rejected'">
                    <td>
                      <span>{{ claims.status | titlecase }} On</span>
                    </td>
                    <td>
                      {{ claims.updatedAt
                      | date: 'MM/dd/yyyy' }}
                    </td>
                  </tr>
                  <tr *ngIf="claims.status === 'rejected' || claims.status === 'denied' || claims.status === 'voided'" class="alert alert-danger">
                    <td colspan="2" *ngIf="claims.status === 'rejected'">
                      What is reason behind reject claim ?<br />
                      {{ claims.statusMessage }}
                    </td>
                    <td colspan="2" *ngIf="claims.status === 'denied'">
                      What is reason behind denying claim ?<br />
                      {{ claims.statusMessage }}
                    </td>
                    <td colspan="2" *ngIf="claims.status === 'voided'">
                      Reason for Voiding<br />
                      {{ claims.statusMessage }}
                    </td>
                  </tr>
                    <tr *ngIf="
                    (loggedInUserType === 'superadmin' ||
                      loggedInUserType === 'dowcsuperadmin') &&
                    claims.status !== 'in review'
                  ">
                      <td colspan="2">
                        <div class="form-group mb-0 status-change">
                          <form [formGroup]="statusChange">
                            <label class="d-block">Update Status</label>
                            <select
                              class="form-control custom-select m-r-10"
                              name="status"
                              formControlName="status"
                            >
                              <option class="custom-select-option" value=""
                                >Select Status</option
                              >
                              <!-- <option class="custom-select-option" *ngIf="
                              claims.status !== 'in progress'" value="in progress"
                                >In Progress</option
                              > -->
                              <option
                                *ngIf="claims.status !== 'in review' && claims.status !== 'in progress'"
                                class="custom-select-option"
                                value="in review"
                                >In Review</option
                              >
                            </select>
                            <button
                              (click)="changeStatus(claims._id)"
                              class="btn btn-primary d-flex align-items-center"
                            >
                              Update
                            </button>
                          </form>
                        </div>
                      </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-4">
              <table class="w-100 preview-table">
                <tbody>
                  <tr class="table-title">
                    <td colspan="2">Customer Information</td>
                  </tr>
                  <tr>
                    <td width="60%">Contract Number</td>
                    <td width="40%">{{ claims.contractNumber }}</td>
                  </tr>
                  <tr>
                    <td>Claim Date</td>
                    <td>{{ claims.claimDate | date: 'MM/dd/yyyy' }}</td>
                  </tr>
                  <tr>
                    <td>Customer Name</td>
                    <td>{{ claims.customerName }}</td>
                  </tr>
                  <tr>
                    <td>Street Address</td>
                    <td>{{ claims.streetAddress }}</td>
                  </tr>
                  <tr>
                    <td>City/State/Zip</td>
                    <td><span *ngIf="claims.city">{{ claims.city }} - {{ claims.state }} - {{ claims.zipCode }}</span></td>
                  </tr>
                  <tr>
                    <td>Phone Number</td>
                    <td>
                      <span *ngIf="claims.phoneNumber > 8">
                        {{ claims.phoneNumber | phone }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>{{ claims.email }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-4">
              <table class="w-100 preview-table">
                <tbody>
                  <tr class="table-title">
                    <td colspan="2">Vehicle Information</td>
                  </tr>
                  <tr>
                    <td width="60%">VIN Number</td>
                    <td width="40%">{{ claims.vinNumber }}</td>
                  </tr>
                  <tr>
                    <td>Current mileage</td>
                    <td>{{ claims.currentMileage }}</td>
                  </tr>
                  <tr>
                    <td>Year - Make - Model</td>
                    <td><span *ngIf="claims.year">{{ claims.year }} - {{ claims.make }} - {{ claims.model }}</span></td>
                  </tr>                  
                </tbody>
              </table>
            </div>
            
          </div>
          <div class="row">
            <div class="col-8">
              <table class="w-100 preview-table">
                <tbody>
                  <tr>
                    <td class="table-title" colspan="2">
                      Customer Documents Needed
                    </td>
                  </tr>
                  <tr>
                    <td width="60%">
                      A copy of this Limited Warranty
                    </td>
                    <td width="40%">
                      <a class="doc" *ngIf="claims.copyOfLimitedWarranty" href="{{ baseUrl }}{{ claims.copyOfLimitedWarranty }}"
                        target="_blank">{{ claims.copyOfLimitedWarranty.replace('theft/', '').split('/')[1].replaceAll('_',' ').substring(14) }}</a>
                    </td>
                  </tr>
                  <tr *ngIf="claims.profOfInstallation">
                    <td width="60%">
                      Proof of installation of the Theft Protection Product to Your Vehicle
                    </td>
                    <td width="40%">
                      <a class="doc" *ngIf="claims.profOfInstallation" href="{{ baseUrl }}{{ claims.profOfInstallation }}"
                        target="_blank">{{ claims.profOfInstallation.replace('theft/', '').split('/')[1].replaceAll('_',' ').substring(14) }}</a>
                    </td>
                  </tr>
                  <tr *ngIf="claims.copyOfOrinalBuyer">
                    <td width="60%">
                      A copy of the original Buyer’s Order for the Vehicle stating the purchase price as well as any factory options and/or accessories affixed thereto at the time of delivery
                    </td>
                    <td width="40%">
                      <a class="doc" *ngIf="claims.copyOfOrinalBuyer" href="{{ baseUrl }}{{ claims.copyOfOrinalBuyer }}"
                        target="_blank">{{ claims.copyOfOrinalBuyer.replace('theft/', '').split('/')[1].replaceAll('_',' ').substring(14) }}</a>
                    </td>
                  </tr>
                  <tr *ngIf="claims.copyOfPoliceReport">
                    <td width="60%">
                      A copy of the police report relating to the theft including any supplements and/or recovery reports
                    </td>
                    <td width="40%">
                      <a class="doc" *ngIf="claims.copyOfPoliceReport" href="{{ baseUrl }}{{ claims.copyOfPoliceReport }}"
                        target="_blank">{{ claims.copyOfPoliceReport.replace('theft/', '').split('/')[1].replaceAll('_',' ').substring(14) }}</a>
                    </td>
                  </tr>
                  <tr *ngIf="claims.copyOfPrimaryCarrierStatement">
                    <td width="60%">
                      A copy of Your Primary Carrier’s settlement check, declaration page, vehicle theft affidavit, proof of loss, and valuation worksheet, if You carry Primary Carrier insurance
                    </td>
                    <td width="40%">
                      <a class="doc" *ngIf="claims.copyOfPrimaryCarrierStatement" href="{{ baseUrl }}{{ claims.copyOfPrimaryCarrierStatement }}"
                        target="_blank">{{ claims.copyOfPrimaryCarrierStatement.replace('theft/', '').split('/')[1].replaceAll('_',' ').substring(14) }}</a>
                    </td>
                  </tr>
                  <tr *ngIf="claims.additionalReasonableDocument">
                    <td width="60%">
                      Any additional reasonable documentation requested by the Administrator. The Administrator will not obtain this information for You
                    </td>
                    <td width="40%">
                      <a class="doc" *ngIf="claims.additionalReasonableDocument" href="{{ baseUrl }}{{ claims.additionalReasonableDocument }}"
                        target="_blank">{{ claims.additionalReasonableDocument.replace('theft/', '').split('/')[1].replaceAll('_',' ').substring(14) }}</a>
                    </td>
                  </tr>
                  <tr *ngIf="claims.onVacation">
                    <td width="60%">
                      Were you on vacation?
                    </td>
                    <td width="40%">
                      {{ claims.onVacation }}
                    </td>
                  </tr>
                  <tr *ngIf="claims.profOfPaymentForExtend && claims.onVacation=='Yes'">
                    <td width="60%">
                      Proof of payment receipt for extended food and lodging
                    </td>
                    <td width="40%">
                      <a class="doc" *ngIf="claims.profOfPaymentForExtend" href="{{ baseUrl }}{{ claims.profOfPaymentForExtend }}"
                        target="_blank">{{ claims.profOfPaymentForExtend.replace('theft/', '').split('/')[1].replaceAll('_',' ').substring(14) }}</a>
                    </td>
                  </tr>
                  <tr *ngIf="claims.isCarRent">
                    <td width="60%">
                      Did you rent a car due to the theft of your vehicle?
                    </td>
                    <td width="40%">
                      {{ claims.isCarRent }}
                    </td>
                  </tr>
                  <tr *ngIf="claims.profOfRentalReceipt && claims.isCarRent=='Yes'">
                    <td width="60%">
                      Proof of payment receipt for rental
                    </td>
                    <td width="40%">
                      <a class="doc" *ngIf="claims.profOfRentalReceipt" href="{{ baseUrl }}{{ claims.profOfRentalReceipt }}"
                        target="_blank">{{ claims.profOfRentalReceipt.replace('theft/', '').split('/')[1].replaceAll('_',' ').substring(14) }}</a>
                    </td>
                  </tr>
                  <tr>
                    <td>Reason & description for replacement</td>
                    <td>{{ claims.reasonForReplace }}</td>
                  </tr> 
                  <!-- <ng-container *ngIf="claims.moreDocs?.length">
                    <ng-container
                      *ngFor="let docs of claims.moreDocs; let i = index"
                    >
                      <tr>
                        <td>{{ docs.name }}</td>
                        <td><a href="{{ baseUrl }}{{ docs.url }}" target="_blank"
                            >{{ docs.url.replace('theft/','').split('/')[1].replaceAll('_',' ').substring(14) }}</a>
                        </td>
                      </tr>
                    </ng-container>
                  </ng-container>                 -->
                </tbody>
              </table>
            </div>
            <div class="col-4" *ngIf="claims.moreDocs?.length">
              <table class="w-100 preview-table">
                <tbody>
                  <tr>
                    <td class="table-title" colspan="2">
                      More Documents
                    </td>
                  </tr>
                  <ng-container *ngIf="claims.moreDocs?.length">
                    <ng-container
                      *ngFor="let docs of claims.moreDocs; let i = index"
                    >
                      <tr>
                        <td>{{ docs.name }}</td>
                        <td><a href="{{ baseUrl }}{{ docs.url }}" target="_blank"
                            >{{ docs.url.replace('theft/','').split('/')[1].replaceAll('_',' ').substring(14) }}</a>
                        </td>
                      </tr>
                    </ng-container>
                  </ng-container>                
                </tbody>
              </table>              
            </div>
          </div>
          <div class="row" *ngIf="loggedInUserType === 'dowcsuperadmin'">
            <div class="col-12" >
              <table width="100%">
                <thead>
                  <tr>
                    <th colspan="3" class="table-title">Audit logs</th>
                  </tr>
                  <tr>
                    <th width="33.34%">Process</th>
                    <th width="33.33%">Updated At</th>
                    <th width="33.33%">Updated By</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="
                          let log of history">
                          <td class="text-capitalize">{{ log.type.replace('keyproduction','').replace('create','created').replace('update','updated').replaceAll('-',' ') }}</td>
                          <td>{{ log.updateTime | date: 'MM/dd/yyyy hh:mm:ss' }}</td>
                          <td>{{ log.cid?.firstName}} {{ log.cid?.lastName}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <app-no-data-msg [message]="'No Data Found'" *ngIf="claims && claims.length === 0 && !isLoading">
        </app-no-data-msg>
        <app-table-loader [isLoading]="isLoading"></app-table-loader>
      </div>
      <div class="card-footer">
        <div class="step-btn-container d-flex align-items-center justify-content-between pull-right">
          <button type="button" class="btn btn-primary d-flex align-items-center" routerLink="/theft-list">
            <i class="mdi mdi-arrow-left mr-1"></i>
            Back
          </button>
        </div>
      </div>
    </div>
    <div class="help-container">
      <div class="help-section">
        <p class="help-text" (click)="openHelpPopup()">
          <i class="hellp-popup-icons"></i>
        </p>
      </div>
    </div>
  </div>
</div>