import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ClaimService } from '@app/core/service/claim.service';
import { KeyfechService } from '@app/core/service/keyfetch.service';
import { shieldService } from '@app/core/service/shield.service';
import { ReimburseService } from '@app/core/service/reimburse.service';
import { TheftService } from '@app/core/service/theft.service';
import { debitcancelService } from '@app/core/service/debitcancel.service';
import { ToastrService } from 'ngx-toastr';
import { LOCAL_VAR } from './../../../core/constants';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-reject-claim',
  templateUrl: './reject-claim.component.html',
  styleUrls: ['./reject-claim.component.scss']
})
export class RejectClaimComponent implements OnInit {
  @Input()
  claimId?= '';
  adminId?= '';
  product?= 'gap';
  amountApproved?= '';
  rejectType?= 'reject';

  selectedCustomer: any = null;
  rejectForm: FormGroup;
  isLoading = false;
  constructor(
    public activeModal: NgbActiveModal,
    public claimService: ClaimService,
    public KeyfechService: KeyfechService,
    public shieldService: shieldService,
    public debitcancelService: debitcancelService,
    public reimburseService: ReimburseService,
    public theftService: TheftService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    public router: Router
  ) {
    this.selectedCustomer = localStorage.getItem(LOCAL_VAR.selectedCustomer);
    this.selectedCustomer = JSON.parse(this.selectedCustomer);
    this.createForm();
  }

  ngOnInit() {
    this.rejectForm.controls['amountApproved'].setValue(this.amountApproved);
  }

  createForm() {
    this.rejectForm = this.formBuilder.group({
      message: ['', [Validators.required]],
      amountApproved: ['', [Validators.required]],
      adminId: [''],
      rejectEdit: [true]
    });
  }

  rejectRequest_old() {
    this.isLoading = true;
    this.rejectForm.get('adminId').setValue(this.adminId);
    var formValue = this.rejectForm.value;
    var rejectapi = (this.rejectType == 'denied') ? 'deny' : 'reject';
    if (this.product === 'shield') {
      this.shieldService
        .rejectRequest(this.claimId, formValue,rejectapi)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe((response: any) => {
          if (response) {
            this.activeModal.close(response);
          } else {
            this.toastrService.warning('something went Wrong');
          }
        });
    } else if (this.product === 'theft') {
      var rejectapi = (this.rejectType == 'denied') ? 'deny' : 'reject';
      delete formValue.amountApproved;
      this.theftService
      .rejectRequest(this.claimId, formValue,rejectapi)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response: any) => {
        if (response) {
          this.activeModal.close(response);
        } else {
          this.toastrService.warning('something went Wrong');
        }
      });
    } else if (this.product === 'reimburse') {
      var rejectapi = (this.rejectType == 'denied') ? 'deny' : 'reject';
      delete formValue.amountApproved;
      this.reimburseService
      .rejectRequest(this.claimId, formValue,rejectapi)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response: any) => {
        if (response) {
          this.activeModal.close(response);
        } else {
          this.toastrService.warning('something went Wrong');
        }
      });
    } else if (this.product === 'lease') {
      this.claimService
        .rejectLeaseRequest(this.claimId, formValue,rejectapi)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe((response: any) => {
          if (response) {
            this.activeModal.close(response);
          } else {
            this.toastrService.warning('something went Wrong');
          }
        });
    } else if (this.product === 'keyfetch') {
      this.KeyfechService
        .rejectRequest(this.claimId, formValue,rejectapi)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe((response: any) => {
          if (response) {
            this.activeModal.close(response);
          } else {
            this.toastrService.warning('something went Wrong');
          }
        });
    } else if(this.product === 'debitcancel'){
      //if (formValue.amountApproved)
        delete formValue.amountApproved;
     this.debitcancelService
       .rejectRequest(this.claimId, formValue,rejectapi)
       .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
       .subscribe((response: any) => {
         if (response) {
           this.activeModal.close(response);
         } else {
           this.toastrService.warning('something went Wrong');
         }
       });
   } else {
       //if (formValue.amountApproved)
         delete formValue.amountApproved;
         if(this.rejectType =='denied'){
          formValue.rejectEdit = false;
          this.claimService
          .denieRequest(this.claimId, formValue)
          .pipe(
            finalize(() => {
              this.isLoading = false;
            })
          )
          .subscribe((response: any) => {
            if (response) {
              this.activeModal.close(response);
            } else {
              this.toastrService.warning('something went Wrong');
            }
          });
         } else {
          this.claimService
          .rejectRequest(this.claimId, formValue)
          .pipe(
            finalize(() => {
              this.isLoading = false;
            })
          )
          .subscribe((response: any) => {
            if (response) {
              this.activeModal.close(response);
            } else {
              this.toastrService.warning('something went Wrong');
            }
          });
         }
     
    }


  }

  rejectRequest() {
    this.isLoading = true;
    this.rejectForm.get('adminId').setValue(this.adminId);
    var formValue = this.rejectForm.value;
    var rejectapi = (this.rejectType == 'denied') ? 'deny' : this.rejectType;

    var model = this.product;
    if (this.product == 'gap') {
      model = 'claim';
      rejectapi = rejectapi == 'deny' ? 'denie' : rejectapi;
    }
    if (this.product == 'gap' || this.product == 'debitcancel' || this.product == 'reimburse' || this.product == 'theft' || this.rejectType=='void' || this.rejectType == 'denied') {
      delete formValue.amountApproved;
    }
    if (this.rejectType=='void') {
      delete formValue.rejectEdit;
    }
    console.log(model, this.claimId, formValue, rejectapi);
    this.claimService
      .changeClaimRequest(model, this.claimId, formValue, rejectapi)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response: any) => {
        if (response) {
          this.activeModal.close(response);
        } else {
          this.toastrService.warning('something went Wrong');
        }
      });
  }

}
