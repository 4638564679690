<div class="row agent-container">
  <div class="col-12">
    <div class="card">

      <div class="modal-header">
        <h3 class="modal-title">Ip Address Detail</h3>
      </div>
      <div class="modal-body">

        <div class="form-group row">
          <label class="col-12 col-xl-3">Ip Adress:</label>
          <div class="col-12 col-xl-9">
            {{ip}}
          </div>
        </div>

        <div class="form-group row" *ngIf="message === ''">
          <label class="col-12 col-xl-3">Country :</label>
          <div class="col-12 col-xl-9">
            {{country}} - {{countryCode}}
          </div>
        </div>
        <div class="form-group row" *ngIf="message === ''">
          <label class="col-12 col-xl-3">Region :</label>
          <div class="col-12 col-xl-9">
            {{regionName}} - {{region}}
          </div>
        </div>
        <div class="form-group row" *ngIf="message === ''">
          <label class="col-12 col-xl-3">city :</label>
          <div class="col-12 col-xl-9">
            {{city}}
          </div>
        </div>
        <div class="form-group row" *ngIf="message !== ''">
          <label class="col-12 col-xl-3 text-danger">Error :</label>
          <div class="col-12 col-xl-9 text-danger">
            {{message}}
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">
          Close
        </button>
      </div>
    </div>
  </div>
</div>