import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ProfileService = /** @class */ (function () {
    function ProfileService(httpClient) {
        this.httpClient = httpClient;
    }
    ProfileService.prototype.updateProfile = function (payload) {
        return this.httpClient.put("/updateprofile", payload).pipe(map(function (body) {
            return body;
        }));
    };
    ProfileService.prototype.updatePassword = function (payload) {
        return this.httpClient.put('/updatepassword', payload).pipe(map(function (body) {
            return body;
        }));
    };
    ProfileService.ngInjectableDef = i0.defineInjectable({ factory: function ProfileService_Factory() { return new ProfileService(i0.inject(i1.HttpClient)); }, token: ProfileService, providedIn: "root" });
    return ProfileService;
}());
export { ProfileService };
