import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService, CONFIRMATION_MSGS, LOCAL_VAR } from '@app/core';
import { CustomerService } from '@app/core/service/customer.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { ClaimHelpComponent } from '@app/shared/modals/claim-help/claim-help.component';
import { Router } from '@angular/router';
@Component({
  selector: 'app-dealergroup-list',
  templateUrl: './dealergroup-list.component.html',
  styleUrls: ['./dealergroup-list.component.scss']
})
export class DealergroupListComponent implements OnInit {
  @ViewChild('goto') nameInput: ElementRef;
  isLoading = false;
  selectedCustomer: any = null;
  closeResult: string;
  isFileterCollapse = true;
  perPage = 10;
  currentPage = 1;
  filterUser: FormGroup;
  dealersGroupList: any;
  sortDir = 1;
  sortBy = 'name';
  loggedInUserType: any = null;
  user: Authentication.User;
  pager = {
    perPage: 10,
    currentPage: 1,
    Totalrecords: 0,
    TotalPages: 0
  };
  PageList: any;
  dealersList: any = {};



  constructor(
    public router: Router,
    private modalService: NgbModal,
    private utilService: UtilService,
    private customerService: CustomerService,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService
  ) {
    this.user = this.authenticationService.credentials.user;
    this.loggedInUserType = this.user.userRoleId.slug;
    this.createForm();
    this.getDealers();

    this.selectedCustomer = localStorage.getItem(LOCAL_VAR.selectedCustomer);
    this.selectedCustomer = JSON.parse(this.selectedCustomer);
    localStorage.setItem(LOCAL_VAR.selectedCustomer, null);
    localStorage.setItem(LOCAL_VAR.selectedProduct, null);
  }

  ngOnInit() {
    if (this.loggedInUserType !== 'dowcsuperadmin') {
      this.router.navigate(['/products']);
    }
  }

  ngAfterViewInit() {
    this.utilService.updateDomainCss();
  }

  createForm() {
    this.isLoading = true;
    this.filterUser = this.formBuilder.group({
      status: [''],
      config: [''],
      dealername: [''],
      domain: [''],
      sortFieldFilter: [''],
      sortByFilter: [''],
      perPage: 10
    });
    this.filterUser.valueChanges.subscribe(values => {
      this.pager.currentPage = 1;
      this.getAllDelaerGroup();
    });

  }

  getAllDelaerGroup() {
    let payload = this.filterUser.value;
    payload = this.utilService.removeWhiteSpace(payload);
    payload.currentPage = this.pager.currentPage;
    this.pager.perPage = payload.perPage;
    this.isLoading = true;
    this.customerService
      .getAllDealerGroup(payload)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response: any) => {
        this.dealersGroupList = response.records;
        this.renderPagination(response.page, response.total, response.totalPage);
      });

  }

  async sort(sortByVal: string) {
    this.sortDir = this.sortDir === 1 ? -1 : 1;
    this.filterUser.value.sortFieldFilter = sortByVal;
    this.filterUser.value.sortByFilter = this.sortDir;
    this.sortBy = sortByVal;
    this.getAllDelaerGroup();
  }

  goPage() {
    let goto = this.nameInput.nativeElement.value;
    this.setPage(goto);
    this.nameInput.nativeElement.value = '';
  }

  setPage(page: any) {
    if (0 < page && page <= this.pager.TotalPages && page != this.pager.currentPage) {
      this.pager.currentPage = page;
      this.getAllDelaerGroup();
    }
  }

  renderPagination(page: number, total: number, totalPage: number) {
    this.pager.currentPage = page;
    this.pager.Totalrecords = total;
    this.pager.TotalPages = totalPage;
    this.PageList = this.utilService.getPageList(this.pager.TotalPages, this.pager.currentPage);
  }

  getDealers() {
    this.customerService
      .getallDealers()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.records) {
          let dealers: any = {};
          response.records.forEach((val: any, index: number) => {
            let key = val._id;
            dealers[key] = val;
          });
          this.dealersList = dealers;
          this.getAllDelaerGroup();
        }
      });
  }


  async openHelpPopup() {
    const modalRef = await this.modalService.open(ClaimHelpComponent);
  }

}

