import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { UtilService } from '../service';

@Injectable()
export class CustomGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private utilService: UtilService
  ) {}

  canActivate(routeSnapshot: ActivatedRouteSnapshot): boolean {
    const urlData = routeSnapshot.data;
    if (urlData && urlData.url && urlData.url === 'user') {
      if (
        this.authenticationService.loggedInUserType === 'admin' ||
        this.authenticationService.loggedInUserType === 'superadmin' ||
        this.authenticationService.loggedInUserType === 'dowcview' ||
        this.authenticationService.loggedInUserType === 'adminview' ||
        this.authenticationService.loggedInUserType === 'dowcadmin' ||
        this.authenticationService.loggedInUserType === 'dowcsuperadmin'
      ) {
        return true;
      } else if (this.authenticationService.loggedInUserType === 'customer') {
        return true;
        // this.router.navigate(['/claim']);
        // return false;
      }
    }
    this.router.navigate(['/']);
    return false;
  }
}
