import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RejectClaimComponent } from '@app/shared/modals/reject-claim/reject-claim.component';
import { ConfirmClaimComponent } from '@app/shared/modals/confirm-claim/confirm-claim.component';
import { Subject, from, Observable } from 'rxjs';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { takeUntil, finalize, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ClaimService } from '@app/core/service/claim.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UtilService, CONFIRMATION_MSGS, LOCAL_VAR } from '@app/core';
import { DateTime } from 'luxon';
import { ConfirmationComponent } from '@app/shared/modals/confirmation';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from '@app/core/service/customer.service';
import { ClaimHelpComponent } from '@app/shared/modals/claim-help/claim-help.component';

@Component({
  selector: 'app-list-claim',
  templateUrl: './list-claim.component.html',
  styleUrls: ['./list-claim.component.scss']
})
export class ListClaimComponent implements OnInit {
  @ViewChild('goto') nameInput: ElementRef;
  loggedInUserType: any = null;
  loggedInUserdealer:any=null;
  user: Authentication.User;
  private unsubscribe: Subject<void> = new Subject();
  selectedCustomer: any = null;
  isLoading = false;
  claims: any = [];
  fiterForm: FormGroup;
  environmentUrl = '';
  sortBy = 'createdAt';
  sortDir = -1;
  perPage = 10;
  currentPage = 1;
  dealersList: any;
  pager = {
    perPage: 10,
    currentPage: 1,
    Totalrecords: 0,
    TotalPages: 0
  };
  PageList: any;
  hostname = window.location.hostname;
  dealers = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term =>
        term.length < 2
          ? []
          : this.dealersList
            .filter(
              (v: { name: string; }) => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1
            )
      )
    );
  formatter = (x: { name: string }) => x.name;


  constructor(
    private modalService: NgbModal,
    private router: Router,
    private authenticationService: AuthenticationService,
    private claimService: ClaimService,
    private formBuilder: FormBuilder,
    public utilService: UtilService,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
    private customerService: CustomerService
  ) {
    this.user = this.authenticationService.credentials.user;
    this.loggedInUserType = this.user.userRoleId.slug;
    this.loggedInUserdealer=this.user.dealerId;
    this.authenticationService.credentials$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((credentials: Authentication.Credentials) => {
        this.user = credentials.user;
        this.loggedInUserType = this.user.userRoleId.slug;
        this.loggedInUserdealer=this.user.dealerId;
      });
    // this.selectedCustomer = localStorage.getItem(LOCAL_VAR.selectedCustomer);
    // this.selectedCustomer = JSON.parse(this.selectedCustomer);
    // localStorage.setItem(LOCAL_VAR.selectedCustomer, null);
    // if (!this.selectedCustomer && this.loggedInUserType === 'admin') {
    //   this.router.navigate(['/customer'], {
    //     replaceUrl: true
    //   });
    // }
    this.createForm();
    this.getClaimsByFilter();
    this.route.queryParams.pipe().subscribe(params => {
      if (params && params.page) {
        if (!isNaN(params.page)) {
          this.currentPage = +params.page;
        }
      }
    });
   }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.utilService.updateDomainCss();
  }

  createForm() {
    this.fiterForm = this.formBuilder.group({
      addendumNumber: ['', Validators.compose([Validators.maxLength(5)])],
      sequence: ['', Validators.compose([Validators.maxLength(5)])],
      date_from: [''],
      date_to: [''],
      customerName: [''],
      email: [''],
      status: [''],
      sortFieldFilter: ['createdAt'],
      sortByFilter: ['-1'],
      dealer: [''],
      perPage: 10
    });

    this.fiterForm.valueChanges.subscribe(values => {
      this.pager.currentPage = 1;
      this.getClaimsByFilter();
    });
    this.customerService
      .getDealers()
      /* .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      ) */
      .subscribe((response: any) => {
        this.dealersList = response.records;
      });
  }

  getClaimsByFilter() {
    let payload = this.fiterForm.value;   
    payload = this.utilService.removeWhiteSpace(payload);
    if (payload.date_from) {
      payload.dateOfLoss_at_from = DateTime.fromObject(
        payload.date_from
      ).toISO();
    }

    if (payload.date_to) {
      payload.dateOfLoss_at_to = DateTime.fromObject(
        payload.date_to
      ).toISO();
    }
    if (typeof payload.dealer == "object") {
      payload.dealer = payload.dealer._id;
    } else if (typeof payload.dealer == "string" && payload.dealer !== "" && this.currentPage === this.pager.currentPage) {
      return false;
    }

    payload.currentPage = this.pager.currentPage;
    this.pager.perPage = payload.perPage;
    this.isLoading = true;
    this.claimService
      .getLeaseClaimsByFilter(payload)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response: any) => {
        this.claims = response.records;
        this.renderPagination(response.page, response.total, response.totalPage);
      });
  }

  acceptRequest(claim: any) {
    const payload = {
      message: `Hi, ${claim.customerName} your deal has been accepted for claim`,
      adminId: this.user._id
    };
    this.claimService
      .acceptLeaseRequest(claim._id, payload)
      .subscribe((response: any) => {
        if (response) {
          this.toastrService.info('Claim accepted Successfully!..');
          this.getClaimsByFilter();
        } else {
          this.toastrService.warning('something went Wrong');
        }
      });
  }

  async openRejectPopup(claim: any, type:any) {
    const modalRef = await this.modalService.open(RejectClaimComponent);
    modalRef.componentInstance.claimId = claim._id;
    modalRef.componentInstance.amountApproved = claim.amountApproved;
    modalRef.componentInstance.adminId = this.user._id;
    modalRef.componentInstance.product = 'lease';
    modalRef.componentInstance.rejectType = type;

    const result: any = await modalRef.result.catch(e => console.log(e));
    if (result) {
      if (type == 'void')
        this.toastrService.info("Your claim has been voided successfully. To view this voided claim, please change the filter status to 'All' or 'Voided.'",'',{ toastClass: 'toast ngx-toastr', positionClass: 'toast-top-right', closeButton: true, timeOut:10000 });
      else
        this.toastrService.info('Claim ' + (type == 'void' ? 'Voided' : type == 'reject' ? 'rejected' : 'Denied') + '!');
      this.getClaimsByFilter();
    }
  }

  async openConfirmPopup(claim: any) {
    const modalRef = await this.modalService.open(ConfirmClaimComponent);
    modalRef.componentInstance.message = `Hi, ${claim.customerName} your deal has been accepted for claim`;
    modalRef.componentInstance.claimId = claim._id;
    modalRef.componentInstance.amountApproved = claim.amountApproved;
    modalRef.componentInstance.adminId = this.user._id;
    modalRef.componentInstance.product = 'lease';

    const result: any = await modalRef.result.catch(e => console.log(e));
    if (result) {
      this.getClaimsByFilter();
    }
  }

  async openDeleteClaim(claimId: string) {
    const modalRef = this.modalService.open(ConfirmationComponent);

    modalRef.componentInstance.title = this.utilService.objectResolver(
      'claims.deleteLeaseClaim.title',
      CONFIRMATION_MSGS
    );
    modalRef.componentInstance.body = this.utilService.objectResolver(
      'claims.deleteLeaseClaim.content',
      CONFIRMATION_MSGS
    );
    modalRef.componentInstance.okBtn = this.utilService.objectResolver(
      'claims.deleteLeaseClaim.okBtn',
      CONFIRMATION_MSGS
    );
    modalRef.componentInstance.cancelBtn = this.utilService.objectResolver(
      'claims.deleteLeaseClaim.cancelBtn',
      CONFIRMATION_MSGS
    );
    modalRef.componentInstance.service = this.utilService.objectResolver(
      'claims.deleteLeaseClaim.service',
      CONFIRMATION_MSGS
    );
    modalRef.componentInstance.method = this.utilService.objectResolver(
      'claims.deleteLeaseClaim.method',
      CONFIRMATION_MSGS
    );
    modalRef.componentInstance.payload = [claimId];

    const result: any = await modalRef.result.catch(e => console.log(e));
    if (result) {
      this.toastrService.info('Claim deleted Successfully!..');
      this.getClaimsByFilter();
    }
  }

  async sort(sortByVal: string) {
    if (this.sortBy === sortByVal) {
      this.sortDir = this.sortDir === 1 ? -1 : 1;
    } else {
      this.sortDir = 1;
    }
    /*  this.sortFieldFilter.setValue(sort);
    this.sortByFilter.setValue(sortDir); */
    this.fiterForm.value.sortFieldFilter = sortByVal;
    this.fiterForm.value.sortByFilter = this.sortDir;
    this.sortBy = sortByVal;
    this.getClaimsByFilter();
  }

  goPage() {
    let goto = this.nameInput.nativeElement.value;
    this.setPage(goto);
    this.nameInput.nativeElement.value = '';
  }

  setPage(page: any) {
    if (0 < page && page <= this.pager.TotalPages  && page != this.pager.currentPage) {
      this.pager.currentPage = page;
      this.getClaimsByFilter();
    }
  }

  renderPagination(page: number, total: number, totalPage: number) {
    this.pager.currentPage = page;
    this.currentPage=page;
    this.pager.Totalrecords = total;
    this.pager.TotalPages = totalPage;
    this.PageList = this.utilService.getPageList(this.pager.TotalPages, this.pager.currentPage);
  }

  omit_special_char(event: any) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k <= 122) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }


  omit_special_Mailchar(event: any) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k <= 122) || k === 46 || k === 64 || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  async openHelpPopup() {
    const modalRef = await this.modalService.open(ClaimHelpComponent);
  }

  checkAdmindealer(dealerId:any){
    let adminDealer = false;
    if (this.user.isGroupDealer) {
      var group: any = this.user.dealerGroupId;
      for (let grp of group.dealers) {
        if (grp.dealerId == dealerId) {
          adminDealer = true
        }
      }
    } else if (dealerId == this.loggedInUserdealer) {
      adminDealer = true
    }
    return adminDealer;
  }

}
