<div class="row agent-container">
  <div class="col-12">
    <div class="card">
      <form [formGroup]="theftForm" (ngSubmit)="submitData()" class="form-horizontal form-material"
        id="theftForm" novalidate>
        <div class="card-header">
          Theft Claim
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12 p-xl-0">
             
              <div class="row" *ngIf="loggedInUserType == 'customer'">
                <div class="col-12">
                  <h3 class="title-case">Find My contract</h3>
                  <div class="form-group row ml-0 mr-0">
                    <div class="form-group col-12 col-xl-6 d-xl-flex">
                      <div class="form-group col-12 col-xl-6 d-xl-flex mb-xl-0">
                            <select class="custom-select form-control mr-2 mb-2 mb-xl-0" name="filter1select"
                              formControlName="filter1select">
                              <option value="">Select Field</option>
                              <option value="firstname">First Name</option>
                              <option value="lastname">Last Name</option>
                              <option value="phone" [disabled]="theftForm.get('filter2select').value == 'phone' ? true : false">Phone</option>
                              <option value="vin" [disabled]="theftForm.get('filter2select').value == 'vin' ? true : false">VIN(Last 8 Digits)</option> 
                            </select>           
                            <input type="text" name="filter1" class="form-control" placeholder="value" formControlName="filter1" />   
                            <!-- 1111111111{{filter2_name}}--asdfasdfasd-         -->
                      </div>
                      <div class="form-group col-12 col-xl-6 d-xl-flex mb-xl-0">
                            <select class="custom-select form-control mr-2 mb-2 mb-xl-0" name="filter2select"
                              formControlName="filter2select">
                              <option value="" >Select Field</option>
                              <option value="contract">Contract No</option>
                              <option value="vin" [disabled]="theftForm.get('filter1select').value == 'vin' ? true : false">VIN(Last 8 Digits)</option>
                              <option value="phone" [disabled]="theftForm.get('filter1select').value == 'phone' ? true : false">Phone</option>
                            </select>                   
                            <input type="text" name="filter2" class="form-control" placeholder="value" formControlName="filter2" />   
                      </div>
                    </div>
                    <div class="form-group col-12 col-xl-6 d-xl-flex text-center">
                        <button
                          type="button"
                          (click)="getMyContract()"
                          class="btn btn-info primary-background mr-2 mb-0"
                        >
                        <app-button-loader [isLoading]="getcontract_loading"></app-button-loader>
                        Search & Fill
                        </button>                      
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-2" *ngIf="show_claims">
                <div class="col-12">
                  <span class="pl-prefill pl-5">Please select contracts below to prefill</span>
                  <div class="row">
                    <div class="col-12 d-xl-flex pl-5 pr-5">
                      <div class="table table-responsive smart-table">
                        <table class="w-100">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Contract No</th>
                              <th>VIN</th>
                              <!--<th>Name</th>
                              <th>Phone No</th>
                              <th>Dealer</th> -->
                              <th>Product</th>
                              <th>Year</th>
                              <th>Make</th>
                              <th>Model</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              *ngFor="
                                let claim of claims_data; let i=index"
                            >
                              <td><input type="radio" value="{{i}}" (click)="claim_select($event)" /></td>
                              <td>{{ claim.ContractNumber }}</td>
                              <td>xxxxxxxxx{{ claim.VIN.substring(9,17) }}</td>
                              <!--<td>{{ claim.FirstName ? claim.FirstName : '' + ' ' + claim.LastName ? claim.LastName : '' }}</td>
                              <td>{{ claim.PhysicalPhone | phone }}</td>
                              <td>{{ claim.DealerName }}</td> -->
                              <td>{{ claim.Product }}</td>
                              <td>{{ claim.VehicleYear }}</td>
                              <td>{{ claim.VehicleMake }}</td>
                              <td>{{ claim.VehicleModel }}</td>
                            </tr>
                          </tbody>
                        </table>              
                      </div>
                    </div>
                  </div>
                </div>
              </div> 
              
              <div class="row">
                <div class="col-xl-4 col-12">
                  <h3 class="title-case">Contract information</h3>
                  <div class="form-group row ml-0 mr-0">
                    <div class="form-group col-12 d-xl-flex">
                      <label class="col-12 col-xl-4"><app-required-indication [control]="theftForm.get('contractNumber')"
                          [label]="'Contract Number'"></app-required-indication>
                          <!-- <i class="fa fa-question-circle"
                          title="It helps you to pre-fill the basic contract details"
                        ></i> -->
                      </label>
                      <div class="col-12" 
                      [ngClass]="{
                        'col-xl-8': loggedInUserType == 'customer',
                        'col-xl-6': loggedInUserType != 'customer'
                      }">
                        <app-form-error-wrapper [control]="theftForm.get('contractNumber')"
                          [controlName]="'Contract Number'" [apiErrorType]="'contractNumber'">
                          <input type="text" name="contractNumber" class="form-control" formControlName="contractNumber" />
                        </app-form-error-wrapper>
                      </div>
                      <div class="col-12 col-xl-2" *ngIf="loggedInUserType != 'customer'">
                        <button
                          type="button"
                          (click)="getContract()"
                          class="btn btn-info primary-background mr-2"
                        >
                          Fill
                        </button>
                      </div>
                    </div>
                    <div class="form-group col-12 d-xl-flex">
                      <label class="col-12 col-xl-4 align-items-center">
                        <app-required-indication [control]="theftForm.get('vinNumber')"
                        [label]="'VIN Number'"></app-required-indication>
                      </label>
                      <div class="col-12 col-xl-8">
                        <app-form-error-wrapper [control]="theftForm.get('vinNumber')" [controlName]="'VIN Number'"
                          [apiErrorType]="'vinNumber'">
                          <input type="text" name="vin-number" class="form-control" mask="AAAAAAAAAAAAAAAAA"
                            [formControl]="theftForm.get('vinNumber')" />
                        </app-form-error-wrapper>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row ml-0 mr-0">
                      <div class="form-group col-12 d-xl-flex">
                        <label class="col-12 col-xl-4">
                          <app-required-indication [control]="theftForm.get('claimDate')"
                            [label]="'Claim Date'">
                          </app-required-indication>
                        </label>
                        <div class="col-12 col-xl-8 pos-rel">
                          <app-form-error-wrapper [control]="theftForm.get('claimDate')"
                            [controlName]="'Date Leased Vehicle Returned'" [apiErrorType]="'claimDate'">
                            <input type="text" name="dateLoss" formControlName="claimDate" placeholder="MM/DD/YYYY"
                              ngbDatepicker #d="ngbDatepicker" class="form-control" (click)="d.toggle()" [readonly]="true"
                              [minDate]="{ year: 1900, month: 1, day: 1 }" [maxDate]="{
                                year: utilService.getCurrentYear,
                                month: utilService.getCurrentMonth,
                                day: utilService.getCurrentDay
                              }" />
                          </app-form-error-wrapper>
                          <a class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer pos-abs datecancel-r15"
                            (click)="utilService.removeDate(theftForm, 'claimDate')" placement="left"
                            ngbTooltip="Remove" *ngIf="theftForm.controls['claimDate'].value">
                            <i class="ti-close text-danger m-r-10"></i>
                          </a>
                        </div>
                      </div>
                      <div class="form-group col-12 d-xl-flex align-items-center">
                        <label class="col-12 col-xl-4">
                          Claim Number<sup style="color: #ff0000">*</sup>
                        </label>
                        <div class="col-12 col-xl-8">
                          <app-form-error-wrapper [control]="theftForm.get('claimNumber')" [controlName]="'claimNumber'"
                            [apiErrorType]="'claimNumber'">
                            <input type="text" name="claimNumber" class="form-control" formControlName="claimNumber"  [required]="isCustomer"/>
                          </app-form-error-wrapper>
                        </div>           
                      </div>
                  </div>
                </div>
                <div class="col-xl-4 col-12">
                  <h3 class="title-case">Customer information </h3>
                  <div class="col-12 p-0">
                    <div class="form-group">
                      <div class="form-group col-12 d-xl-flex align-items-center">
                        <label class="col-12 col-xl-4">
                          <app-required-indication [control]="theftForm.get('customerName')"
                            [label]="'Customer Name'">
                          </app-required-indication>
                        </label>
                        <div class="col-12 col-xl-8 p-ls-xl-0">
                          <app-form-error-wrapper [control]="theftForm.get('customerName')"
                            [controlName]="'Customer Name'" [apiErrorType]="'customerName'">
                            <input type="text" name="customerName" class="form-control" formControlName="customerName" />
                          </app-form-error-wrapper>
                        </div>
                      </div>
                      <div class="form-group col-12 d-xl-flex align-items-center">
                        <label class="col-12 col-xl-4">
                          <app-required-indication [control]="theftForm.get('phoneNumber')" [label]="'Phone Number'">
                          </app-required-indication>
                        </label>
                        <div class="col-12 col-xl-8 p-ls-xl-0">
                          <app-form-error-wrapper
                          [control]="theftForm.get('phoneNumber')"
                          [controlName]="'Phone Number'"
                          [apiErrorType]="'phoneNumber'"
                        >
                          <input
                            type="tel"
                            name="phonenumber"
                            mask="000-000-0000"
                            class="form-control"
                            [formControl]="theftForm.controls['phoneNumber']"
                          />
                          </app-form-error-wrapper>
                          <span *ngIf="contractPhone">As per our record: XXX-XXX-{{contractPhone.slice(-4)}}</span>
                        </div>
                      </div>
                      <div class="form-group col-12 d-xl-flex align-items-center">
                        <label class="col-12 col-xl-4">
                          <app-required-indication [control]="theftForm.get('email')" [label]="'Email'">
                          </app-required-indication>
                        </label>
                        <div class="col-12 col-xl-8">
                          <app-form-error-wrapper [control]="theftForm.get('email')" [controlName]="'Email'"
                            [apiErrorType]="'email'">
                            <input type="email" name="email" class="form-control" formControlName="email" />
                          </app-form-error-wrapper>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="form-group col-12 d-xl-flex align-items-center">
                        <label class="col-12 col-xl-4">
                          Address<sup style="color: #ff0000">*</sup>
                        </label>
                        <div class="col-12 col-xl-8">
                          <app-form-error-wrapper [control]="theftForm.get('streetAddress')"
                            [controlName]="'Street Address'" [apiErrorType]="'streetAddress'">
                            <input type="text" name="street-address" class="form-control" formControlName="streetAddress"  required/>
                          </app-form-error-wrapper>
                        </div>
                      </div>
                      <div class="form-group col-12 d-xl-flex align-items-center">
                        <label class="col-12 col-xl-4">
                          City<sup style="color: #ff0000">*</sup>
                        </label>
                        <div class="col-12 col-xl-8">
                          <app-form-error-wrapper [control]="theftForm.get('city')" [controlName]="'City'"
                            [apiErrorType]="'city'">
                            <input type="text" name="city" class="form-control" formControlName="city"  [required]="isCustomer"/>
                          </app-form-error-wrapper>
                        </div>           
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="form-group col-12 d-xl-flex">
                        <label class="col-12 col-xl-4">State<sup style="color: #ff0000">*</sup>
                        </label>
                        <div class="col-12 col-xl-8">
                          <app-form-error-wrapper [control]="theftForm.get('state')" [controlName]="'State'"
                            [apiErrorType]="'state'">
                            <select class="custom-select form-control" name="state" formControlName="state" [required]="isCustomer">
                              <option value="">Select State</option>
                              <option *ngFor="let stat of states" value="{{ stat.code }}">{{ stat.name }}</option>
                            </select>
                          </app-form-error-wrapper>
                        </div>
                      </div>
                      <div class="form-group col-12 d-xl-flex align-items-center">
                        <label class="col-12 col-xl-4">
                          Zip Code<sup style="color: #ff0000">*</sup>
                        </label>
                        <div class="col-12 col-xl-8">
                          <app-form-error-wrapper [control]="theftForm.get('zipCode')" [controlName]="'Zip Code'"
                            [apiErrorType]="'zipCode'">
                            <input type="tel" class="form-control" [formControl]="theftForm.controls['zipCode']"  required
                              mask="00000" />
                          </app-form-error-wrapper>
                        </div>
                      </div>
                    </div>
                  </div>                  
                </div>
                <div class="col-xl-4 col-12">
                  <h3 class="title-case">Vehicle Information</h3>
                    <div class="form-group">
                      <div class="form-group col-12 d-xl-flex">
                        <label class="col-12 col-xl-4">
                          <app-required-indication [control]="theftForm.get('currentMileage')"
                            [label]="'Current Mileage'">
                          </app-required-indication>
                        </label>
                        <div class="col-12 col-xl-8 p-ls-xl-0">
                          <app-form-error-wrapper
                          [control]="theftForm.get('currentMileage')"
                          [controlName]="'Current mileage'"
                          [apiErrorType]="'currentMileage'"
                        >
                          <input
                            type="text"
                            name="currentMileage"
                            class="form-control"
                            mask="000000"
                            [formControl]="theftForm.get('currentMileage')"
                          />
                        </app-form-error-wrapper>
                        </div>
                      </div>
                      <div class="form-group col-12 d-xl-flex">
                        <label class="col-12 col-xl-4">
                          <app-required-indication [control]="theftForm.get('year')" [label]="'Year'">
                          </app-required-indication>
                        </label>
                        <div class="col-12 col-xl-8 p-ls-xl-0">
                          <app-form-error-wrapper [control]="theftForm.get('year')" [controlName]="'Year'"
                            [apiErrorType]="'year'">
                            <input type="text" name="year" mask="0000" class="form-control"
                              [formControl]="theftForm.get('year')" />
                          </app-form-error-wrapper>
                        </div>
                      </div>
                      <div class="form-group col-12 d-xl-flex">
                        <label class="col-12 col-xl-4">
                          <app-required-indication [control]="theftForm.get('make')" [label]="'Make'">
                          </app-required-indication>
                        </label>
                        <div class="col-12 col-xl-8">
                          <app-form-error-wrapper [control]="theftForm.get('make')" [controlName]="'Make'"
                            [apiErrorType]="'make'">
                            <input type="text" name="make" class="form-control"
                              [formControl]="theftForm.get('make')" />
                          </app-form-error-wrapper>
                        </div>
                      </div>
                      <div class="form-group col-12 d-xl-flex">
                        <label class="col-12 col-xl-4">
                          <app-required-indication [control]="theftForm.get('model')" [label]="'Model'">
                          </app-required-indication>
                        </label>
                        <div class="col-12 col-xl-8">
                          <app-form-error-wrapper [control]="theftForm.get('model')" [controlName]="'Model'"
                            [apiErrorType]="'model'">
                            <input type="text" name="model" class="form-control" formControlName="model" />
                          </app-form-error-wrapper>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12 col-12">
                  <h3 class="title-case">Customer Documents Needed</h3>
                     <div class="form-group d-xl-flex align-items-center">
                      <label class="col-12 col-xl-6">
                        A copy of this Limited Warranty
                        <sup style="color: #ff0000">*</sup>
                      </label>
                      <!-- <label class="col-12 col-xl-6">
                        <app-required-indication
                          [control]="theftForm.get('RepairOrderInvoice')"
                          [label]="
                            'Repair order invoice (with detailed parts and labor breakdown)'"
                        >
                        </app-required-indication>
                      </label>                       -->
                      <div class="col-12 col-xl-6">
                        <div *ngIf="uploadedImg.copyOfLimitedWarranty">
                          <label class="col-12 col-xl-9 float-left">{{
                            uploadedImg.copyOfLimitedWarranty.replace('uploads/', '').replace('theft/','')
                          }}</label>
                          <div class="col-12 col-xl-3 float-left">
                            <a
                              class="ng2-smart-action ng2-smart-action-edit-edit ml-2"
                              placement="left"
                              ngbTooltip="View Document"
                              href="{{ baseUrl }}{{
                                uploadedImg.copyOfLimitedWarranty
                              }}"
                              target="_blank"
                            >
                              <i class="ti-eye text-info m-r-10"></i>
                            </a>
                            <a
                              class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer ml-2"
                              (click)="removeImage('copyOfLimitedWarranty')"
                              placement="left"
                              ngbTooltip="Change Document"
                            >
                              <i class="ti-trash text-danger m-r-10"></i>
                            </a>
                          </div>
                        </div>
                        <div *ngIf="!uploadedImg.copyOfLimitedWarranty">
                          <input
                            type="file"
                            name="deductiblesSection"
                            (change)="onSelectFile($event, 'copyOfLimitedWarranty')"
                            class="file-upload-width"
                            required 
                          />
                          <a
                            *ngIf="theftForm.get('copyOfLimitedWarranty').value"
                            class="ng2-smart-action ng2-smart-action-edit-edit ml-2 float-right"
                            placement="left"
                            ngbTooltip="View Document"
                            href="{{ baseUrl }}{{
                              theftForm.get('copyOfLimitedWarranty').value
                            }}"
                            target="_blank"
                          >
                            <i class="ti-eye text-info m-r-10"></i>
                          </a>
                        </div>
                      </div>
                     </div>
                     <div class="form-group d-xl-flex align-items-center">
                      <label class="col-12 col-xl-6">
                        Proof of installation of the Theft Protection Product to Your Vehicle
                        <sup style="color: #ff0000">*</sup>
                      </label>
                      <!-- <label class="col-12 col-xl-6">
                        <app-required-indication
                          [control]="theftForm.get('RepairOrderInvoice')"
                          [label]="
                            'Repair order invoice (with detailed parts and labor breakdown)'"
                        >
                        </app-required-indication>
                      </label>                       -->
                      <div class="col-12 col-xl-6">
                        <div *ngIf="uploadedImg.profOfInstallation">
                          <label class="col-12 col-xl-9 float-left">{{
                            uploadedImg.profOfInstallation.replace('uploads/', '').replace('theft/','')
                          }}</label>
                          <div class="col-12 col-xl-3 float-left">
                            <a
                              class="ng2-smart-action ng2-smart-action-edit-edit ml-2"
                              placement="left"
                              ngbTooltip="View Document"
                              href="{{ baseUrl }}{{
                                uploadedImg.profOfInstallation
                              }}"
                              target="_blank"
                            >
                              <i class="ti-eye text-info m-r-10"></i>
                            </a>
                            <a
                              class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer ml-2"
                              (click)="removeImage('profOfInstallation')"
                              placement="left"
                              ngbTooltip="Change Document"
                            >
                              <i class="ti-trash text-danger m-r-10"></i>
                            </a>
                          </div>
                        </div>
                        <div *ngIf="!uploadedImg.profOfInstallation">
                          <input
                            type="file"
                            name="deductiblesSection"
                            (change)="onSelectFile($event, 'profOfInstallation')"
                            class="file-upload-width"
                            required 
                          />
                          <a
                            *ngIf="theftForm.get('profOfInstallation').value"
                            class="ng2-smart-action ng2-smart-action-edit-edit ml-2 float-right"
                            placement="left"
                            ngbTooltip="View Document"
                            href="{{ baseUrl }}{{
                              theftForm.get('profOfInstallation').value
                            }}"
                            target="_blank"
                          >
                            <i class="ti-eye text-info m-r-10"></i>
                          </a>
                        </div>
                      </div>
                     </div>
                     <div class="form-group d-xl-flex align-items-center">
                      <label class="col-12 col-xl-6">
                        A copy of the original Buyer’s Order for the Vehicle stating the purchase price as well as any factory options and/or accessories affixed thereto at the time of delivery
                        <sup style="color: #ff0000">*</sup>
                      </label>
                      <!-- <label class="col-12 col-xl-6">
                        <app-required-indication
                          [control]="theftForm.get('RepairOrderInvoice')"
                          [label]="
                            'Repair order invoice (with detailed parts and labor breakdown)'"
                        >
                        </app-required-indication>
                      </label>                       -->
                      <div class="col-12 col-xl-6">
                        <div *ngIf="uploadedImg.copyOfOrinalBuyer">
                          <label class="col-12 col-xl-9 float-left">{{
                            uploadedImg.copyOfOrinalBuyer.replace('uploads/', '').replace('theft/','')
                          }}</label>
                          <div class="col-12 col-xl-3 float-left">
                            <a
                              class="ng2-smart-action ng2-smart-action-edit-edit ml-2"
                              placement="left"
                              ngbTooltip="View Document"
                              href="{{ baseUrl }}{{
                                uploadedImg.copyOfOrinalBuyer
                              }}"
                              target="_blank"
                            >
                              <i class="ti-eye text-info m-r-10"></i>
                            </a>
                            <a
                              class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer ml-2"
                              (click)="removeImage('copyOfOrinalBuyer')"
                              placement="left"
                              ngbTooltip="Change Document"
                            >
                              <i class="ti-trash text-danger m-r-10"></i>
                            </a>
                          </div>
                        </div>
                        <div *ngIf="!uploadedImg.copyOfOrinalBuyer">
                          <input
                            type="file"
                            name="deductiblesSection"
                            (change)="onSelectFile($event, 'copyOfOrinalBuyer')"
                            class="file-upload-width"
                            required 
                          />
                          <a
                            *ngIf="theftForm.get('copyOfOrinalBuyer').value"
                            class="ng2-smart-action ng2-smart-action-edit-edit ml-2 float-right"
                            placement="left"
                            ngbTooltip="View Document"
                            href="{{ baseUrl }}{{
                              theftForm.get('copyOfOrinalBuyer').value
                            }}"
                            target="_blank"
                          >
                            <i class="ti-eye text-info m-r-10"></i>
                          </a>
                        </div>
                      </div>
                     </div>
                     <div class="form-group d-xl-flex align-items-center">
                      <label class="col-12 col-xl-6">
                        A copy of the police report relating to the theft including any supplements and/or recovery reports
                        <sup style="color: #ff0000">*</sup>
                      </label>
                      <!-- <label class="col-12 col-xl-6">
                        <app-required-indication
                          [control]="theftForm.get('RepairOrderInvoice')"
                          [label]="
                            'Repair order invoice (with detailed parts and labor breakdown)'"
                        >
                        </app-required-indication>
                      </label>                       -->
                      <div class="col-12 col-xl-6">
                        <div *ngIf="uploadedImg.copyOfPoliceReport">
                          <label class="col-12 col-xl-9 float-left">{{
                            uploadedImg.copyOfPoliceReport.replace('uploads/', '').replace('theft/','')
                          }}</label>
                          <div class="col-12 col-xl-3 float-left">
                            <a
                              class="ng2-smart-action ng2-smart-action-edit-edit ml-2"
                              placement="left"
                              ngbTooltip="View Document"
                              href="{{ baseUrl }}{{
                                uploadedImg.copyOfPoliceReport
                              }}"
                              target="_blank"
                            >
                              <i class="ti-eye text-info m-r-10"></i>
                            </a>
                            <a
                              class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer ml-2"
                              (click)="removeImage('copyOfPoliceReport')"
                              placement="left"
                              ngbTooltip="Change Document"
                            >
                              <i class="ti-trash text-danger m-r-10"></i>
                            </a>
                          </div>
                        </div>
                        <div *ngIf="!uploadedImg.copyOfPoliceReport">
                          <input
                            type="file"
                            name="deductiblesSection"
                            (change)="onSelectFile($event, 'copyOfPoliceReport')"
                            class="file-upload-width"
                            required 
                          />
                          <a
                            *ngIf="theftForm.get('copyOfPoliceReport').value"
                            class="ng2-smart-action ng2-smart-action-edit-edit ml-2 float-right"
                            placement="left"
                            ngbTooltip="View Document"
                            href="{{ baseUrl }}{{
                              theftForm.get('copyOfPoliceReport').value
                            }}"
                            target="_blank"
                          >
                            <i class="ti-eye text-info m-r-10"></i>
                          </a>
                        </div>
                      </div>
                     </div>
                     <div class="form-group d-xl-flex align-items-center">
                      <label class="col-12 col-xl-6">
                        A copy of Your Primary Carrier’s settlement check, declaration page, vehicle theft affidavit, proof of loss, and valuation worksheet, if You carry Primary Carrier insurance
                        <sup style="color: #ff0000">*</sup>
                      </label>
                      <!-- <label class="col-12 col-xl-6">
                        <app-required-indication
                          [control]="theftForm.get('RepairOrderInvoice')"
                          [label]="
                            'Repair order invoice (with detailed parts and labor breakdown)'"
                        >
                        </app-required-indication>
                      </label>                       -->
                      <div class="col-12 col-xl-6">
                        <div *ngIf="uploadedImg.copyOfPrimaryCarrierStatement">
                          <label class="col-12 col-xl-9 float-left">{{
                            uploadedImg.copyOfPrimaryCarrierStatement.replace('uploads/', '').replace('theft/','')
                          }}</label>
                          <div class="col-12 col-xl-3 float-left">
                            <a
                              class="ng2-smart-action ng2-smart-action-edit-edit ml-2"
                              placement="left"
                              ngbTooltip="View Document"
                              href="{{ baseUrl }}{{
                                uploadedImg.copyOfPrimaryCarrierStatement
                              }}"
                              target="_blank"
                            >
                              <i class="ti-eye text-info m-r-10"></i>
                            </a>
                            <a
                              class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer ml-2"
                              (click)="removeImage('copyOfPrimaryCarrierStatement')"
                              placement="left"
                              ngbTooltip="Change Document"
                            >
                              <i class="ti-trash text-danger m-r-10"></i>
                            </a>
                          </div>
                        </div>
                        <div *ngIf="!uploadedImg.copyOfPrimaryCarrierStatement">
                          <input
                            type="file"
                            name="deductiblesSection"
                            (change)="onSelectFile($event, 'copyOfPrimaryCarrierStatement')"
                            class="file-upload-width"
                            required 
                          />
                          <a
                            *ngIf="theftForm.get('copyOfPrimaryCarrierStatement').value"
                            class="ng2-smart-action ng2-smart-action-edit-edit ml-2 float-right"
                            placement="left"
                            ngbTooltip="View Document"
                            href="{{ baseUrl }}{{
                              theftForm.get('copyOfPrimaryCarrierStatement').value
                            }}"
                            target="_blank"
                          >
                            <i class="ti-eye text-info m-r-10"></i>
                          </a>
                        </div>
                      </div>
                     </div>
                     <div class="form-group d-xl-flex align-items-center">
                      <label class="col-12 col-xl-6">
                        Any additional reasonable documentation requested by the Administrator. The Administrator will not obtain this information for You
                        <sup style="color: #ff0000">*</sup>
                      </label>
                      <!-- <label class="col-12 col-xl-6">
                        <app-required-indication
                          [control]="theftForm.get('RepairOrderInvoice')"
                          [label]="
                            'Repair order invoice (with detailed parts and labor breakdown)'"
                        >
                        </app-required-indication>
                      </label>                       -->
                      <div class="col-12 col-xl-6">
                        <div *ngIf="uploadedImg.additionalReasonableDocument">
                          <label class="col-12 col-xl-9 float-left">{{
                            uploadedImg.additionalReasonableDocument.replace('uploads/', '').replace('theft/','')
                          }}</label>
                          <div class="col-12 col-xl-3 float-left">
                            <a
                              class="ng2-smart-action ng2-smart-action-edit-edit ml-2"
                              placement="left"
                              ngbTooltip="View Document"
                              href="{{ baseUrl }}{{
                                uploadedImg.additionalReasonableDocument
                              }}"
                              target="_blank"
                            >
                              <i class="ti-eye text-info m-r-10"></i>
                            </a>
                            <a
                              class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer ml-2"
                              (click)="removeImage('additionalReasonableDocument')"
                              placement="left"
                              ngbTooltip="Change Document"
                            >
                              <i class="ti-trash text-danger m-r-10"></i>
                            </a>
                          </div>
                        </div>
                        <div *ngIf="!uploadedImg.additionalReasonableDocument">
                          <input
                            type="file"
                            name="deductiblesSection"
                            (change)="onSelectFile($event, 'additionalReasonableDocument')"
                            class="file-upload-width"
                            required 
                          />
                          <a
                            *ngIf="theftForm.get('additionalReasonableDocument').value"
                            class="ng2-smart-action ng2-smart-action-edit-edit ml-2 float-right"
                            placement="left"
                            ngbTooltip="View Document"
                            href="{{ baseUrl }}{{
                              theftForm.get('additionalReasonableDocument').value
                            }}"
                            target="_blank"
                          >
                            <i class="ti-eye text-info m-r-10"></i>
                          </a>
                        </div>
                      </div>
                     </div>
                     <div class="form-group col-12 d-xl-flex">
                      <label class="col-12 col-xl-6 p-0">
                        <app-required-indication [control]="theftForm.get('onVacation')"
                          [label]="'Were you on vacation?'">
                        </app-required-indication>
                      </label>
                      <div class="col-12 col-xl-3 p-ls-xl-0">
                        <div class="d-flex align-items-center">
                          <div class="custom-control custom-radio">
                            <input type="radio" id="onVacation-yes" name="onVacation" class="custom-control-input"
                              formControlName="onVacation" [value]="'Yes'" />
                            <label class="custom-control-label mr-2" for="onVacation-yes">Yes</label>
                          </div>
                          <div class="custom-control custom-radio">
                            <input type="radio" id="onVacation-no" name="onVacation" class="custom-control-input"
                              formControlName="onVacation" [value]="'No'" checked />
                            <label class="custom-control-label mr-2" for="onVacation-no">No</label>
                          </div>
                        </div>
                      </div>
                    </div>
                     <div class="form-group d-xl-flex align-items-center" *ngIf="theftForm.get('onVacation').value=='Yes'">
                      <label class="col-12 col-xl-6">
                        Proof of payment receipt for extended food and lodging
                        <sup style="color: #ff0000">*</sup>
                      </label>
                      <!-- <label class="col-12 col-xl-6">
                        <app-required-indication
                          [control]="theftForm.get('RepairOrderInvoice')"
                          [label]="
                            'Repair order invoice (with detailed parts and labor breakdown)'"
                        >
                        </app-required-indication>
                      </label>                       -->
                      <div class="col-12 col-xl-6">
                        <div *ngIf="uploadedImg.profOfPaymentForExtend">
                          <label class="col-12 col-xl-9 float-left">{{
                            uploadedImg.profOfPaymentForExtend.replace('uploads/', '').replace('theft/','')
                          }}</label>
                          <div class="col-12 col-xl-3 float-left">
                            <a
                              class="ng2-smart-action ng2-smart-action-edit-edit ml-2"
                              placement="left"
                              ngbTooltip="View Document"
                              href="{{ baseUrl }}{{
                                uploadedImg.profOfPaymentForExtend
                              }}"
                              target="_blank"
                            >
                              <i class="ti-eye text-info m-r-10"></i>
                            </a>
                            <a
                              class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer ml-2"
                              (click)="removeImage('profOfPaymentForExtend')"
                              placement="left"
                              ngbTooltip="Change Document"
                            >
                              <i class="ti-trash text-danger m-r-10"></i>
                            </a>
                          </div>
                        </div>
                        <div *ngIf="!uploadedImg.profOfPaymentForExtend">
                          <input
                            type="file"
                            name="deductiblesSection"
                            (change)="onSelectFile($event, 'profOfPaymentForExtend')"
                            class="file-upload-width"
                            required 
                          />
                          <a
                            *ngIf="theftForm.get('profOfPaymentForExtend').value"
                            class="ng2-smart-action ng2-smart-action-edit-edit ml-2 float-right"
                            placement="left"
                            ngbTooltip="View Document"
                            href="{{ baseUrl }}{{
                              theftForm.get('profOfPaymentForExtend').value
                            }}"
                            target="_blank"
                          >
                            <i class="ti-eye text-info m-r-10"></i>
                          </a>
                        </div>
                      </div>
                     </div>
                     <div class="form-group col-12 d-xl-flex">
                      <label class="col-12 col-xl-6 p-0">
                        <app-required-indication [control]="theftForm.get('isCarRent')"
                          [label]="'Did you rent a car due to the theft of your vehicle? '">
                        </app-required-indication>
                      </label>
                      <div class="col-12 col-xl-3 p-ls-xl-0">
                        <div class="d-flex align-items-center">
                          <div class="custom-control custom-radio">
                            <input type="radio" id="isCarRent-yes" name="isCarRent" class="custom-control-input"
                              formControlName="isCarRent" [value]="'Yes'" />
                            <label class="custom-control-label mr-2" for="isCarRent-yes">Yes</label>
                          </div>
                          <div class="custom-control custom-radio">
                            <input type="radio" id="isCarRent-no" name="isCarRent" class="custom-control-input"
                              formControlName="isCarRent" [value]="'No'" checked />
                            <label class="custom-control-label mr-2" for="isCarRent-no">No</label>
                          </div>
                        </div>
                      </div>
                    </div>
                     <div class="form-group d-xl-flex align-items-center"  *ngIf="theftForm.get('isCarRent').value=='Yes'">
                      <label class="col-12 col-xl-6">
                        Proof of payment receipt for rental
                        <sup style="color: #ff0000">*</sup>
                      </label>
                      <!-- <label class="col-12 col-xl-6">
                        <app-required-indication
                          [control]="theftForm.get('RepairOrderInvoice')"
                          [label]="
                            'Repair order invoice (with detailed parts and labor breakdown)'"
                        >
                        </app-required-indication>
                      </label>                       -->
                      <div class="col-12 col-xl-6">
                        <div *ngIf="uploadedImg.profOfRentalReceipt">
                          <label class="col-12 col-xl-9 float-left">{{
                            uploadedImg.profOfRentalReceipt.replace('uploads/', '').replace('theft/','')
                          }}</label>
                          <div class="col-12 col-xl-3 float-left">
                            <a
                              class="ng2-smart-action ng2-smart-action-edit-edit ml-2"
                              placement="left"
                              ngbTooltip="View Document"
                              href="{{ baseUrl }}{{
                                uploadedImg.profOfRentalReceipt
                              }}"
                              target="_blank"
                            >
                              <i class="ti-eye text-info m-r-10"></i>
                            </a>
                            <a
                              class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer ml-2"
                              (click)="removeImage('profOfRentalReceipt')"
                              placement="left"
                              ngbTooltip="Change Document"
                            >
                              <i class="ti-trash text-danger m-r-10"></i>
                            </a>
                          </div>
                        </div>
                        <div *ngIf="!uploadedImg.profOfRentalReceipt">
                          <input
                            type="file"
                            name="deductiblesSection"
                            (change)="onSelectFile($event, 'profOfRentalReceipt')"
                            class="file-upload-width"
                            required 
                          />
                          <a
                            *ngIf="theftForm.get('profOfRentalReceipt').value"
                            class="ng2-smart-action ng2-smart-action-edit-edit ml-2 float-right"
                            placement="left"
                            ngbTooltip="View Document"
                            href="{{ baseUrl }}{{
                              theftForm.get('profOfRentalReceipt').value
                            }}"
                            target="_blank"
                          >
                            <i class="ti-eye text-info m-r-10"></i>
                          </a>
                        </div>
                      </div>
                     </div>                
                </div>
              </div>
              <div>
                <div class="form-group d-xl-flex align-items-center">
                  <!-- <label class="col-12">
                    <app-required-indication
                      [label]="
                        'Add More Documents'"
                    >
                    </app-required-indication>
                  </label> -->
                  <h3 class="title-case ml-3 mr-2">Add More Documents</h3>
                  <button
                      type="button"
                      class="btn btn-primary primary-background"
                      (click)="addDoc()"
                    >
                      <i class="mdi mdi-plus"></i> Add Doc
                    </button>
                </div>
                <div formArrayName="moreDocs" *ngIf="moreDocs.controls.length>0" class="mb-5">
                <div *ngFor="let item of moreDocs.controls; let i = index">
                  <div [formGroupName]="i">
                    <div class="form-group d-xl-flex align-items-center">
                      <div
                        class="form-group m-xl-0 col-12 col-xl-6 d-xl-flex align-items-center p-0"
                      >
                        <label class="col-12 col-xl-4 text-xl-center">
                          <app-required-indication
                            [control]="item.get('url')"
                            [label]="'Document'"
                          ></app-required-indication>
                        </label>
                        <div class="col-12 col-xl-8">
                          <input
                            type="file"
                            *ngIf="!item.get('url').value"
                            (change)="onChangeFile($event, i)"
                            name="url"
                            value="item.get('url').value"
                          />
                          <div *ngIf="item.get('url').value">
                            <label class="m-r-10">{{
                              item.get('url').value &&
                                item.get('url').value.substr(
                                  item.get('url').value.lastIndexOf('/') + 1
                                )
                            }}</label>
                            <a
                              class="ng2-smart-action ng2-smart-action-edit-edit"
                              placement="right"
                              ngbTooltip="View Request"
                              href="{{ baseUrl }}{{
                                item.get('url').value
                              }}"
                              target="_blank"
                              ><i class="ti-eye text-info m-r-10"></i>
                            </a>
                            <a
                              class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer"
                              (click)="changeImage(i)"
                              placement="top"
                              ngbTooltip="Change Image"
                            >
                              <i class="ti-move text-danger m-r-10"></i>
                            </a>
                          </div>
                      </div>
                      </div>
                      <div
                        class="form-group m-xl-0 col-12 col-xl-6 d-xl-flex align-items-center p-0"
                      >
                        <label class="col-12 col-xl-3">
                          <app-required-indication
                            [control]="item.get('name')"
                            [label]="'Document Description'"
                          >
                          </app-required-indication>
                        </label>
                        <div class="d-flex col-12 col-xl-9 p-0">
                          <div class="col-10">
                            <app-form-error-wrapper
                              [control]="item.get('name')"
                              [controlName]="'Title'"
                              [apiErrorType]="'name'"
                            >
                              <input
                                type="text"
                                name="title-{{ i }}"
                                class="form-control"
                                formControlName="name"
                              />
                            </app-form-error-wrapper>
                          </div>
                          <div class="col-2 text-right">
                            <button
                              class="btn btn-danger"
                              (click)="removeDoc(i)"
                            >
                              <i class="mdi mdi-close"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group d-xl-flex align-items-center ">
                  <label class="col-12 col-xl-6"></label>
                  <div class="col-12 col-xl-6 pl-5">
                    <!-- <button
                      type="button"
                      class="btn btn-primary primary-background"
                      (click)="addDoc()"
                    >
                      <i class="mdi mdi-plus"></i> Add Doc
                    </button> -->
                  </div>
                </div>
              </div>

              </div>
              
            </div>
            <div class="col-12 p-xl-0">
              <div class="form-group align-items-center">
                <label class="col-12 col-xl-12 mt-2">
                  <app-required-indication [control]="theftForm.get('reasonForReplace')"
                    [label]="'Reason & description for replacement'">
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9 mt-1">
                  <app-form-error-wrapper [control]="theftForm.get('reasonForReplace')"
                    [controlName]="'Reason & description for replacement'" [apiErrorType]="'reasonForReplace'">
                    <textarea type="text" name="reasonForReplace" rows="5" class="form-control" formControlName="reasonForReplace"></textarea>
                  </app-form-error-wrapper>
                </div>
              </div> 
            </div>
          </div>

        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-12 text-right">
              <button
              type="button"
              (click)="cancelClick()"
              class="btn btn-secondary mr-2"
            >
              Cancel
            </button>
              <button *ngIf="isEditMode" class="btn btn-info primary-background" [disabled]="theftForm.invalid || isLoading">
                <app-button-loader [isLoading]="isLoading" [label]="'Update Theft'"></app-button-loader>
              </button>
              <button *ngIf="isEditMode===false" class="btn btn-info primary-background" [disabled]="theftForm.invalid || isLoading">
                <app-button-loader [isLoading]="isLoading" [label]="'Create Theft'"></app-button-loader>
              </button>
              <!-- <button class="btn btn-primary" routerLink="/agents">Create Agent</button> -->
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="help-container">
      <div class="help-section">
        <p class="help-text" (click)="openHelpPopup()">
          <i class="hellp-popup-icons"></i>
        </p>
      </div>
    </div>
  </div>
</div>