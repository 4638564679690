<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div id="main-wrapper" [ngClass]="
    {
        'defaultdark': color == 'defaultdark',
        'greendark': color == 'greendark',
        'reddark': color == 'reddark',
        'bluedark': color == 'bluedark',
        'purpledark': color == 'purpledark',
        'megnadark': color == 'megnadark',
        'default': color == 'default',
        'green': color == 'green',
        'red': color == 'red',
        'blue': color == 'blue',
        'purple': color == 'purple',
        'megna': color == 'megna',
        'mini-sidebar': showMinisidebar,
        'dark-theme': showDarktheme
    }
">
    <!-- ============================================================== -->
    <!-- Topbar header - style you can find in pages.scss -->
    <!-- ============================================================== -->

    <app-navigation></app-navigation>
    <!-- ============================================================== -->
    <!-- Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <aside class="left-sidebar hide">
        <!-- Sidebar scroll-->
        <div class="scroll-sidebar" [perfectScrollbar]="config">
            <app-sidebar></app-sidebar>
        </div>
        <!-- End Sidebar scroll-->
    </aside>
    <!-- ============================================================== -->
    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Page wrapper  -->
    <!-- ============================================================== -->
    <div class="page-wrapper ml-0">
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid p-10">
            <app-breadcrumb></app-breadcrumb>
            <!-- ============================================================== -->
            <!-- Start Page Content -->
            <!-- ============================================================== -->
            <router-outlet></router-outlet>
            <!-- ============================================================== -->
            <!-- End Start Page Content -->
            <!-- ============================================================== -->
            <!-- ============================================================== -->
            <!-- Setting Right sidebar -->
            <!-- ============================================================== -->
            <!--<button class="right-side-toggle btn-inverse btn btn-circle btn-lg" (click)="showSettings = !showSettings"><i class="ti-settings text-white fa-spin"></i></button>
            <div class="right-sidebar" [ngClass]="{'shw-rside': showSettings}">
                <div class="slimscrollright">
                    <div class="rpanel-title"> Settings <span><i class="ti-close right-side-toggle" (click)="showSettings = !showSettings"></i></span> </div>
                    <div class="r-panel-body" [perfectScrollbar]="config">

                        <ul>
                            <li class="m-b-20 m-t-20"><b>Light Sidebar / Colors</b></li>
                            <li>

                                <div class="m-b-10">
                                    <div class="custom-control custom-checkbox">
                                      <input class="custom-control-input" id="customCheck1" name="radio-stacked" type="radio" value="default" [(ngModel)]="color">
                                      <label class="custom-control-label" for="customCheck1">Default</label>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="m-b-10">

                                    <div class="custom-control custom-checkbox">
                                      <input class="custom-control-input" id="customCheck2" name="radio-stacked" type="radio" value="green" [(ngModel)]="color">
                                      <label class="custom-control-label" for="customCheck2">Green</label>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="m-b-10">
                                    <div class="custom-control custom-checkbox">
                                      <input class="custom-control-input" id="customCheck3" name="radio-stacked" type="radio" value="red" [(ngModel)]="color">
                                      <label class="custom-control-label" for="customCheck3">Red</label>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="m-b-10">

                                    <div class="custom-control custom-checkbox">
                                      <input class="custom-control-input" id="customCheck4" name="radio-stacked" type="radio" value="blue" [(ngModel)]="color">
                                      <label class="custom-control-label" for="customCheck4">Blue</label>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="m-b-10">

                                    <div class="custom-control custom-checkbox">
                                      <input class="custom-control-input" id="customCheck5" name="radio-stacked" type="radio" value="purple" [(ngModel)]="color">
                                      <label class="custom-control-label" for="customCheck5">Purple</label>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="m-b-10">
                                    <div class="custom-control custom-checkbox">
                                      <input class="custom-control-input" id="customCheck6" name="radio-stacked" type="radio" value="megna" [(ngModel)]="color">
                                      <label class="custom-control-label" for="customCheck6">Megna</label>
                                    </div>
                                </div>
                            </li>
                        </ul>
                            <hr>
                        <ul>
                            <li class="m-b-20"><b>Dark Sidebar / Colors</b></li>
                            <li>
                                <div class="m-b-10">
                                    <div class="custom-control custom-checkbox">
                                      <input class="custom-control-input" id="customCheck7" name="radio-stacked" type="radio" value="defaultdark" [(ngModel)]="color">
                                      <label class="custom-control-label" for="customCheck7">Default Dark</label>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="m-b-10">
                                    <div class="custom-control custom-checkbox">
                                      <input class="custom-control-input" id="customCheck8" name="radio-stacked" type="radio" value="greendark" [(ngModel)]="color">
                                      <label class="custom-control-label" for="customCheck8">Green Dark</label>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="m-b-10">
                                    <div class="custom-control custom-checkbox">
                                      <input class="custom-control-input" id="customCheck9" name="radio-stacked" type="radio" value="reddark" [(ngModel)]="color">
                                      <label class="custom-control-label" for="customCheck9">Red Dark</label>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="m-b-10">
                                    <div class="custom-control custom-checkbox">
                                      <input class="custom-control-input" id="customCheck10" name="radio-stacked" type="radio" value="bluedark" [(ngModel)]="color">
                                      <label class="custom-control-label" for="customCheck10">Blue Dark</label>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="m-b-10">
                                    <div class="custom-control custom-checkbox">
                                      <input class="custom-control-input" id="customCheck11" name="radio-stacked" type="radio" value="purpledark" [(ngModel)]="color">
                                      <label class="custom-control-label" for="customCheck11">Purple Dark</label>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="m-b-10">
                                    <div class="custom-control custom-checkbox">
                                      <input class="custom-control-input" id="customCheck12" name="radio-stacked" type="radio" value="megnadark" [(ngModel)]="color">
                                      <label class="custom-control-label" for="customCheck12">Megna Dark</label>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <hr>
                        <ul>
                            <li class="m-b-20 m-t-20"><b>General Setting</b></li>
                            <li>
                                <div class="m-b-10">

                                    <div class="custom-control custom-checkbox">
                                      <input class="custom-control-input sidebartoggler" id="customCheck13" type="checkbox" [(ngModel)]="showMinisidebar">
                                      <label class="custom-control-label" for="customCheck13">Mini Sidebar</label>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="m-b-10">

                                    <div class="custom-control custom-checkbox">
                                      <input class="custom-control-input" id="customCheck14" type="checkbox"  [(ngModel)]="showDarktheme">
                                      <label class="custom-control-label" for="customCheck14">Dark Theme</label>
                                    </div>

                                </div>
                            </li>
                        </ul>
                        <hr>
                    </div>
                </div>
            </div>-->
            <!-- ============================================================== -->
            <!-- End Right sidebar -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Page wrapper  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- footer -->
    <!-- ============================================================== -->

    <!-- ============================================================== -->
    <!-- End footer -->
    <!-- ============================================================== -->

</div>
