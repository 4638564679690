var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { ClaimService } from '@app/core/service/claim.service';
import { environment } from '../../../environments/environment';
import { UtilService, LOCAL_VAR } from '@app/core';
import { ClaimHelpComponent } from '@app/shared/modals/claim-help/claim-help.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
var HomeComponent = /** @class */ (function () {
    function HomeComponent(router, modalService, authenticationService, claimService, utilService) {
        this.router = router;
        this.modalService = modalService;
        this.authenticationService = authenticationService;
        this.claimService = claimService;
        this.utilService = utilService;
        this.isLoading = false;
        this.appname = environment.name;
        this.applogo = environment.logo;
        this.productname = '';
        this.loggedInUserType = '';
        this.claims = { gap: 0, lease: 0, keyfetch: 0, shield: 0, debitcancel: 0, reimburse: 0, theft: 0, gap_void: 0, lease_void: 0, keyfetch_void: 0, shield_void: 0, debitcancel_void: 0, reimburse_void: 0, theft_void: 0 };
        this.credentials$ = new EventEmitter();
        this.user = this.authenticationService.credentials.user;
        this.loggedInUserType = this.user.userRoleId.slug;
    }
    HomeComponent.prototype.ngOnInit = function () {
        // if (this.loggedInUserType === 'customer') {
        this.getProdutsList();
        //}
    };
    HomeComponent.prototype.ngAfterViewInit = function () {
        var domainconfig = JSON.parse(localStorage.getItem(LOCAL_VAR.domainconfig));
        if (domainconfig) {
            if (domainconfig.secondaryColor) {
                this.utilService.changeCssAttribute('card-body', 'background-color', domainconfig.primaryColor);
            }
        }
    };
    HomeComponent.prototype.selectProduct = function (product) {
        switch (product) {
            case 'gap':
                localStorage.setItem(LOCAL_VAR.selectedProduct, 'gap');
                if (this.loggedInUserType !== 'customer') {
                    this.router.navigate(['/dashboard'], {
                        replaceUrl: true
                    });
                }
                else {
                    this.router.navigate([(this.claims.gap != 0 || this.claims.gap_void != 0) ? '/claim' : '/claim/create'], {
                        replaceUrl: true
                    });
                }
                break;
            case 'lease':
                localStorage.setItem(LOCAL_VAR.selectedProduct, 'lease');
                this.router.navigate([(this.claims.lease != 0 || this.claims.lease_void != 0) ? '/lease-list' : '/lease-create'], {
                    replaceUrl: true
                });
                break;
            case 'keyfetch':
                localStorage.setItem(LOCAL_VAR.selectedProduct, 'keyfetch');
                this.router.navigate([(this.claims.keyfetch != 0 || this.claims.keyfetch_void != 0) ? '/keyprotection-list' : '/keyprotection-create'], {
                    replaceUrl: true
                });
                break;
            case 'shield':
                localStorage.setItem(LOCAL_VAR.selectedProduct, 'shield');
                this.router.navigate([(this.claims.shield != 0 || this.claims.shield_void != 0) ? '/360shield-list' : '/360shield-create'], {
                    replaceUrl: true
                });
                break;
            case 'debitcancellation':
                localStorage.setItem(LOCAL_VAR.selectedProduct, 'debitcancellation');
                this.router.navigate([(this.claims.debitcancel != 0 || this.claims.debitcancel_void != 0) ? '/debitcancellation-list' : '/debitcancellation-create'], {
                    replaceUrl: true
                });
                break;
            case 'reimburse':
                localStorage.setItem(LOCAL_VAR.selectedProduct, 'reimburse');
                this.router.navigate([(this.claims.reimburse != 0 || this.claims.reimburse_void != 0) ? '/reimburse-list' : '/reimburse-create'], {
                    replaceUrl: true
                });
                break;
            case 'theft':
                localStorage.setItem(LOCAL_VAR.selectedProduct, 'theft');
                this.router.navigate([(this.claims.theft != 0 || this.claims.theft_void != 0) ? '/theft-list' : '/theft-create'], {
                    replaceUrl: true
                });
                break;
        }
    };
    HomeComponent.prototype.getProdutsList = function () {
        var _this = this;
        this.isLoading = true;
        this.claimService
            .getProducts()
            .pipe(finalize(function () {
            _this.isLoading = false;
        }))
            .subscribe(function (response) {
            if (response) {
                _this.claims = response;
            }
        });
    };
    HomeComponent.prototype.openHelpPopup = function () {
        return __awaiter(this, void 0, void 0, function () {
            var modalRef;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalService.open(ClaimHelpComponent)];
                    case 1:
                        modalRef = _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return HomeComponent;
}());
export { HomeComponent };
