export const environment = {
  env: 'PROD',
  production: true,
  // live url
  // serverBaseUrl: 'https://claim-api.dowc.com',
  // serverUrl: 'https://claim-api.dowc.com/v1',
  // domainUrl: 'https://claims.dowc.com/',
  // s3Url: 'https://dowc-gap-claims.s3.amazonaws.com/',
  // adminSystemURL: "https://apps-api.dowc.com/v1/",
  // sharepointAPI:"https://service.dowc.com/sharepointapi/v1/",
  // fileClaim:"https://dowc.com/contract-holders/#claim",
  // siteKey:"6LdnHzMcAAAAAGvK778g8xYFLNlekFZAQdEDzl8l",
  // name:'DOWC',
  // logo:'dowc',

   // qa url
   serverBaseUrl: 'https://qaclaim-api.dowc.com',
   serverUrl: 'https://qaclaim-api.dowc.com/v1',
   domainUrl: 'http://qaclaims.dowc.com/',
   s3Url: 'https://gap-claim.s3.amazonaws.com/',
   adminSystemURL: "https://apibeta-apps.dowc.com/v1/",
   sharepointAPI:"https://service.dowc.com/sharepointapi/v1/",
   fileClaim:"https://dowc.com/contract-holders/#claim",
   siteKey:"6Lc_PT4cAAAAABMKyj-dH5cU9cYBS0M8mq9JcBnT",
   name:'DOWC',
   logo:'DOWC',

   // UAT url
   // serverBaseUrl: 'https://uatclaimsapi.dowc.com',
   // serverUrl: 'https://uatclaimsapi.dowc.com/v1',
   // domainUrl: 'http://uatclaimsfront.dowc.com/',
   // s3Url: 'https://gap-claim.s3.amazonaws.com/',
   // adminSystemURL: "https://apibeta-apps.dowc.com/v1/",
   // sharepointAPI:"https://service.dowc.com/sharepointapi/v1/",
   // fileClaim:"https://dowc.com/contract-holders/#claim",
   // siteKey:"6Lc_PT4cAAAAABMKyj-dH5cU9cYBS0M8mq9JcBnT",
   // name:'DOWC',
   // logo:'DOWC',

  //local url
  // serverBaseUrl: 'http://localhost:1400',
  // serverUrl: 'http://localhost:1400/v1',
  // domainUrl: 'http://localhost:4404/',
  // s3Url: 'https://gap-claim.s3.amazonaws.com/',
  // adminSystemURL: 'https://apibeta-apps.dowc.com/v1/',
  // sharepointAPI:"http://localhost/sharepoint_api/",
  // fileClaim:"https://dowc.com/contract-holders/#claim",
  // siteKey: '6Ld-igwcAAAAAHCMyfLXQIVwxrbytQI_udVVb4Rv',
  // name: 'APS',
  // logo: 'APS', 

};
