var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, ElementRef } from '@angular/core';
import { DealerCreateComponent } from '@app/shared/modals/dealer-create/dealer-create.component';
import { DealerEditComponent } from '@app/shared/modals/dealer-edit/dealer-edit.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService, LOCAL_VAR } from '@app/core';
import { CustomerService } from '@app/core/service/customer.service';
import { FormBuilder } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { ClaimHelpComponent } from '@app/shared/modals/claim-help/claim-help.component';
import { Router } from '@angular/router';
var DealerListComponent = /** @class */ (function () {
    function DealerListComponent(router, modalService, utilService, customerService, formBuilder, authenticationService) {
        this.router = router;
        this.modalService = modalService;
        this.utilService = utilService;
        this.customerService = customerService;
        this.formBuilder = formBuilder;
        this.authenticationService = authenticationService;
        this.isLoading = false;
        this.selectedCustomer = null;
        this.isFileterCollapse = true;
        this.perPage = 10;
        this.currentPage = 1;
        this.sortDir = 1;
        this.sortBy = 'name';
        this.loggedInUserType = null;
        this.pager = {
            perPage: 10,
            currentPage: 1,
            Totalrecords: 0,
            TotalPages: 0
        };
        this.user = this.authenticationService.credentials.user;
        this.loggedInUserType = this.user.userRoleId.slug;
        this.createForm();
        this.getAllDelaers();
        this.selectedCustomer = localStorage.getItem(LOCAL_VAR.selectedCustomer);
        this.selectedCustomer = JSON.parse(this.selectedCustomer);
        localStorage.setItem(LOCAL_VAR.selectedCustomer, null);
        localStorage.setItem(LOCAL_VAR.selectedProduct, null);
    }
    DealerListComponent.prototype.ngOnInit = function () {
        if (this.loggedInUserType !== 'dowcsuperadmin') {
            this.router.navigate(['/products']);
        }
    };
    DealerListComponent.prototype.ngAfterViewInit = function () {
        this.utilService.updateDomainCss();
    };
    DealerListComponent.prototype.createForm = function () {
        var _this = this;
        this.isLoading = true;
        this.filterUser = this.formBuilder.group({
            status: [''],
            dealername: [''],
            sortFieldFilter: [''],
            sortByFilter: [''],
            perPage: 10
        });
        this.filterUser.valueChanges.subscribe(function (values) {
            _this.pager.currentPage = 1;
            _this.getAllDelaers();
        });
    };
    DealerListComponent.prototype.getAllDelaers = function () {
        var _this = this;
        var payload = this.filterUser.value;
        payload = this.utilService.removeWhiteSpace(payload);
        payload.currentPage = this.pager.currentPage;
        this.pager.perPage = payload.perPage;
        this.isLoading = true;
        this.customerService
            .getAllDealers(payload)
            .pipe(finalize(function () {
            _this.isLoading = false;
        }))
            .subscribe(function (response) {
            _this.dealersList = response.records;
            _this.renderPagination(response.page, response.total, response.totalPage);
        });
    };
    DealerListComponent.prototype.sort = function (sortByVal) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.sortDir = this.sortDir === 1 ? -1 : 1;
                this.filterUser.value.sortFieldFilter = sortByVal;
                this.filterUser.value.sortByFilter = this.sortDir;
                this.sortBy = sortByVal;
                this.getAllDelaers();
                return [2 /*return*/];
            });
        });
    };
    DealerListComponent.prototype.goPage = function () {
        var goto = this.nameInput.nativeElement.value;
        this.setPage(goto);
        this.nameInput.nativeElement.value = '';
    };
    DealerListComponent.prototype.setPage = function (page) {
        if (0 < page && page <= this.pager.TotalPages && page != this.pager.currentPage) {
            this.pager.currentPage = page;
            this.getAllDelaers();
        }
    };
    DealerListComponent.prototype.renderPagination = function (page, total, totalPage) {
        this.pager.currentPage = page;
        this.pager.Totalrecords = total;
        this.pager.TotalPages = totalPage;
        this.PageList = this.utilService.getPageList(this.pager.TotalPages, this.pager.currentPage);
    };
    DealerListComponent.prototype.createDealer = function () {
        return __awaiter(this, void 0, void 0, function () {
            var modalRef, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalService.open(DealerCreateComponent)];
                    case 1:
                        modalRef = _a.sent();
                        return [4 /*yield*/, modalRef.result.catch(function (e) { return e; })];
                    case 2:
                        result = _a.sent();
                        if (result) {
                            this.currentPage = 1;
                            this.getAllDelaers();
                            this.createForm();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    DealerListComponent.prototype.editDealer = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var modalRef, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalService.open(DealerEditComponent)];
                    case 1:
                        modalRef = _a.sent();
                        modalRef.componentInstance.name = data.name;
                        modalRef.componentInstance.status = data.status;
                        modalRef.componentInstance.id = data._id;
                        return [4 /*yield*/, modalRef.result.catch(function (e) { return e; })];
                    case 2:
                        result = _a.sent();
                        if (result) {
                            this.getAllDelaers();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    DealerListComponent.prototype.openHelpPopup = function () {
        return __awaiter(this, void 0, void 0, function () {
            var modalRef;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalService.open(ClaimHelpComponent)];
                    case 1:
                        modalRef = _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return DealerListComponent;
}());
export { DealerListComponent };
