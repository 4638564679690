import { Component, OnDestroy, OnInit } from '@angular/core';
import { USER_TYPES } from '@app/core';
import { Subject } from 'rxjs';
declare var $: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit, OnDestroy {
  user: Authentication.User;
  showMenu = '';
  showSubMenu = '';
  public sidebarnavItems: any[];
  public superUserSidebarnavItems: any[];
  testMode: boolean;
  userRoles = USER_TYPES;
  private unsubscribe: Subject<void> = new Subject();

  constructor() {}

  ngOnInit() {
    $(function() {
      $('.sidebartoggler').on('click', function() {
        if ($('#main-wrapper').hasClass('mini-sidebar')) {
          $('body').trigger('resize');
          $('#main-wrapper').removeClass('mini-sidebar');
        } else {
          $('body').trigger('resize');
          $('#main-wrapper').addClass('mini-sidebar');
        }
      });
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // this is for the open close
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }
  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }
  // End open close
}
