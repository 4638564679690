<div class="row agent-container">
  <div class="col-12">
    <div class="card">
      <form [formGroup]="debitForm" (ngSubmit)="submitData()" class="form-horizontal form-material" id="debitForm"
        novalidate>
        <div class="card-header">
          Debt Cancellation Processing Form
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12 p-0">
              <h2 class="calcuationstep col-12">Data Entry</h2>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('contractNumber')"
                    [label]="'Contract Number'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('contractNumber')"
                    [controlName]="'Contract Number'"
                    [apiErrorType]="'contractNumber'"
                  >
                    <input
                      type="text"
                      name="contractNumber"
                      class="form-control"
                      formControlName="contractNumber"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('gapCalculationApproval')"
                    [label]="'Gap Calculation Approval'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <select
                    class="form-control custom-select"
                    name="gapCalculationApproval"
                    formControlName="gapCalculationApproval"
                  >
                    <option value="approved">Approved</option>
                    <option value="denied">Denied</option>
                  </select>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('claimDate')"
                    [label]="'Date of Loss'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('claimDate')"
                    [controlName]="'Date of Loss'"
                    [apiErrorType]="'claimDate'"
                  >
                    <input
                      type="text"
                      name="claimDate"
                      formControlName="claimDate"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d="ngbDatepicker"
                      class="form-control"
                      (click)="d.toggle()"
                      [readonly]="true"
                      [minDate]="{ year: 1900, month: 1, day: 1 }"
                      [maxDate]="{
                        year: utilService.getCurrentYear + 10,
                        month: 12,
                        day: 31
                      }"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication [control]="debitForm.get('agreementPurchasePrice')"
                    [label]="'Agreement Purchase Price'">
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper [control]="debitForm.get('agreementPurchasePrice')"
                    [controlName]="'Deductible amount'" [apiErrorType]="'agreementPurchasePrice'">
                    <input type="text" name="agreementPurchasePrice" class="form-control" currencyMask
                      autocomplete="off" [formControl]="debitForm.controls['agreementPurchasePrice']" />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 p-0 d-xl-flex align-items-center">
                <label class="col-12 col-xl-3">
                  <app-required-indication [control]="debitForm.get('agreementPurchaseDate')"
                    [label]="'Agreement Purchase Date'">
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9 pos-rel">
                  <app-form-error-wrapper [control]="debitForm.get('agreementPurchaseDate')"
                    [controlName]="'Date Leased Vehicle Returned'" [apiErrorType]="'agreementPurchaseDate'">
                    <input type="text" name="agreementPurchaseDate" formControlName="agreementPurchaseDate"
                      placeholder="MM/DD/YYYY" ngbDatepicker #d1="ngbDatepicker" class="form-control"
                      (click)="d1.toggle()" [readonly]="true" [minDate]="{ year: 1900, month: 1, day: 1 }"
                      [maxDate]="{
                        year: utilService.getCurrentYear,
                        month: utilService.getCurrentMonth,
                        day: utilService.getCurrentDay
                      }" />
                  </app-form-error-wrapper>
                  <a class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer pos-abs datecancel-r15"
                    (click)="utilService.removeDate(debitForm, 'agreementPurchaseDate')" placement="left"
                    ngbTooltip="Remove" *ngIf="debitForm.controls['agreementPurchaseDate'].value">
                    <i class="ti-close text-danger m-r-10"></i>
                  </a>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('maximunBenefitLimit')"
                    [label]="'Maximum Benefit Limit'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('maximunBenefitLimit')"
                    [controlName]="'Maximum Benefit Limit'"
                    [apiErrorType]="'maximunBenefitLimit'"
                  >
                    <input
                      type="text"
                      name="maximunBenefitLimit"
                      class="form-control"
                      formControlName="maximunBenefitLimit"
                      placeholder="$0.00"
                      currencyMask
                      [options]="{ allowNegative: false }"
                      autocomplete="off"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('vehiclePurchasePrice')"
                    [label]="'Cash Price of Vehicle'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('vehiclePurchasePrice')"
                    [controlName]="'Cash Price of Vehicle'"
                    [apiErrorType]="'vehiclePurchasePrice'"
                  >
                    <input
                      type="text"
                      name="vehiclePurchasePrice"
                      class="form-control"
                      formControlName="vehiclePurchasePrice"
                      placeholder="$0.00"
                      currencyMask
                      [options]="{ allowNegative: false }"
                      autocomplete="off"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 p-0 d-xl-flex align-items-center">
                <label class="col-12 col-xl-3">
                  <app-required-indication [control]="debitForm.get('dateOfFirstPayment')"
                    [label]="'Date of First Payment'">
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9 pos-rel">
                  <app-form-error-wrapper [control]="debitForm.get('dateOfFirstPayment')"
                    [controlName]="'Date of First Payment'" [apiErrorType]="'dateOfFirstPayment'">
                    <input type="text" name="dateOfFirstPayment" formControlName="dateOfFirstPayment"
                      placeholder="MM/DD/YYYY" ngbDatepicker #d2="ngbDatepicker" class="form-control"
                      (click)="d2.toggle()" [readonly]="true" [minDate]="{ year: 1900, month: 1, day: 1 }" [maxDate]="{
                        year: utilService.getCurrentYear,
                        month: utilService.getCurrentMonth,
                        day: utilService.getCurrentDay
                      }" />
                  </app-form-error-wrapper>
                  <a class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer pos-abs datecancel-r15"
                    (click)="utilService.removeDate(debitForm, 'dateOfFirstPayment')" placement="left"
                    ngbTooltip="Remove" *ngIf="debitForm.controls['dateOfFirstPayment'].value">
                    <i class="ti-close text-danger m-r-10"></i>
                  </a>
                </div>
              </div>
              <div class="form-group col-12 p-0 d-xl-flex align-items-center">
                <label class="col-12 col-xl-3">
                  <app-required-indication [control]="debitForm.get('APR')" [label]="'APR'">
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper [control]="debitForm.get('APR')" [controlName]="'APR'"
                    [apiErrorType]="'APR'">
                    <input type="text" name="APR" class="form-control"
                    maxlength="3" 
                    min="0" 
                    max="100" [formControl]="debitForm.controls['APR']" (keypress)="omit_char($event)" />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('amountFinanced')"
                    [label]="'Finance Amount'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('amountFinanced')"
                    [controlName]="'Finance Amount'"
                    [apiErrorType]="'amountFinanced'"
                  >
                    <input
                      type="text"
                      name="amountFinanced"
                      class="form-control"
                      formControlName="amountFinanced"
                      placeholder="$0.00"
                      currencyMask
                      [options]="{ allowNegative: false }"
                      autocomplete="off"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('balloonPayment')"
                    [label]="'Balloon Payment ($)'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('balloonPayment')"
                    [controlName]="'Balloon Payments'"
                    [apiErrorType]="'balloonPayment'"
                  >
                    <input
                      type="text"
                      name="balloonPayment"
                      class="form-control"
                      formControlName="balloonPayment"
                      placeholder="$0.00"
                      currencyMask
                      [options]="{ allowNegative: false }"
                      autocomplete="off"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 p-0 d-xl-flex align-items-center">
                <label class="col-12 col-xl-3">
                  <app-required-indication [control]="debitForm.get('totalOfAllPayement')" [label]="'Total of All Payment'">
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper [control]="debitForm.get('totalOfAllPayement')"
                    [controlName]="'totalOfAllPayement'" [apiErrorType]="'totalOfAllPayement'">
                    <input type="text" name="totalOfAllPayement" class="form-control" currencyMask
                      autocomplete="off" [formControl]="debitForm.controls['totalOfAllPayement']" />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 col-xl-12 d-xl-flex align-items-center">
                <label class="col-12 col-xl-3 p-0">
                  <app-required-indication [control]="debitForm.get('financingContractType')"
                    [label]="'Type of Financing Contract'">
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-4">
                  <div class="custom-control custom-checkbox p-0">
                    <div class="custom-control custom-checkbox ">
                      <input id="checkbox-retailInstallmentSales" class="custom-control-input"
                        (click)="selectCheckbox('retailInstallmentSales', $event, 'financingContractType')"
                        type="checkbox"
                        [checked]="debitForm.get('financingContractType').value.retailInstallmentSales" />
                      <label for="checkbox-retailInstallmentSales" class="custom-control-label">Retail Installment
                        Sales (CONVENTIONAL LOANS)</label>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-xl-2">
                  <div class="custom-control custom-checkbox p-0">
                    <input id="checkbox-lease" class="custom-control-input"
                      (click)="selectCheckbox('lease', $event, 'financingContractType')" type="checkbox"
                      [checked]="debitForm.get('financingContractType').value.lease" />
                    <label for="checkbox-lease" class="custom-control-label">LEASE</label>
                  </div>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('dateOfFirstDue')"
                    [label]="'First Due Date'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('dateOfFirstDue')"
                    [controlName]="'First Due Date'"
                    [apiErrorType]="'dateOfFirstDue'"
                  >
                    <input
                      type="text"
                      name="dateOfFirstDue"
                      formControlName="dateOfFirstDue"
                      placeholder="MM/DD/YYYY"
                      ngbDatepicker
                      #d3="ngbDatepicker"
                      class="form-control"
                      (click)="d3.toggle()"
                      [minDate]="{ year: 1900, month: 1, day: 1 }"
                      [maxDate]="{
                        year: utilService.getCurrentYear + 10,
                        month: 12,
                        day: 31
                      }"
                    />
                  </app-form-error-wrapper>
                  <a
                    class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer pos-abs datecancel-r15"
                    (click)="utilService.removeDate(debitForm, 'dateOfFirstDue')"
                    placement="left"
                    ngbTooltip="Remove"
                    *ngIf="debitForm.controls['dateOfFirstDue'].value"
                  >
                    <i class="ti-close text-danger m-r-10"></i>
                  </a>
                </div>
              </div> 
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('paymentsPerPeriod')"
                    [label]="'Payment (per period)'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('paymentsPerPeriod')"
                    [controlName]="'paymentsPerPeriod'"
                    [apiErrorType]="'paymentsPerPeriod'"
                  >
                    <input
                      type="text"
                      name="paymentsPerPeriod"
                      class="form-control"
                      formControlName="paymentsPerPeriod"
                      placeholder="$0.00"
                      currencyMask
                      [options]="{ allowNegative: false }"
                      autocomplete="off"
                    />
                  </app-form-error-wrapper>
                </div>
              </div> 
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('numberOfPayments')"
                    [label]="'Number Of Payments'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('numberOfPayments')"
                    [controlName]="'Number Of Payments'"
                    [apiErrorType]="'numberOfPayments'"
                  >
                    <input
                      type="number"
                      name="numberOfPayments"
                      min="0"
                      class="form-control"
                      formControlName="numberOfPayments"
                      autocomplete="off"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('frequencyOfPayments')"
                    [label]="'Frequency (Of Payments )'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('frequencyOfPayments')"
                    [controlName]="'Frequency (Of Payments )'"
                    [apiErrorType]="'frequencyOfPayments'"
                  >
                  </app-form-error-wrapper>
                  <select
                    class="form-control custom-select"
                    name="frequencyOfPayments"
                    formControlName="frequencyOfPayments"
                  >
                    <option value="12">Monthly</option>
                    <option value="365">Daily</option>
                    <option value="24">Semi-Monthly</option>
                    <option value="26">Bi-Weekly</option>
                    <option value="52">Weekly</option>
                  </select>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('includeSalvage')"
                    [label]="'Include Salvage (Yes/No)'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('includeSalvage')"
                    [controlName]="'Include Salvage (Yes/No)'"
                    [apiErrorType]="'includeSalvage'"
                  >
                    <select
                    class="form-control custom-select"
                    name="includeSalvage"
                    formControlName="includeSalvage"
                  >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('accessories')"
                    [label]="'Accessories'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('accessories')"
                    [controlName]="'Accessories'"
                    [apiErrorType]="'accessories'"
                  >
                    <input
                      type="text"
                      name="accessories"
                      class="form-control"
                      formControlName="accessories"
                      placeholder="$0.00"
                      currencyMask
                      [options]="{ allowNegative: false }"
                      autocomplete="off"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('serviceContract')"
                    [label]="'Service Contract'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('serviceContract')"
                    [controlName]="'Service Contract'"
                    [apiErrorType]="'serviceContract'"
                  >
                    <input
                      type="text"
                      name="serviceContract"
                      class="form-control"
                      formControlName="serviceContract"
                      placeholder="$0.00"
                      currencyMask
                      [options]="{ allowNegative: false }"
                      autocomplete="off"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('closingFee')"
                    [label]="'Closing Fee'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('closingFee')"
                    [controlName]="'Closing Fee'"
                    [apiErrorType]="'closingFee'"
                  >
                    <input
                      type="text"
                      name="closingFee"
                      class="form-control"
                      formControlName="closingFee"
                      placeholder="$0.00"
                      currencyMask
                      [options]="{ allowNegative: false }"
                      autocomplete="off"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('titleFee')"
                    [label]="'Title Fee'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('titleFee')"
                    [controlName]="'Title Fee'"
                    [apiErrorType]="'titleFee'"
                  >
                    <input
                      type="text"
                      name="titleFee"
                      class="form-control"
                      formControlName="titleFee"
                      placeholder="$0.00"
                      currencyMask
                      [options]="{ allowNegative: false }"
                      autocomplete="off"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('salesTax')"
                    [label]="'Sales Tax'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('salesTax')"
                    [controlName]="'Sales Tax'"
                    [apiErrorType]="'salesTax'"
                  >
                    <input
                      type="text"
                      name="salesTax"
                      class="form-control"
                      formControlName="salesTax"
                      placeholder="$0.00"
                      currencyMask
                      [options]="{ allowNegative: false }"
                      autocomplete="off"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('license')"
                    [label]="'License'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('license')"
                    [controlName]="'License'"
                    [apiErrorType]="'license'"
                  >
                    <input
                      type="text"
                      name="license"
                      class="form-control"
                      formControlName="license"
                      placeholder="$0.00"
                      currencyMask
                      [options]="{ allowNegative: false }"
                      autocomplete="off"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('actualPayoffAmount')"
                    [label]="'Actual Payoff Amount'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('actualPayoffAmount')"
                    [controlName]="'Actual Payoff Amount'"
                    [apiErrorType]="'actualPayoffAmount'"
                  >
                    <input
                      type="text"
                      name="actualPayoffAmount"
                      class="form-control"
                      formControlName="actualPayoffAmount"
                      placeholder="$0.00"
                      currencyMask
                      [options]="{ allowNegative: false }"
                      autocomplete="off"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('liabilityInsuranceSettlement')"
                    [label]="'Liability Insurance Settlement'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('liabilityInsuranceSettlement')"
                    [controlName]="'Liability Insurance Settlement'"
                    [apiErrorType]="'liabilityInsuranceSettlement'"
                  >
                    <input
                      type="text"
                      name="liabilityInsuranceSettlement"
                      class="form-control"
                      [formControl]="debitForm.get('liabilityInsuranceSettlement')"
                      placeholder="$0.00"
                      currencyMask
                      [options]="{ allowNegative: false }"
                      autocomplete="off"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('comprehensiveInsuranceSettlement')"
                    [label]="'Comprehensive Insurance Settlement'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('comprehensiveInsuranceSettlement')"
                    [controlName]="'Comprehensive Insurance Settlement'"
                    [apiErrorType]="'comprehensiveInsuranceSettlement'"
                  >
                    <input
                      type="text"
                      name="comprehensiveInsuranceSettlement"
                      class="form-control"
                      [formControl]="debitForm.get('comprehensiveInsuranceSettlement')"
                      placeholder="$0.00"
                      currencyMask
                      [options]="{ allowNegative: false }"
                      autocomplete="off"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('Dedectibles')"
                    [label]="'Deductible'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('Dedectibles')"
                    [controlName]="'Deductible'"
                    [apiErrorType]="'Dedectibles'"
                  >
                    <input
                      type="text"
                      name="Dedectibles"
                      class="form-control"
                      [formControl]="debitForm.get('Dedectibles')"
                      placeholder="$0.00"
                      currencyMask
                      [options]="{ allowNegative: false }"
                      autocomplete="off"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('conditionAdjustmentA')"
                    [label]="'Condition Adjustment 1'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('conditionAdjustmentA')"
                    [controlName]="'Condition Adjustment 1'"
                    [apiErrorType]="'conditionAdjustmentA'"
                  >
                    <input
                      type="text"
                      name="conditionAdjustmentA"
                      class="form-control"
                      formControlName="conditionAdjustmentA"
                      placeholder="$0.00"
                      currencyMask
                      [options]="{ allowNegative: false }"
                      autocomplete="off"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('conditionAdjustmentB')"
                    [label]="'Condition Adjustment 2'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('conditionAdjustmentB')"
                    [controlName]="'Condition Adjustment 2'"
                    [apiErrorType]="'conditionAdjustmentB'"
                  >
                    <input
                      type="text"
                      name="conditionAdjustmentB"
                      class="form-control"
                      formControlName="conditionAdjustmentB"
                      placeholder="$0.00"
                      currencyMask
                      [options]="{ allowNegative: false }"
                      autocomplete="off"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('conditionAdjustmentC')"
                    [label]="'Condition Adjustment 3'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('conditionAdjustmentC')"
                    [controlName]="'Condition Adjustment 3'"
                    [apiErrorType]="'conditionAdjustmentC'"
                  >
                    <input
                      type="text"
                      name="conditionAdjustmentC"
                      class="form-control"
                      formControlName="conditionAdjustmentC"
                      placeholder="$0.00"
                      currencyMask
                      [options]="{ allowNegative: false }"
                      autocomplete="off"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('financeAPR')"
                    [label]="'Finance APR %'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('financeAPR')"
                    [controlName]="'finance APR'"
                    [apiErrorType]="'financeAPR'"
                  >
                    <input
                      type="number"
                      min="0"
                      name="financeAPR"
                      class="form-control"
                      formControlName="financeAPR"
                      autocomplete="off"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('projectedPrincipalBalance')"
                    [label]="'Projected Principal Balance'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('projectedPrincipalBalance')"
                    [controlName]="'Projected Principal Balance'"
                    [apiErrorType]="'projectedPrincipalBalance'"
                  >
                    <input
                      type="text"
                      name="projectedPrincipalBalance"
                      class="form-control"
                      formControlName="projectedPrincipalBalance"
                      placeholder="$0.00"
                      currencyMask
                      autocomplete="off"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('estimatedPrincipalPaid')"
                    [label]="'Estimated Principle Paid'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('estimatedPrincipalPaid')"
                    [controlName]="'Estimated Principal Paid'"
                    [apiErrorType]="'estimatedPrincipalPaid'"
                  >
                    <input
                      type="text"
                      name="estimatedPrincipalPaid"
                      class="form-control"
                      formControlName="estimatedPrincipalPaid"
                      placeholder="$0.00"
                      currencyMask
                      autocomplete="off"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('numberofPaymentsperYear')"
                    [label]="'Number Of Payments Per Year'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('numberofPaymentsperYear')"
                    [controlName]="'Number Of Payments Per Year'"
                    [apiErrorType]="'numberofPaymentsperYear'"
                  >
                    <input
                      type="text"
                      name="numberofPaymentsperYear"
                      class="form-control"
                      formControlName="numberofPaymentsperYear"
                      placeholder=""
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('numberofPaymentsElapsed')"
                    [label]="'Number Of Payments Elapsed'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('numberofPaymentsElapsed')"
                    [controlName]="'Number Of Payments Elapsed'"
                    [apiErrorType]="'numberofPaymentsElapsed'"
                  >
                    <input
                      type="text"
                      name="numberofPaymentsElapsed"
                      class="form-control"
                      formControlName="numberofPaymentsElapsed"
                      autocomplete="off"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('realizedAPR')"
                    [label]="'Realized APR %'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('realizedAPR')"
                    [controlName]="'Realized APR'"
                    [apiErrorType]="'realizedAPR'"
                  >
                    <input
                      type="text"
                      name="realizedAPR"
                      class="form-control"
                      formControlName="realizedAPR"
                      autocomplete="off"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <h2 class="calcuationstep col-12">Settlement Calculations</h2>
              <!-- gap calculation - begin -->
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('projectedPrincipalBalance')"
                    [label]="'Projected Principal Balance'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('projectedPrincipalBalance')"
                    [controlName]="'Projected Principal Balance'"
                    [apiErrorType]="'projectedPrincipalBalance'"
                  >
                    <input
                      type="text"
                      name="projectedPrincipalBalance"
                      class="form-control"
                      formControlName="projectedPrincipalBalance"
                      placeholder="$0.00"
                      currencyMask
                      autocomplete="off"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('actualPayoffAmount1')"
                    [label]="'Actual Payoff Amount'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('actualPayoffAmount1')"
                    [controlName]="'Actual Payoff Amount'"
                    [apiErrorType]="'actualPayoffAmount1'"
                  >
                    <input
                      type="text"
                      name="actualPayoffAmount1"
                      class="form-control"
                      formControlName="actualPayoffAmount1"
                      placeholder="$0.00"
                      currencyMask
                      autocomplete="off"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3 cal-imp">
                  <app-required-indication
                    [control]="debitForm.get('lesserAdjustedAmount')"
                    [label]="'Lesser of Actual and Projected Payoff Amount (A)'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('lesserAdjustedAmount')"
                    [controlName]="
                      'Lesser of Actual and Projected Payoff Amount (A)'
                    "
                    [apiErrorType]="'lesserAdjustedAmount'"
                  >
                    <input
                      type="text"
                      name="lesserAdjustedAmount"
                      class="form-control"
                      formControlName="lesserAdjustedAmount"
                      placeholder="$0.00"
                      disabled="true"
                      currencyMask
                      autocomplete="off"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('liabilityInsuranceSettlement1')"
                    [label]="'Liability Insurance Settlement'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('liabilityInsuranceSettlement1')"
                    [controlName]="'Liability Insurance Settlement'"
                    [apiErrorType]="'liabilityInsuranceSettlement1'"
                  >
                    <input
                      type="text"
                      name="liabilityInsuranceSettlement1"
                      class="form-control"
                      [formControl]="debitForm.get('liabilityInsuranceSettlement1')"
                      placeholder="$0.00"
                      disabled="true"
                      currencyMask
                      autocomplete="off"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('comprehensiveInsuranceSettlement1')"
                    [label]="'Comprehensive Insurance Settlement'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('comprehensiveInsuranceSettlement1')"
                    [controlName]="'Comprehensive Insurance Settlement'"
                    [apiErrorType]="'comprehensiveInsuranceSettlement1'"
                  >
                    <input
                      type="text"
                      name="comprehensiveInsuranceSettlement1"
                      class="form-control"
                      [formControl]="debitForm.get('comprehensiveInsuranceSettlement1')"
                      placeholder="$0.00"
                      disabled="true"
                      currencyMask
                      autocomplete="off"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('Dedectibles1')"
                    [label]="'Deductible'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('Dedectibles1')"
                    [controlName]="'Deductible'"
                    [apiErrorType]="'Dedectibles1'"
                  >
                    <input
                      type="text"
                      name="Dedectibles1"
                      class="form-control"
                      [formControl]="debitForm.get('Dedectibles1')"
                      placeholder="$0.00"
                      currencyMask
                      autocomplete="off"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('accessories1')"
                    [label]="'Accessories'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('accessories1')"
                    [controlName]="'Accessories'"
                    [apiErrorType]="'accessories'"
                  >
                    <input
                      type="text"
                      name="accessories1"
                      class="form-control"
                      formControlName="accessories1"
                      placeholder="$0.00"
                      currencyMask
                      [options]="{ allowNegative: false }"
                      autocomplete="off"
                      disabled="true"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('serviceContract1')"
                    [label]="'Service Contract'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('serviceContract1')"
                    [controlName]="'Service Contract'"
                    [apiErrorType]="'serviceContract1'"
                  >
                    <input
                      type="text"
                      name="serviceContract1"
                      class="form-control"
                      formControlName="serviceContract1"
                      placeholder="$0.00"
                      currencyMask
                      [options]="{ allowNegative: false }"
                      autocomplete="off"
                      disabled="true"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('closingFee1')"
                    [label]="'Closing Fee'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('closingFee1')"
                    [controlName]="'Closing Fee'"
                    [apiErrorType]="'closingFee1'"
                  >
                    <input
                      type="text"
                      name="closingFee1"
                      class="form-control"
                      formControlName="closingFee1"
                      placeholder="$0.00"
                      currencyMask
                      [options]="{ allowNegative: false }"
                      autocomplete="off"
                      disabled="true"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('titleFee1')"
                    [label]="'Title Fee'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('titleFee1')"
                    [controlName]="'Title Fee'"
                    [apiErrorType]="'titleFee1'"
                  >
                    <input
                      type="text"
                      name="titleFee1"
                      class="form-control"
                      formControlName="titleFee1"
                      placeholder="$0.00"
                      currencyMask
                      [options]="{ allowNegative: false }"
                      autocomplete="off"
                      disabled="true"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('salesTax1')"
                    [label]="'Sales Tax'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('salesTax1')"
                    [controlName]="'Sales Tax'"
                    [apiErrorType]="'salesTax'"
                  >
                    <input
                      type="text"
                      name="salesTax1"
                      class="form-control"
                      formControlName="salesTax1"
                      placeholder="$0.00"
                      currencyMask
                      [options]="{ allowNegative: false }"
                      autocomplete="off"
                      disabled="true"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('license1')"
                    [label]="'License'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('license1')"
                    [controlName]="'License'"
                    [apiErrorType]="'license1'"
                  >
                    <input
                      type="text"
                      name="license1"
                      class="form-control"
                      formControlName="license1"
                      placeholder="$0.00"
                      currencyMask
                      [options]="{ allowNegative: false }"
                      autocomplete="off"
                      disabled="true"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('conditionAdjustmentA1')"
                    [label]="'Condition Adjustment 1'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('conditionAdjustmentA1')"
                    [controlName]="'Condition Adjustment 1'"
                    [apiErrorType]="'conditionAdjustmentA1'"
                  >
                    <input
                      type="text"
                      name="conditionAdjustmentA1"
                      class="form-control"
                      formControlName="conditionAdjustmentA1"
                      placeholder="$0.00"
                      currencyMask
                      autocomplete="off"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('conditionAdjustmentB1')"
                    [label]="'Condition Adjustment 2'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('conditionAdjustmentB1')"
                    [controlName]="'Condition Adjustment 2'"
                    [apiErrorType]="'conditionAdjustmentB1'"
                  >
                    <input
                      type="text"
                      name="conditionAdjustmentB1"
                      class="form-control"
                      formControlName="conditionAdjustmentB1"
                      placeholder="$0.00"
                      currencyMask
                      autocomplete="off"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('conditionAdjustmentC1')"
                    [label]="'Condition Adjustment 3'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('conditionAdjustmentC1')"
                    [controlName]="'Condition Adjustment 3'"
                    [apiErrorType]="'conditionAdjustmentC1'"
                  >
                    <input
                      type="text"
                      name="conditionAdjustmentC1"
                      class="form-control"
                      formControlName="conditionAdjustmentC1"
                      placeholder="$0.00"
                      currencyMask
                      autocomplete="off"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('salvageIncludeValue')"
                    [label]="'Salvage Value (20% of original value) - Included - '"
                  >
                  </app-required-indication>{{debitForm.get('includeSalvage').value}}
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('salvageIncludeValue')"
                    [controlName]="'Salvage Value (20% of original value) - Included'"
                    [apiErrorType]="'salvageIncludeValue'"
                  >
                    <input
                      type="text"
                      name="salvageIncludeValue"
                      class="form-control"
                      formControlName="salvageIncludeValue"
                      placeholder="$0.00"
                      currencyMask
                      autocomplete="off"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3 cal-imp">
                  <app-required-indication
                    [control]="debitForm.get('totalDeduction')"
                    [label]="'Total Deductions (B)'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('totalDeduction')"
                    [controlName]="'Total Deductions (B)'"
                    [apiErrorType]="'totalDeduction'"
                  >
                    <input
                      type="text"
                      name="totalDeduction"
                      class="form-control"
                      formControlName="totalDeduction"
                      placeholder="$0.00"
                      currencyMask
                      [options]="{ allowNegative: true }"
                      autocomplete="off"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3 cal-imp">
                  <app-required-indication
                    [control]="debitForm.get('settlementBeforeSalvage')"
                    [label]="'Settlement Amount (C = A-B)'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('settlementBeforeSalvage')"
                    [controlName]="'Settlement Amount (C = A-B)'"
                    [apiErrorType]="'settlementBeforeSalvage'"
                  >
                    <input
                      type="text"
                      name="settlementBeforeSalvage"
                      class="form-control"
                      formControlName="settlementBeforeSalvage"
                      placeholder="$0.00"
                      currencyMask
                      [options]="{ allowNegative: true }"
                      autocomplete="off"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('settlementBeforeSalvage1')"
                    [label]="'Settlement Before Salvage (A-B)'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('settlementBeforeSalvage1')"
                    [controlName]="'Settlement Before Salvage (A-B)'"
                    [apiErrorType]="'settlementBeforeSalvage1'"
                  >
                    <input
                      type="text"
                      name="settlementBeforeSalvage1"
                      class="form-control"
                      formControlName="settlementBeforeSalvage1"
                      placeholder="$0.00"
                      currencyMask
                      [options]="{ allowNegative: true }"
                      autocomplete="off"
                      disabled="true"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3">
                  <app-required-indication
                    [control]="debitForm.get('maximunBenefitLimit1')"
                    [label]="'Maximum Benefit Limit'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('maximunBenefitLimit1')"
                    [controlName]="'Maximum Benefit Limit'"
                    [apiErrorType]="'maximunBenefitLimit1'"
                  >
                    <input
                      type="text"
                      name="maximunBenefitLimit1"
                      class="form-control"
                      formControlName="maximunBenefitLimit1"
                      placeholder="$0.00"
                      currencyMask
                      [options]="{ allowNegative: true }"
                      autocomplete="off"
                      disabled="true"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3 cal-imp">
                  <app-required-indication
                    [control]="debitForm.get('lesserPayofforAmortized')"
                    [label]="'Lessor of Settlement Amount and Maximum Benefit Limit (D)'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('lesserPayofforAmortized')"
                    [controlName]="
                      'Lessor of Settlement Amount and Maximum Benefit Limit (D)'
                    "
                    [apiErrorType]="'lesserPayofforAmortized'"
                  >
                    <input
                      type="text"
                      name="lesserPayofforAmortized"
                      class="form-control"
                      formControlName="lesserPayofforAmortized"
                      placeholder="$0.00"
                      currencyMask
                      [options]="{ allowNegative: true }"
                      autocomplete="off"
                      disabled="true"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group col-12 d-xl-flex align-items-center p-0">
                <label class="col-12 col-xl-3 cal-imp">
                  <app-required-indication
                    [control]="debitForm.get('gapsettlement')"
                    [label]="'Final Debt Cancellation Settlement (D)'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-9">
                  <app-form-error-wrapper
                    [control]="debitForm.get('gapsettlement')"
                    [controlName]="'Final Debt Cancellation Settlement (D)'"
                    [apiErrorType]="'gapsettlement'"
                  >
                    <input
                      type="text"
                      name="gapsettlement"
                      class="form-control"
                      formControlName="gapsettlement"
                      placeholder="$0.00"
                      currencyMask
                      [options]="{ allowNegative: true }"
                      autocomplete="off"
                      disabled="true"
                    />
                  </app-form-error-wrapper>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-12 text-right">
              <button type="button" (click)="cancelClick()" class="btn btn-secondary mr-2">
                Cancel
              </button>
              <button type="button" (click)="backClick()" class="btn btn-primary mr-2">
                Back
              </button>
              <button *ngIf="isEditMode" class="btn btn-info primary-background" [disabled]="debitForm.invalid || isLoading">
                <app-button-loader [isLoading]="isLoading" [label]="'Update Claim'" (click)="checkValidfields()"></app-button-loader>
              </button>
              <button *ngIf="isEditMode===false" class="btn btn-info primary-background" [disabled]="debitForm.invalid || isLoading">
                <app-button-loader [isLoading]="isLoading" [label]="'Create Claim'"></app-button-loader>
              </button>
              <!-- <button class="btn btn-primary" routerLink="/agents">Create Agent</button> -->
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="help-container">
      <div class="help-section">
        <p class="help-text" (click)="openHelpPopup()">
          <i class="hellp-popup-icons"></i>
        </p>
      </div>
    </div>
  </div>
</div>