<div class="row agent-container">
  <div class="col-12">
    <div class="card">
      <form [formGroup]="reimburseForm" (ngSubmit)="submitData()" class="form-horizontal form-material"
        id="reimburseForm" novalidate>
        <div class="card-header">
          Reimbursement Claim
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12 p-xl-0">
             
              <div class="row" *ngIf="loggedInUserType == 'customer'">
                <div class="col-12">
                  <h3 class="title-case">Find My contract</h3>
                  <div class="form-group row ml-0 mr-0">
                    <div class="form-group col-12 col-xl-6 d-xl-flex">
                      <div class="form-group col-12 col-xl-6 d-xl-flex mb-xl-0">
                            <select class="custom-select form-control mr-2 mb-2 mb-xl-0" name="filter1select"
                              formControlName="filter1select">
                              <option value="">Select Field</option>
                              <option value="firstname">First Name</option>
                              <option value="lastname">Last Name</option>
                              <option value="phone" [disabled]="reimburseForm.get('filter2select').value == 'phone' ? true : false">Phone</option>
                              <option value="vin" [disabled]="reimburseForm.get('filter2select').value == 'vin' ? true : false">VIN(Last 8 Digits)</option> 
                            </select>           
                            <input type="text" name="filter1" class="form-control" placeholder="value" formControlName="filter1" />   
                            <!-- 1111111111{{filter2_name}}--asdfasdfasd-         -->
                      </div>
                      <div class="form-group col-12 col-xl-6 d-xl-flex mb-xl-0">
                            <select class="custom-select form-control mr-2 mb-2 mb-xl-0" name="filter2select"
                              formControlName="filter2select">
                              <option value="" >Select Field</option>
                              <option value="contract">Contract No</option>
                              <option value="vin" [disabled]="reimburseForm.get('filter1select').value == 'vin' ? true : false">VIN(Last 8 Digits)</option>
                              <option value="phone" [disabled]="reimburseForm.get('filter1select').value == 'phone' ? true : false">Phone</option>
                            </select>                   
                            <input type="text" name="filter2" class="form-control" placeholder="value" formControlName="filter2" />   
                      </div>
                    </div>
                    <div class="form-group col-12 col-xl-6 d-xl-flex text-center">
                        <button
                          type="button"
                          (click)="getMyContract()"
                          class="btn btn-info primary-background mr-2 mb-0"
                        >
                        <app-button-loader [isLoading]="getcontract_loading"></app-button-loader>
                        Search & Fill
                        </button>                      
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-2" *ngIf="show_claims">
                <div class="col-12">
                  <span class="pl-prefill pl-5">Please select contracts below to prefill</span>
                  <div class="row">
                    <div class="col-12 d-xl-flex pl-5 pr-5">
                      <div class="table table-responsive smart-table">
                        <table class="w-100">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Contract No</th>
                              <th>VIN</th>
                              <!--<th>Name</th>
                              <th>Phone No</th>
                              <th>Dealer</th> -->
                              <th>Product</th>
                              <th>Year</th>
                              <th>Make</th>
                              <th>Model</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              *ngFor="
                                let claim of claims_data; let i=index"
                            >
                              <td><input type="radio" value="{{i}}" (click)="claim_select($event)" /></td>
                              <td>{{ claim.ContractNumber }}</td>
                              <td>xxxxxxxxx{{ claim.VIN.substring(9,17) }}</td>
                              <!--<td>{{ claim.FirstName ? claim.FirstName : '' + ' ' + claim.LastName ? claim.LastName : '' }}</td>
                              <td>{{ claim.PhysicalPhone | phone }}</td>
                              <td>{{ claim.DealerName }}</td> -->
                              <td>{{ claim.Product }}</td>
                              <td>{{ claim.VehicleYear }}</td>
                              <td>{{ claim.VehicleMake }}</td>
                              <td>{{ claim.VehicleModel }}</td>
                            </tr>
                          </tbody>
                        </table>              
                      </div>
                    </div>
                  </div>
                </div>
              </div> 
              
              <div class="row">
                <div class="col-xl-4 col-12">
                  <h3 class="title-case">Contract information</h3>
                  <div class="form-group row ml-0 mr-0">
                    <div class="form-group col-12 d-xl-flex">
                      <label class="col-12 col-xl-4"><app-required-indication [control]="reimburseForm.get('contractNumber')"
                          [label]="'Contract Number'"></app-required-indication>
                          <!-- <i class="fa fa-question-circle"
                          title="It helps you to pre-fill the basic contract details"
                        ></i> -->
                      </label>
                      <div class="col-12" 
                      [ngClass]="{
                        'col-xl-8': loggedInUserType == 'customer',
                        'col-xl-6': loggedInUserType != 'customer'
                      }">
                        <app-form-error-wrapper [control]="reimburseForm.get('contractNumber')"
                          [controlName]="'Contract Number'" [apiErrorType]="'contractNumber'">
                          <input type="text" name="contractNumber" class="form-control" formControlName="contractNumber" />
                        </app-form-error-wrapper>
                      </div>
                      <div class="col-12 col-xl-2" *ngIf="loggedInUserType != 'customer'">
                        <button
                          type="button"
                          (click)="getContract()"
                          class="btn btn-info primary-background mr-2"
                        >
                          Fill
                        </button>
                      </div>
                    </div>
                    <div class="form-group col-12 d-xl-flex">
                      <label class="col-12 col-xl-4 align-items-center">
                        <app-required-indication [control]="reimburseForm.get('vinNumber')"
                        [label]="'VIN Number'"></app-required-indication>
                      </label>
                      <div class="col-12 col-xl-8">
                        <app-form-error-wrapper [control]="reimburseForm.get('vinNumber')" [controlName]="'VIN Number'"
                          [apiErrorType]="'vinNumber'">
                          <input type="text" name="vin-number" class="form-control" mask="AAAAAAAAAAAAAAAAA"
                            [formControl]="reimburseForm.get('vinNumber')" />
                        </app-form-error-wrapper>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row ml-0 mr-0">
                      <div class="form-group col-12 d-xl-flex">
                        <label class="col-12 col-xl-4">
                          <app-required-indication [control]="reimburseForm.get('claimDate')"
                            [label]="'Claim Date'">
                          </app-required-indication>
                        </label>
                        <div class="col-12 col-xl-8 pos-rel">
                          <app-form-error-wrapper [control]="reimburseForm.get('claimDate')"
                            [controlName]="'Date Leased Vehicle Returned'" [apiErrorType]="'claimDate'">
                            <input type="text" name="dateLoss" formControlName="claimDate" placeholder="MM/DD/YYYY"
                              ngbDatepicker #d="ngbDatepicker" class="form-control" (click)="d.toggle()" [readonly]="true"
                              [minDate]="{ year: 1900, month: 1, day: 1 }" [maxDate]="{
                                year: utilService.getCurrentYear,
                                month: utilService.getCurrentMonth,
                                day: utilService.getCurrentDay
                              }" />
                          </app-form-error-wrapper>
                          <a class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer pos-abs datecancel-r15"
                            (click)="utilService.removeDate(reimburseForm, 'claimDate')" placement="left"
                            ngbTooltip="Remove" *ngIf="reimburseForm.controls['claimDate'].value">
                            <i class="ti-close text-danger m-r-10"></i>
                          </a>
                        </div>
                      </div>
                      <div class="form-group col-12 d-xl-flex align-items-center">
                        <label class="col-12 col-xl-4">
                          Claim Number<sup style="color: #ff0000">*</sup>
                        </label>
                        <div class="col-12 col-xl-8">
                          <app-form-error-wrapper [control]="reimburseForm.get('claimNumber')" [controlName]="'claimNumber'"
                            [apiErrorType]="'claimNumber'">
                            <input type="text" name="claimNumber" class="form-control" formControlName="claimNumber"  [required]="isCustomer"/>
                          </app-form-error-wrapper>
                        </div>           
                      </div>
                      <div class="form-group col-12 d-xl-flex align-items-center">
                        <label class="col-12 col-xl-4">
                          Description<sup style="color: #ff0000">*</sup>
                        </label>
                        <div class="col-12 col-xl-8">
                          <app-form-error-wrapper [control]="reimburseForm.get('description')" [controlName]="'description'"
                            [apiErrorType]="'description'">
                            <select class="custom-select form-control mr-2 mb-2 mb-xl-0" name="description"
                              formControlName="description">
                              <option value="" disabled>Select</option>
                              <option value="Mechanical">Mechanical</option>
                              <option value="Rental">Rental</option>
                              <option value="Wheel and Tire">Wheel and Tire</option>
                              <option value="EV charger">EV charger</option>
                              <option value="Maintenance">Maintenance</option>
                              <option value="Windshield">Windshield</option>
                              <option value="Paintless Dent Repair">Paintless Dent Repair</option>
                              <option value="Other">Other</option>
                            </select>
                          </app-form-error-wrapper>
                        </div>           
                      </div>
                      <div class="form-group col-12 d-xl-flex">
                        <label class="col-12 col-xl-4">
                          <app-required-indication [control]="reimburseForm.get('dealer')" [label]="'Dealer'">
                          </app-required-indication>
                        </label>
                        <div class="col-12 col-xl-8">
                          <app-form-error-wrapper [control]="reimburseForm.get('dealer')" [controlName]="'Dealer'"
                          [apiErrorType]="'dealer'">
                          <ng-template #rt let-r="result" let-t="term">
                            {{ r.name }}
                          </ng-template>
                          <input class="form-control" type="text" [ngbTypeahead]="dealers" [inputFormatter]="formatter"
                            formControlName="dealer" [resultTemplate]="rt" autocomplete="off"
                            placeholder="Search and select Dealer" name="dealer" />
                        </app-form-error-wrapper>
                        </div>
                      </div>
                  </div>
                </div>
                <div class="col-xl-4 col-12">
                  <h3 class="title-case">Customer information </h3>
                  <div class="col-12 p-0">
                    <div class="form-group">
                      <div class="form-group col-12 d-xl-flex align-items-center">
                        <label class="col-12 col-xl-4">
                          <app-required-indication [control]="reimburseForm.get('customerName')"
                            [label]="'Customer Name'">
                          </app-required-indication>
                        </label>
                        <div class="col-12 col-xl-8 p-ls-xl-0">
                          <app-form-error-wrapper [control]="reimburseForm.get('customerName')"
                            [controlName]="'Customer Name'" [apiErrorType]="'customerName'">
                            <input type="text" name="customerName" class="form-control" formControlName="customerName" />
                          </app-form-error-wrapper>
                        </div>
                      </div>
                      <div class="form-group col-12 d-xl-flex align-items-center">
                        <label class="col-12 col-xl-4">
                          <app-required-indication [control]="reimburseForm.get('phoneNumber')" [label]="'Phone Number'">
                          </app-required-indication>
                        </label>
                        <div class="col-12 col-xl-8 p-ls-xl-0">
                          <app-form-error-wrapper
                          [control]="reimburseForm.get('phoneNumber')"
                          [controlName]="'Phone Number'"
                          [apiErrorType]="'phoneNumber'"
                        >
                          <input
                            type="tel"
                            name="phonenumber"
                            mask="000-000-0000"
                            class="form-control"
                            [formControl]="reimburseForm.controls['phoneNumber']"
                          />
                          </app-form-error-wrapper>
                          <span *ngIf="contractPhone">As per our record: XXX-XXX-{{contractPhone.slice(-4)}}</span>
                        </div>
                      </div>
                      <div class="form-group col-12 d-xl-flex align-items-center">
                        <label class="col-12 col-xl-4">
                          <app-required-indication [control]="reimburseForm.get('email')" [label]="'Email'">
                          </app-required-indication>
                        </label>
                        <div class="col-12 col-xl-8">
                          <app-form-error-wrapper [control]="reimburseForm.get('email')" [controlName]="'Email'"
                            [apiErrorType]="'email'">
                            <input type="email" name="email" class="form-control" formControlName="email" />
                          </app-form-error-wrapper>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="form-group col-12 d-xl-flex align-items-center">
                        <label class="col-12 col-xl-4">
                          Address<sup style="color: #ff0000">*</sup>
                        </label>
                        <div class="col-12 col-xl-8">
                          <app-form-error-wrapper [control]="reimburseForm.get('streetAddress')"
                            [controlName]="'Street Address'" [apiErrorType]="'streetAddress'">
                            <input type="text" name="street-address" class="form-control" formControlName="streetAddress"  required/>
                          </app-form-error-wrapper>
                        </div>
                      </div>
                      <div class="form-group col-12 d-xl-flex align-items-center">
                        <label class="col-12 col-xl-4">
                          City<sup style="color: #ff0000">*</sup>
                        </label>
                        <div class="col-12 col-xl-8">
                          <app-form-error-wrapper [control]="reimburseForm.get('city')" [controlName]="'City'"
                            [apiErrorType]="'city'">
                            <input type="text" name="city" class="form-control" formControlName="city"  [required]="isCustomer"/>
                          </app-form-error-wrapper>
                        </div>           
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="form-group col-12 d-xl-flex">
                        <label class="col-12 col-xl-4">State<sup style="color: #ff0000">*</sup>
                        </label>
                        <div class="col-12 col-xl-8">
                          <app-form-error-wrapper [control]="reimburseForm.get('state')" [controlName]="'State'"
                            [apiErrorType]="'state'">
                            <select class="custom-select form-control" name="state" formControlName="state" [required]="isCustomer">
                              <option value="">Select State</option>
                              <option *ngFor="let stat of states" value="{{ stat.code }}">{{ stat.name }}</option>
                            </select>
                          </app-form-error-wrapper>
                        </div>
                      </div>
                      <div class="form-group col-12 d-xl-flex align-items-center">
                        <label class="col-12 col-xl-4">
                          Zip Code<sup style="color: #ff0000">*</sup>
                        </label>
                        <div class="col-12 col-xl-8">
                          <app-form-error-wrapper [control]="reimburseForm.get('zipCode')" [controlName]="'Zip Code'"
                            [apiErrorType]="'zipCode'">
                            <input type="tel" class="form-control" [formControl]="reimburseForm.controls['zipCode']"  required
                              mask="00000" />
                          </app-form-error-wrapper>
                        </div>
                      </div>
                    </div>
                  </div>                  
                </div>
                <div class="col-xl-4 col-12">
                  <h3 class="title-case">Payment Information</h3>
                  <div>
                    <div class="form-group">
                      <div class="form-group col-12 d-xl-flex">
                        <label class="col-12 col-xl-4">
                          <app-required-indication [control]="reimburseForm.get('paymentType')"
                            [label]="'Payment Type'">
                          </app-required-indication>
                        </label>
                        <div class="col-12 col-xl-8">
                          <app-form-error-wrapper [control]="reimburseForm.get('paymentType')"
                            [controlName]="'Payment Type'" [apiErrorType]="'paymentType'">
                            <select class="custom-select form-control" name="paymentType" required disabled readonly
                              formControlName="paymentType">
                              <option value="Check">Check</option>
                              <!-- <option value="ACH">ACH</option> -->
                            </select>
                          </app-form-error-wrapper>
                        </div>
                      </div>
                      <!-- <div class="form-group col-12 d-xl-flex align-items-center ">
                        <label class="col-12 col-xl-4">
                          Account Number<sup *ngIf="reimburseForm.controls['paymentType'].value == 'ACH'" style="color: #ff0000">*</sup>
                        </label>
                        <div class="col-12 col-xl-8">
                          <app-form-error-wrapper [control]="reimburseForm.get('accountNumber')"
                            [controlName]="'Account'" [apiErrorType]="'accountNumber'">
                            <input type="text" name="accountNumber" class="form-control"
                              formControlName="accountNumber" minlength="1" maxlength="17" pattern="\d*" (keypress)="omit_all_char($event)" 
                              [required]="reimburseForm.controls['paymentType'].value == 'ACH'?true:false"  />
                          </app-form-error-wrapper>
                        </div>
                      </div>
                      <div class="form-group col-12 d-xl-flex align-items-center ">
                        <label class="col-12 col-xl-4">
                          Routing Number<sup *ngIf="reimburseForm.controls['paymentType'].value == 'ACH'" style="color: #ff0000">*</sup>
                        </label>
                        <div class="col-12 col-xl-8">
                          <app-form-error-wrapper
                          [control]="reimburseForm.get('routingNumber')"
                          [controlName]="'routingNumber'"
                          [apiErrorType]="'routingNumber'"
                        >
                          <input
                            type="text"
                            name="routingNumber"
                            class="form-control"
                            [formControl]="reimburseForm.controls['routingNumber']"
                            minlength="10" maxlength="10"
                            (keypress)="omit_all_char($event)" 
                            [required]="reimburseForm.controls['paymentType'].value == 'ACH'?true:false" 
                          />
                          </app-form-error-wrapper>
                        </div>
                      </div> -->
                      <div class="form-group col-12 d-xl-flex align-items-center">
                        <label class="col-12 col-xl-4">
                          <app-required-indication [control]="reimburseForm.get('amount')"
                            [label]="'Reimbursement Amount'">
                          </app-required-indication>
                        </label>
                        <div class="col-12 col-xl-8">
                          <app-form-error-wrapper [control]="reimburseForm.get('amount')"
                            [controlName]="'Deductible amount'" [apiErrorType]="'amount'">
                            <input type="text" name="amount" class="form-control" currencyMask
                              autocomplete="off" [formControl]="reimburseForm.controls['amount']" required />
                          </app-form-error-wrapper>
                        </div>
                      </div>                
                    </div>
                  </div>                  
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-12">
                  <h3 class="title-case">Vehicle Information</h3>
                    <div class="form-group">
                      <div class="form-group col-12 d-xl-flex">
                        <label class="col-12 col-xl-4">
                          <app-required-indication [control]="reimburseForm.get('currentMileage')"
                            [label]="'Current Mileage'">
                          </app-required-indication>
                        </label>
                        <div class="col-12 col-xl-8 p-ls-xl-0">
                          <app-form-error-wrapper
                          [control]="reimburseForm.get('currentMileage')"
                          [controlName]="'Current mileage'"
                          [apiErrorType]="'currentMileage'"
                        >
                          <input
                            type="text"
                            name="currentMileage"
                            class="form-control"
                            mask="000000"
                            [formControl]="reimburseForm.get('currentMileage')"
                          />
                        </app-form-error-wrapper>
                        </div>
                      </div>
                      <div class="form-group col-12 d-xl-flex">
                        <label class="col-12 col-xl-4">
                          <app-required-indication [control]="reimburseForm.get('year')" [label]="'Year'">
                          </app-required-indication>
                        </label>
                        <div class="col-12 col-xl-8 p-ls-xl-0">
                          <app-form-error-wrapper [control]="reimburseForm.get('year')" [controlName]="'Year'"
                            [apiErrorType]="'year'">
                            <input type="text" name="year" mask="0000" class="form-control"
                              [formControl]="reimburseForm.get('year')" />
                          </app-form-error-wrapper>
                        </div>
                      </div>
                      <div class="form-group col-12 d-xl-flex">
                        <label class="col-12 col-xl-4">
                          <app-required-indication [control]="reimburseForm.get('make')" [label]="'Make'">
                          </app-required-indication>
                        </label>
                        <div class="col-12 col-xl-8">
                          <app-form-error-wrapper [control]="reimburseForm.get('make')" [controlName]="'Make'"
                            [apiErrorType]="'make'">
                            <input type="text" name="make" class="form-control"
                              [formControl]="reimburseForm.get('make')" />
                          </app-form-error-wrapper>
                        </div>
                      </div>
                      <div class="form-group col-12 d-xl-flex">
                        <label class="col-12 col-xl-4">
                          <app-required-indication [control]="reimburseForm.get('model')" [label]="'Model'">
                          </app-required-indication>
                        </label>
                        <div class="col-12 col-xl-8">
                          <app-form-error-wrapper [control]="reimburseForm.get('model')" [controlName]="'Model'"
                            [apiErrorType]="'model'">
                            <input type="text" name="model" class="form-control" formControlName="model" />
                          </app-form-error-wrapper>
                        </div>
                      </div>
                    </div>
                </div>
                <div class="col-xl-8 col-12">
                  <h3 class="title-case">Customer Documents Needed</h3>
                    <div class="form-group d-xl-flex align-items-center">
                      <label class="col-12 col-xl-6">
                        <app-required-indication
                          [control]="reimburseForm.get('RepairOrderInvoice')"
                          [label]="
                            'Repair order invoice (with detailed parts and labor breakdown)'"
                        >
                        </app-required-indication>
                      </label>
                      <div class="col-12 col-xl-6">
                        <div *ngIf="uploadedImg.RepairOrderInvoice">
                          <label class="col-12 col-xl-9 float-left">{{
                            uploadedImg.RepairOrderInvoice.replace('uploads/', '').replace('reimburse/','')
                          }}</label>
                          <div class="col-12 col-xl-3 float-left">
                            <a
                              class="ng2-smart-action ng2-smart-action-edit-edit ml-2"
                              placement="left"
                              ngbTooltip="View Document"
                              href="{{ baseUrl }}{{
                                uploadedImg.RepairOrderInvoice
                              }}"
                              target="_blank"
                            >
                              <i class="ti-eye text-info m-r-10"></i>
                            </a>
                            <a
                              class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer ml-2"
                              (click)="removeImage('RepairOrderInvoice')"
                              placement="left"
                              ngbTooltip="Change Document"
                            >
                              <i class="ti-trash text-danger m-r-10"></i>
                            </a>
                          </div>
                        </div>
                        <div *ngIf="!uploadedImg.RepairOrderInvoice">
                          <input
                            type="file"
                            name="deductiblesSection"
                            (change)="onSelectFile($event, 'RepairOrderInvoice')"
                            class="file-upload-width"
                          />
                          <a
                            *ngIf="reimburseForm.get('RepairOrderInvoice').value"
                            class="ng2-smart-action ng2-smart-action-edit-edit ml-2 float-right"
                            placement="left"
                            ngbTooltip="View Document"
                            href="{{ baseUrl }}{{
                              reimburseForm.get('RepairOrderInvoice').value
                            }}"
                            target="_blank"
                          >
                            <i class="ti-eye text-info m-r-10"></i>
                          </a>
                        </div>
                      </div>
                     </div>
                     <div class="form-group d-xl-flex align-items-center">
                      <label class="col-12 col-xl-6">
                        Proof of payment
                        <sup style="color: #ff0000">*</sup>
                      </label>
                      <!-- <label class="col-12 col-xl-6">
                        <app-required-indication
                          [control]="reimburseForm.get('RepairOrderInvoice')"
                          [label]="
                            'Repair order invoice (with detailed parts and labor breakdown)'"
                        >
                        </app-required-indication>
                      </label>                       -->
                      <div class="col-12 col-xl-6">
                        <div *ngIf="uploadedImg.ProfOfPayment">
                          <label class="col-12 col-xl-9 float-left">{{
                            uploadedImg.ProfOfPayment.replace('uploads/', '').replace('reimburse/','')
                          }}</label>
                          <div class="col-12 col-xl-3 float-left">
                            <a
                              class="ng2-smart-action ng2-smart-action-edit-edit ml-2"
                              placement="left"
                              ngbTooltip="View Document"
                              href="{{ baseUrl }}{{
                                uploadedImg.ProfOfPayment
                              }}"
                              target="_blank"
                            >
                              <i class="ti-eye text-info m-r-10"></i>
                            </a>
                            <a
                              class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer ml-2"
                              (click)="removeImage('ProfOfPayment')"
                              placement="left"
                              ngbTooltip="Change Document"
                            >
                              <i class="ti-trash text-danger m-r-10"></i>
                            </a>
                          </div>
                        </div>
                        <div *ngIf="!uploadedImg.ProfOfPayment">
                          <input
                            type="file"
                            name="deductiblesSection"
                            (change)="onSelectFile($event, 'ProfOfPayment')"
                            class="file-upload-width"
                            required 
                          />
                          <a
                            *ngIf="reimburseForm.get('ProfOfPayment').value"
                            class="ng2-smart-action ng2-smart-action-edit-edit ml-2 float-right"
                            placement="left"
                            ngbTooltip="View Document"
                            href="{{ baseUrl }}{{
                              reimburseForm.get('ProfOfPayment').value
                            }}"
                            target="_blank"
                          >
                            <i class="ti-eye text-info m-r-10"></i>
                          </a>
                        </div>
                      </div>
                     </div>
                     <div class="form-group align-items-center">
                      <label class="col-12 col-xl-12">
                        <app-required-indication [control]="reimburseForm.get('reasonForReplace')"
                          [label]="'Reason & description for replacement'">
                        </app-required-indication>
                      </label>
                      <div class="col-12 col-xl-12 mt-1">
                        <app-form-error-wrapper [control]="reimburseForm.get('reasonForReplace')"
                          [controlName]="'Reason & description for replacement'" [apiErrorType]="'reasonForReplace'">
                          <textarea type="text" name="reasonForReplace" rows="5" class="form-control" formControlName="reasonForReplace"></textarea>
                        </app-form-error-wrapper>
                      </div>
                    </div>                 
                </div>
              </div>
              <div>
                <div formArrayName="moreDocs" *ngIf="moreDocs" class="mb-5">
                <div class="form-group d-xl-flex align-items-center">
                  <!-- <label class="col-12">
                    <app-required-indication
                      [label]="
                        'Add More Documents'"
                    >
                    </app-required-indication>
                  </label> -->
                  <h3 class="title-case mr-2">Add More Documents</h3>
                  <button
                      type="button"
                      class="btn btn-primary primary-background"
                      (click)="addDoc()"
                    >
                      <i class="mdi mdi-plus"></i> Add Doc
                    </button>
                </div>
                <div *ngFor="let item of moreDocs.controls; let i = index">
                  <div [formGroupName]="i">
                    <div class="form-group d-xl-flex align-items-center">
                      <div
                        class="form-group m-xl-0 col-12 col-xl-6 d-xl-flex align-items-center p-0"
                      >
                        <label class="col-12 col-xl-4 text-xl-center">
                          <app-required-indication
                            [control]="item.get('url')"
                            [label]="'Document'"
                          ></app-required-indication>
                        </label>
                        <div class="col-12 col-xl-8">
                          <input
                            type="file"
                            *ngIf="!item.get('url').value"
                            (change)="onChangeFile($event, i)"
                            name="url"
                            value="item.get('url').value"
                          />
                          <div *ngIf="item.get('url').value">
                            <label class="m-r-10">{{
                              item.get('url').value &&
                                item.get('url').value.substr(
                                  item.get('url').value.lastIndexOf('/') + 1
                                )
                            }}</label>
                            <a
                              class="ng2-smart-action ng2-smart-action-edit-edit"
                              placement="right"
                              ngbTooltip="View Request"
                              href="{{ baseUrl }}{{
                                item.get('url').value
                              }}"
                              target="_blank"
                              ><i class="ti-eye text-info m-r-10"></i>
                            </a>
                            <a
                              class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer"
                              (click)="changeImage(i)"
                              placement="top"
                              ngbTooltip="Change Image"
                            >
                              <i class="ti-move text-danger m-r-10"></i>
                            </a>
                          </div>
                      </div>
                      </div>
                      <div
                        class="form-group m-xl-0 col-12 col-xl-6 d-xl-flex align-items-center p-0"
                      >
                        <label class="col-12 col-xl-3">
                          <app-required-indication
                            [control]="item.get('name')"
                            [label]="'Document Description'"
                          >
                          </app-required-indication>
                        </label>
                        <div class="d-flex col-12 col-xl-9 p-0">
                          <div class="col-10">
                            <app-form-error-wrapper
                              [control]="item.get('name')"
                              [controlName]="'Title'"
                              [apiErrorType]="'name'"
                            >
                              <input
                                type="text"
                                name="title-{{ i }}"
                                class="form-control"
                                formControlName="name"
                              />
                            </app-form-error-wrapper>
                          </div>
                          <div class="col-2 text-right">
                            <button
                              class="btn btn-danger"
                              (click)="removeDoc(i)"
                            >
                              <i class="mdi mdi-close"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group d-xl-flex align-items-center ">
                  <label class="col-12 col-xl-6"></label>
                  <div class="col-12 col-xl-6 pl-5">
                    <!-- <button
                      type="button"
                      class="btn btn-primary primary-background"
                      (click)="addDoc()"
                    >
                      <i class="mdi mdi-plus"></i> Add Doc
                    </button> -->
                  </div>
                </div>
              </div>
              </div>
              
            </div>
          </div>

        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-12 text-right">
              <button
              type="button"
              (click)="cancelClick()"
              class="btn btn-secondary mr-2"
            >
              Cancel
            </button>
              <button *ngIf="isEditMode" class="btn btn-info primary-background" [disabled]="reimburseForm.invalid || isLoading">
                <app-button-loader [isLoading]="isLoading" [label]="'Update Reimburse'"></app-button-loader>
              </button>
              <button *ngIf="isEditMode===false" class="btn btn-info primary-background" [disabled]="reimburseForm.invalid || isLoading">
                <app-button-loader [isLoading]="isLoading" [label]="'Create Reimburse'"></app-button-loader>
              </button>
              <!-- <button class="btn btn-primary" routerLink="/agents">Create Agent</button> -->
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="help-container">
      <div class="help-section">
        <p class="help-text" (click)="openHelpPopup()">
          <i class="hellp-popup-icons"></i>
        </p>
      </div>
    </div>
  </div>
</div>