import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationComponent } from '@app/shared/modals/confirmation';
import { STATES } from '@app/core/constants';
import { DateTime } from 'luxon';
import { CustomerService } from '@app/core/service/customer.service';
import {
  finalize,
  tap,
  map,
  switchMap,
  distinctUntilChanged,
  debounceTime,
  catchError,
  filter
} from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { UtilService, CONFIRMATION_MSGS, LOCAL_VAR } from '@app/core';
import { of as observableOf, Observable, Subject, merge, of } from 'rxjs';
import { NgbTypeaheadConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { ClaimHelpComponent } from '@app/shared/modals/claim-help/claim-help.component';
import { ReimburseService } from '@app/core/service/reimburse.service';
import { ClaimService } from '@app/core/service/claim.service';
import { environment } from '@env/environment.local';

@Component({
  selector: 'app-reimburse-create',
  templateUrl: './reimburse-create.component.html',
  styleUrls: ['./reimburse-create.component.scss'],
  providers: [NgbTypeaheadConfig]
})
export class ReimburseCreateComponent implements OnInit {
  reimburseForm: FormGroup;
  moreDocs: FormArray;
  isLoading = false;
  queryParams: any;
  states: any = STATES;
  roles: string[];
  dealersList: [{ name: 'DOWC'; _id: '0' }]; //selectedPersons: Person[] = <any>[{ name: 'Karyn Wright' }, { name: 'Other' }];
  user: any;
  loggedInUserType: string;
  loggedinuserdetail: any;
  claimData: any;
  isreivewMode = false;
  isEditMode = false;
  claimId: any = null;
  baseUrl = '';
  uploadedImg: any = {
    RepairOrderInvoice: null,
    ProfOfPayment: null
  };
  selectedCustomer: any = null;
  moreDoc: any = [];
  showmoreDocs: any = [];
  isCustomer = true;
  isRepairFacility = false;
  filter1_name: any;
  filter1_value: any;
  filter2_name: any;
  filter2_value: any;
  claims_data: any;
  show_claims = false;
  getcontract_loading = false;
  enterBy = 'selling_dealer';
  onlyCustomer = false;
  enableForm: boolean = true;
  contractPhone='';

  @ViewChild('instance') instance: any;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  //formatter = (result: string) => result.toUpperCase();
  dealers = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term =>
        term.length < 3
          ? []
          : this.dealersList
            .filter(
              v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1
            )
            .slice(0, 10)
      )
    );
  formatter = (x: { name: string }) => x.name;
  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private customerService: CustomerService,
    private toastrService: ToastrService,
    public utilService: UtilService,
    private claimService: ReimburseService,
    private Claims: ClaimService,
    private authenticationService: AuthenticationService,
  ) {
    this.user = this.authenticationService.credentials.user;
    this.loggedInUserType = this.user.userRoleId.slug;
    this.baseUrl = environment.s3Url;
    this.selectedCustomer = localStorage.getItem(LOCAL_VAR.selectedCustomer);
    this.selectedCustomer = JSON.parse(this.selectedCustomer);
    if (this.user.usertype) {
      this.enterBy = this.user.usertype;
    } else if(this.loggedInUserType=='customer') {
      this.enterBy = 'contract_holder';
    }
    if (this.router.url.indexOf('review') !== -1) {
      this.isreivewMode = true;
    } else {
      this.isreivewMode = false;
    }
    if (this.router.url.indexOf('edit') !== -1) {
      this.isEditMode = true;
    } else {
      this.isEditMode = false;
    }
    this.enableForm = (this.enterBy == 'contract_holder' && this.isEditMode == false)?false:true;

    this.route.queryParams.pipe().subscribe(params => {
      this.queryParams = params;
    });

    if (this.isEditMode && this.route.snapshot.params['claimId']) {
      this.claimId = this.route.snapshot.params['claimId'];
    }
    if (this.claimId) {
      this.getLeaseDealbyId(this.claimId);
    }
    this.createForm();
    this.getDealers();
  }

  ngOnInit() {
    if (this.loggedInUserType === 'dowcview' || this.loggedInUserType === 'adminview') {
      this.router.navigate(['/products'], { replaceUrl: true });
    }
  }

  private createForm() {

    if (this.claimData) {
      this.uploadedImg.RepairOrderInvoice = this.claimData
        .RepairOrderInvoice
        ? this.claimData.RepairOrderInvoice
        : null;
      this.uploadedImg.ProfOfPayment = this.claimData
        .ProfOfPayment
        ? this.claimData.ProfOfPayment
        : null;
    }

    this.reimburseForm = this.formBuilder.group({
      contractNumber: [
        this.claimData ? this.claimData.contractNumber : '',
        [Validators.required]
      ],
      claimDate: [
        this.claimData
          ? this.claimData.claimDate
            ? DateTime.fromISO(this.claimData.claimDate).toObject()
            : null
          : '',
        [Validators.required]
      ],
      claimNumber: [
        this.claimData ? this.claimData.claimNumber : '',
        [Validators.required]
      ],
      customerName: [
        this.claimData ? this.claimData.customerName : '',
        [Validators.required]
      ],
      email: [
        this.claimData ? this.claimData.email : this.user.email,
        [Validators.required, Validators.pattern(/^\d{10}|\w+([\.+-]?\w+)*@\w+([\.-]?\w+)$/)]
      ],
      streetAddress: [
        this.claimData ? this.claimData.streetAddress : ''
      ],
      city: [this.claimData ? this.claimData.city : ''],
      state: [this.claimData ? this.claimData.state : ''],
      zipCode: [
        this.claimData ? this.claimData.zipCode : '',
        Validators.compose([
          Validators.minLength(5),
          Validators.maxLength(5)
        ])
      ],
      phoneNumber: [
        this.claimData ? this.claimData.phoneNumber : '',
        Validators.compose([
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(12)
        ])
      ],
      dealer: [
        this.claimData
          ? this.claimData.dealer == null
            ? ''
            : {
              _id: this.claimData.dealer._id,
              name: this.claimData.dealer.name
            }
          : '',
        /* this.claimData ? this.claimData.dealer : '', */
        [Validators.required]
      ],
      description: [
        this.claimData && this.claimData.description
          ? this.claimData.description
          : ''
      ],
      paymentType: [
        this.claimData && this.claimData.paymentType
          ? this.claimData.paymentType
          : 'Check'
      ],
      accountNumber: [
        this.claimData && this.claimData.accountNumber
          ? this.claimData.accountNumber
          : null
      ],
      routingNumber: [
        this.claimData && this.claimData.routingNumber
          ? this.claimData.routingNumber
          : null
      ],
      amount: [
        this.claimData && this.claimData.amount
          ? this.claimData.amount
          : null
      ],
      year: [
        this.claimData && this.claimData.year ? this.claimData.year : null,
        Validators.compose([
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(4)
        ])
      ],
      make: [
        this.claimData && this.claimData.make ? this.claimData.make : null,
        Validators.required
      ],
      model: [
        this.claimData && this.claimData.model ? this.claimData.model : null,
        Validators.required
      ],
      currentMileage: [
        this.claimData && this.claimData.currentMileage
          ? this.claimData.currentMileage
          : null,
        Validators.required
      ],
      vinNumber: [
        this.claimData && this.claimData.vinNumber
          ? this.claimData.vinNumber
          : null,
        Validators.compose([Validators.required, Validators.minLength(17)])
      ],
      RepairOrderInvoice: [
        this.claimData ? this.claimData.RepairOrderInvoice : '',
        [Validators.required]
      ],
      ProfOfPayment: [
        this.claimData ? this.claimData.ProfOfPayment : ''
      ],
      reasonForReplace: [
        this.claimData ? this.claimData.reasonForReplace : '',
        [Validators.required]
      ],
      moreDocs: this.formBuilder.array([]),
      filter1select: [''],
      filter1: [],
      filter2select: [''],
      filter2: []
    });
    //this.formControlsValueChanges();
    this.moreDocs = this.reimburseForm.get('moreDocs') as FormArray;
    if (
      this.isEditMode &&
      this.claimData &&
      this.claimData.moreDocs &&
      this.claimData.moreDocs.length > 0
    ) {
      this.claimData.moreDocs.forEach((addition: any, index: number) => {
        this.moreDocs.push(this.createmoreDocsForm(addition));
        this.moreDoc.push(addition.url);
        this.showmoreDocs.push(addition.url ? true : false);
      });
    } else {
      // this.moreDocs.push(this.createmoreDocsForm());
    }

    // this.reimburseForm.get('dealer').valueChanges.subscribe(values => {
    //   this.checkDealer();
    // });
  }

  ngAfterViewInit() {
    $("app-required-indication").each(function (index, element) {
      var text = element.innerHTML;
      element.innerHTML = text.replace('\n<!--', '<!--');
    });

    var domainconfig = JSON.parse(localStorage.getItem(LOCAL_VAR.domainconfig));
    if (domainconfig) {
      if (domainconfig.secondaryColor) {
        this.utilService.changeCssAttribute('card-header', 'background-color', domainconfig.secondaryColor);
      }
    }
  }

  formControlsValueChanges() {
    // this.reimburseForm.get('isVehicleReturn').valueChanges.subscribe(value => {
    //   if (this.reimburseForm.get('isVehicleReturn').value === false) {
    //     this.toastrService.warning("You must return your vehicle to your selling dealer.  If you have any questions, please call the number listed on your contract.");
    //   }
    // });
    // this.reimburseForm.get('isVehicleInspected').valueChanges.subscribe(value => {
    //   if (this.reimburseForm.get('isVehicleInspected').value === false) {
    //     this.toastrService.warning("Your vehicle must be inspected prior to turning it in.  If you have any questions, please call the number listed on your contract.");
    //   }
    // });
  }

  submitData() {
    // this.onSubmitStepChange.emit();
    // if (this.selectedCustomer) {
    this.isLoading = true;
    let payload = this.reimburseForm.value;
    payload = this.utilService.removeEmpty(payload);
    payload.userId = this.selectedCustomer
      ? this.selectedCustomer._id
      : this.user._id
        ? this.user._id
        : null;
    if (payload.claimDate) {
      payload.claimDate = DateTime.fromObject(
        payload.claimDate
      ).toISO();

    }
    if (payload.leaseExpireDate) {
      payload.leaseExpireDate = DateTime.fromObject(
        payload.leaseExpireDate
      ).toISO();
    }
    let docs = this.moreDocs.controls.filter((a: any) => a.value);
    if (docs && docs.length > 0) {
      payload.moreDocs.map((docs: any, index: number) => {
        // docs.url = this.moreDoc[index]
        //   ? this.moreDoc[index]
        //   : null;
        this.showmoreDocs[index] = true;
      });
    }
    if (this.claimData) {
      this.claimService
        .update(this.claimId, payload)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe((response: any) => {
          this.toastrService.info('Thank you for submitting your Reimbursement.  One of our Claims Specialists will contact you shortly.');
          this.router.navigate(['/reimburse-list']);
        });
    } else {
      this.claimService
        .add(payload)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe((response: any) => {
          this.toastrService.info('Thank you for submitting your Reimbursement.  One of our Claims Specialists will contact you shortly.');
          this.router.navigate(['/reimburse-list']);
        });
    }
  }

  getDealers() {
    this.customerService
      .getDealers()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.records) {
          this.dealersList = response.records;
        }
      });
  }

  getLeaseDealbyId(DealId: string) {
    this.claimService
      .getById(DealId)
      .subscribe((response: any) => {
        if (response.records) {
          this.claimData = response.records;
          if (this.claimData.status == 'voided') {
            localStorage.setItem(LOCAL_VAR.selectedProduct, 'reimburse');
            this.router.navigate(['/reimburse-list']);
          }
          if (this.claimData.enterBy) {
            this.enterBy = this.claimData.enterBy;
          }
          this.createForm();
        } else if (this.isEditMode) {
          localStorage.setItem(LOCAL_VAR.selectedProduct, 'reimburse');
          this.router.navigate(['/reimburse-list']);
        }
      });
  }

  onSelectFile(event: any, type: any) {
    this.isLoading = true;
    if (event.target.files && event.target.files.length) {
      const formData: FormData = new FormData();
      formData.append('file', event.target.files[0]);
      if (this.claimData)
        formData.append('claimId', this.claimData._id);
      formData.append('fieldName', type);
      formData.append('prod', 'reimburse');
      this.authenticationService.uploadPhoto(formData).subscribe(
        (res: any) => {
          this.isLoading = false;
          if (res.path) {
            this.reimburseForm.controls[type].setValue(res.path);
            this.reimburseForm.controls[type].markAsDirty();
            //this.reimburseForm.controls[type].setValue(null);
            //TODO - change ui
            this.uploadedImg[type] = res.path;
          } else {
            this.toastrService.error('Invalid file');
          }
        },
        (error: any) => {
          this.isLoading = false;
          console.log(error);
          this.toastrService.warning('Invalid file');
        }
      );
    }
  }

  async removeImage(type: any) {
    const modalRef = this.modalService.open(ConfirmationComponent);

    modalRef.componentInstance.title = this.utilService.objectResolver(
      'files.deleteFiles.title',
      CONFIRMATION_MSGS
    );
    modalRef.componentInstance.body = this.utilService.objectResolver(
      'files.deleteFiles.content',
      CONFIRMATION_MSGS
    );
    modalRef.componentInstance.okBtn = this.utilService.objectResolver(
      'files.deleteFiles.okBtn',
      CONFIRMATION_MSGS
    );
    modalRef.componentInstance.cancelBtn = this.utilService.objectResolver(
      'files.deleteFiles.cancelBtn',
      CONFIRMATION_MSGS
    );
    modalRef.componentInstance.service = this.utilService.objectResolver(
      'files.deleteFiles.service',
      CONFIRMATION_MSGS
    );
    modalRef.componentInstance.method = this.utilService.objectResolver(
      'files.deleteFiles.method',
      CONFIRMATION_MSGS
    );
    let payload: any;
    if (this.claimData) {
      payload = { claimId: this.claimData._id, fieldName: type, prod: 'reimburse' };
    } else {
      let filename = this.reimburseForm.controls[type].value;
      payload = { fieldName: filename, prod: 'reimburse' };
    }

    modalRef.componentInstance.payload = [payload];

    const result: any = await modalRef.result.catch(e => console.log(e));
    if (result) {
      this.toastrService.info('Document deleted Successfully!..');
      this.isLoading = false;
      this.uploadedImg[type] = null;
      this.reimburseForm.controls[type].setValue(null);
      this.reimburseForm.controls[type].markAsDirty();
    }

  }

  cancelClick() {
    this.router.navigate(['/reimburse-list']);
  }

  async openHelpPopup() {
    const modalRef = await this.modalService.open(ClaimHelpComponent);
  }

  omit_special_char(event: any) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k <= 122) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }


  omit_special_Mailchar(event: any) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k <= 122) || k === 46 || k === 64 || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  getMyContract() {
    this.getcontract_loading = true;
    this.filter1_name = this.reimburseForm.controls.filter1select.value;
    this.filter1_value = $.trim(this.reimburseForm.controls.filter1.value);
    this.filter2_name = this.reimburseForm.controls.filter2select.value;
    this.filter2_value = $.trim(this.reimburseForm.controls.filter2.value);
    // if(this.filter1_name == '' || this.filter1_value == '' || this.filter2_name == '' || this.filter2_value =='')
    // {
    //   this.toastrService.warning('please select all fields and values');
    //   return true;
    // }
    // console.log(this.filter1_name,'1',this.filter1_value,'2',this.filter2_name,'3',this.filter2_value,'4')
    // var data = { [filter1_name] : filter1_value, [filter2_name]: filter2_value };
    if (this.filter1_name && this.filter1_value && this.filter2_name && this.filter2_value) {

      var data = {

        "firstname": this.filter1_name == 'firstname' ? $.trim(this.filter1_value) : '',
        "lastname": this.filter1_name == 'lastname' ? $.trim(this.filter1_value) : '',
        "phone": this.filter1_name == 'phone' ? $.trim(this.filter1_value) : (this.filter2_name == 'phone' ? $.trim(this.filter2_value) : ''),
        "vin": this.filter1_name == 'vin' ? $.trim(this.filter1_value) : (this.filter2_name == 'vin' ? $.trim(this.filter2_value) : ''),
        "contract": this.filter2_name == 'contract' ? $.trim(this.filter2_value) : '',
        "ProductType": "",
        "ProductSubType": "",
        "Product": ""
      }
      this.Claims
        .getContractDetails(data)
        .subscribe((res: any) => {
          this.getcontract_loading = false;
          if (res.status === 1) {
            this.toastrService.info('The contract information provided is for reference only. Please edit or make any changes you deem necessary.', 'We have found a contract matching your search!');
            if (res.data.length == 1) {
              // this.show_claims = true;
              this.setContractData(res.data[0]);
              // this.claims_data = res.data;
            }
            else {
              this.claims_data = res.data;
              this.show_claims = true;
            }
          } else {
            this.show_claims = false;
            this.toastrService.warning("Try a different combination or fill in the details manually.", "Sorry! we couldn't find that contract!");
            this.resetContractData();
          }
        });
    }
    else {
      this.toastrService.warning('please select / fill all fields and values');
      this.show_claims = false;
      this.getcontract_loading = false;
      return true;
    }
  }

  getContract() {
      var contactNumber = $.trim(this.reimburseForm.controls.contractNumber.value);
    if (contactNumber !== '') {
      var data = { "contractNo": contactNumber, "productType":"ANC" };
      this.Claims
        .getContractInfo(data)
        .subscribe((res: any) => {
          if (res.status === 1) {
            this.toastrService.info(res.msg);
            this.setContractData(res.data);
          } else {
            this.toastrService.warning(res.msg);
            this.resetContractData();
          }
        });
    } else {
      this.toastrService.warning('please fill Contract No');
    }
  }

  claim_select(event: any) {
    // console.log(event.target.value,'valuuuuu')
    this.setContractData(this.claims_data[event.target.value]);
    this.show_claims = false;
  }

  setContractData(data: any) {
    this.reimburseForm.controls['contractNumber'].setValue(data.ContractNumber);
    this.reimburseForm.controls['vinNumber'].setValue(data.VIN);
    this.reimburseForm.controls['customerName'].setValue(data.FullName ? data.FullName : '');
    // this.reimburseForm.controls['phoneNumber'].setValue(data.PhysicalPhone);
    this.contractPhone = data.PhysicalPhone;
    // let address = '';
    // if (data.PhysicalAddressLine1)
    //   address = data.PhysicalAddressLine1
    // if (data.PhysicalAddressLine2)
    //   address = address + data.PhysicalAddressLine1 ? ', ' : ' ' + data.PhysicalAddressLine2
    // this.reimburseForm.controls['streetAddress'].setValue(address);
    // this.reimburseForm.controls['city'].setValue(data.PhysicalCity);
    // this.reimburseForm.controls['state'].setValue(data.PhysicalState);
    // this.reimburseForm.controls['zipCode'].setValue(data.PhysicalZipCode);
    this.reimburseForm.controls['make'].setValue(data.VehicleMake);
    this.reimburseForm.controls['model'].setValue(data.VehicleModel);
    this.reimburseForm.controls['year'].setValue(data.VehicleYear);
    this.reimburseForm.controls['dealer'].setValue(this.dealersList.find((user: any) => { return (user.name.toLowerCase() == data.DealerName.toLowerCase() /*||  user.dealerNo == data.dealerNo */) }));
  }

  resetContractData() {
    this.contractPhone ='';
    this.reimburseForm.controls['contractNumber'].setValue('');
    this.reimburseForm.controls['vinNumber'].setValue('');
    this.reimburseForm.controls['customerName'].setValue('');
    // this.reimburseForm.controls['phoneNumber'].setValue('');
    // this.reimburseForm.controls['streetAddress'].setValue('');
    // this.reimburseForm.controls['city'].setValue('');
    // this.reimburseForm.controls['state'].setValue('');
    // this.reimburseForm.controls['zipCode'].setValue('');
    this.reimburseForm.controls['make'].setValue('');
    this.reimburseForm.controls['model'].setValue('');
    this.reimburseForm.controls['year'].setValue('');
  }

  createmoreDocsForm(data?: any) {
    return this.formBuilder.group({
      name: [
        (this.isEditMode && data && data.name)
          ? data.name
          : ''
      ],
      url: [
        (this.isEditMode && data && data.url)
          ? data.url
          : '',
        [Validators.required]
      ]
    });
  }

  addDoc(): void {
    this.moreDocs = this.reimburseForm.get('moreDocs') as FormArray;
    this.moreDocs.push(this.createmoreDocsForm());
  }

  removeDoc(index: number): void {
    this.reimburseForm.markAsDirty();
    this.moreDoc[index] = '';
    this.showmoreDocs[index] = false;
    this.moreDocs.removeAt(index);
  }

  onChangeFile(event: any, index: number) {
    this.isLoading = true;
    if (event.target.files && event.target.files.length) {
      var phots = this.moreDocs.controls[index];
      const formData: FormData = new FormData();
      formData.append('file', event.target.files[0]);
      // if (this.claimData)
      //   formData.append('claimId', this.claimData._id);
      formData.append('fieldName', 'moreDocs');
      formData.append('prod', 'reimburse');
      this.authenticationService.uploadPhoto(formData).subscribe(
        (res: any) => {
          this.moreDocs.markAsDirty();
          this.isLoading = false;
          this.moreDoc[index] = res.path;
          this.moreDocs.controls[index].get('url').setValue(res.path);
          this.showmoreDocs[index] = true;
        },
        (error: any) => {
          this.isLoading = false;
          console.log(error);
          this.toastrService.warning('Invalid file');
        }
      );
    }
  }

  changeImage(index: number) {
    this.moreDocs.markAsDirty();
    this.moreDoc[index] = '';
    this.showmoreDocs[index] = false;
    this.moreDocs.controls[index].get('url').setValue(null);
  }

  checkDealer() {
    if (typeof this.reimburseForm.controls['dealer'].value !== 'object') {
      this.reimburseForm.controls.dealer.setErrors({ 'incorrect': true });
    }
  }  

  omit_all_char(event:any)
  {   
     var k;  
     k = event.charCode;  //         k = event.keyCode;  (Both can be used)
     return((k >= 48 && k <= 57)); 
  }

}

