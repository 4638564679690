import { countries } from './static/countries';
import { timezones } from './static/timezones';
import { FormGroup, ValidationErrors } from '@angular/forms';

export const API_ENDPOINT: any = [];

export const ROLES: any = {
  administrator: 'Administrator',
  developer: 'Developer',
  operator: 'Analyst',
  support: 'Support Specialist',
  read_only: 'View Only'
};

export const LOCAL_VAR: any = {
  selectedCustomer: 'claim_selectedCustomer',
  credentialsKey: 'claim_credentials',
  selectedProduct: 'claim_selectedproduct',
  domainconfig: 'claim_domainconfig'
};

export const USER_TYPES: any = {
  admin: 'admin',
  customer: 'customer'
};

export const DATE_TIME_FORMATE: any = {
  DEFAULT: 'yyyy/MM/dd H:mm:ss',
  DEFAULT12Hours: 'yyyy/MM/dd h:mm:ss'
};
export const DATE_FORMATE: any = {
  DEFAULT: 'yyyy/MM/dd',
  APIKEY: 'MMM dd, yyyy',
  INVOICE: 'MMM d, yyyy'
};
export const TIME_FORMATE: any = {
  DEFAULT: 'H:mm:ss'
};

export const COUNTRIES: Country.Object[] = countries;
export const TIMEZONES: Timezone.Object[] = timezones;

export const ERROR_OBJECTS: Object = {
  required: '{{field}} is required',
  email: 'Please enter valid email',
  min: 'Please enter value more than {{value}}',
  max: 'Please enter value not more than {{value}}',
  minlength: 'Please enter value with min length {{value}}',
  maxlength: 'Please enter value with max length {{value}}',
  pattern: 'Please enter valid value',
  ccNumber: 'Please enter valid card number'
};

export const TOASTR_CONFIG: any = {
  timeOut: 3500,
  positionClass: 'toast-top-center'
};

export const validations: any = {
  uri: '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'
};

// Validators
export const matchValidation: Function = (
  control1: string,
  control2: string
): Function => {
  return (control: FormGroup): ValidationErrors | null => {
    const mainControl = control.get(control1);
    const controlToMatch = control.get(control2);

    return mainControl.value !== controlToMatch.value
      ? { doesNotMatch: true }
      : null;
  };
};

export const STATES: any = [
  {
    name: 'Alabama',
    code: 'AL',
    _id: '1'
  },
  {
    name: 'Alaska',
    code: 'AK',
    _id: '2'
  },
  {
    name: 'Arizona',
    code: 'AZ',
    _id: '3'
  },
  {
    name: 'Arkansas',
    code: 'AR',
    _id: '4'
  },
  {
    name: 'California',
    code: 'CA',
    _id: '5'
  },
  {
    name: 'Colorado',
    code: 'CO',
    _id: '6'
  },
  {
    name: 'Connecticut',
    code: 'CT',
    _id: '7'
  },
  {
    name: 'Delaware',
    code: 'DE',
    _id: '8'
  },
  {
    name: 'Florida',
    code: 'FL',
    _id: '10'
  },
  {
    name: 'Georgia',
    code: 'GA',
    _id: '11'
  },
  {
    name: 'Hawaii',
    code: 'HI',
    _id: '12'
  },
  {
    name: 'Idaho',
    code: 'ID',
    _id: '13'
  },
  {
    name: 'Illinois',
    code: 'IL',
    _id: '14'
  },
  {
    name: 'Indiana',
    code: 'IN',
    _id: '15'
  },
  {
    name: 'Iowa',
    code: 'IA',
    _id: '16'
  },
  {
    name: 'Kansas',
    code: 'KS',
    _id: '17'
  },
  {
    name: 'Kentucky',
    code: 'KY',
    _id: '18'
  },
  {
    name: 'Louisiana',
    code: 'LA',
    _id: '19'
  },
  {
    name: 'Maine',
    code: 'ME',
    _id: '20'
  },
  {
    name: 'Maryland',
    code: 'MD',
    _id: '21'
  },
  {
    name: 'Massachusetts',
    code: 'MA',
    _id: '22'
  },
  {
    name: 'Michigan',
    code: 'MI',
    _id: '23'
  },
  {
    name: 'Minnesota',
    code: 'MN',
    _id: '24'
  },
  {
    name: 'Mississippi',
    code: 'MS',
    _id: '25'
  },
  {
    name: 'Missouri',
    code: 'MO',
    _id: '26'
  },
  {
    name: 'Montana',
    code: 'MT',
    _id: '27'
  },
  {
    name: 'Nebraska',
    code: 'NE',
    _id: '28'
  },
  {
    name: 'Nevada',
    code: 'NV',
    _id: '29'
  },
  {
    name: 'New Hampshire',
    code: 'NH',
    _id: '30'
  },
  {
    name: 'New Jersey',
    code: 'NJ',
    _id: '31'
  },
  {
    name: 'New Mexico',
    code: 'NM',
    _id: '32'
  },
  {
    name: 'New York',
    code: 'NY',
    _id: '33'
  },
  {
    name: 'North Carolina',
    code: 'NC',
    _id: '34'
  },
  {
    name: 'North Dakota',
    code: 'ND',
    _id: '35'
  },
  {
    name: 'Ohio',
    code: 'OH',
    _id: '36'
  },
  {
    name: 'Oklahoma',
    code: 'OK',
    _id: '37'
  },
  {
    name: 'Oregon',
    code: 'OR',
    _id: '38'
  },
  {
    name: 'Pennsylvania',
    code: 'PA',
    _id: '39'
  },
  {
    name: 'Rhode Island',
    code: 'RI',
    _id: '40'
  },
  {
    name: 'South Carolina',
    code: 'SC',
    _id: '41'
  },
  {
    name: 'South Dakota',
    code: 'SD',
    _id: '42'
  },
  {
    name: 'Tennessee',
    code: 'TN',
    _id: '43'
  },
  {
    name: 'Texas',
    code: 'TX',
    _id: '44'
  },
  {
    name: 'Utah',
    code: 'UT',
    _id: '45'
  },
  {
    name: 'Vermont',
    code: 'VT',
    _id: '46'
  },
  {
    name: 'Virginia',
    code: 'VA',
    _id: '47'
  },
  {
    name: 'Washington',
    code: 'WA',
    _id: '48'
  },
  {
    name: 'West Virginia',
    code: 'WV',
    _id: '51'
  },
  {
    name: 'Wisconsin',
    code: 'WI',
    _id: '49'
  },
  {
    name: 'Wyoming',
    code: 'WY',
    _id: '50'
  }
];

export const CONFIRMATION_MSGS: any = {
  switchAgent: {
    confirmation: {
      title: `Switch Agent Confirmation`,
      content: 'Are you sure want to switch agent?',
      okBtn: 'Switch Agent',
      cancelBtn: 'Cancel'
    }
  },
  agents: {
    deleteAgent: {
      title: `Delete Agent`,
      content: `Demo Agent will no longer be able to access this account.`,
      okBtn: 'Delete Agent',
      cancelBtn: 'Cancel'
    }
  },
  users: {
    deleteUser: {
      title: `Delete User`,
      content: `Demo User will no longer be able to access this account.`,
      okBtn: 'Delete User',
      cancelBtn: 'Cancel'
    }
  },
  customers: {
    deleteCustomer: {
      title: `Delete Customer`,
      content: `Are you sure want to delete this customer?`,
      okBtn: 'Delete Customer',
      cancelBtn: 'Cancel',
      service: 'CustomerService',
      method: 'deleteCustomer'
    }
  },
  claims: {
    deleteClaim: {
      title: `Delete Claim`,
      content: `Are you sure want to delete this claim?`,
      okBtn: 'Delete Claim',
      cancelBtn: 'Cancel',
      service: 'ClaimService',
      method: 'deleteClaim'
    },
    deleteLeaseClaim: {
      title: `Delete Lease Claim`,
      content: `Are you sure want to delete this claim?`,
      okBtn: 'Delete Claim',
      cancelBtn: 'Cancel',
      service: 'ClaimService',
      method: 'deleteLeaseClaim'
    },
    deleteKeyprotection: {
      title: `Delete Ketprotection Claim`,
      content: `Are you sure want to delete this claim?`,
      okBtn: 'Delete Claim',
      cancelBtn: 'Cancel',
      service: 'KeyfechService',
      method: 'deleteClaim'
    },
    deleteShield: {
      title: `Delete 360 Shield Claim`,
      content: `Are you sure want to delete this claim?`,
      okBtn: 'Delete Claim',
      cancelBtn: 'Cancel',
      service: 'shieldService',
      method: 'deleteClaim'
    },
    deleteDebitcancel: {
      title: `Delete Claim`,
      content: `Are you sure want to delete this claim?`,
      okBtn: 'Delete Claim',
      cancelBtn: 'Cancel',
      service: 'debitcancelService',
      method: 'deleteClaim'
    },
    deleteReimburse: {
      title: `Delete Claim`,
      content: `Are you sure want to delete this Reimbursement?`,
      okBtn: 'Delete Reimbursement',
      cancelBtn: 'Cancel',
      service: 'reimbuseService',
      method: 'deleteClaim'
    },
    deleteTheft: {
      title: `Delete Claim`,
      content: `Are you sure want to delete this Theft Claim?`,
      okBtn: 'Delete Theft Claim',
      cancelBtn: 'Cancel',
      service: 'theftService',
      method: 'deleteClaim'
    }

  },
  files: {
    deleteFiles: {
      title: `Delete Document`,
      content: `Are you sure want to delete this document`,
      okBtn: 'Delete',
      cancelBtn: 'Cancel',
      service: 'AuthenticationService',
      method: 'deleteFile'
    },
    deleteOtherFiles: {
      title: `Delete Document`,
      content: `Are you sure want to delete this document`,
      okBtn: 'Delete',
      cancelBtn: 'Cancel',
      service: 'AuthenticationService',
      method: 'deleteOtherFile'
    },
    deleteLeaseFiles: {
      title: `Delete Document`,
      content: `Are you sure want to delete this document`,
      okBtn: 'Delete',
      cancelBtn: 'Cancel',
      service: 'AuthenticationService',
      method: 'deleteLeaseFile'
    }
  }
};
