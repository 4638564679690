import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { takeUntil, finalize } from 'rxjs/operators';
import { ClaimService } from '@app/core/service/claim.service';
import { TheftService } from '@app/core/service/theft.service';
import { UtilService, CONFIRMATION_MSGS, LOCAL_VAR } from '@app/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ClaimHelpComponent } from '@app/shared/modals/claim-help/claim-help.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-theft-view',
  templateUrl: './theft-view.component.html',
  styleUrls: ['./theft-view.component.scss']
})
export class TheftViewComponent implements OnInit {
  user: Authentication.User;
  private unsubscribe: Subject<void> = new Subject();
  isLoading = false;
  loggedInUserType: any = null;
  claimId: any = null;
  selectedCustomer: any = null;
  setUserData: any;
  claims: any = [];
  history:any=[];
  rejectEdit = '';
  path: string;
  baseUrl = '';
  appname=environment.name;
  statusChange: FormGroup;
  claimDomain: any;

  constructor(
    private modalService: NgbModal,
    private toastrService: ToastrService,
    public router: Router,
    private route: ActivatedRoute,
    public utilService: UtilService,
    private authenticationService: AuthenticationService,
    private claimService: ClaimService,
    private TheftService: TheftService,
    private formBuilder: FormBuilder
  ) {
    this.user = this.authenticationService.credentials.user;
    this.authenticationService.credentials$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((credentials: Authentication.Credentials) => {
        this.user = credentials.user;
      });
    this.loggedInUserType = this.user.userRoleId.slug;
    this.claimId = this.route.snapshot.params['claimId'];
    this.getClaim();
    this.selectedCustomer = localStorage.getItem(LOCAL_VAR.selectedCustomer);
    this.selectedCustomer = JSON.parse(this.selectedCustomer);
    this.baseUrl = environment.s3Url;
  }

  ngOnInit() { }

  ngAfterViewInit() {
    this.utilService.updateDomainCss();
  }

  getClaim() {
    this.isLoading = true;
    this.TheftService
      .getById(this.claimId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response: any) => {
        if (response) {
          if (response.records) {
            this.claims = response.records;
            this.history = response.history ? response.history : null;
            if (this.claims.rejectEdit === true && (this.loggedInUserType === 'superadmin' || this.loggedInUserType === 'dowcsuperadmin')) {
              this.rejectEdit = 'allow';
            }
            this.getClaimDomainInfo();
          } else {
            localStorage.setItem(LOCAL_VAR.selectedProduct, 'theft');
            this.router.navigate(['/theft-list']);
          }
        }
      });
    this.statusChange = this.formBuilder.group({
      status: ['']
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  async downloadDocuments(claimId: string) {
    this.isLoading = true;
    this.claimService.downloadDocuments(claimId).subscribe(
      respData => {
        this.downLoadFile(respData.records);
        this.toastrService.info('File has been downloaded.');
        this.isLoading = false;
      },
      error => {
        this.toastrService.warning('Sorry! Documents not found.');
        this.isLoading = false;
      }
    );
  }

  downLoadFile(data: string) {
    const aTag = document.createElement('a');
    document.body.appendChild(aTag);
    aTag.style.display = 'none';
    const url = environment.serverUrl.slice(0, -3) + '/downloads/' + data;
    aTag.href = url;
    aTag.setAttribute('target', '_blank');
    aTag.click();
    document.body.removeChild(aTag);
  }
  changeStatus(claimId: any) {
    const payload = {
      status: this.statusChange.value.status
    };
    this.TheftService
      .changeStatus(claimId, payload)
      .subscribe((response: any) => {
        if (response) {
          this.toastrService.info('Claim Status Updated');
          setTimeout(() => {
            this.router.navigate(['/theft-list']);
          }, 1000);
        } else {
          this.toastrService.warning('something went Wrong');
        }
      });
  }

  async openHelpPopup() {
    const modalRef = await this.modalService.open(ClaimHelpComponent);
  }

  getClaimDomainInfo() {
    if (this.claims.domain) {
      var hostname = window.location.hostname;
      if (hostname != this.claims.domain) {
        this.authenticationService.getconfig(this.claims.domain)
          .subscribe((response: any) => {
            if (response && response.records) {
              this.claimDomain = response.records;
              setTimeout(() => {
                this.utilService.changeCssAttribute('domain-logo','background-color',this.claimDomain.primaryColor);
                this.utilService.changeCssAttribute('domain-logo','color',this.claimDomain.secondaryColor);
                this.utilService.changeCssAttribute('domain-bg','background-color',this.claimDomain.primaryColor);
                this.utilService.changeCssAttribute('domain-bg','color',this.claimDomain.secondaryColor);
              }, 200);
              
            }
          });
      }
    }
  }

}


