import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ClaimService } from '@app/core/service/claim.service';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { LOCAL_VAR, UtilService } from '@app/core';

@Component({
  selector: 'app-claim-help',
  templateUrl: './claim-help.component.html',
  styleUrls: ['./claim-help.component.scss']
})
export class ClaimHelpComponent implements OnInit {
  helpForm: FormGroup;
  helpFormdata: any;
  user: Authentication.User;
  constructor(
    public activeModal: NgbActiveModal,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    public claimService: ClaimService,
    public utilService: UtilService,
    private authenticationService: AuthenticationService
  ) {
    this.user = this.authenticationService.credentials
      ? this.authenticationService.credentials.user
      : null;
    this.createForm();
  }

  ngOnInit() {}

  ngAfterViewInit() {
    var domainconfig = JSON.parse(localStorage.getItem(LOCAL_VAR.domainconfig));
    if(domainconfig){
      if(domainconfig.primaryColor){
        this.utilService.changeCssAttribute('btn-primary','border',domainconfig.primaryColor);
        this.utilService.changeCssAttribute('btn-primary','background',domainconfig.primaryColor);
      }
      if(domainconfig.secondaryColor){
        this.utilService.changeCssAttribute('bg-inprogress','background-color',domainconfig.secondaryColor);
      }
    }
  }
  
  createForm() {
    this.helpForm = this.formBuilder.group({
      firstName: [this.user ? this.user.firstName : '', [Validators.required]],
      lastName: [this.user ? this.user.lastName : '', [Validators.required]],
      phone: [
        this.user ? this.user.phone : '',
        Validators.compose([
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(12)
        ])
      ],
      email: [
        this.user ? this.user.email : '',
        Validators.compose([
          Validators.required,
          Validators.pattern(/^(\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)
        ])
      ],
      request: 'general',
      message: ['', [Validators.required]]
    });
  }

  helpaction() {
    this.helpFormdata = this.helpForm.value;
    if (this.helpForm.valid) {
      this.helpFormdata.userId = this.user ? this.user._id : null;
      this.claimService
        .helprequest(this.helpFormdata)
        .subscribe((response: any) => {
          console.log(response, 'Accept Request response');
          if (response) {
            this.helpFormdata.ticketId = response.sequence ? response.sequence : '';
            this.claimService
              .createSharepointTicket(this.helpFormdata)
              .subscribe((response: any) => {
                console.log(response, 'Ticket created');
              });
            this.toastrService.info('Request raised Successfully..!');
           this.activeModal.dismiss(response);
          } else {
            this.toastrService.warning('something went Wrong');
          }
        });
      
    }
  }
}
