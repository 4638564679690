import * as $ from 'jquery';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ChartsModule } from 'ng2-charts';
import { NgxMaskModule } from 'ngx-mask';
import { NgxCurrencyModule } from "ngx-currency";

import { Approutes } from '@app/app.routing';
import { AppComponent } from '@app/app.component';
import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { DashboardClaimListComponent } from './dashboard/dashboard-claim-list.component';
import { AuditComponent } from './customer/audit/audit.component'
import { ConfigComponent } from './customer/config/config.component'
import { DealerListComponent } from './customer/dealer-list/dealer-list.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbDateCustomParserFormatter } from './datepicker.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { AnalyticsComponent } from './dashboard/analytics/analytics.component';
import { ReportComponent } from './dashboard/report/report.component';
import { CreateClaimComponent } from './leaseclaim/create-claim/create-claim.component';
import { ListClaimComponent } from './leaseclaim/list-claim/list-claim.component';
import { HomeComponent } from './dashboard/home/home.component';
import { LeaseAnalyticsComponent } from './leaseclaim/lease-analytics/lease-analytics.component';
import { KeyfetchListComponent } from './keyfetch/keyfetch-list/keyfetch-list.component';
import { KeyfetchCreateComponent } from './keyfetch/keyfetch-create/keyfetch-create.component';
import { KeyfetchAnalyticsComponent } from './keyfetch/keyfetch-analytics/keyfetch-analytics.component';
import { LeasePreviewComponent } from './leaseclaim/lease-preview/lease-preview.component';
import { KeyfetchPreviewComponent } from './keyfetch/keyfetch-preview/keyfetch-preview.component';
import { ShieldCreateComponent } from './shield/shield-create/shield-create.component';
import { ShieldListComponent } from './shield/shield-list/shield-list.component';
import { ShieldPreviewComponent } from './shield/shield-preview/shield-preview.component';
import { ShieldAnalyticsComponent } from './shield/shield-analytics/shield-analytics.component';
import { SupportComponent } from './customer/support/support.component';
import { DebitcancellationCreateComponent } from './debit-cancellation/debitcancellation-create/debitcancellation-create.component';
import { DebitcancellationListComponent } from './debit-cancellation/debitcancellation-list/debitcancellation-list.component';
import { DebitcancellationPreviewComponent } from './debit-cancellation/debitcancellation-preview/debitcancellation-preview.component';
import { DebitcancellationAnalyticsComponent } from './debit-cancellation/debitcancellation-analytics/debitcancellation-analytics.component';
import { DealergroupListComponent } from './customer/dealergroup-list/dealergroup-list.component';
import { DealergroupCreateComponent } from './customer/dealergroup-create/dealergroup-create.component';
import { ClaimFindComponent } from './claim/claim-find/claim-find.component';
import { DebitCancellationCalculationComponent } from './debit-cancellation/debit-cancellation-calculation/debit-cancellation-calculation.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ReimburseCreateComponent } from './reimburse/reimburse-create/reimburse-create.component';
import { ReimburseListComponent } from './reimburse/reimburse-list/reimburse-list.component';
import { ReimburseViewComponent } from './reimburse/reimburse-view/reimburse-view.component';
import { ReimburseAnalyticsComponent } from './reimburse/reimburse-analytics/reimburse-analytics.component';
import { TheftCreateComponent } from './theft/theft-create/theft-create.component';
import { TheftListComponent } from './theft/theft-list/theft-list.component';
import { TheftViewComponent } from './theft/theft-view/theft-view.component';
import { TheftAnalyticsComponent } from './theft/theft-analytics/theft-analytics.component';
import { ReimburseReportComponent } from './reimburse/reimburse-report/reimburse-report.component';

@NgModule({
  declarations: [
    AppComponent,
    ChangePasswordComponent,
    DashboardClaimListComponent,
    AuditComponent,
    ConfigComponent,
    DealerListComponent,
    AnalyticsComponent,
    ReportComponent,
    CreateClaimComponent,
    ListClaimComponent,
    HomeComponent,
    LeaseAnalyticsComponent,
    KeyfetchListComponent,
    KeyfetchCreateComponent,
    KeyfetchAnalyticsComponent,
    LeasePreviewComponent,
    KeyfetchPreviewComponent,
    ShieldCreateComponent,
    ShieldListComponent,
    ShieldPreviewComponent,
    ShieldAnalyticsComponent,
    SupportComponent,
    DebitcancellationCreateComponent,
    DebitcancellationListComponent,
    DebitcancellationPreviewComponent,
    DebitcancellationAnalyticsComponent,    
    DealergroupListComponent,
    DealergroupCreateComponent,
    ClaimFindComponent,
    DebitCancellationCalculationComponent,
    ReimburseCreateComponent,
    ReimburseListComponent,
    ReimburseViewComponent,
    ReimburseAnalyticsComponent,
    TheftCreateComponent,
    TheftListComponent,
    TheftViewComponent,
    TheftAnalyticsComponent,
    ReimburseReportComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgbModule.forRoot(),
    RouterModule.forRoot(Approutes),
    CoreModule,
    SharedModule,
    FormsModule,
    ChartsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    NgxMaskModule,
    NgxCurrencyModule,
    NgxCaptchaModule
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  ]
})
export class AppModule { }
