import { Injector, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ClaimService } from './../../../core/service/claim.service';
import { KeyfechService } from './../../../core/service/keyfetch.service';
import { shieldService } from './../../../core/service/shield.service';
import { debitcancelService } from './../../../core/service/debitcancel.service';
import { ReimburseService } from './../../../core/service/reimburse.service';
import { TheftService } from '@app/core/service/theft.service';
import { CustomerService } from '@app/core/service/customer.service';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
var services = {
    CustomerService: CustomerService,
    ClaimService: ClaimService,
    KeyfechService: KeyfechService,
    shieldService: shieldService,
    debitcancelService: debitcancelService,
    reimbuseService: ReimburseService,
    theftService: TheftService,
    AuthenticationService: AuthenticationService
};
var ConfirmationComponent = /** @class */ (function () {
    function ConfirmationComponent(activeModal, injector, router) {
        this.activeModal = activeModal;
        this.injector = injector;
        this.router = router;
        this.title = 'Are you sure want to delete?';
        this.body = 'Click on Yes to delete';
        this.okBtn = 'Yes';
        this.cancelBtn = 'Cancel';
        this.service = '';
        this.method = '';
        this.payload = [];
        this.isLoading = false;
    }
    ConfirmationComponent.prototype.ngOnInit = function () {
        if (this.service && services.hasOwnProperty(this.service)) {
            this.serviceProvider = this.injector.get(services[this.service]);
        }
        else {
            console.error(this.service + " does not exist");
        }
    };
    ConfirmationComponent.prototype.onSuccess = function () {
        var _this = this;
        var _a;
        if (this.serviceProvider && this.method) {
            this.isLoading = true;
            (_a = this.serviceProvider)[this.method].apply(_a, this.payload).pipe(finalize(function () {
                _this.isLoading = false;
            }))
                .subscribe(function (response) {
                if (response === null) {
                    response = true;
                }
                _this.activeModal.close(response);
            }, function (error) {
                _this.isLoading = false;
                console.log('Confirmation modal error:', error);
            });
        }
        else {
            this.activeModal.close();
            this.router.navigate(['/user']);
        }
    };
    return ConfirmationComponent;
}());
export { ConfirmationComponent };
