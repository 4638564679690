import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, from, Observable } from 'rxjs';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { takeUntil, finalize, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UtilService, CONFIRMATION_MSGS, LOCAL_VAR } from '@app/core';
import { DateTime } from 'luxon';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CustomerService } from '@app/core/service/customer.service';
import { ClaimHelpComponent } from '@app/shared/modals/claim-help/claim-help.component';
import { ReimburseService } from '@app/core/service/reimburse.service';

@Component({
  selector: 'app-reimburse-report',
  templateUrl: './reimburse-report.component.html',
  styleUrls: ['./reimburse-report.component.scss']
})
export class ReimburseReportComponent implements OnInit {
  @ViewChild('goto') nameInput: ElementRef;
  loggedInUserType: any = null;
  loggedInUserdealer: any = null;
  user: Authentication.User;
  selectedCustomer: any = null;
  isLoading = false;
  claims: any = [];
  fiterForm: FormGroup;
  environmentUrl = '';
  sortBy = 'createdAt';
  sortDir = -1;
  perPage = 10;
  currentPage = 1;
  dealersList: any;
  pager = {
    perPage: 10,
    currentPage: 1,
    Totalrecords: 0,
    TotalPages: 0
  };
  PageList: any;
  dealers = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term =>
        term.length < 2
          ? []
          : this.dealersList
            .filter(
              (v: { name: string; }) => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1
            )
      )
    );
  formatter = (x: { name: string }) => x.name;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private authenticationService: AuthenticationService,
    private claimService: ReimburseService,
    private formBuilder: FormBuilder,
    public utilService: UtilService,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
    private customerService: CustomerService
  ) {
    this.environmentUrl = environment.serverUrl;
    this.user = this.authenticationService.credentials.user;
    this.loggedInUserType = this.user.userRoleId.slug;
    this.loggedInUserdealer = this.user.dealerId;

    // if (!this.selectedCustomer && this.loggedInUserType === 'admin') {
    //   this.router.navigate(['/customer'], {
    //     replaceUrl: true
    //   });
    // }
    this.createForm();
    this.getClaimsByFilter('report');
    this.route.queryParams.pipe().subscribe(params => {
      if (params && params.page) {
        if (!isNaN(params.page)) {
          this.currentPage = +params.page;
        }
      }
    });
  }

  ngOnInit() {
    if (this.loggedInUserType !== 'superadmin' && this.loggedInUserType !== 'dowcsuperadmin') {
      this.router.navigate(['/products']);
    }
  }

  ngAfterViewInit() {
    this.utilService.updateDomainCss();
  }

  createForm() {
    let startdate = new Date();
    let todate = new Date();
    this.fiterForm = this.formBuilder.group({
      addendumNumber: ['', Validators.compose([Validators.maxLength(5)])],
      date_from: [{ year: startdate.getFullYear(), month: startdate.getMonth() - 2, day: startdate.getDate() }],
      date_to: [{ year: todate.getFullYear(), month: todate.getMonth() + 1, day: todate.getDate() }],
      customerName: [''],
      email: [''],
      status: [''],
      description: [''],
      sortFieldFilter: ['createdAt'],
      sortByFilter: ['-1'],
      dealer: [''],
      perPage: 10
    });

    this.fiterForm.valueChanges.subscribe(values => {
      this.pager.currentPage = 1;
      this.getClaimsByFilter('report');
    });
    this.isLoading = true;
    this.customerService
      .getDealers()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      ) 
      .subscribe((response: any) => {
        if(this.user.dealerGroupId && this.loggedInUserType == 'superadmin') {
          this.customerService
          .getDealerGroupDetail(this.user.dealerGroupId)
          .subscribe((res: any) => {
            if (res.records) {
              let dealersgroup:any = [];
              $.each(res.records.dealers, function (index, val) {
                $.each(response.records, function (index, val1) {
                  if (val.dealerId.toString() === val1._id.toString()) {
                    dealersgroup.push(val1);
                    return false;
                  }
                });
              });
              this.dealersList = dealersgroup;
            } 
          });
        } else {
          this.dealersList = response.records;
        }
      });
  }

  exportReport() {
    this.getClaimsByFilter('export');
  }

  getClaimsByFilter(mode: any) {
    let payload = this.fiterForm.value;
    payload = this.utilService.removeWhiteSpace(payload);
    if (payload.date_from) {
      payload.claimDate_at_from = DateTime.fromObject(
        payload.date_from
      ).toISO();
    }

    if (payload.date_to) {
      payload.claimDate_at_to = DateTime.fromObject(
        payload.date_to
      ).toISO();
    }

    if (typeof payload.dealer == "object") {
      payload.dealer = payload.dealer._id;
    } else if (typeof payload.dealer == "string" && payload.dealer !== "" && mode != "export") {
      return false;
    }
    // console.log(payload);
    payload.currentPage = this.pager.currentPage;
    payload.mode = mode;
    this.pager.perPage = payload.perPage;
    this.isLoading = true;
    this.claimService
      .getClaimReport(payload)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response: any) => {
          this.claims = response.records;
          this.renderPagination(response.page, response.total, response.totalPage);

      });
  }


  async sort(sortByVal: string) {
    if (this.sortBy === sortByVal) {
      this.sortDir = this.sortDir === 1 ? -1 : 1;
    } else {
      this.sortDir = 1;
    }
    /*  this.sortFieldFilter.setValue(sort);
    this.sortByFilter.setValue(sortDir); */
    this.fiterForm.value.sortFieldFilter = sortByVal;
    this.fiterForm.value.sortByFilter = this.sortDir;
    this.sortBy = sortByVal;
    this.getClaimsByFilter('report');
  }

  goPage() {
    let goto = this.nameInput.nativeElement.value;
    this.setPage(goto);
    this.nameInput.nativeElement.value = '';
  }

  setPage(page: any) {
    if (0 < page && page <= this.pager.TotalPages && page != this.pager.currentPage) {
      this.pager.currentPage = page;
      this.getClaimsByFilter('report');
    }
  }

  renderPagination(page: number, total: number, totalPage: number) {
    this.pager.currentPage = page;
    this.pager.Totalrecords = total;
    this.pager.TotalPages = totalPage;
    this.PageList = this.utilService.getPageList(this.pager.TotalPages, this.pager.currentPage);
  }

  omit_special_char(event: any) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k <= 122) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }


  omit_special_Mailchar(event: any) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k <= 122) || k === 46 || k === 64 || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  async openHelpPopup() {
    const modalRef = await this.modalService.open(ClaimHelpComponent);
  }

}

