import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter } from '@angular/core';
import { map } from 'rxjs/operators';
import { DateTime } from 'luxon';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var debitcancelService = /** @class */ (function () {
    function debitcancelService(httpClient) {
        this.httpClient = httpClient;
        this.claimData = null;
        this.claimData$ = new EventEmitter();
    }
    debitcancelService.prototype.add = function (payload) {
        return this.httpClient.post("/debitcanceladd", payload).pipe(map(function (body) {
            return body;
        }));
    };
    debitcancelService.prototype.update = function (claimId, payload) {
        return this.httpClient.post("/debitcancelupdate/" + claimId, payload).pipe(map(function (body) {
            return body;
        }));
    };
    debitcancelService.prototype.updateCal = function (claimId, payload) {
        return this.httpClient.post("/debitcancelcalc/" + claimId, payload).pipe(map(function (body) {
            return body;
        }));
    };
    debitcancelService.prototype.getByFilter = function (payload, customerId) {
        var params = customerId
            ? new HttpParams().set('customerId', "" + customerId)
            : new HttpParams();
        params = payload.claimDate_from
            ? params.append('claimDate_from', "" + payload.claimDate_from)
            : params;
        params = payload.claimDate_to
            ? params.append('claimDate_to', "" + payload.claimDate_to)
            : params;
        params = payload.addendumNumber
            ? params.append('addendumNumber', "" + payload.addendumNumber)
            : params;
        params = payload.sequence
            ? params.append('sequence', "" + payload.sequence)
            : params;
        params = payload.status
            ? params.append('status', "" + payload.status)
            : params;
        params = payload.debitorName
            ? params.append('debitorName', "" + payload.debitorName)
            : params;
        params = payload.debitorEmail
            ? params.append('debitorEmail', "" + payload.debitorEmail)
            : params;
        params = payload.dealer
            ? params.append('dealer', "" + payload.dealer)
            : params;
        params = payload.sortFieldFilter
            ? params.append('sortFieldFilter', "" + payload.sortFieldFilter)
            : params;
        params = payload.sortByFilter
            ? params.append('sortByFilter', "" + payload.sortByFilter)
            : params;
        params = params.append('currentPage', "" + payload.currentPage);
        params = params.append('perPage', "" + payload.perPage);
        return this.httpClient.get("/debitcancelclaims", { params: params }).pipe(map(function (body) {
            return body;
        }));
    };
    debitcancelService.prototype.getById = function (claimId) {
        return this.httpClient.get("/debitcancel/" + claimId).pipe(map(function (body) {
            return body;
        }));
    };
    debitcancelService.prototype.acceptRequest = function (claimId, payload) {
        return this.httpClient.put("/debitcancel/" + claimId + "/acceptrequest", payload).pipe(map(function (body) {
            return body;
        }));
    };
    debitcancelService.prototype.rejectRequest = function (claimId, payload, type) {
        return this.httpClient.put("/debitcancel/" + claimId + "/" + type + "request", payload).pipe(map(function (body) {
            return body;
        }));
    };
    debitcancelService.prototype.changeStatus = function (claimId, payload) {
        return this.httpClient.put("/debitcancel/" + claimId + "/changestatus", payload).pipe(map(function (body) {
            return body;
        }));
    };
    debitcancelService.prototype.deleteClaim = function (claimId) {
        return this.httpClient.delete("/debitcancel/" + claimId).pipe(map(function (body) {
            return body;
        }));
    };
    debitcancelService.prototype.getAnalytics = function (payload) {
        var params = null;
        if (payload) {
            params = new HttpParams().set('from', "" + payload.from);
            params = params.append('to', "" + payload.to);
        }
        if (payload.dealer && payload.dealer !== '') {
            params = params.append('dealer', "" + payload.dealer);
        }
        return this.httpClient.get("/debitcancelanalytics", { params: params }).pipe(map(function (body) {
            return body;
        }));
    };
    debitcancelService.prototype.DebtCalculation = function (params) {
        var adjusted_amount = 0;
        //new calculation - begin
        if (params.frequencyOfPayments != null) {
            params.numberofPaymentsperYear = params.frequencyOfPayments;
        }
        else {
            params.numberofPaymentsperYear = 0;
        }
        if (params.dateOfFirstDue != null) {
            var date1 = new Date(params.claimDate);
            var date2 = new Date(DateTime.fromObject(params.dateOfFirstDue).toISO());
            var Difference_In_Days = Math.round((date1.getTime() - date2.getTime()) / (1000 * 3600 * 24));
            params.numberofPaymentsElapsed = Math.max(Math.floor((Difference_In_Days / 365) * params.numberofPaymentsperYear) + 1, 0);
        }
        else {
        }
        if (params.numberOfPayments)
            params.realizedAPR = (parseFloat((this.RATE(params.numberOfPayments, params.paymentsPerPeriod, -params.amountFinanced, params.balloonPayment, 0, undefined) * params.numberofPaymentsperYear).toFixed(5)) * 100).toFixed(4);
        var fv_param_1 = params.realizedAPR / 100 / params.numberofPaymentsperYear;
        this.math_min_param_1 = this.FV(fv_param_1, params.numberofPaymentsElapsed, params.paymentsPerPeriod, -params.amountFinanced, 0);
        params.projectedPrincipalBalance = Math.min(this.math_min_param_1, params.amountFinanced);
        params.estimatedPrincipalPaid =
            params.amountFinanced - params.projectedPrincipalBalance;
        params.lesserAdjustedAmount = parseFloat(Math.min(params.projectedPrincipalBalance, params.actualPayoffAmount).toFixed(2));
        var salvageValue = 0;
        if (params.includeSalvage == 'Yes') {
            salvageValue = parseFloat((params.vehiclePurchasePrice * 0.2).toFixed(2));
        }
        params.salvageIncludeValue = salvageValue;
        params.totalDeduction = parseFloat(params.liabilityInsuranceSettlement +
            params.comprehensiveInsuranceSettlement +
            params.Dedectibles +
            params.accessories +
            params.serviceContract +
            params.closingFee +
            params.titleFee +
            params.salesTax +
            params.license +
            params.conditionAdjustmentA +
            params.conditionAdjustmentB +
            params.conditionAdjustmentC +
            salvageValue).toFixed(2);
        params.settlementBeforeSalvage = parseFloat((params.lesserAdjustedAmount -
            params.totalDeduction).toFixed(2));
        params.lesserPayofforAmortized = (Math.min(params.maximunBenefitLimit, params.settlementBeforeSalvage)).toFixed(2);
        params.gapsettlement = parseFloat(params.lesserPayofforAmortized).toFixed(2);
        return params;
    };
    debitcancelService.prototype.RATE = function (periods, payment, present, future, type, guess) {
        guess = guess === undefined ? 0.01 : guess;
        future = future === undefined ? 0 : future;
        type = type === undefined ? 0 : type;
        periods = this.parseNumber(periods);
        payment = this.parseNumber(payment);
        present = this.parseNumber(present);
        future = this.parseNumber(future);
        type = this.parseNumber(type);
        guess = this.parseNumber(guess);
        var epsMax = 1e-10;
        var iterMax = 20;
        var rate = guess;
        type = type ? 1 : 0;
        for (var i = 0; i < iterMax; i++) {
            if (rate <= -1) {
                return true;
            }
            var y = void 0, f = void 0;
            if (Math.abs(rate) < epsMax) {
                y = present * (1 + periods * rate) + payment * (1 + rate * type) * periods + future;
            }
            else {
                f = Math.pow(1 + rate, periods);
                y = present * f + payment * (1 / rate + type) * (f - 1) + future;
            }
            if (Math.abs(y) < epsMax) {
                return rate;
            }
            var dy = void 0;
            if (Math.abs(rate) < epsMax) {
                dy = present * periods + payment * type * periods;
            }
            else {
                f = Math.pow(1 + rate, periods);
                var df = periods * Math.pow(1 + rate, periods - 1);
                dy = present * df + payment * (1 / rate + type) * df + payment * (-1 / (rate * rate)) * (f - 1);
            }
            rate -= y / dy;
        }
        return rate;
    };
    ;
    debitcancelService.prototype.FV = function (rate, nper, pmt, pv, type) {
        var pow = Math.pow(1 + rate, nper);
        var fv;
        if (rate) {
            fv = (pmt * (1 + rate * type) * (1 - pow)) / rate - pv * pow;
        }
        else {
            fv = -1 * (pv + pmt * nper);
        }
        return fv.toFixed(2);
    };
    debitcancelService.prototype.parseNumber = function (string) {
        if (string instanceof Error) {
            return string;
        }
        if (string === undefined || string === null || string === '') {
            return 0;
        }
        if (typeof string === 'boolean') {
            string = +string;
        }
        if (!isNaN(string)) {
            return parseFloat(string);
        }
        return string;
    };
    debitcancelService.ngInjectableDef = i0.defineInjectable({ factory: function debitcancelService_Factory() { return new debitcancelService(i0.inject(i1.HttpClient)); }, token: debitcancelService, providedIn: "root" });
    return debitcancelService;
}());
export { debitcancelService };
