<form [formGroup]="rejectForm" (ngSubmit)="rejectRequest()">
    <div class="modal-header">
        <h4 class="modal-title text-capitalize">{{rejectType=='denied'?'Deny':rejectType}} Claim</h4>
    </div>
    <div class="modal-body">
        <div class="form-group row">
            <p class="col-12 mb-0">
            </p>
        </div>
        <div class="form-group row">
            <app-required-indication class="col-12" *ngIf="rejectType=='reject'" [control]="rejectForm.controls['message']" [label]="'What is reason behind reject claim ?'">
            </app-required-indication>
            <app-required-indication class="col-12" *ngIf="rejectType=='denied'" [control]="rejectForm.controls['message']" [label]="'What is reason behind denying claim ?'">
            </app-required-indication>
            <app-required-indication class="col-12" *ngIf="rejectType=='void'" [control]="rejectForm.controls['message']" [label]="'Reason for Voiding'">
            </app-required-indication>
            <div class="col-12">
                <app-form-error-wrapper class="col-3 w-30" [control]="rejectForm.get('message')" [controlName]="'Enter Message'" [apiErrorType]="'message'">
                    <textarea type="text" name="reason" rows="5" class="form-control" formControlName="message"></textarea>
                </app-form-error-wrapper>
            </div>
        </div>
        <div class="form-group row" *ngIf="product !== 'gap' && product !== 'debitcancel' && product !== 'reimburse' && product !== 'theft' && rejectType!='void'">
            <app-required-indication class="col-12" [control]="rejectForm.controls['amountApproved']"
                [label]="'Approved Amount'">
            </app-required-indication>
            <div class="col-12">
                <app-form-error-wrapper class="col-3 w-30" [control]="rejectForm.get('amountApproved')"
                    [controlName]="'Enter amountApproved'" [apiErrorType]="'amountApproved'">
                    <input type="text" name="amountApproved" class="form-control" formControlName="amountApproved"
                        placeholder="$0.00" currencyMask [options]="{ allowNegative: false }" autocomplete="off" />
                </app-form-error-wrapper>
            </div>
        </div>

        <div class="form-group row" *ngIf="rejectType=='reject'">
            <div class="col-md-12">
                <div class="custom-control custom-checkbox ">
                    <input id="rejectEdit" class="custom-control-input" formControlName="rejectEdit"
                      [checked]="rejectForm.get('rejectEdit').value" type="checkbox" />
                    <label for="rejectEdit" class="custom-control-label pos-abs">Allow Resubmission</label>
                  </div>
            </div>
        </div>

        <div class="form-group row" style="display:none">
            <label class="col-12">Admin Id ?</label>
            <div class="col-12">
                <app-form-error-wrapper class="col-3 w-30" [control]="rejectForm.get('adminId')" [controlName]="'Enter adminId'" [apiErrorType]="'adminId'">
                    <input type="text" name="adminId" class="form-control" formControlName="adminId">
                </app-form-error-wrapper>
            </div>
        </div>



    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">
      Cancel
    </button>
        <button class="btn btn-danger" type="submit" [disabled]="isLoading">
            <app-button-loader [isLoading]="isLoading" *ngIf="rejectType=='reject'" [label]="'Reject Claim'"></app-button-loader>
            <app-button-loader [isLoading]="isLoading"  *ngIf="rejectType=='denied'" [label]="'Deny Claim'"></app-button-loader>
            <app-button-loader [isLoading]="isLoading" *ngIf="rejectType=='void'" [label]="'Void Claim'"></app-button-loader>
    </button>
    </div>
</form>