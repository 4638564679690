/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./full.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../header-navigation/navigation.component.ngfactory";
import * as i4 from "../../header-navigation/navigation.component";
import * as i5 from "@angular/router";
import * as i6 from "../../../core/authentication/authentication.service";
import * as i7 from "../../../core/service/navigation.service";
import * as i8 from "@ng-bootstrap/ng-bootstrap";
import * as i9 from "../../../core/service/util.service";
import * as i10 from "ngx-perfect-scrollbar";
import * as i11 from "../../sidebar/sidebar.component.ngfactory";
import * as i12 from "../../sidebar/sidebar.component";
import * as i13 from "../../breadcrumb/breadcrumb.component.ngfactory";
import * as i14 from "../../breadcrumb/breadcrumb.component";
import * as i15 from "./full.component";
var styles_FullComponent = [i0.styles];
var RenderType_FullComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FullComponent, data: {} });
export { RenderType_FullComponent as RenderType_FullComponent };
export function View_FullComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["id", "main-wrapper"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { "defaultdark": 0, "greendark": 1, "reddark": 2, "bluedark": 3, "purpledark": 4, "megnadark": 5, "default": 6, "green": 7, "red": 8, "blue": 9, "purple": 10, "megna": 11, "mini-sidebar": 12, "dark-theme": 13 }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-navigation", [], null, null, null, i3.View_NavigationComponent_0, i3.RenderType_NavigationComponent)), i1.ɵdid(4, 4440064, null, 0, i4.NavigationComponent, [i5.Router, i6.AuthenticationService, i5.ActivatedRoute, i7.NavigationService, i8.NgbModal, i9.UtilService], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "aside", [["class", "left-sidebar hide"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "scroll-sidebar"]], null, [[null, "ps-scroll-y"], [null, "ps-scroll-x"], [null, "ps-scroll-up"], [null, "ps-scroll-down"], [null, "ps-scroll-left"], [null, "ps-scroll-right"], [null, "ps-y-reach-end"], [null, "ps-y-reach-start"], [null, "ps-x-reach-end"], [null, "ps-x-reach-start"]], function (_v, en, $event) { var ad = true; if (("ps-scroll-y" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).psScrollY($event) !== false);
        ad = (pd_0 && ad);
    } if (("ps-scroll-x" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).psScrollX($event) !== false);
        ad = (pd_1 && ad);
    } if (("ps-scroll-up" === en)) {
        var pd_2 = (i1.ɵnov(_v, 7).psScrollUp($event) !== false);
        ad = (pd_2 && ad);
    } if (("ps-scroll-down" === en)) {
        var pd_3 = (i1.ɵnov(_v, 7).psScrollDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("ps-scroll-left" === en)) {
        var pd_4 = (i1.ɵnov(_v, 7).psScrollLeft($event) !== false);
        ad = (pd_4 && ad);
    } if (("ps-scroll-right" === en)) {
        var pd_5 = (i1.ɵnov(_v, 7).psScrollRight($event) !== false);
        ad = (pd_5 && ad);
    } if (("ps-y-reach-end" === en)) {
        var pd_6 = (i1.ɵnov(_v, 7).psReachEndY($event) !== false);
        ad = (pd_6 && ad);
    } if (("ps-y-reach-start" === en)) {
        var pd_7 = (i1.ɵnov(_v, 7).psReachStartY($event) !== false);
        ad = (pd_7 && ad);
    } if (("ps-x-reach-end" === en)) {
        var pd_8 = (i1.ɵnov(_v, 7).psReachEndX($event) !== false);
        ad = (pd_8 && ad);
    } if (("ps-x-reach-start" === en)) {
        var pd_9 = (i1.ɵnov(_v, 7).psReachStartX($event) !== false);
        ad = (pd_9 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 999424, null, 0, i10.PerfectScrollbarDirective, [i1.NgZone, i1.KeyValueDiffers, i1.ElementRef, i1.PLATFORM_ID, [2, i10.PERFECT_SCROLLBAR_CONFIG]], { config: [0, "config"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-sidebar", [], null, null, null, i11.View_SidebarComponent_0, i11.RenderType_SidebarComponent)), i1.ɵdid(9, 245760, null, 0, i12.SidebarComponent, [], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 5, "div", [["class", "page-wrapper ml-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [["class", "container-fluid p-10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "app-breadcrumb", [], null, null, null, i13.View_BreadcrumbComponent_0, i13.RenderType_BreadcrumbComponent)), i1.ɵdid(13, 245760, null, 0, i14.BreadcrumbComponent, [i5.Router, i5.ActivatedRoute], null, null), (_l()(), i1.ɵeld(14, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(15, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 1, [(_co.color == "defaultdark"), (_co.color == "greendark"), (_co.color == "reddark"), (_co.color == "bluedark"), (_co.color == "purpledark"), (_co.color == "megnadark"), (_co.color == "default"), (_co.color == "green"), (_co.color == "red"), (_co.color == "blue"), (_co.color == "purple"), (_co.color == "megna"), _co.showMinisidebar, _co.showDarktheme]); _ck(_v, 1, 0, currVal_0); _ck(_v, 4, 0); var currVal_1 = _co.config; _ck(_v, 7, 0, currVal_1); _ck(_v, 9, 0); _ck(_v, 13, 0); _ck(_v, 15, 0); }, null); }
export function View_FullComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-full-layout", [], null, null, null, View_FullComponent_0, RenderType_FullComponent)), i1.ɵdid(1, 114688, null, 0, i15.FullComponent, [i5.Router, i5.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FullComponentNgFactory = i1.ɵccf("app-full-layout", i15.FullComponent, View_FullComponent_Host_0, {}, {}, []);
export { FullComponentNgFactory as FullComponentNgFactory };
