var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from '@app/core/service/customer.service';
import { finalize, map, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { UtilService, LOCAL_VAR } from '@app/core';
import { Observable, Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { ClaimHelpComponent } from '@app/shared/modals/claim-help/claim-help.component';
import { ClaimService } from '@app/core/service/claim.service';
import { environment } from '@env/environment.local';
var DealergroupCreateComponent = /** @class */ (function () {
    function DealergroupCreateComponent(modalService, formBuilder, route, router, customerService, toastrService, utilService, Claims, authenticationService) {
        var _this = this;
        this.modalService = modalService;
        this.formBuilder = formBuilder;
        this.route = route;
        this.router = router;
        this.customerService = customerService;
        this.toastrService = toastrService;
        this.utilService = utilService;
        this.Claims = Claims;
        this.authenticationService = authenticationService;
        this.isLoading = false;
        this.isreivewMode = false;
        this.isEditMode = false;
        this.dealerGroupId = null;
        this.baseUrl = '';
        this.selectedCustomer = null;
        this.dealerGroupList = [];
        this.invalidDealer = true;
        this.focus$ = new Subject();
        this.click$ = new Subject();
        //formatter = (result: string) => result.toUpperCase();
        this.dealers = function (text$) {
            return text$.pipe(debounceTime(200), distinctUntilChanged(), map(function (term) {
                return term.length < 3
                    ? []
                    : _this.dealersList
                        .filter(function (v) { return v.name.toLowerCase().indexOf(term.toLowerCase()) > -1; })
                        .slice(0, 10);
            }));
        };
        this.formatter = function (x) { return x.name; };
        this.user = this.authenticationService.credentials.user;
        this.loggedInUserType = this.user.userRoleId.slug;
        this.baseUrl = environment.s3Url;
        this.selectedCustomer = localStorage.getItem(LOCAL_VAR.selectedCustomer);
        this.selectedCustomer = JSON.parse(this.selectedCustomer);
        if (this.router.url.indexOf('review') !== -1) {
            this.isreivewMode = true;
        }
        else {
            this.isreivewMode = false;
        }
        if (this.router.url.indexOf('edit') !== -1) {
            this.isEditMode = true;
        }
        else {
            this.isEditMode = false;
        }
        this.route.queryParams.pipe().subscribe(function (params) {
            _this.queryParams = params;
        });
        if (this.isEditMode && this.route.snapshot.params['dealerGroupId']) {
            this.dealerGroupId = this.route.snapshot.params['dealerGroupId'];
        }
        if (this.dealerGroupId) {
            this.getDealerGroup(this.dealerGroupId);
        }
        this.createForm();
        this.getDealers();
    }
    DealergroupCreateComponent.prototype.ngOnInit = function () {
        if (this.loggedInUserType === 'dowcview' || this.loggedInUserType === 'adminview') {
            this.router.navigate(['/products'], { replaceUrl: true });
        }
    };
    DealergroupCreateComponent.prototype.createForm = function () {
        var _this = this;
        this.dealerGroupForm = this.formBuilder.group({
            name: [
                this.dealerGroup ? this.dealerGroup.name : '',
                [Validators.required]
            ],
            dealers: [
                this.dealerGroup ? this.dealerGroup.dealers : '',
                [Validators.required]
            ],
            website: [this.dealerGroup ? this.dealerGroup.website : ''],
            appName: [this.dealerGroup ? this.dealerGroup.appName : ''],
            domain: [this.dealerGroup ? this.dealerGroup.domain : ''],
            baseurl: [this.dealerGroup ? this.dealerGroup.baseurl : ''],
            portal: [this.dealerGroup ? this.dealerGroup.portal : ''],
            appLogo: [this.dealerGroup ? this.dealerGroup.appLogo : ''],
            logo: [this.dealerGroup ? this.dealerGroup.logo : ''],
            emailer: [this.dealerGroup ? this.dealerGroup.emailer : ''],
            mailerKey: [this.dealerGroup ? this.dealerGroup.mailerKey : ''],
            senderMail: [this.dealerGroup ? this.dealerGroup.senderMail : ''],
            notificationSenderMail: [this.dealerGroup ? this.dealerGroup.notificationSenderMail : ''],
            supportMail: [this.dealerGroup ? this.dealerGroup.supportMail : ''],
            HelpMail: [this.dealerGroup ? this.dealerGroup.HelpMail : ''],
            notifyClaimMail: [this.dealerGroup ? this.dealerGroup.notifyClaimMail : ''],
            dealersNotifyMail: [this.dealerGroup ? this.dealerGroup.dealersNotifyMail : ''],
            notifyToDefault: [(this.dealerGroup && this.dealerGroup.notifyToDefault === "enable") ? true : false],
            defaultMail: [this.dealerGroup ? this.dealerGroup.defaultMail : ''],
            MAIL_FLAG: [(this.dealerGroup && this.dealerGroup.MAIL_FLAG === "enable") ? true : false],
            MailFooter: [this.dealerGroup ? this.dealerGroup.MailFooter : ''],
            stoneEagleAPI: [this.dealerGroup ? this.dealerGroup.stoneEagleAPI : ''],
            primaryColor: [this.dealerGroup ? this.dealerGroup.primaryColor : ''],
            secondaryColor: [this.dealerGroup ? this.dealerGroup.secondaryColor : ''],
            status: [this.dealerGroup ? this.dealerGroup.status : 'active',
                [Validators.required]
            ],
            config: [this.dealerGroup ? this.dealerGroup.config : 'inactive',
                [Validators.required]
            ],
            dealer: [''],
        });
        //this.formControlsValueChanges();
        if (this.dealerGroup) {
            this.getDealersList();
        }
        this.dealerGroupForm.get('dealer').valueChanges.subscribe(function (values) {
            _this.checkDealer();
        });
    };
    DealergroupCreateComponent.prototype.ngAfterViewInit = function () {
        $("app-required-indication").each(function (index, element) {
            var text = element.innerHTML;
            element.innerHTML = text.replace('\n<!--', '<!--');
        });
        this.utilService.updateDomainCss();
    };
    DealergroupCreateComponent.prototype.formControlsValueChanges = function () {
    };
    DealergroupCreateComponent.prototype.submitData = function () {
        var _this = this;
        // this.onSubmitStepChange.emit();
        // if (this.selectedCustomer) {
        // this.isLoading = true;
        var payload = this.dealerGroupForm.value;
        payload = this.utilService.removeEmpty(payload);
        payload.MAIL_FLAG = (payload.MAIL_FLAG) ? 'enable' : 'disable';
        payload.notifyToDefault = (payload.notifyToDefault) ? 'enable' : 'disable';
        delete payload.dealer;
        if (this.dealerGroup) {
            this.customerService
                .updateDealerGroup(this.dealerGroupId, payload)
                .pipe(finalize(function () {
                _this.isLoading = false;
            }))
                .subscribe(function (response) {
                _this.toastrService.info('Dealer Group Created.');
                _this.router.navigate(['/dealergroup']);
            });
        }
        else {
            this.customerService
                .createDealerGroup(payload)
                .pipe(finalize(function () {
                _this.isLoading = false;
            }))
                .subscribe(function (response) {
                _this.toastrService.info('Dealer Group updated');
                _this.router.navigate(['/dealergroup']);
            });
        }
    };
    DealergroupCreateComponent.prototype.getDealers = function () {
        var _this = this;
        this.customerService
            .getallDealers()
            .pipe(finalize(function () {
            _this.isLoading = false;
        }))
            .subscribe(function (response) {
            if (response && response.records) {
                _this.dealersList = response.records;
                _this.getDealersList();
            }
        });
    };
    DealergroupCreateComponent.prototype.getDealerGroup = function (dealerGroupId) {
        var _this = this;
        this.customerService
            .getDealerGroupDetail(dealerGroupId)
            .subscribe(function (response) {
            if (response.records) {
                _this.dealerGroup = response.records;
                _this.createForm();
                _this.getDealersList();
            }
            else if (_this.isEditMode) {
                _this.router.navigate(['/dealergroup']);
            }
        });
    };
    DealergroupCreateComponent.prototype.cancelClick = function () {
        this.router.navigate(['/dealergroup']);
    };
    DealergroupCreateComponent.prototype.openHelpPopup = function () {
        return __awaiter(this, void 0, void 0, function () {
            var modalRef;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalService.open(ClaimHelpComponent)];
                    case 1:
                        modalRef = _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    DealergroupCreateComponent.prototype.omit_special_char = function (event) {
        var k;
        k = event.charCode; //         k = event.keyCode;  (Both can be used)
        return ((k > 64 && k < 91) || (k > 96 && k <= 122) || k == 8 || k == 32 || (k >= 48 && k <= 57));
    };
    DealergroupCreateComponent.prototype.omit_special_Mailchar = function (event) {
        var k;
        k = event.charCode; //         k = event.keyCode;  (Both can be used)
        return ((k > 64 && k < 91) || (k > 96 && k <= 122) || k === 46 || k === 64 || k == 8 || k == 32 || (k >= 48 && k <= 57));
    };
    DealergroupCreateComponent.prototype.checkDealer = function () {
        if (typeof this.dealerGroupForm.controls['dealer'].value !== 'object') {
            this.invalidDealer = true;
        }
        else {
            this.invalidDealer = false;
        }
    };
    DealergroupCreateComponent.prototype.addDealer = function () {
        var _this = this;
        if (typeof this.dealerGroupForm.controls['dealer'].value === 'object') {
            var dealer_1 = this.dealerGroupForm.controls['dealer'].value;
            this.customerService
                .checkDealerGroupByDealer(dealer_1._id)
                .subscribe(function (response) {
                //  console.log(response)
                if (response && response.data) {
                    _this.toastrService.warning(dealer_1.name + ' already added in ' + response.data.name + ' Dealer');
                    _this.dealerGroupForm.controls.dealer.setValue('');
                }
                else {
                    _this.addAfterVerify();
                }
            });
        }
    };
    DealergroupCreateComponent.prototype.addAfterVerify = function () {
        if (typeof this.dealerGroupForm.controls['dealer'].value === 'object') {
            var dealer_2 = this.dealerGroupForm.controls['dealer'].value;
            var avail_1 = true;
            this.dealerGroupList.forEach(function (val, index) {
                if (dealer_2._id.toString() === val._id.toString()) {
                    avail_1 = false;
                }
            });
            if (avail_1) {
                this.dealerGroupList.push(this.dealerGroupForm.controls['dealer'].value);
            }
            this.dealerGroupForm.controls.dealer.setValue('');
        }
        var dealers = [];
        this.dealerGroupList.forEach(function (val, index) {
            dealers.push({ dealerId: val._id });
        });
        this.dealerGroupForm.controls.dealers.setValue(dealers);
    };
    DealergroupCreateComponent.prototype.removeDealer = function (index) {
        this.dealerGroupForm.markAsDirty();
        this.dealerGroupList.splice(index, 1);
        ;
        var dealers = [];
        this.dealerGroupList.forEach(function (val, index) {
            dealers.push({ dealerId: val._id });
        });
        this.dealerGroupForm.controls.dealers.setValue(dealers);
    };
    DealergroupCreateComponent.prototype.getDealersList = function () {
        if (this.isEditMode &&
            this.dealerGroup &&
            this.dealerGroup.dealers &&
            this.dealerGroup.dealers.length > 0 && this.dealerGroupList.length === 0 && this.dealersList) {
            var list_1 = this.dealersList;
            var dealers_1 = [];
            this.dealerGroup.dealers.forEach(function (val, index) {
                list_1.forEach(function (dealer, index) {
                    if (dealer._id && val.dealerId && (dealer._id.toString() === val.dealerId.toString())) {
                        dealers_1.push(dealer);
                    }
                });
            });
            this.dealerGroupList = dealers_1;
        }
    };
    return DealergroupCreateComponent;
}());
export { DealergroupCreateComponent };
