import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-no-data-msg',
  templateUrl: './no-data-msg.component.html',
  styleUrls: ['./no-data-msg.component.scss']
})
export class NoDataMsgComponent implements OnInit {
  @Input()
  message = 'No Data Found';

  constructor() {}

  ngOnInit() {}
}
