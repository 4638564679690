var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { STATES } from '@app/core/constants';
import { DateTime } from 'luxon';
import { CustomerService } from '@app/core/service/customer.service';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { UtilService, LOCAL_VAR } from '@app/core';
import { NgbTypeaheadConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { ClaimHelpComponent } from '@app/shared/modals/claim-help/claim-help.component';
import { debitcancelService } from '@app/core/service/debitcancel.service';
import { ClaimService } from '@app/core/service/claim.service';
import { environment } from '@env/environment.local';
var DebitCancellationCalculationComponent = /** @class */ (function () {
    function DebitCancellationCalculationComponent(modalService, formBuilder, route, router, customerService, toastrService, utilService, claimService, Claims, authenticationService, config) {
        var _this = this;
        this.modalService = modalService;
        this.formBuilder = formBuilder;
        this.route = route;
        this.router = router;
        this.customerService = customerService;
        this.toastrService = toastrService;
        this.utilService = utilService;
        this.claimService = claimService;
        this.Claims = Claims;
        this.authenticationService = authenticationService;
        this.isLoading = false;
        this.states = STATES;
        this.isreivewMode = false;
        this.isEditMode = false;
        this.isAdmin = null;
        this.claimId = null;
        this.baseUrl = '';
        this.selectedCustomer = null;
        this.show_claims = false;
        this.user = this.authenticationService.credentials.user;
        this.loggedInUserType = this.user.userRoleId.slug;
        this.baseUrl = environment.s3Url;
        this.selectedCustomer = localStorage.getItem(LOCAL_VAR.selectedCustomer);
        this.selectedCustomer = JSON.parse(this.selectedCustomer);
        if (this.router.url.indexOf('debitcancellation-cal') !== -1) {
            this.isEditMode = true;
        }
        else {
            this.isEditMode = false;
        }
        this.route.queryParams.pipe().subscribe(function (params) {
            _this.queryParams = params;
        });
        if (this.isEditMode && this.route.snapshot.params['claimId']) {
            this.claimId = this.route.snapshot.params['claimId'];
        }
        if (this.claimId) {
            this.getClaimbyId(this.claimId);
        }
        this.createForm();
        this.handleOnChanges();
    }
    DebitCancellationCalculationComponent.prototype.ngOnInit = function () {
        if (this.loggedInUserType === 'dowcview' || this.loggedInUserType === 'adminview') {
            this.router.navigate(['/products'], { replaceUrl: true });
        }
    };
    DebitCancellationCalculationComponent.prototype.createForm = function () {
        if (this.claimData && (this.loggedInUserType === 'dowcsuperadmin' || this.loggedInUserType === 'superadmin')) {
            this.isAdmin = true;
        }
        this.debitForm = this.formBuilder.group({
            claimId: [this.claimData ? this.claimData._id : ''],
            contractNumber: [
                {
                    value: this.claimData
                        ? this.claimData.contractNumber
                            ? this.claimData.contractNumber
                            : 0
                        : null,
                    disabled: true
                },
                [Validators.required]
            ],
            claimDate: [
                {
                    value: this.claimData && this.claimData.claimDate
                        ? typeof this.claimData.claimDate === 'object'
                            ? this.claimData.claimDate
                            : DateTime.fromISO(this.claimData.claimDate).toObject()
                        : null,
                    disabled: true
                },
                Validators.required
            ],
            gapCalculationApproval: [
                this.claimData
                    ? this.claimData.gapCalculationApproval
                        ? this.claimData.gapCalculationApproval
                        : ''
                    : '',
                [Validators.required]
            ],
            agreementPurchasePrice: [
                this.claimData ? this.claimData.agreementPurchasePrice : '',
                [Validators.required]
            ],
            agreementPurchaseDate: [
                this.claimData
                    ? this.claimData.agreementPurchaseDate
                        ? DateTime.fromISO(this.claimData.agreementPurchaseDate).toObject()
                        : null
                    : '',
                [Validators.required]
            ],
            maximunBenefitLimit: [
                this.claimData
                    ? this.claimData.maximunBenefitLimit
                        ? this.claimData.maximunBenefitLimit
                        : 0
                    : null,
                [Validators.required]
            ],
            maximunBenefitLimit1: [
                this.claimData
                    ? this.claimData.maximunBenefitLimit
                        ? this.claimData.maximunBenefitLimit
                        : 0
                    : null,
                [Validators.required]
            ],
            vehiclePurchasePrice: [
                this.claimData ? this.claimData.vehiclePurchasePrice : '',
                [Validators.required]
            ],
            dateOfFirstPayment: [
                this.claimData
                    ? this.claimData.dateOfFirstPayment
                        ? DateTime.fromISO(this.claimData.dateOfFirstPayment).toObject()
                        : null
                    : '',
                this.loggedInUserType !== 'customer' ? [Validators.required] : ''
            ],
            APR: [
                this.claimData ? this.claimData.APR : 0,
                this.loggedInUserType !== 'customer' ? Validators.compose([
                    Validators.required,
                    Validators.minLength(0),
                    Validators.maxLength(3)
                ]) : ''
            ],
            // monthlyPaymentAmount: [
            //   this.claimData ? this.claimData.monthlyPaymentAmount : '',
            //   this.loggedInUserType !=='customer' ? [Validators.required] : ''
            // ],
            amountFinanced: [
                this.claimData ? this.claimData.amountFinanced : '',
                this.loggedInUserType !== 'customer' ? [Validators.required] : ''
            ],
            financingContractType: this.formBuilder.group({
                retailInstallmentSales: [
                    this.claimData ? this.claimData.financingContractType.retailInstallmentSales : false
                ],
                lease: [
                    this.claimData ? this.claimData.financingContractType.lease : false
                ]
            }),
            totalOfAllPayement: [
                this.claimData ? this.claimData.totalOfAllPayement : '',
                this.loggedInUserType !== 'customer' ? [Validators.required] : ''
            ],
            salvageIncludeValue: [
                {
                    value: this.claimData
                        ? this.claimData.salvageIncludeValue
                            ? this.claimData.salvageIncludeValue
                            : 0
                        : null,
                    disabled: true
                }
            ],
            balloonPayment: [
                this.claimData
                    ? this.claimData.balloonPayment
                        ? this.claimData.balloonPayment
                        : 0
                    : null,
                [Validators.required]
            ],
            dateOfFirstDue: [
                this.claimData
                    ? this.claimData.dateOfFirstDue
                        ? DateTime.fromISO(this.claimData.dateOfFirstDue).toObject()
                        : null
                    : '',
                [Validators.required]
            ],
            financeAPR: [
                this.claimData
                    ? this.claimData.financeAPR
                        ? this.claimData.financeAPR
                        : 0
                    : null,
                [Validators.required]
            ],
            numberOfPayments: [
                this.claimData
                    ? this.claimData.numberOfPayments
                        ? this.claimData.numberOfPayments
                        : 0
                    : null,
                [Validators.required]
            ],
            frequencyOfPayments: [
                this.claimData
                    ? this.claimData.frequencyOfPayments
                        ? this.claimData.frequencyOfPayments
                        : 12
                    : null,
                [Validators.required]
            ],
            includeSalvage: [
                this.claimData
                    ? this.claimData.includeSalvage
                        ? this.claimData.includeSalvage
                        : 'Yes'
                    : 'Yes',
                [Validators.required]
            ],
            accessories: [
                this.claimData
                    ? this.claimData.accessories
                        ? this.claimData.accessories
                        : 0
                    : null,
                [Validators.required]
            ],
            serviceContract: [
                this.claimData
                    ? this.claimData.serviceContract
                        ? this.claimData.serviceContract
                        : 0
                    : null,
                [Validators.required]
            ],
            closingFee: [
                this.claimData
                    ? this.claimData.closingFee
                        ? this.claimData.closingFee
                        : 0
                    : null,
                [Validators.required]
            ],
            titleFee: [
                this.claimData
                    ? this.claimData.titleFee
                        ? this.claimData.titleFee
                        : 0
                    : null,
                [Validators.required]
            ],
            salesTax: [
                this.claimData
                    ? this.claimData.salesTax
                        ? this.claimData.salesTax
                        : 0
                    : null,
                [Validators.required]
            ],
            license: [
                this.claimData
                    ? this.claimData.license
                        ? this.claimData.license
                        : 0
                    : null,
                [Validators.required]
            ],
            accessories1: [
                this.claimData
                    ? this.claimData.accessories
                        ? this.claimData.accessories
                        : 0
                    : null,
                [Validators.required]
            ],
            serviceContract1: [
                this.claimData
                    ? this.claimData.serviceContract
                        ? this.claimData.serviceContract
                        : 0
                    : null,
                [Validators.required]
            ],
            closingFee1: [
                this.claimData
                    ? this.claimData.closingFee
                        ? this.claimData.closingFee
                        : 0
                    : null,
                [Validators.required]
            ],
            titleFee1: [
                this.claimData
                    ? this.claimData.titleFee
                        ? this.claimData.titleFee
                        : 0
                    : null,
                [Validators.required]
            ],
            salesTax1: [
                this.claimData
                    ? this.claimData.salesTax
                        ? this.claimData.salesTax
                        : 0
                    : null,
                [Validators.required]
            ],
            license1: [
                this.claimData
                    ? this.claimData.license
                        ? this.claimData.license
                        : 0
                    : null,
                [Validators.required]
            ],
            actualPayoffAmount: [
                this.claimData
                    ? this.claimData.actualPayoffAmount
                        ? this.claimData.actualPayoffAmount
                        : 0
                    : null,
                [Validators.required]
            ],
            conditionAdjustmentA: [
                this.claimData
                    ? this.claimData.conditionAdjustmentA
                        ? this.claimData.conditionAdjustmentA
                        : 0
                    : null,
                [Validators.required]
            ],
            conditionAdjustmentB: [
                this.claimData
                    ? this.claimData.conditionAdjustmentB
                        ? this.claimData.conditionAdjustmentB
                        : 0
                    : null,
                [Validators.required]
            ],
            conditionAdjustmentC: [
                this.claimData
                    ? this.claimData.conditionAdjustmentC
                        ? this.claimData.conditionAdjustmentC
                        : 0
                    : null,
                [Validators.required]
            ],
            conditionAdjustmentA1: [
                {
                    value: this.claimData
                        ? this.claimData.conditionAdjustmentA
                            ? this.claimData.conditionAdjustmentA
                            : 0
                        : null,
                    disabled: true
                },
                [Validators.required]
            ],
            conditionAdjustmentB1: [
                {
                    value: this.claimData
                        ? this.claimData.conditionAdjustmentB
                            ? this.claimData.conditionAdjustmentB
                            : 0
                        : null,
                    disabled: true
                },
                [Validators.required]
            ],
            conditionAdjustmentC1: [
                {
                    value: this.claimData
                        ? this.claimData.conditionAdjustmentC
                            ? this.claimData.conditionAdjustmentC
                            : 0
                        : null,
                    disabled: true
                },
                [Validators.required]
            ],
            projectedPrincipalBalance: [
                {
                    value: this.claimData
                        ? this.claimData.projectedPrincipalBalance
                            ? this.claimData.projectedPrincipalBalance
                            : 0
                        : null,
                    disabled: true
                },
                [Validators.required]
            ],
            estimatedPrincipalPaid: [
                {
                    value: this.claimData
                        ? this.claimData.estimatedPrincipalPaid
                            ? this.claimData.estimatedPrincipalPaid
                            : 0
                        : null,
                    disabled: true
                },
                [Validators.required]
            ],
            numberofPaymentsperYear: [
                {
                    value: this.claimData
                        ? this.claimData.numberofPaymentsperYear
                            ? this.claimData.numberofPaymentsperYear
                            : 0
                        : null,
                    disabled: true
                },
                [Validators.required]
            ],
            numberofPaymentsElapsed: [
                {
                    value: this.claimData
                        ? this.claimData.numberofPaymentsElapsed
                            ? this.claimData.numberofPaymentsElapsed
                            : 0
                        : null,
                    disabled: true
                },
                [Validators.required]
            ],
            realizedAPR: [
                {
                    value: this.claimData
                        ? this.claimData.realizedAPR
                            ? this.claimData.realizedAPR
                            : 0
                        : null,
                    disabled: true
                },
                [Validators.required]
            ],
            paymentsPerPeriod: [
                this.claimData
                    ? this.claimData.paymentsPerPeriod
                        ? this.claimData.paymentsPerPeriod
                        : this.claimData.monthlyPaymentAmount
                    : null,
                [Validators.required]
            ],
            amountFinancedActual: [
                {
                    value: this.claimData
                        ? this.claimData.amountFinancedActual
                            ? this.claimData.amountFinancedActual
                            : 0
                        : null,
                    disabled: true
                },
                [Validators.required]
            ],
            amountFinancedActual1: [
                {
                    value: this.claimData
                        ? this.claimData.amountFinancedActual1
                            ? this.claimData.amountFinancedActual1
                            : 0
                        : null,
                    disabled: true
                },
                [Validators.required]
            ],
            actualPayoffAmount1: [
                {
                    value: this.claimData
                        ? this.claimData.actualPayoffAmount
                            ? this.claimData.actualPayoffAmount
                            : 0
                        : null,
                    disabled: true
                },
                [Validators.required]
            ],
            lesserAdjustedAmount: [
                {
                    value: this.claimData
                        ? this.claimData.lesserAdjustedAmount
                            ? this.claimData.lesserAdjustedAmount
                            : 0
                        : null,
                    disabled: true
                },
                [Validators.required]
            ],
            lesserAdjustedAmount1: [
                {
                    value: this.claimData
                        ? this.claimData.lesserAdjustedAmount
                            ? this.claimData.lesserAdjustedAmount
                            : 0
                        : null,
                    disabled: true
                },
                [Validators.required]
            ],
            liabilityInsuranceSettlement: [
                {
                    value: this.claimData
                        ? this.claimData.liabilityInsuranceSettlement
                            ? this.claimData.liabilityInsuranceSettlement
                            : 0
                        : null,
                    disabled: false
                },
                [Validators.required]
            ],
            comprehensiveInsuranceSettlement: [
                {
                    value: this.claimData
                        ? this.claimData.comprehensiveInsuranceSettlement
                            ? this.claimData.comprehensiveInsuranceSettlement
                            : 0
                        : null,
                    disabled: false
                },
                [Validators.required]
            ],
            liabilityInsuranceSettlement1: [
                {
                    value: this.claimData
                        ? this.claimData.liabilityInsuranceSettlement
                            ? this.claimData.liabilityInsuranceSettlement
                            : 0
                        : null,
                    disabled: true
                },
                [Validators.required]
            ],
            comprehensiveInsuranceSettlement1: [
                {
                    value: this.claimData
                        ? this.claimData.comprehensiveInsuranceSettlement
                            ? this.claimData.comprehensiveInsuranceSettlement
                            : 0
                        : null,
                    disabled: true
                },
                [Validators.required]
            ],
            Dedectibles: [
                {
                    value: this.claimData
                        ? this.claimData.Dedectibles
                            ? this.claimData.Dedectibles
                            : 0
                        : null,
                    disabled: false
                },
                [Validators.required]
            ],
            Dedectibles1: [
                {
                    value: this.claimData
                        ? this.claimData.Dedectibles
                            ? this.claimData.Dedectibles
                            : 0
                        : null,
                    disabled: true
                },
                [Validators.required]
            ],
            totalDeduction: [
                {
                    value: this.claimData
                        ? this.claimData.totalDeduction
                            ? this.claimData.totalDeduction
                            : 0
                        : null,
                    disabled: true
                },
                [Validators.required]
            ],
            settlementBeforeSalvage: [
                {
                    value: this.claimData
                        ? this.claimData.settlementBeforeSalvage
                            ? this.claimData.settlementBeforeSalvage
                            : 0
                        : null,
                    disabled: true
                },
                [Validators.required]
            ],
            settlementBeforeSalvage1: [
                {
                    value: this.claimData
                        ? this.claimData.settlementBeforeSalvage
                            ? this.claimData.settlementBeforeSalvage
                            : 0
                        : null,
                    disabled: true
                },
                [Validators.required]
            ],
            lesserPayofforAmortized: [
                {
                    value: this.claimData
                        ? this.claimData.lesserPayofforAmortized
                            ? this.claimData.lesserPayofforAmortized
                            : 0
                        : null,
                    disabled: true
                },
                [Validators.required]
            ],
            gapsettlement: [
                {
                    value: this.claimData
                        ? this.claimData.gapsettlement
                            ? this.claimData.gapsettlement
                            : 0
                        : null,
                    disabled: true
                },
                [Validators.required]
            ]
        });
    };
    DebitCancellationCalculationComponent.prototype.ngAfterViewInit = function () {
        $("app-required-indication").each(function (index, element) {
            var text = element.innerHTML;
            element.innerHTML = text.replace('\n<!--', '<!--');
        });
        var domainconfig = JSON.parse(localStorage.getItem(LOCAL_VAR.domainconfig));
        if (domainconfig) {
            if (domainconfig.secondaryColor) {
                this.utilService.changeCssAttribute('card-header', 'background-color', domainconfig.secondaryColor);
            }
        }
    };
    DebitCancellationCalculationComponent.prototype.submitData = function () {
        var _this = this;
        // this.onSubmitStepChange.emit();
        // if (this.selectedCustomer) {
        // this.isLoading = true;
        var payload = this.debitForm.getRawValue();
        payload = this.utilService.removeEmpty(payload);
        var duplicate = [
            'contractNumber',
            'claimDate',
            'gapContractRefund1',
            // 'maximunBenefitLimit',
            // 'vehiclePurchasePrice', 
            // 'amountFinanced',
            /* 'otherProductsRefunds2',
            'otherProductsRefunds3', */
            'conditionAdjustmentA1',
            'conditionAdjustmentB1',
            'conditionAdjustmentC1',
            'comprehensiveInsuranceSettlement1',
            'liabilityInsuranceSettlement1',
            // 'dateOfFirstPayment', 
            'claimId',
            'amountFinancedActual1',
            'amountFinancedTruncated1',
            'ActualvsTruncated1',
            'lessprincipalPayment1',
            'settlementBeforeSalvage1',
            'adjustedAmountFinancednew1',
            'actualPayoffAmount1',
            /* 'actualPayoffAmount2',
            'actualPayoffAmount3', */
            'lesserAdjustedAmount1',
            'insuranceSettlement1',
            /* 'insuranceSettlement2',
            'insuranceSettlement3', */
            'Dedectibles1',
            'insurenceSettlementAllRefund1',
            'totalConditionAdjustment1'
        ];
        for (var _i = 0, duplicate_1 = duplicate; _i < duplicate_1.length; _i++) {
            var iterator = duplicate_1[_i];
            delete payload[iterator];
        }
        if (payload.dateOfFirstDue) {
            payload.dateOfFirstDue = DateTime.fromObject(payload.dateOfFirstDue).toISO();
        }
        if (payload.agreementPurchaseDate) {
            payload.agreementPurchaseDate = DateTime.fromObject(payload.agreementPurchaseDate).toISO();
        }
        if (payload.dateOfFirstPayment) {
            payload.dateOfFirstPayment = DateTime.fromObject(payload.dateOfFirstPayment).toISO();
        }
        console.log(payload);
        // return;
        if (this.claimData) {
            this.claimService
                .updateCal(this.claimId, payload)
                .pipe(finalize(function () {
                _this.isLoading = false;
            }))
                .subscribe(function (response) {
                _this.toastrService.info('Claim Submitted');
                _this.router.navigate(['/debitcancellation-list']);
            });
        }
    };
    DebitCancellationCalculationComponent.prototype.checkValidfields = function () {
        if (this.debitForm.invalid || this.isLoading) {
            this.toastrService.warning('Please fill all required fields.');
            for (var i in this.debitForm.controls) {
                this.debitForm.controls[i].markAsTouched();
                var control = this.debitForm.controls[i];
                if (control instanceof FormGroup) {
                    for (var j in control.controls)
                        control.controls[j].markAsTouched();
                    // this.debitForm.controls[i]
                }
                if (control instanceof FormArray) {
                    var formarraycontrol = control.controls[0];
                    if (formarraycontrol instanceof FormGroup) {
                        for (var i_1 in formarraycontrol.controls)
                            formarraycontrol.controls[i_1].markAsTouched();
                    }
                }
            }
            console.log(this.debitForm, 'validddd1');
        }
    };
    DebitCancellationCalculationComponent.prototype.getClaimbyId = function (DealId) {
        var _this = this;
        this.claimService
            .getById(DealId)
            .subscribe(function (response) {
            if (response.records) {
                _this.claimData = response.records;
                if (_this.claimData.status == 'voided') {
                    localStorage.setItem(LOCAL_VAR.selectedProduct, 'debitcancellation');
                    _this.router.navigate(['/debitcancellation-list']);
                }
                _this.createForm();
                _this.handleOnChanges();
                _this.DebtCalculation();
            }
            else if (_this.isEditMode) {
                localStorage.setItem(LOCAL_VAR.selectedProduct, 'debitcancellation');
                _this.router.navigate(['/debitcancellation-list']);
            }
        });
    };
    DebitCancellationCalculationComponent.prototype.cancelClick = function () {
        this.router.navigate(['/debitcancellation-list']);
    };
    DebitCancellationCalculationComponent.prototype.backClick = function () {
        this.router.navigate(['/debitcancellation-edit/' + this.claimId]);
    };
    DebitCancellationCalculationComponent.prototype.openHelpPopup = function () {
        return __awaiter(this, void 0, void 0, function () {
            var modalRef;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalService.open(ClaimHelpComponent)];
                    case 1:
                        modalRef = _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    DebitCancellationCalculationComponent.prototype.handleOnChanges = function () {
        var _this = this;
        // return;
        //new calculation - begin
        this.debitForm.get('maximunBenefitLimit').valueChanges.subscribe(function (res) {
            _this.DebtCalculation();
        });
        this.debitForm.get('amountFinanced').valueChanges.subscribe(function (res) {
            _this.DebtCalculation();
        });
        this.debitForm.get('includeSalvage').valueChanges.subscribe(function (res) {
            _this.DebtCalculation();
        });
        this.debitForm.get('financeAPR').valueChanges.subscribe(function (res) {
            _this.DebtCalculation();
        });
        this.debitForm.get('frequencyOfPayments').valueChanges.subscribe(function (res) {
            _this.DebtCalculation();
        });
        this.debitForm.get('balloonPayment').valueChanges.subscribe(function (res) {
            _this.DebtCalculation();
        });
        this.debitForm.get('dateOfFirstDue').valueChanges.subscribe(function (res) {
            _this.DebtCalculation();
        });
        this.debitForm.get('actualPayoffAmount').valueChanges.subscribe(function (res) {
            _this.DebtCalculation();
        });
        this.debitForm.get('accessories').valueChanges.subscribe(function (res) {
            _this.DebtCalculation();
        });
        this.debitForm.get('serviceContract').valueChanges.subscribe(function (res) {
            _this.DebtCalculation();
        });
        this.debitForm.get('closingFee').valueChanges.subscribe(function (res) {
            _this.DebtCalculation();
        });
        this.debitForm.get('titleFee').valueChanges.subscribe(function (res) {
            _this.DebtCalculation();
        });
        this.debitForm.get('salesTax').valueChanges.subscribe(function (res) {
            _this.DebtCalculation();
        });
        this.debitForm.get('license').valueChanges.subscribe(function (res) {
            _this.DebtCalculation();
        });
        this.debitForm.get('liabilityInsuranceSettlement').valueChanges.subscribe(function (res) {
            _this.DebtCalculation();
        });
        this.debitForm.get('comprehensiveInsuranceSettlement').valueChanges.subscribe(function (res) {
            _this.DebtCalculation();
        });
        this.debitForm.get('Dedectibles').valueChanges.subscribe(function (res) {
            _this.DebtCalculation();
        });
        this.debitForm.get('amountFinanced').valueChanges.subscribe(function (res) {
            _this.debitForm
                .get('amountFinancedActual')
                .setValue(_this.debitForm.get('amountFinanced').value);
            _this.DebtCalculation();
        });
        // this.debitForm.get('gapContractRefund').valueChanges.subscribe(res => {
        //   this.DebtCalculation();
        // });
        // this.debitForm.get('otherProductsRefunds').valueChanges.subscribe(res => {
        //   this.DebtCalculation();
        // });
        this.debitForm.get('conditionAdjustmentA').valueChanges.subscribe(function (res) {
            _this.DebtCalculation();
        });
        this.debitForm.get('conditionAdjustmentB').valueChanges.subscribe(function (res) {
            _this.DebtCalculation();
        });
        this.debitForm.get('conditionAdjustmentC').valueChanges.subscribe(function (res) {
            _this.DebtCalculation();
        });
    };
    DebitCancellationCalculationComponent.prototype.DebtCalculation = function () {
        //Calc - adjustedAmountFinanced
        this.claimData.amountFinanced = this.debitForm.get('amountFinanced').value;
        //this.claimData.principalPaymentsReduction = this.debitForm.get('principalPaymentsReduction').value;
        this.claimData.conditionAdjustmentA = this.debitForm.get('conditionAdjustmentA').value;
        this.claimData.conditionAdjustmentB = this.debitForm.get('conditionAdjustmentB').value;
        this.claimData.conditionAdjustmentC = this.debitForm.get('conditionAdjustmentC').value;
        //new calculation - begin
        this.claimData.frequencyOfPayments = this.debitForm.get('frequencyOfPayments').value;
        // console.log('valueee==',this.debitForm.get('dateOfFirstDue').value);
        this.claimData.dateOfFirstDue = this.debitForm.get('dateOfFirstDue').value;
        this.claimData.balloonPayment = this.debitForm.get('balloonPayment').value;
        this.claimData.financeAPR = this.debitForm.get('financeAPR').value;
        this.claimData.maximunBenefitLimit = this.debitForm.get('maximunBenefitLimit').value;
        this.claimData.includeSalvage = this.debitForm.get('includeSalvage').value;
        this.claimData.numberOfPayments = this.debitForm.get('numberOfPayments').value;
        this.claimData.amountFinanced = this.debitForm.get('amountFinanced').value;
        this.claimData.amountFinancedActual = this.debitForm.get('amountFinancedActual').value;
        this.claimData.accessories = this.debitForm.get('accessories').value;
        this.claimData.serviceContract = this.debitForm.get('serviceContract').value;
        this.claimData.closingFee = this.debitForm.get('closingFee').value;
        this.claimData.titleFee = this.debitForm.get('titleFee').value;
        this.claimData.salesTax = this.debitForm.get('salesTax').value;
        this.claimData.license = this.debitForm.get('license').value;
        this.claimData.actualPayoffAmount = this.debitForm.get('actualPayoffAmount').value;
        this.claimData.liabilityInsuranceSettlement = this.debitForm.get('liabilityInsuranceSettlement').value;
        this.claimData.comprehensiveInsuranceSettlement = this.debitForm.get('comprehensiveInsuranceSettlement').value;
        // this.claimData.insuranceSettlement = this.debitForm.get(
        //   'insuranceSettlement'
        // ).value;
        this.claimData.Dedectibles = this.debitForm.get('Dedectibles').value;
        this.claimData.settlementBeforeSalvage = this.debitForm.get('settlementBeforeSalvage').value;
        // this.claimData.gapContractRefund = this.debitForm.get(
        //   'gapContractRefund'
        // ).value;
        this.claimData.salvageIncludeValue = this.debitForm.get('salvageIncludeValue').value;
        this.claimData.totalDeduction = this.debitForm.get('totalDeduction').value;
        this.claimData.lesserPayofforAmortized = this.debitForm.get('lesserPayofforAmortized').value;
        this.claimData.gapCalculationApproval = this.debitForm.get('gapCalculationApproval').value;
        this.claimData.paymentsPerPeriod = this.debitForm.get('paymentsPerPeriod').value;
        this.claimData.realizedAPR = this.debitForm.get('realizedAPR').value;
        //new calculation - end
        // console.log(this.claimData.amountFinanced,'this.claimData.amountFinanced form field');
        // console.log(this.claimData.amountFinancedActual,'this.claimData.amountFinancedActual disabled');
        var calculated = this.claimService.DebtCalculation(this.claimData);
        console.log(calculated);
        isNaN(calculated.numberofPaymentsperYear)
            ? this.debitForm.get('numberofPaymentsperYear').setValue(0)
            : this.debitForm
                .get('numberofPaymentsperYear')
                .setValue(calculated.numberofPaymentsperYear);
        //this.debitForm.get('numberofPaymentsElapsed').setValue(calculated.numberofPaymentsElapsed);
        isNaN(calculated.numberofPaymentsElapsed)
            ? this.debitForm.get('numberofPaymentsElapsed').setValue(0)
            : this.debitForm
                .get('numberofPaymentsElapsed')
                .setValue(calculated.numberofPaymentsElapsed);
        isNaN(calculated.realizedAPR)
            ? this.debitForm.get('realizedAPR').setValue(0)
            : this.debitForm.get('realizedAPR').setValue(calculated.realizedAPR);
        isNaN(calculated.paymentsPerPeriod)
            ? this.debitForm.get('paymentsPerPeriod').setValue(0)
            : this.debitForm
                .get('paymentsPerPeriod')
                .setValue(calculated.paymentsPerPeriod);
        // this.debitForm.get('paymentsPerPeriod').setValue(calculated.paymentsPerPeriod);
        isNaN(calculated.projectedPrincipalBalance)
            ? this.debitForm.get('projectedPrincipalBalance').setValue(0)
            : this.debitForm
                .get('projectedPrincipalBalance')
                .setValue(calculated.projectedPrincipalBalance);
        isNaN(calculated.estimatedPrincipalPaid)
            ? this.debitForm.get('estimatedPrincipalPaid').setValue(0)
            : this.debitForm
                .get('estimatedPrincipalPaid')
                .setValue(calculated.estimatedPrincipalPaid);
        isNaN(calculated.lesserAdjustedAmount)
            ? this.debitForm.get('lesserAdjustedAmount').setValue(0)
            : this.debitForm
                .get('lesserAdjustedAmount')
                .setValue(calculated.lesserAdjustedAmount);
        isNaN(calculated.salvageIncludeValue)
            ? this.debitForm.get('salvageIncludeValue').setValue(0)
            : this.debitForm
                .get('salvageIncludeValue')
                .setValue(calculated.salvageIncludeValue);
        isNaN(calculated.totalDeduction)
            ? this.debitForm.get('totalDeduction').setValue(0)
            : this.debitForm
                .get('totalDeduction')
                .setValue(calculated.totalDeduction);
        isNaN(calculated.settlementBeforeSalvage)
            ? this.debitForm.get('settlementBeforeSalvage').setValue(0)
            : this.debitForm
                .get('settlementBeforeSalvage')
                .setValue(calculated.settlementBeforeSalvage);
        isNaN(calculated.gapsettlement)
            ? this.debitForm.get('gapsettlement').setValue(0)
            : this.debitForm.get('gapsettlement').setValue(calculated.gapsettlement);
        isNaN(calculated.lesserPayofforAmortized)
            ? this.debitForm.get('lesserPayofforAmortized').setValue(0)
            : this.debitForm
                .get('lesserPayofforAmortized')
                .setValue(calculated.lesserPayofforAmortized);
        this.debitForm
            .get('amountFinancedActual')
            .setValue(this.debitForm.get('amountFinanced').value);
        this.debitForm
            .get('actualPayoffAmount1')
            .setValue(this.debitForm.get('actualPayoffAmount').value);
        this.debitForm
            .get('salvageIncludeValue')
            .setValue(this.debitForm.get('salvageIncludeValue').value);
        this.debitForm
            .get('conditionAdjustmentA1')
            .setValue(this.debitForm.get('conditionAdjustmentA').value);
        this.debitForm
            .get('conditionAdjustmentB1')
            .setValue(this.debitForm.get('conditionAdjustmentB').value);
        this.debitForm
            .get('conditionAdjustmentC1')
            .setValue(this.debitForm.get('conditionAdjustmentC').value);
        isNaN(calculated.amountFinancedActual1)
            ? this.debitForm.get('amountFinancedActual1').setValue(0)
            : this.debitForm
                .get('amountFinancedActual1')
                .setValue(calculated.amountFinancedActual1);
        this.debitForm
            .get('lesserAdjustedAmount1')
            .setValue(this.debitForm.get('lesserAdjustedAmount').value);
        this.debitForm
            .get('liabilityInsuranceSettlement1')
            .setValue(this.debitForm.get('liabilityInsuranceSettlement').value);
        this.debitForm
            .get('comprehensiveInsuranceSettlement1')
            .setValue(this.debitForm.get('comprehensiveInsuranceSettlement').value);
        this.debitForm
            .get('Dedectibles1')
            .setValue(this.debitForm.get('Dedectibles').value);
        this.debitForm
            .get('accessories1')
            .setValue(this.debitForm.get('accessories').value);
        this.debitForm
            .get('serviceContract1')
            .setValue(this.debitForm.get('serviceContract').value);
        this.debitForm
            .get('closingFee1')
            .setValue(this.debitForm.get('closingFee').value);
        this.debitForm
            .get('titleFee1')
            .setValue(this.debitForm.get('titleFee').value);
        this.debitForm
            .get('salesTax1')
            .setValue(this.debitForm.get('salesTax').value);
        this.debitForm
            .get('license1')
            .setValue(this.debitForm.get('license').value);
        this.debitForm
            .get('settlementBeforeSalvage1')
            .setValue(this.debitForm.get('settlementBeforeSalvage').value);
        this.debitForm
            .get('maximunBenefitLimit1')
            .setValue(this.debitForm.get('maximunBenefitLimit').value);
    };
    DebitCancellationCalculationComponent.prototype.omit_char = function (event) {
        var k;
        k = event.charCode; //         k = event.keyCode;  (Both can be used)
        return ((k > 95 && k <= 105) || (k >= 48 && k <= 57));
    };
    DebitCancellationCalculationComponent.prototype.selectCheckbox = function (control, e, group) {
        if (group) {
            var formGroup = this.debitForm.controls[group];
            formGroup.controls[control].setValue(e.target.checked);
            formGroup.controls[control].markAsDirty();
        }
        else {
            this.debitForm.controls[control].setValue(e.target.checked);
            this.debitForm.controls[control].markAsDirty();
        }
    };
    return DebitCancellationCalculationComponent;
}());
export { DebitCancellationCalculationComponent };
