import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ClaimService } from '@app/core/service/claim.service';
import { KeyfechService } from '@app/core/service/keyfetch.service';
import { shieldService } from '@app/core/service/shield.service';
import { ToastrService } from 'ngx-toastr';
import { LOCAL_VAR } from './../../../core/constants';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-confirm-claim',
  templateUrl: './confirm-claim.component.html',
  styleUrls: ['./confirm-claim.component.scss']
})
export class ConfirmClaimComponent implements OnInit {

  @Input()
  claimId?= '';
  adminId?= '';
  product?= '';
  message?= '';
  amountApproved?= '';

  selectedCustomer: any = null;
  confirmForm: FormGroup;
  isLoading = false;
  constructor(
    public activeModal: NgbActiveModal,
    public claimService: ClaimService,
    public KeyfechService: KeyfechService,
    public shieldService: shieldService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    public router: Router
  ) {
    this.selectedCustomer = localStorage.getItem(LOCAL_VAR.selectedCustomer);
    this.selectedCustomer = JSON.parse(this.selectedCustomer);
    this.createForm();
  }

  ngOnInit() {
   // this.confirmForm.controls['message'].setValue(this.message);
    this.confirmForm.controls['amountApproved'].setValue(this.amountApproved);
  }

  createForm() {
    this.confirmForm = this.formBuilder.group({
      message: [''],
      amountApproved: ['', [Validators.required]],
      adminId: ['']
    });
  }

  approveClaim() {
    this.confirmForm.get('adminId').setValue(this.adminId);
    var formValue = this.confirmForm.value;
    // formValue.message = formValue.message?formValue.message:this.message;
    this.isLoading = true;
    if (this.product === 'shield') {
      this.shieldService
        .acceptRequest(this.claimId, formValue)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe((response: any) => {
          if (response) {
            this.activeModal.close(response);
          } else {
            this.toastrService.warning('something went Wrong');
          }
        });
    } else if (this.product === 'lease') {
      this.claimService
        .acceptLeaseRequest(this.claimId, formValue)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe((response: any) => {
          if (response) {
            this.activeModal.close(response);
          } else {
            this.toastrService.warning('something went Wrong');
          }
        });
    } else if (this.product === 'keyfetch') {
      this.KeyfechService
        .acceptRequest(this.claimId, formValue)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe((response: any) => {
          if (response) {
            this.activeModal.close(response);
          } else {
            this.toastrService.warning('something went Wrong');
          }
        });
    }

  }
}

