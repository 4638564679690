/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./no-data-msg.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../pipes/safe-html.pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../directives/route-transformer-directive.directive";
import * as i5 from "@angular/router";
import * as i6 from "./no-data-msg.component";
var styles_NoDataMsgComponent = [i0.styles];
var RenderType_NoDataMsgComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NoDataMsgComponent, data: {} });
export { RenderType_NoDataMsgComponent as RenderType_NoDataMsgComponent };
export function View_NoDataMsgComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.SafeHtmlPipe, [i3.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h5", [["appRouteTransformerDirective", ""], ["class", "text-center m-0"]], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i4.RouteTransformerDirectiveDirective, [i1.ElementRef, i5.Router], null, null), i1.ɵppd(3, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i1.ɵnov(_v, 0), _co.message)); _ck(_v, 1, 0, currVal_0); }); }
export function View_NoDataMsgComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-no-data-msg", [], null, null, null, View_NoDataMsgComponent_0, RenderType_NoDataMsgComponent)), i1.ɵdid(1, 114688, null, 0, i6.NoDataMsgComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NoDataMsgComponentNgFactory = i1.ɵccf("app-no-data-msg", i6.NoDataMsgComponent, View_NoDataMsgComponent_Host_0, { message: "message" }, {}, []);
export { NoDataMsgComponentNgFactory as NoDataMsgComponentNgFactory };
