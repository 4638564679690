import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { IpDetailComponent } from '@app/shared/modals/ip-detail/ip-detail.component';
import { UtilService, LOCAL_VAR } from '@app/core';
import { CustomerService } from '@app/core/service/customer.service';
import { finalize } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss']
})
export class AuditComponent implements OnInit {
  @ViewChild('goto') nameInput: ElementRef;
  auditList: any;
  isLoading = false;
  closeResult: string;
  isFileterCollapse = true;
  fiterForm: FormGroup;
  customerList: any;
  actionList: any = ["login", "create-claim", "update-claim-step-1", "update-claim-step-2", "update-claim-step-3", "update-claim-step-4", "update-claim-step-5", "update-claim-step-6", "update-claim-step-7", "forced-status-change", "claim-deleted", "claim-rejected", "register", "claim-approved", "user-delete", "account-activation"];
  sortDir = -1;
  perPage = 10;
  currentPage = 1;
  sortBy = 'updateTime';
  loggedInUserType: any = null;
  user: Authentication.User;
  pager = {
    perPage: 10,
    currentPage: 1,
    Totalrecords: 0,
    TotalPages: 0
  };
  PageList: any;
  selectedCustomer: any = null;

  constructor(
    public router: Router,
    private modalService: NgbModal,
    public utilService: UtilService,
    private customerService: CustomerService,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService
  ) {
    this.user = this.authenticationService.credentials.user;
    this.loggedInUserType = this.user.userRoleId.slug;
    localStorage.setItem(LOCAL_VAR.selectedCustomer, null);
    localStorage.setItem(LOCAL_VAR.selectedProduct, null);
    this.createForm();
    this.getActions();
  }

  ngOnInit() {
    if (this.loggedInUserType !== 'dowcsuperadmin' && this.user.audit != 1) {
      this.router.navigate(['/products']);
    }
  }

  ngAfterViewInit() {
    this.utilService.updateDomainCss();
  }
  
  createForm() {
    this.isLoading = true;
    this.fiterForm = this.formBuilder.group({
      cid: [''],
      rid: [''],
      name: [''],
      email: [''],
      type: [''],
      date_from: [''],
      date_to: [''],
      sortFieldFilter: [''],
      sortByFilter: [''],
      perPage: 10,
      domain: ['']
    });
    //this.actionList = ["create-claim", "update-claim-step-1", "update-claim-step-2", "update-claim-step-3", "update-claim-step-4", "update-claim-step-5", "update-claim-step-6", "update-claim-step-7", "claim-approved", "forced-status-change", "claim-deleted", "claim-exception-accept", "register", "account-activation", "user-delete", "login", "profile-update", "password-update", "create-dealer", "update-dealer"];

    this.fiterForm.valueChanges.subscribe(values => {
      this.pager.currentPage = 1;
      this.getAllAuditByFilter();
    });
    // this.customerService
    //   .getAllCustomers()
    //   .pipe(
    //     finalize(() => {
    //       this.isLoading = false;
    //     })
    //   )
    //   .subscribe((response: any) => {
    //     this.customerList = response.records;
    //   });

    this.getAudit(this.pager.currentPage, {});
  }

  async sort(sortByVal: string) {
    if (this.sortBy === sortByVal) {
      this.sortDir = this.sortDir === 1 ? -1 : 1;
    } else {
      this.sortDir = 1;
    }
    this.fiterForm.value.sortFieldFilter = sortByVal;
    this.fiterForm.value.sortByFilter = this.sortDir;
    this.sortBy = sortByVal;
    this.getAllAuditByFilter();
  }

  getActions() {
    this.customerService
      .getAuditActions()
      .subscribe((response: any) => {
        this.actionList=response;
      });
  }

  getAllAuditByFilter() {
    let payload = this.fiterForm.value;
    payload = this.utilService.removeWhiteSpace(payload);
    if (payload.date_from) {
      payload.datetime_from = DateTime.fromObject(
        payload.date_from
      ).toISO();
    }
    if (payload.date_to) {
      payload.datetime_to = DateTime.fromObject(payload.date_to).toISO();
    }
    this.pager.perPage = payload.perPage;
    delete payload.perPage
    this.getAudit(this.pager.currentPage, payload);
  }

  getAudit(page: number, payload: any) {
    payload.currentPage = page;
    payload.perPage = this.pager.perPage;
    //payload = this.utilService.removeWhiteSpace(payload);
    this.isLoading = true;
    this.customerService
      .getAllAuditsByFilter(payload)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response: any) => {
        let list: any = [];
        response.records.forEach(function (val: any) {
          let type = val.type;
          let url = '';
          if (type.match(/dealergroup/g)) {
            url = "dealergroup-edit/" + val.rId;
          } else if (type.match(/debit/g)) {
            url = 'debitcancellationview/' + val.rId;
          } else if (type.match(/360shield/g)) {
            url = '360shieldview/' + val.rId;
          } else if (type.match(/lease/g)) {
            url = 'leaseview/' + val.rId;
          } else if (type.match(/production/g) || type.match(/protection/g)) {
            url = 'keyprotectionview/' + val.rId;
          } else if (type.match(/customer/g) || type.match(/user/g) || type.match(/send-code/g) || type.match(/send-code/g)) {
            url = 'user/edit/' + val.rId;
          } else if (type.match(/dealer/g)) {
            //url='/user/edit/'+val.rId;
          } else if (type.match(/claim/g) || type.match(/forced-status-change/g)) {
            url = 'claim/preview/' + val.rId;
          }
          val.url = url;
          list.push(val);
        });
        this.auditList = list;
        this.renderPagination(response.page, response.total, response.totalPage);
      });
  }

  goPage() {
    let goto = this.nameInput.nativeElement.value;
    this.setPage(goto);
    this.nameInput.nativeElement.value = '';
  }

  setPage(page: any) {
    if (0 < page && page <= this.pager.TotalPages && page != this.pager.currentPage) {
      this.pager.currentPage = page;
      this.getAllAuditByFilter();
    }
  }

  renderPagination(page: number, total: number, totalPage: number) {
    this.pager.currentPage = page;
    this.pager.Totalrecords = total;
    this.pager.TotalPages = totalPage;
    this.PageList = this.utilService.getPageList(this.pager.TotalPages, this.pager.currentPage);
  }

  ipDetails(ip: any) {

    this.customerService.
      getIpdetails(ip)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response: any) => {
        this.showIpdetails(ip, response);
      });


  }
  async showIpdetails(ip: any, details: any) {
    const modalRef = await this.modalService.open(IpDetailComponent);
    modalRef.componentInstance.ip = ip;
    if (details.status == "success") {
      modalRef.componentInstance.country = details.country;
      modalRef.componentInstance.countryCode = details.countryCode;
      modalRef.componentInstance.regionName = details.regionName;
      modalRef.componentInstance.region = details.region;
      modalRef.componentInstance.city = details.city;
    } else if (details.message) {
      modalRef.componentInstance.message = details.message;
    }


    const result: any = await modalRef.result.catch(e => { return e });
  }

  omit_special_char(event: any) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k <= 122) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  omit_special_Mailchar(event: any) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k <= 122) || k === 46 || k === 64 || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }
}
