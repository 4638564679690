<div class="row agent-container">
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        <div class="fl-lt ">Debt Cancellation Claim Preview</div>
        <div class="card-actions">
          <button class="btn btn-white" routerLink="/debitcancellation-list">
            <i class="mdi mdi-arrow-left mr-1"></i> <span>Back</span>
          </button>          
        </div>
      </div>

      <div class="card-group"  *ngIf="claimDomain">
        <div class="card-body pb-0">
          <div class="row">
            <div class="col-md-12 text-center p-1 domain-bg">
              <img class="domain-logo" style="width: 150px;" src={{claimDomain.appLogo}} alt={{claimDomain.appName}}> 
            </div>      
          </div>
        </div>
      </div>

      <div class="card-group" *ngIf="claims">
        <div class="card-body">
          <div class="row">
            <div class="col-4">
              <table class="w-100 preview-table">
                <tbody>
                  <tr class="table-title">
                    <td colspan="2">Claim Details</td>
                  </tr>
                  <tr>
                    <td width="60%">
                      <span>Claim No</span>
                    </td>
                    <td width="40%">
                      {{ claims.sequence }}
                    </td>
                  </tr>
                  <tr>
                    <td>Claim Date</td>
                    <td>{{ claims.claimDate | date: 'MM/dd/yyyy' }}</td>
                  </tr>
                  <tr>
                    <td>
                      <span>Claim Status</span>
                    </td>
                    <td>
                      <span class="label text-white"
                      [ngClass]="{
                        'bg-inprogress': claims.status === 'in progress',
                        'bg-inreview': claims.status === 'in review',
                        'bg-approved': claims.status === 'approved',
                        'bg-completed': claims.status === 'completed',
                        'bg-rejected': claims.status === 'rejected',
                        'bg-denied': claims.status === 'denied',
                        'bg-voided': claims.status === 'voided'
                      }">{{ claims.status | titlecase }}</span>
                    </td>
                  </tr>
                  <tr *ngIf="claims.status === 'approved' && claims.statusMessage">
                    <td>
                      <span>Message</span>
                    </td>
                    <td>
                      {{ claims.statusMessage}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Created By</span>:
                    </td>
                    <td>
                      <div *ngIf="claims?.userId?.firstName; else cCustomer">
                        {{ claims.userId?.firstName }} {{ claims.userId?.lastName }}
                      </div>
                      <ng-template #cCustomer>
                        {{ appname }} User
                      </ng-template>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Created On</span>
                    </td>
                    <td>
                      {{ claims.createdAt | date: 'MM/dd/yyyy' }}
                    </td>
                  </tr>
                  <tr *ngIf="claims.status === 'approved' || claims.status === 'rejected'">
                    <td>
                      <span>{{ claims.status | titlecase }} By</span>
                    </td>
                    <td>
                      <span *ngIf="claims?.adminId?.firstName; else dAdmin">
                        {{ claims.adminId?.firstName }}
                        {{ claims.adminId?.lastName }}
                      </span>
                      <ng-template #dAdmin> {{ appname }} Admin </ng-template>
                    </td>

                  </tr>
                  <tr *ngIf="claims.status === 'approved' || claims.status === 'rejected'">
                    <td>
                      <span>{{ claims.status | titlecase }} On</span>
                    </td>
                    <td>
                      {{ claims.updatedAt
                      | date: 'MM/dd/yyyy' }}
                    </td>
                  </tr>
                  <tr *ngIf="claims.status === 'rejected' || claims.status === 'denied' || claims.status === 'voided'" class="alert alert-danger">
                    <td colspan="2" *ngIf="claims.status === 'rejected'">
                      What is reason behind reject claim ?<br />
                      {{ claims.statusMessage }}
                    </td>
                    <td colspan="2" *ngIf="claims.status === 'denied'">
                      What is reason behind denying claim ?<br />
                      {{ claims.statusMessage }}
                    </td>
                    <td colspan="2" *ngIf="claims.status === 'voided'">
                      Reason for Voiding<br />
                      {{ claims.statusMessage }}
                    </td>
                  </tr>
                    <tr *ngIf="
                    (loggedInUserType === 'superadmin' ||
                      loggedInUserType === 'dowcsuperadmin') &&
                    claims.status !== 'in progress'
                  ">
                      <td colspan="2">
                        <div class="form-group mb-0 status-change">
                          <form [formGroup]="statusChange">
                            <label class="d-block">Update Status</label>
                            <select
                              class="form-control custom-select m-r-10"
                              name="status"
                              formControlName="status"
                            >
                              <option class="custom-select-option" value=""
                                >Select Status</option
                              >
                              <option class="custom-select-option" *ngIf="
                              claims.status !== 'in progress'" value="in progress"
                                >In Progress</option
                              >
                              <option
                                *ngIf="claims.status !== 'in review' && claims.status !== 'in progress'"
                                class="custom-select-option"
                                value="in review"
                                >In Review</option
                              >
                            </select>
                            <button
                              (click)="changeStatus(claims._id)"
                              class="btn btn-primary d-flex align-items-center"
                            >
                              Update
                            </button>
                          </form>
                        </div>
                      </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-4">
              <table class="w-100 preview-table">
                <tbody>
                  <tr class="table-title">
                    <td colspan="2">Customer Information</td>
                  </tr>
                  <tr>
                    <td  width="60%">Contract Number</td>
                    <td width="40%">{{ claims.contractNumber }}</td>
                  </tr>
                  <tr>
                    <td class="buyer-name">Buyer/Debtor Name</td>
                    <td>{{ claims.debitorName }}</td>
                  </tr>
                  <tr>
                    <td>Phone Number</td>
                    <td>
                      <span *ngIf="claims.debitorPhone > 8">
                        {{ claims.debitorPhone | phone }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>{{ claims.debitorEmail }}</td>
                  </tr>
                  <tr>
                    <td>Street Address</td>
                    <td>{{ claims.debitorStreet }}</td>
                  </tr>
                  <tr>
                    <td>City/State/Zip</td>
                    <td><span *ngIf="claims.debitorCity">{{ claims.debitorCity }} - {{ claims.debitorState }} - {{ claims.debitorZip }}</span></td>
                  </tr>   
                  <tr>
                    <td class="buyer-name">Co-Buyer/ Co-Debtor Name</td>
                    <td>{{ claims.codebitorName }}</td>
                  </tr>
                  <tr>
                    <td>Phone Number</td>
                    <td>
                      <span *ngIf="claims.codebitorPhone > 8">
                        {{ claims.codebitorPhone | phone }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>{{ claims.codebitorEmail }}</td>
                  </tr>
                  <tr>
                    <td>Street Address</td>
                    <td>{{ claims.codebitorStreet }}</td>
                  </tr>
                  <tr>
                    <td>City/State/Zip</td>
                    <td><span *ngIf="claims.codebitorCity">{{ claims.codebitorCity }} - {{ claims.codebitorState }} - {{ claims.codebitorZip }}</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-4">
              <table class="w-100 preview-table">                
                <tbody>
                  <tr class="table-title">
                    <td colspan="2">Vehicle Information</td>
                  </tr>
                  <tr>
                    <td width="60%">VIN Number</td>
                    <td width="40%">{{ claims.vinNumber }}</td>
                  </tr>
                  <tr>
                    <td width="60%">Current mileage</td>
                    <td width="40%">{{ claims.currentMileage }}</td>
                  </tr>
                  <tr>
                    <td>Year</td>
                    <td>{{ claims.year }}</td>
                  </tr>
                  <tr>
                    <td>Make</td>
                    <td>{{ claims.make }}</td>
                  </tr>
                  <tr>
                    <td>Model</td>
                    <td>{{ claims.model }}</td>
                  </tr>
                  <tr>
                    <td>Trim</td>
                    <td>{{ claims.trim }}</td>
                  </tr>
                  <tr>
                    <td colspan="2">
                          Vechile Type
                          <table  class="w-100">
                            <tr>
                              <td>New</td>
                              <td>
                                :
                                <img
                                  *ngIf="
                                    claims?.vehicleType?.isNew;
                                    else no2
                                  "
                                  width="23"
                                  src="assets/images/icon/tick-box-50.png"
                                />
                                <ng-template #no2>
                                  <img
                                    width="23"
                                    src="assets/images/icon/unchecked-checkbox-filled-50.png"
                                  />
                                </ng-template>
                              </td>
                            </tr>
    
                            <tr>
                              <td>PRE-OWNED</td>
                              <td>
                                :
                                <img
                                  *ngIf="
                                    claims?.vehicleType?.isPreOwned;
                                    else no2
                                  "
                                  width="23"
                                  src="assets/images/icon/tick-box-50.png"
                                />
                                <ng-template #no2>
                                  <img
                                    width="23"
                                    src="assets/images/icon/unchecked-checkbox-filled-50.png"
                                  />
                                </ng-template>
                              </td>
                            </tr>
    
                            <tr>
                              <td>CERTIFIED PRE-OWNED</td>
                              <td>
                                :
                                <img
                                  *ngIf="
                                    claims?.vehicleType?.isCertifiedPreOwned;
                                    else no2
                                  "
                                  width="23"
                                  src="assets/images/icon/tick-box-50.png"
                                />
                                <ng-template #no2>
                                  <img
                                    width="23"
                                    src="assets/images/icon/unchecked-checkbox-filled-50.png"
                                  />
                                </ng-template>
                              </td>
                            </tr>
                          </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <table class="w-100 preview-table">
                <tbody>
                  <tr class="table-title">
                    <td colspan="2">DEALER/CREDITOR INFORMATION</td>
                  </tr>
                  <tr>
                    <td width="60%">Name</td>
                    <td width="40%" *ngIf="claims.dealer">{{ claims?.dealer?.name }}</td>
                    <td width="40%" *ngIf="!claims.dealer"></td>
                  </tr>
                  <tr>
                    <td>Phone Number</td>
                    <td>
                      <span *ngIf="claims.dealerPhone > 8">
                        {{ claims.dealerPhone | phone }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Street Address</td>
                    <td>{{ claims.dealerStreet }}</td>
                  </tr>
                  <tr>
                    <td>City/State/Zip</td>
                    <td><span *ngIf="claims.dealerCity">{{ claims.dealerCity }} - {{ claims.dealerState }} - {{ claims.dealerZip }}</span></td>
                  </tr>                 
                </tbody>
              </table>
              <table class="w-100 preview-table mt-3">
                <tbody>
                  <tr>
                    <td class="table-title" colspan="2">
                      ASSIGNEE/LIENHOLDER INFORMATION
                    </td>
                  </tr>
                  <tr>
                    <td width="60%">Name</td>
                    <td width="40%">{{ claims.lienholderName }}</td>
                  </tr>
                  <tr>
                    <td>Phone Number</td>
                    <td>
                      <span *ngIf="claims.lienholderPhone > 8">
                        {{ claims.lienholderPhone | phone }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Street Address</td>
                    <td>{{ claims.lienholderStreet }}</td>
                  </tr>
                  <tr>
                    <td>City/State/Zip</td>
                    <td><span *ngIf="claims.lienholderCity">{{ claims.lienholderCity }} - {{ claims.lienholderState }} - {{ claims.lienholderZip }}</span></td>
                  </tr>                  
                </tbody>
              </table>
              <!-- <table class="w-100 preview-table mt-1">
                <tbody>
                  <tr>
                    <td class="table-title" colspan="2">
                      AGREEMENT AND FINANCE CONTRACT INFORMATION
                    </td>
                  </tr>
                  <tr>
                    <td width="60%">Agreement Purchase Price</td>
                    <td width="40%">{{ claims.agreementPurchasePrice | currency: 'USD':'symbol':'1.2-2' }}</td>
                  </tr>
                  <tr>
                    <td>Agreement Purchase Date</td>
                    <td>{{ claims.agreementPurchaseDate | date: 'MM/dd/yyyy' }}</td>
                  </tr>
                  <tr>
                    <td>Maximum benefit limit</td>
                    <td>{{ claims.maximunBenefitLimit | currency: 'USD':'symbol':'1.2-2' }}</td>
                  </tr>
                  <tr>
                    <td>Vehicle Purchase Price</td>
                    <td>{{ claims.vehiclePurchasePrice | currency: 'USD':'symbol':'1.2-2' }}</td>
                  </tr>
                  <tr>
                    <td>Date of First Payment</td>
                    <td>{{ claims.dateOfFirstPayment | date: 'MM/dd/yyyy' }}</td>
                  </tr>
                  <tr>
                    <td>APR</td>
                    <td>{{ claims.APR }}%</td>
                  </tr>
                  <tr>
                    <td>Monthly Payment Amount</td>
                    <td>{{ claims.monthlyPaymentAmount | currency: 'USD':'symbol':'1.2-2' }}</td>
                  </tr>
                  <tr>
                    <td>Amount Financed</td>
                    <td>{{ claims.amountFinanced | currency: 'USD':'symbol':'1.2-2' }}</td>
                  </tr>
                  <tr>
                    <td>Total of All Payment</td>
                    <td>{{ claims.totalOfAllPayement | currency: 'USD':'symbol':'1.2-2' }}</td>
                  </tr>
                  <tr>
                    <td colspan="2">
                          Type of Financing Contract
    
                          <table class="w-100">
                            <tr>
                              <td>Retail Installment Sales (CONVENTIONAL LOANS)</td>
                              <td>
                                :
                                <img
                                  *ngIf="
                                    claims?.financingContractType?.retailInstallmentSales;
                                    else no2
                                  "
                                  width="23"
                                  src="assets/images/icon/tick-box-50.png"
                                />
                                <ng-template #no2>
                                  <img
                                    width="23"
                                    src="assets/images/icon/unchecked-checkbox-filled-50.png"
                                  />
                                </ng-template>
                              </td>
                            </tr>
    
                            <tr>
                              <td>LEASE</td>
                              <td>
                                :
                                <img
                                  *ngIf="
                                    claims?.financingContractType?.lease;
                                    else no2
                                  "
                                  width="23"
                                  src="assets/images/icon/tick-box-50.png"
                                />
                                <ng-template #no2>
                                  <img
                                    width="23"
                                    src="assets/images/icon/unchecked-checkbox-filled-50.png"
                                  />
                                </ng-template>
                              </td>
                            </tr>
                          </table>
                    </td>
                  </tr>                 
                </tbody>
              </table> -->
              <!-- <div class="mt-1" *ngIf="claims.status !== 'in progress' && (loggedInUserType==='dowcsuperadmin' || loggedInUserType==='superadmin')">
                <table class="w-100 preview-table">
                  <tbody>
                    <tr class="table-title">
                      <td colspan="2">CALCULATIONS</td>
                    </tr>
                    <tr>
                      <td width="60%">Outstanding amount financed</td>
                      <td width="40%">{{ claims.outstandingAmountFinanced | currency: 'USD':'symbol':'1.2-2' }}</td>
                    </tr><tr>
                      <td>Cancelled products</td>
                      <td>{{ claims.cancelledProducts | currency: 'USD':'symbol':'1.2-2' }}</td>
                    </tr><tr>
                      <td>Subtotal</td>
                      <td>{{ claims.subTotal | currency: 'USD':'symbol':'1.2-2' }}</td>
                    </tr>
                    <tr>
                      <td>Consumer liability</td>
                      <td>{{ claims.consumerLiability | currency: 'USD':'symbol':'1.2-2' }}</td>
                    </tr>
                    <tr>
                      <td>Maximum Debt cancellation amount </td>
                      <td>{{ claims.maximumDebtCancellation | currency: 'USD':'symbol':'1.2-2' }}</td>
                    </tr>                
                  </tbody>
                </table>
                <table class="w-100 preview-table mt-3">
                  <tbody>
                    <tr>
                      <td class="table-title" colspan="2">
                        PAYOUT
                      </td>
                    </tr>
                    <tr>
                      <td width="60%">Truncated amount</td>
                      <td width="40%">{{ claims.truncatedAmount | currency: 'USD':'symbol':'1.2-2' }}</td>
                    </tr>
                    <tr>
                      <td>Salvage credit (10%)</td>
                      <td>{{ claims.salvageCredit | currency: 'USD':'symbol':'1.2-2' }}</td>
                    </tr><tr>
                      <td>Final Payout to Creditor</td>
                      <td>{{ claims.finalPayout | currency: 'USD':'symbol':'1.2-2' }}</td>
                    </tr>                 
                  </tbody>
                </table>
              </div> -->
            </div>
            <div class="col-8">
              <table class="w-100 preview-table">
                <tbody>
                  <tr class="table-title">
                    <td colspan="2">Customer Documents Needed</td>
                  </tr>
                  <tr>
                    <td width="60%">A complete copy of the Primary Carriers Settlement showing all additions and deductions for the liability settlement amount, including the valuation report</td>
                    <td width="40%">
                      <a class="doc" *ngIf="claims.liabilitySettlement" href="{{ baseUrl }}{{ claims.liabilitySettlement }}"
                        target="_blank">{{ claims.liabilitySettlement.replace('debitcancellation/', '').split('/')[1].replaceAll('_',' ').substring(14) }}</a>
                    </td>
                  </tr>
                  <tr>
                    <td>A complete copy of the Primary Carriers Settlement showing all additions and deductions for the comprehensive or collision settlement amount, including the valuation report, if any</td>
                    <td>
                      <a class="doc" *ngIf="claims.comprehensiveOrCollisionSettlement" href="{{ baseUrl }}{{ claims.comprehensiveOrCollisionSettlement }}"
                        target="_blank">{{ claims.comprehensiveOrCollisionSettlement.replace('debitcancellation/', '').split('/')[1].replaceAll('_',' ').substring(14) }}</a>
                    </td>
                  </tr>
                  <tr>
                    <td>A copy of the accident/police report</td>
                    <td>
                      <a class="doc" *ngIf="claims.accidentOrPoliceReport" href="{{ baseUrl }}{{ claims.accidentOrPoliceReport }}"
                        target="_blank">{{ claims.accidentOrPoliceReport.replace('debitcancellation/', '').split('/')[1].replaceAll('_',' ').substring(14) }}</a>
                    </td>
                  </tr>
                  <tr>
                    <td>A copy of the fire report (if applicable) </td>
                    <td>
                      <a class="doc" *ngIf="claims.fireReport" href="{{ baseUrl }}{{ claims.fireReport }}"
                        target="_blank">{{ claims.fireReport.replace('debitcancellation/', '').split('/')[1].replaceAll('_',' ').substring(14) }}</a>
                    </td>
                  </tr>
                  <tr>
                    <td>A copy of the estimate of damage and repair </td>
                    <td>
                      <a class="doc" *ngIf="claims.estimateDamageRepair" href="{{ baseUrl }}{{ claims.estimateDamageRepair }}"
                        target="_blank">{{ claims.estimateDamageRepair.replace('debitcancellation/', '').split('/')[1].replaceAll('_',' ').substring(14) }}</a>
                    </td>
                  </tr>
                  <tr>
                    <td>A copy of the debt cancellation agreement </td>
                    <td>
                      <a class="doc" *ngIf="claims.debtCancellationAgreement" href="{{ baseUrl }}{{ claims.debtCancellationAgreement }}"
                        target="_blank">{{ claims.debtCancellationAgreement.replace('debitcancellation/', '').split('/')[1].replaceAll('_',' ').substring(14) }}</a>
                    </td>
                  </tr>
                  <tr>
                    <td>A copy of the Vehicles original bill of sale </td>
                    <td>
                      <a class="doc" *ngIf="claims.vehiclesOriginalBill" href="{{ baseUrl }}{{ claims.vehiclesOriginalBill }}"
                        target="_blank">{{ claims.vehiclesOriginalBill.replace('debitcancellation/', '').split('/')[1].replaceAll('_',' ').substring(14) }}</a>
                    </td>
                  </tr>
                  <tr>
                    <td>A copy and history of Your Finance Contract, including the Dealer/Creditor’s or Assignee/Lienholder’s name, address, and account number, showing all payments made, late charges, and fees </td>
                    <td>
                      <a class="doc" *ngIf="claims.historyOfFinanceContract" href="{{ baseUrl }}{{ claims.historyOfFinanceContract }}"
                        target="_blank">{{ claims.historyOfFinanceContract.replace('debitcancellation/', '').split('/')[1].replaceAll('_',' ').substring(14) }}</a>
                    </td>
                  </tr>
                  <tr>
                    <td>A copy of the pay-off statement for Your Finance Contract as calculated by the Dealer/Creditor or Assignee/Lienholder as of the Date of Total Loss </td>
                    <td>
                      <a class="doc" *ngIf="claims.payOffStatement" href="{{ baseUrl }}{{ claims.payOffStatement }}"
                        target="_blank">{{ claims.payOffStatement.replace('debitcancellation/', '').split('/')[1].replaceAll('_',' ').substring(14) }}</a>
                    </td>
                  </tr>
                  <tr>
                    <td>A copy of refund checks or authorized cancellation forms confirming the refund amount for any credit insurance, mechanical repair coverage, or other similar products that were purchased and included in the Finance Contract </td>
                    <td>
                      <a class="doc" *ngIf="claims.refundChecksOrAuthorizedCancellation" href="{{ baseUrl }}{{ claims.refundChecksOrAuthorizedCancellation }}"
                        target="_blank">{{ claims.refundChecksOrAuthorizedCancellation.replace('debitcancellation/', '').split('/')[1].replaceAll('_',' ').substring(14) }}</a>
                    </td>
                  </tr>
                  <tr>
                    <td>If available, a copy of the window sticker or vehicle invoice showing the MSRP(For new vehicles only)</td>
                    <td>
                      <a class="doc" *ngIf="claims.windowStickerOrMSRP" href="{{ baseUrl }}{{ claims.windowStickerOrMSRP }}"
                        target="_blank">{{ claims.windowStickerOrMSRP.replace('debitcancellation/', '').split('/')[1].replaceAll('_',' ').substring(14) }}</a>
                    </td>
                  </tr>
                  <tr>
                    <td>Upon request, a sworn proof of loss statement and/or an examination under path </td>
                    <td>
                      <a class="doc" *ngIf="claims.swornProofOfLossStatement" href="{{ baseUrl }}{{ claims.swornProofOfLossStatement }}"
                        target="_blank">{{ claims.swornProofOfLossStatement.replace('debitcancellation/', '').split('/')[1].replaceAll('_',' ').substring(14) }}</a>
                    </td>
                  </tr> 
                  <ng-container *ngIf="claims.additionDocs?.length">
                    <ng-container
                      *ngFor="let docs of claims.additionDocs; let i = index"
                    >
                      <tr>
                        <td>{{ docs.name }}</td>
                        <td><a href="{{ baseUrl }}{{ docs.url }}" target="_blank"
                            >{{ docs.url.replace('debitcancellation/','').split('/')[1].replaceAll('_',' ').substring(14) }}</a>
                        </td>
                      </tr>
                    </ng-container>
                  </ng-container>                                    
                </tbody>
              </table>
            </div> 
            <div class="col-6">
              <table class="w-100 preview-table">
                <tbody>

                  <tr>
                    <td colspan="2" class="table-title">Data Entry</td>
                  </tr>
                  <tr>
                    <td width="60%">Contract number</td>
                    <td width="40%">{{ claims.contractNumber }}</td>
                  </tr>
                  <tr>
                    <td>Approval</td>
                    <td>
                      {{ claims?.gapCalculationApproval }}
                    </td>
                  </tr>
                  <tr>
                    <td>Date of Loss</td>
                    <td>{{ claims.claimDate | date: 'MM/dd/yyyy' }}</td>
                  </tr>
                  <tr>
                    <td>Agreement Purchase Price</td>
                    <td>{{ claims.agreementPurchasePrice | currency: 'USD':'symbol':'1.2-2' }}</td>
                  </tr>
                  <tr>
                    <td>Agreement Purchase Date</td>
                    <td>{{ claims.agreementPurchaseDate | date: 'MM/dd/yyyy' }}</td>
                  </tr>
                  <tr>
                    <td>Maximum benefit limit</td>
                    <td>{{ claims.maximunBenefitLimit | currency: 'USD':'symbol':'1.2-2' }}</td>
                  </tr>
                  <tr>
                    <td>Cash Price of Vehicle</td>
                    <td>
                      {{
                      claims?.vehiclePurchasePrice
                      | currency: 'USD':'symbol':'1.2-2'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Date of First Payment</td>
                    <td>{{ claims.dateOfFirstPayment | date: 'MM/dd/yyyy' }}</td>
                  </tr>
                  <tr>
                    <td>APR</td>
                    <td>{{ claims.APR }}%</td>
                  </tr>
                  <tr>
                    <td>Finance Amount</td>
                    <td>{{ claims.amountFinanced | currency: 'USD':'symbol':'1.2-2' }}</td>
                  </tr>
                  <tr>
                    <td>Balloon Payment ($)</td>
                    <td>
                      {{
                      claims?.balloonPayment | currency: 'USD':'symbol':'1.2-2'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Total of All Payment</td>
                    <td>{{ claims.totalOfAllPayement | currency: 'USD':'symbol':'1.2-2' }}</td>
                  </tr>
                  <tr>
                    <td colspan="2">
                          Type of Financing Contract
    
                          <table class="w-100">
                            <tr>
                              <td>Retail Installment Sales (CONVENTIONAL LOANS)</td>
                              <td>
                                :
                                <img
                                  *ngIf="
                                    claims?.financingContractType?.retailInstallmentSales;
                                    else no2
                                  "
                                  width="23"
                                  src="assets/images/icon/tick-box-50.png"
                                />
                                <ng-template #no2>
                                  <img
                                    width="23"
                                    src="assets/images/icon/unchecked-checkbox-filled-50.png"
                                  />
                                </ng-template>
                              </td>
                            </tr>
    
                            <tr>
                              <td>LEASE</td>
                              <td>
                                :
                                <img
                                  *ngIf="
                                    claims?.financingContractType?.lease;
                                    else no2
                                  "
                                  width="23"
                                  src="assets/images/icon/tick-box-50.png"
                                />
                                <ng-template #no2>
                                  <img
                                    width="23"
                                    src="assets/images/icon/unchecked-checkbox-filled-50.png"
                                  />
                                </ng-template>
                              </td>
                            </tr>
                          </table>
                    </td>
                  </tr>
                  <tr>
                    <td>First Due Date</td>
                    <td>{{ claims?.dateOfFirstDue | date: 'MM/dd/yyyy' }}</td>
                  </tr>
                  <tr>
                    <td>Payment (per period)</td>
                    <td>
                      {{ claims?.paymentsPerPeriod | currency: 'USD':'symbol':'1.2-2' }}
                    </td>
                  </tr>
                  <tr>
                    <td>Number Of Payments</td>
                    <td>{{ claims?.numberOfPayments }}</td>
                  </tr>
                  <tr>
                    <td>Frequency (Of Payments )</td>
                    <td>
                      {{
                      claims?.frequencyOfPayments === 12
                      ? 'Monthly'
                      : claims?.frequencyOfPayments === 365
                      ? 'Daily'
                      : claims?.frequencyOfPayments === 24
                      ? 'Semi-Monthly'
                      : claims?.frequencyOfPayments === 26
                      ? 'Bi-Weekly'
                      : claims?.frequencyOfPayments === 52
                      ? 'Weekly'
                      : ''
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Include Salvage</td>
                    <td>{{ claims?.includeSalvage }}</td>
                  </tr>
                  <tr>
                    <td>Accessories</td>
                    <td>
                      {{
                      claims?.accessories
                      | currency: 'USD':'symbol':'1.2-2'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Service Contract</td>
                    <td>
                      {{
                      claims?.serviceContract
                      | currency: 'USD':'symbol':'1.2-2'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Closing Fee</td>
                    <td>
                      {{
                      claims?.closingFee
                      | currency: 'USD':'symbol':'1.2-2'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Title Fee</td>
                    <td>
                      {{
                      claims?.titleFee
                      | currency: 'USD':'symbol':'1.2-2'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Sales Tax</td>
                    <td>
                      {{
                      claims?.salesTax
                      | currency: 'USD':'symbol':'1.2-2'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>License</td>
                    <td>
                      {{
                      claims?.license
                      | currency: 'USD':'symbol':'1.2-2'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Actual Payoff Amount</td>
                    <td>
                      {{
                      claims?.actualPayoffAmount
                      | currency: 'USD':'symbol':'1.2-2'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Liability Insurance Settlement</td>
                    <td>
                      {{
                      claims?.liabilityInsuranceSettlement
                      | currency: 'USD':'symbol':'1.2-2'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Comprehensive Insurance Settlement</td>
                    <td>
                      {{
                      claims?.comprehensiveInsuranceSettlement
                      | currency: 'USD':'symbol':'1.2-2'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Deductible</td>
                    <td>
                      {{ claims?.Dedectibles | currency: 'USD':'symbol':'1.2-2' }}
                    </td>
                  </tr>
                  <tr>
                    <td>Condition Adjustment 1</td>
                    <td>
                      {{
                      claims?.conditionAdjustmentA
                      | currency: 'USD':'symbol':'1.2-2'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Condition Adjustment 2</td>
                    <td>
                      {{
                      claims?.conditionAdjustmentB
                      | currency: 'USD':'symbol':'1.2-2'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Condition Adjustment 3</td>
                    <td>
                      {{
                      claims?.conditionAdjustmentC
                      | currency: 'USD':'symbol':'1.2-2'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Finance APR</td>
                    <td>
                      {{claims?.financeAPR}}%
                    </td>
                  </tr>
                  <tr>
                    <td>Projected Principle Balance</td>
                    <td>
                      {{
                      claims?.projectedPrincipalBalance
                      | currency: 'USD':'symbol':'1.2-2'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Estimated Principle Paid</td>
                    <td>
                      {{
                      claims?.estimatedPrincipalPaid
                      | currency: 'USD':'symbol':'1.2-2'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Number Of Payments Per Year</td>
                    <td>
                      {{ claims?.numberofPaymentsperYear }}
                    </td>
                  </tr>
                  <tr>
                    <td>Number Of Payments Elapsed</td>
                    <td>
                      {{ claims?.numberofPaymentsElapsed }}
                    </td>
                  </tr>

                  <tr>
                    <td>Realized APR</td>
                    <td>
                      {{ claims?.realizedAPR}}%
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
            <div class="col-6">
              <table class="w-100 preview-table">
                <tbody>
                  <tr>
                    <td colspan="2" class="table-title cal-imp">
                      Settlement Calculations
                    </td>
                  </tr>
                  <tr>
                    <td width="60%">Projected Principal Balance</td>
                    <td width="40%">
                      {{
                      claims?.projectedPrincipalBalance
                      | currency: 'USD':'symbol':'1.2-2'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Actual Payoff Amount</td>
                    <td>
                      {{
                      claims?.actualPayoffAmount | currency: 'USD':'symbol':'1.2-2'
                      }}
                    </td>
                  </tr>
                  <tr class="cal-imp">
                    <td>Lesser of Actual and Projected Payoff Amount (A)</td>
                    <td>
                      {{
                      claims?.lesserAdjustedAmount
                      | currency: 'USD':'symbol':'1.2-2'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Liability Insurance Settlement</td>
                    <td>
                      {{
                      claims?.liabilityInsuranceSettlement
                      | currency: 'USD':'symbol':'1.2-2'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Comprehensive Insurance Settlement</td>
                    <td>
                      {{
                      claims?.comprehensiveInsuranceSettlement
                      | currency: 'USD':'symbol':'1.2-2'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Deductible</td>
                    <td>
                      {{ claims?.Dedectibles | currency: 'USD':'symbol':'1.2-2' }}
                    </td>
                  </tr>
                  <tr>
                    <td>Accessories</td>
                    <td>
                      {{
                      claims?.accessories
                      | currency: 'USD':'symbol':'1.2-2'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Service Contract</td>
                    <td>
                      {{
                      claims?.serviceContract
                      | currency: 'USD':'symbol':'1.2-2'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Closing Fee</td>
                    <td>
                      {{
                      claims?.closingFee
                      | currency: 'USD':'symbol':'1.2-2'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Title Fee</td>
                    <td>
                      {{
                      claims?.titleFee
                      | currency: 'USD':'symbol':'1.2-2'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Sales Tax</td>
                    <td>
                      {{
                      claims?.salesTax
                      | currency: 'USD':'symbol':'1.2-2'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>License</td>
                    <td>
                      {{
                      claims?.license
                      | currency: 'USD':'symbol':'1.2-2'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Condition Adjustment 1</td>
                    <td>
                      {{
                      claims?.conditionAdjustmentA
                      | currency: 'USD':'symbol':'1.2-2'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Condition Adjustment 2</td>
                    <td>
                      {{
                      claims?.conditionAdjustmentB
                      | currency: 'USD':'symbol':'1.2-2'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Condition Adjustment 3</td>
                    <td>
                      {{
                      claims?.conditionAdjustmentC
                      | currency: 'USD':'symbol':'1.2-2'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Salvage Value (20% of original value) - Included - {{claims?.includeSalvage}}</td>
                    <td>
                      {{
                      claims?.salvageIncludeValue
                      | currency: 'USD':'symbol':'1.2-2'
                      }}
                    </td>
                  </tr>                  
                  <tr class="cal-imp">
                    <td>Total Deductions (B)</td>
                    <td>
                      {{
                      claims?.totalDeduction
                      | currency: 'USD':'symbol':'1.2-2'
                      }}
                    </td>
                  </tr>
                  <tr class="cal-imp">
                    <td>Settlement Amount (C = A-B)</td>
                    <td>
                      {{
                        claims?.settlementBeforeSalvage
                        | currency: 'USD':'symbol':'1.2-2'
                        }}</td>
                  </tr>
                  <tr class="cal-imp">
                    <td>Settlement Before Salvage (A-B)</td>
                    <td>
                      {{
                      claims?.settlementBeforeSalvage
                      | currency: 'USD':'symbol':'1.2-2'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Maximum Benefit Limit</td>
                    <td>
                      {{
                      claims?.maximunBenefitLimit
                      | currency: 'USD':'symbol':'1.2-2'
                      }}
                    </td>
                  </tr>
                  <tr class="cal-imp">
                    <td>Lessor of Settlement Amount and Maximum Benefit Limit (D)</td>
                    <td>
                      {{
                        claims?.lesserPayofforAmortized
                        | currency: 'USD':'symbol':'1.2-2'
                        }} </td>
                  </tr>
                  <tr class="table-warning cal-imp">
                    <td>Final Debt Cancellation Settlement (D)</td>
                    <td>
                      {{
                        claims?.gapsettlement
                        | currency: 'USD':'symbol':'1.2-2'
                        }} </td>
                  </tr>
                </tbody>
              </table>                
            </div>
          </div>
          <div class="row" *ngIf="loggedInUserType === 'dowcsuperadmin'">
            <div class="col-12" >
              <table width="100%">
                <thead>
                  <tr>
                    <th colspan="3" class="table-title">Audit logs</th>
                  </tr>
                  <tr>
                    <th width="33.34%">Process</th>
                    <th width="33.33%">Updated At</th>
                    <th width="33.33%">Updated By</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="
                          let log of history">
                          <td class="text-capitalize">{{ log.type.replace('debit-cancellation','').replace('create','created').replace('update','updated').replaceAll('-',' ') }}</td>
                          <td>{{ log.updateTime | date: 'MM/dd/yyyy hh:mm:ss' }}</td>
                          <td>{{ log.cid?.firstName}} {{ log.cid?.lastName}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <app-no-data-msg [message]="'No Data Found'" *ngIf="claims && claims.length === 0 && !isLoading">
        </app-no-data-msg>
        <app-table-loader [isLoading]="isLoading"></app-table-loader>
      </div>

      <!-- old view -->
      <!-- <div
        *ngIf="
          (loggedInUserType === 'superadmin' ||
            loggedInUserType === 'dowcsuperadmin') && claims.finalPayout
        "
        class="status-chnage-container card-body"
      >
        <div class="form-group mb-0 status-change">
          <form [formGroup]="statusChange">
            <label class="d-block">Update Status</label>
            <select
              class="form-control custom-select m-r-10"
              name="status"
              formControlName="status"
            >
              <option class="custom-select-option" value=""
                >Select Status</option
              >
              <option class="custom-select-option" *ngIf="claims.status !== 'in progress'" value="in progress"
                >In Progress</option
              >
              <option
                *ngIf="
                  claims.status !== 'in review'
                "
                class="custom-select-option"
                value="in review"
                >In Review</option
              >
            </select>
            <button
              (click)="changeStatus(claims._id)"
              class="btn btn-primary d-flex align-items-center"
            >
              Update
            </button>
          </form>
        </div>
      </div>
      <div class="card-body pr-0 pl-0">
        <div class="table-responsive">
          <table class="table table-striped mb-table">
            <thead>
              <tr>
                <th colspan="2">Claim Details</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span style="padding-right: 75px;">Claim No</span>:
                  {{ claims.sequence }}
                </td>
                <td>
                  <span style="padding-right: 48px;">Claim Status</span>:
                  {{ claims.status | titlecase }}
                </td>                
              </tr>
              <tr>
                <td>
                  <div *ngIf="claims?.userId?.firstName; else cCustomer">
                    <span style="padding-right: 60px;">Created By</span>:
                    {{ claims.userId?.firstName }} {{ claims.userId?.lastName }}
                  </div>
                  <ng-template #cCustomer>
                    <span style="padding-right: 60px;">Created By</span>:
                    {{ appname }} User
                  </ng-template>
                </td>
                <td>
                  <span style="padding-right: 57px;">Created On</span>:
                  {{ claims.createdAt | date: 'MM/dd/yyyy' }}
                </td>
              </tr>
              <tr *ngIf="claims.status === 'completed'">
                <td>
                  <span style="padding-right: 34px;">{{ claims.status | titlecase }} By</span>:
                  <span *ngIf="claims?.adminId?.firstName; else dAdmin">
                    {{ claims.adminId?.firstName }}
                    {{ claims.adminId?.lastName }}
                  </span>
                  <ng-template #dAdmin> {{ appname }} Admin </ng-template>
                </td>
                <td>
                  <span style="padding-right: 32px;">{{ claims.status | titlecase }} On</span>:
                  {{ claims.updatedAt | date: 'MM/dd/yyyy' }}
                </td>
              </tr>
              <tr *ngIf="
                  claims.status === 'approved' || claims.status === 'rejected'
                ">
                <td>
                  <span style="padding-right: 48px;">{{ claims.status | titlecase }} By</span>:
                  <span *ngIf="claims?.adminId?.firstName; else dAdmin">
                    {{ claims.adminId?.firstName }}
                    {{ claims.adminId?.lastName }}
                  </span>
                  <ng-template #dAdmin> {{ appname }} Admin </ng-template>
                </td>
                <td>
                  <span style="padding-right: 45px;">{{ claims.status | titlecase }} On</span>:
                  {{ claims.updatedAt | date: 'MM/dd/yyyy' }}
                </td>
              </tr>
              <tr>
                <td>
                  <span style="padding-right: 60px;">Claim Date</span>:
                  {{ claims.claimDate | date: 'MM/dd/yyyy' }}
                </td>
                <td>
                </td>                
              </tr>
            </tbody>
          </table>
        </div>

        <div *ngIf="claims.status === 'rejected'" class="alert alert-danger">
          What is reason behind reject claim ?<br />
          {{ claims.statusMessage }}
        </div>
        <div class="table table-responsive smart-table table-striped" *ngIf="claims">
          <table class="w-100 preview-table">
            <tbody>
              <tr class="table-success">
                <td colspan="2">Customer information</td>
              </tr>
              <tr>
                <td>Contract Number</td>
                <td>{{ claims.contractNumber }}</td>
              </tr>
              <tr>
                <td>Buyer/Debtor Name</td>
                <td>{{ claims.debitorName }}</td>
              </tr>
              <tr>
                <td>Phone Number</td>
                <td>
                  <span *ngIf="claims.debitorPhone > 8">
                    {{ claims.debitorPhone | phone }}
                  </span>
                </td>
              </tr>
              <tr>
                <td>Email</td>
                <td>{{ claims.debitorEmail }}</td>
              </tr>
              <tr>
                <td>Street Address</td>
                <td>{{ claims.debitorStreet }}</td>
              </tr>
              <tr>
                <td>City</td>
                <td>{{ claims.debitorCity }}</td>
              </tr>
              <tr>
                <td>State</td>
                <td>{{ claims.debitorState }}</td>
              </tr>
              <tr>
                <td>Zip Code</td>
                <td>{{ claims.debitorZip }}</td>
              </tr>   
              <tr>
                <td>Co-Buyer/ Co-Debtor Name</td>
                <td>{{ claims.codebitorName }}</td>
              </tr>
              <tr>
                <td>Phone Number</td>
                <td>
                  <span *ngIf="claims.codebitorPhone > 8">
                    {{ claims.codebitorPhone | phone }}
                  </span>
                </td>
              </tr>
              <tr>
                <td>Email</td>
                <td>{{ claims.codebitorEmail }}</td>
              </tr>
              <tr>
                <td>Street Address</td>
                <td>{{ claims.codebitorStreet }}</td>
              </tr>
              <tr>
                <td>City</td>
                <td>{{ claims.codebitorCity }}</td>
              </tr>
              <tr>
                <td>State</td>
                <td>{{ claims.codebitorState }}</td>
              </tr>
              <tr>
                <td>Zip Code</td>
                <td>{{ claims.codebitorZip }}</td>
              </tr>
              <tr class="table-success">
                <td colspan="2">Vehicle Information</td>
              </tr>
              <tr>
                <td>VIN Number</td>
                <td>{{ claims.vinNumber }}</td>
              </tr>
              <tr>
                <td>Current mileage</td>
                <td>{{ claims.currentMileage }}</td>
              </tr>
              <tr>
                <td>Year</td>
                <td>{{ claims.year }}</td>
              </tr>
              <tr>
                <td>Make</td>
                <td>{{ claims.make }}</td>
              </tr>
              <tr>
                <td>Model</td>
                <td>{{ claims.model }}</td>
              </tr>
              <tr>
                <td>Trim</td>
                <td>{{ claims.trim }}</td>
              </tr>
              <tr>
                <td colspan="2">
                  <div class="row">
                    <div class="col-sm">
                      Vechile Type
                    </div>

                    <div class="w-100 p-0 m-0 col-sm">
                      <table >
                        <tr>
                          <td>New</td>
                          <td>
                            :
                            <img
                              *ngIf="
                                claims?.vehicleType?.isNew;
                                else no2
                              "
                              width="23"
                              src="assets/images/icon/tick-box-50.png"
                            />
                            <ng-template #no2>
                              <img
                                width="23"
                                src="assets/images/icon/unchecked-checkbox-filled-50.png"
                              />
                            </ng-template>
                          </td>
                        </tr>

                        <tr>
                          <td>PRE-OWNED</td>
                          <td>
                            :
                            <img
                              *ngIf="
                                claims?.vehicleType?.isPreOwned;
                                else no2
                              "
                              width="23"
                              src="assets/images/icon/tick-box-50.png"
                            />
                            <ng-template #no2>
                              <img
                                width="23"
                                src="assets/images/icon/unchecked-checkbox-filled-50.png"
                              />
                            </ng-template>
                          </td>
                        </tr>

                        <tr>
                          <td>CERTIFIED PRE-OWNED</td>
                          <td>
                            :
                            <img
                              *ngIf="
                                claims?.vehicleType?.isCertifiedPreOwned;
                                else no2
                              "
                              width="23"
                              src="assets/images/icon/tick-box-50.png"
                            />
                            <ng-template #no2>
                              <img
                                width="23"
                                src="assets/images/icon/unchecked-checkbox-filled-50.png"
                              />
                            </ng-template>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
              </tr>
              <tr class="table-success">
                <td colspan="2">DEALER/CREDITOR INFORMATION</td>
              </tr>
              <tr>
                <td>Name</td>
                <td *ngIf="claims.dealer">{{ claims?.dealer?.name }}</td>
                <td *ngIf="!claims.dealer"></td>
              </tr>
              <tr>
                <td>Phone Number</td>
                <td>
                  <span *ngIf="claims.dealerPhone > 8">
                    {{ claims.dealerPhone | phone }}
                  </span>
                </td>
              </tr>
              <tr>
                <td>Street Address</td>
                <td>{{ claims.dealerStreet }}</td>
              </tr>
              <tr>
                <td>City</td>
                <td>{{ claims.dealerCity }}</td>
              </tr>
              <tr>
                <td>State</td>
                <td>{{ claims.dealerState }}</td>
              </tr>
              <tr>
                <td>Zip Code</td>
                <td>{{ claims.dealerZip }}</td>
              </tr>  
              <tr>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
              </tr>
              <tr class="table-success">
                <td colspan="2">ASSIGNEE/LIENHOLDER INFORMATION</td>
              </tr>
              <tr>
                <td>Name</td>
                <td>{{ claims.lienholderName }}</td>
              </tr>
              <tr>
                <td>Phone Number</td>
                <td>
                  <span *ngIf="claims.lienholderPhone > 8">
                    {{ claims.lienholderPhone | phone }}
                  </span>
                </td>
              </tr>
              <tr>
                <td>Street Address</td>
                <td>{{ claims.lienholderStreet }}</td>
              </tr>
              <tr>
                <td>City</td>
                <td>{{ claims.lienholderCity }}</td>
              </tr>
              <tr>
                <td>State</td>
                <td>{{ claims.lienholderState }}</td>
              </tr>
              <tr>
                <td>Zip Code</td>
                <td>{{ claims.lienholderZip }}</td>
              </tr> 
              <tr class="table-success">
                <td colspan="2">AGREEMENT AND FINANCE CONTRACT INFORMATION</td>
              </tr>
              <tr>
                <td>Agreement Purchase Price</td>
                <td>{{ claims.agreementPurchasePrice | currency: 'USD':'symbol':'1.2-2' }}</td>
              </tr>
              <tr>
                <td>Agreement Purchase Date</td>
                <td>{{ claims.agreementPurchaseDate | date: 'MM/dd/yyyy' }}</td>
              </tr>
              <tr>
                <td>Maximum benefit limit</td>
                <td>{{ claims.maximunBenefitLimit | currency: 'USD':'symbol':'1.2-2' }}</td>
              </tr>
              <tr>
                <td>Vehicle Purchase Price</td>
                <td>{{ claims.vehiclePurchasePrice | currency: 'USD':'symbol':'1.2-2' }}</td>
              </tr>
              <tr>
                <td>Date of First Payment</td>
                <td>{{ claims.dateOfFirstPayment | date: 'MM/dd/yyyy' }}</td>
              </tr>
              <tr>
                <td>APR</td>
                <td>{{ claims.APR }}%</td>
              </tr>
              <tr>
                <td>Monthly Payment Amount</td>
                <td>{{ claims.monthlyPaymentAmount | currency: 'USD':'symbol':'1.2-2' }}</td>
              </tr>
              <tr>
                <td>Amount Financed</td>
                <td>{{ claims.amountFinanced | currency: 'USD':'symbol':'1.2-2' }}</td>
              </tr>
              <tr>
                <td>Total of All Payment</td>
                <td>{{ claims.totalOfAllPayement | currency: 'USD':'symbol':'1.2-2' }}</td>
              </tr>
              <tr>
                <td colspan="2">
                  <div class="row">
                    <div class="col-sm">
                      Type of Financing Contract
                    </div>

                    <div class="w-100 p-0 m-0 col-sm">
                      <table>
                        <tr>
                          <td>Retail Installment Sales (CONVENTIONAL LOANS)</td>
                          <td>
                            :
                            <img
                              *ngIf="
                                claims?.financingContractType?.retailInstallmentSales;
                                else no2
                              "
                              width="23"
                              src="assets/images/icon/tick-box-50.png"
                            />
                            <ng-template #no2>
                              <img
                                width="23"
                                src="assets/images/icon/unchecked-checkbox-filled-50.png"
                              />
                            </ng-template>
                          </td>
                        </tr>

                        <tr>
                          <td>LEASE</td>
                          <td>
                            :
                            <img
                              *ngIf="
                                claims?.financingContractType?.lease;
                                else no2
                              "
                              width="23"
                              src="assets/images/icon/tick-box-50.png"
                            />
                            <ng-template #no2>
                              <img
                                width="23"
                                src="assets/images/icon/unchecked-checkbox-filled-50.png"
                              />
                            </ng-template>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
              </tr>
              <ng-container *ngIf="claims.status !== 'in progress' && (loggedInUserType==='dowcsuperadmin' || loggedInUserType==='superadmin')">
              <tr class="table-success">
                <td colspan="2">CALCULATIONS</td>
              </tr>              
              <tr>
                <td>Outstanding amount financed</td>
                <td>{{ claims.outstandingAmountFinanced | currency: 'USD':'symbol':'1.2-2' }}</td>
              </tr><tr>
                <td>Cancelled products</td>
                <td>{{ claims.cancelledProducts | currency: 'USD':'symbol':'1.2-2' }}</td>
              </tr><tr>
                <td>Subtotal</td>
                <td>{{ claims.subTotal | currency: 'USD':'symbol':'1.2-2' }}</td>
              </tr>
              <tr>
                <td>Consumer liability</td>
                <td>{{ claims.consumerLiability | currency: 'USD':'symbol':'1.2-2' }}</td>
              </tr>
              <tr>
                <td>Maximum Debt cancellation amount </td>
                <td>{{ claims.maximumDebtCancellation | currency: 'USD':'symbol':'1.2-2' }}</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
              </tr>
              <tr class="table-success">
                <td colspan="2">PAYOUT</td>
              </tr>
              <tr>
                <td>Truncated amount</td>
                <td>{{ claims.truncatedAmount | currency: 'USD':'symbol':'1.2-2' }}</td>
              </tr>
              <tr>
                <td>Salvage credit (10%)</td>
                <td>{{ claims.salvageCredit | currency: 'USD':'symbol':'1.2-2' }}</td>
              </tr><tr>
                <td>Final Payout to Creditor</td>
                <td>{{ claims.finalPayout | currency: 'USD':'symbol':'1.2-2' }}</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
              </tr>
            </ng-container>
            </tbody>
          </table>
        </div>

        
      </div> -->

      <div class="card-footer">
        <div class="step-btn-container d-flex align-items-center justify-content-between pull-right">
          <button type="button" class="btn btn-primary d-flex align-items-center" routerLink="/debitcancellation-list">
            <i class="mdi mdi-arrow-left mr-1"></i>
            Back
          </button>
        </div>
      </div>
    </div>
    <div class="help-container">
      <div class="help-section">
        <p class="help-text" (click)="openHelpPopup()">
          <i class="hellp-popup-icons"></i>
        </p>
      </div>
    </div>
  </div>
</div>