/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./form-error-wrapper.component";
import * as i3 from "../../core/service/error-message.service";
var styles_FormErrorWrapperComponent = [];
var RenderType_FormErrorWrapperComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FormErrorWrapperComponent, data: {} });
export { RenderType_FormErrorWrapperComponent as RenderType_FormErrorWrapperComponent };
function View_FormErrorWrapperComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "help-block"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formateError(_co.errorObject[_v.parent.context.$implicit], _co.control.errors[_v.parent.context.$implicit]); _ck(_v, 1, 0, currVal_0); }); }
function View_FormErrorWrapperComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FormErrorWrapperComponent_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.control.hasError(_v.context.$implicit) && _co.control.touched) && _co.showError); _ck(_v, 2, 0, currVal_0); }, null); }
function View_FormErrorWrapperComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "help-block"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.apiErrorMessage; _ck(_v, 1, 0, currVal_0); }); }
export function View_FormErrorWrapperComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(2, { "has-error": 0, "has-placeholder-error": 1 }), i0.ɵncd(null, 0), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FormErrorWrapperComponent_1)), i0.ɵdid(5, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FormErrorWrapperComponent_3)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.hasError(), _co.hasPlaceHoderError()); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.errorKeys; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.apiErrorMessage; _ck(_v, 7, 0, currVal_2); }, null); }
export function View_FormErrorWrapperComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-form-error-wrapper", [], null, null, null, View_FormErrorWrapperComponent_0, RenderType_FormErrorWrapperComponent)), i0.ɵdid(1, 4833280, null, 0, i2.FormErrorWrapperComponent, [i3.ErrorMessageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormErrorWrapperComponentNgFactory = i0.ɵccf("app-form-error-wrapper", i2.FormErrorWrapperComponent, View_FormErrorWrapperComponent_Host_0, { control: "control", controlName: "controlName", apiErrorType: "apiErrorType", apiServiceUrl: "apiServiceUrl", config: "config", showError: "showError" }, {}, ["*"]);
export { FormErrorWrapperComponentNgFactory as FormErrorWrapperComponentNgFactory };
