<div class="row agent-container">
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        Audit
      </div>
      <div class="card-body p-0">
        <div class="card mb-0">
          <div class="card-body pl-0 pr-0 pb-0">
            <form [formGroup]="fiterForm">
              <a class="cursor-pointer hidden-xl-up pl-2" 
              (click)="utilService.toggleDivClass('filter-audits','hidden-lg-down')">
                <i class="fa fa-plus mr-2"></i><i class="fa fa-minus mr-2 d-none"></i>Filter
              </a>
              <div class="row hidden-lg-down" id="filter-audits">
                <div class="col-12 d-xl-flex align-items-start">
                  <div class="col-12 col-xl-2">
                    <div class="form-group mb-0">
                      <label class="d-block small">Action</label>
                      <select class="form-control custom-select" name="type" formControlName="type">
                        <option value="">All</option>
                        <option *ngFor="let action of actionList" [value]="action" class="text-capitalize">{{action }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-12 col-xl-1">
                    <div class="form-group mb-0 pos-rel">
                      <label class="d-block small">From</label>
                      <input class="form-control small-date" type="text" name="dateTimeFromFilter" ngbDatepicker
                        placeholder="MM/DD/YYYY" #date_from="ngbDatepicker" (click)="date_from.toggle()"
                        formControlName="date_from" [readonly]="true" [minDate]="{ year: 1900, month: 1, day: 1 }"
                        [maxDate]="{
                          year: utilService.getCurrentYear,
                          month: utilService.getCurrentMonth,
                          day: utilService.getCurrentDay
                        }" />
                      <a class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer pos-abs datecancel top-73"
                        (click)="
                          utilService.removeDate(
                            fiterForm,
                            'date_from'
                          )
                        " placement="left" ngbTooltip="Remove" *ngIf="fiterForm.controls['date_from'].value">
                        <i class="ti-close text-danger m-r-10"></i>
                      </a>
                    </div>
                  </div>
                  <div class="col-12 col-xl-1">
                    <div class="form-group mb-0 pos-rel">
                      <label class="d-block small">To</label>
                      <input class="form-control small-date" type="text" name="dateTimeToFilter" ngbDatepicker
                        placeholder="MM/DD/YYYY" #date_to="ngbDatepicker" (click)="date_to.toggle()"
                        formControlName="date_to" [readonly]="true" [minDate]="{ year: 1900, month: 1, day: 1 }"
                        [maxDate]="{
                          year: utilService.getCurrentYear,
                          month: utilService.getCurrentMonth,
                          day: utilService.getCurrentDay
                        }" />
                      <a class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer pos-abs datecancel top-73"
                        (click)="
                          utilService.removeDate(fiterForm, 'date_to')
                        " placement="left" ngbTooltip="Remove" *ngIf="fiterForm.controls['date_to'].value">
                        <i class="ti-close text-danger m-r-10"></i>
                      </a>
                    </div>
                  </div>
                  <!--   <div class="col-12 col-xl-2">
                    <div class="form-group mb-0">
                      <label class="d-block">Customer</label>
                      <select
                        class="form-control custom-select"
                        name="cid"
                        formControlName="cid"
                      >
                        <option value="">All</option>
                        <option
                          *ngFor="let customer of customerList"
                          [value]="customer._id"
                          >{{ customer.firstName+' '+customer.lastName }}</option
                        >
                      </select>
                    </div>
                  </div>-->
                  <div class="col-12 col-xl-2">
                    <div class="form-group mb-0">
                      <label class="d-block small">Name</label>
                      <input type="text" name="name" class="form-control" formControlName="name"
                        (keypress)="omit_special_char($event)" />
                    </div>
                  </div>
                  <div class="col-12 col-xl-2">
                    <div class="form-group mb-0">
                      <label class="d-block small">Email</label>
                      <input type="text" name="email" class="form-control" formControlName="email"
                        (keypress)="omit_special_Mailchar($event)" />
                    </div>
                  </div>
                  <div class="col-12 col-xl-2">
                    <div class="form-group mb-0">
                      <label class="d-block small">Reference</label>
                      <input type="text" name="rid" class="form-control" formControlName="rid"
                        (keypress)="omit_special_char($event)" />
                    </div>
                  </div>
                  <div class="col-12 col-xl-2">
                    <div class="form-group mb-0">
                      <label class="d-block small">Domain</label>
                      <input type="text" name="domain" class="form-control" formControlName="domain"
                        (keypress)="omit_special_char($event)" />
                    </div>
                  </div>
                  <div class="none">
                    <input type="hidden" name="sortFieldFilter" formControlName="sortFieldFilter" #sortFieldFilter />
                    <input type="hidden" name="sortByFilter" formControlName="sortByFilter" #sortByFilter />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="card-body" *ngIf="!isLoading && auditList">
        <div class="table table-responsive smart-table" *ngIf="auditList && auditList.length > 0">
          <table class="w-100">
            <thead>
              <tr>
                <th class="pointer">
                  <div [ngClass]="{
                      sort: sortBy === 'type',
                      asc: sortDir == 1,
                      desc: sortDir == -1
                    }" (click)="sort('type')">
                    Action
                  </div>
                </th>
                <th class="pointer">
                  <div [ngClass]="{
                      sort: sortBy === 'updateTime',
                      asc: sortDir == 1,
                      desc: sortDir == -1
                    }" (click)="sort('updateTime')">
                    DateTime
                  </div>
                </th>
                <th>Customer</th>
                <th>Email</th>
                <th>Reference</th>
                <th>Domain</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="
                  let audit of auditList
                ">
                <td class="text-capitalize">{{ audit.type }}</td>
                <td>{{ audit.updateTime | date: 'MM/dd/yyyy HH:mm:ss'  }}</td>
                <td><span *ngIf="audit.cid">{{ audit.cid.firstName+' '+ audit.cid.lastName }}</span></td>
                <td><span *ngIf="audit.cid">{{ audit.cid.email }}</span></td>
                <td>
                  <a class="ng2-smart-action ng2-smart-action-edit-edit" target="_blank"
                    routerLink="/{{ audit.url }}" placement="top" ngbTooltip="View">{{ audit.rId }}</a>
                </td>
                <td><a *ngIf="audit.domain" href="https://{{ audit.detail }}"></a>{{ audit.domain }}</td>
                <td>
                 <!--  <a *ngIf="audit.type !=='claim-deleted' && audit.type!=='send-code' && audit.type !=='update-dealer' && audit.type!=='create-dealer'" href="http://ip-api.com/{{ audit.detail }}"  target="_blank">{{ audit.detail }}</a>-->
                 <a *ngIf="audit.type !=='claim-deleted' && audit.type!=='send-code' && audit.type !=='update-dealer' && audit.type!=='create-dealer'" (click)="ipDetails(audit.detail)" >{{ audit.detail }}</a>
                  <span *ngIf="audit.type ==='claim-deleted' || audit.type ==='update-dealer' ||  audit.type ==='create-dealer'">{{ audit.detail}}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <app-no-data-msg [message]="'No Audits Found'" *ngIf="auditList && auditList.length === 0">
        </app-no-data-msg>

      </div>
      <div class="card-footer" *ngIf="auditList && auditList.length > 0">
        <div class="row">
          <div class="col-md">
            <span>{{
                              utilService.showPaginationInfo(pager.Totalrecords, pager.perPage, pager.currentPage)
                            }}</span>
          </div>
          <div class="col-sm">
            <form [formGroup]="fiterForm">
              <div class="form-group mb-0 pull-right">
                <small>Rows:</small>
                <select class="form-control custom-select rows-button" name="perPage" formControlName="perPage">
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                <input *ngIf="pager.TotalPages && pager.TotalPages>1" type="number" #goto name="goto"
                  class="form-control goto-input" placeholder="Page No" min="1" max="{{pager.TotalPages}}" />
                <span *ngIf="pager.TotalPages && pager.TotalPages>1" class="btn btn-link page-goto goto-btn" (click)="goPage()">
                  Go
                </span>
              </div>
            </form>
          </div>
          <div class="col-md" *ngIf="pager.TotalPages && pager.TotalPages>1">
            <div class="">
              <ul class="pagination pull-right">
                <li>
                  <a (click)="setPage(pager.currentPage - 1)"><i class="icon-arrow-left"></i></a>
                </li>
                <li *ngFor="let page of PageList" [ngClass]="{active:pager.currentPage === page.page}">
                  <a (click)="setPage(page.page)">{{page.label}}</a>
                </li>
                <li>
                  <a (click)="setPage(pager.currentPage + 1)"><i class="icon-arrow-right"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-table-loader [isLoading]="isLoading"></app-table-loader>
  </div>
</div>