import { Component, OnInit } from '@angular/core';
import { UtilService } from '@app/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { CustomerService } from '@app/core/service/customer.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-dealer-create',
  templateUrl: './dealer-create.component.html',
  styleUrls: ['./dealer-create.component.scss']
})
export class DealerCreateComponent implements OnInit {
  dealerForm: FormGroup;
  dealerFormdata: any;
  user: Authentication.User;
  isLoading = false;
  constructor(
    public activeModal: NgbActiveModal,
    public utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private customerService: CustomerService
  ) {
    this.user = this.authenticationService.credentials.user;
    this.createForm();
  }

  ngOnInit() {
  }

  createForm() {
    this.dealerForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      status: ['Active', [Validators.required]]
    });
  }

  create() {
    this.dealerFormdata = this.utilService.removeWhiteSpace(this.dealerForm.value);
    if (this.dealerForm.valid) {

      this.customerService
      .createDealer(this.dealerFormdata)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response: any) => {
        if (response.status) {
          this.toastrService.info('Dealer created Successfully..!');
          this.activeModal.dismiss(response);
        } else {
          this.toastrService.warning(response.message);
        }
      });
   
    }
  }

}
