<form [formGroup]="dealerForm" (ngSubmit)="create()">
  <div class="modal-header">
    <h4 class="modal-title">Create Dealer</h4>
  </div>
  <div class="modal-body">

    <div class="form-group row">
      <label class="col-12 col-xl-3">Name</label>
      <div class="col-12 col-xl-9">
        <input type="text" name="name" class="form-control" formControlName="name" />
      </div>
    </div>
    <div class="form-group row">
      <label class="col-12 col-xl-3">Status</label>
      <div class="col-12 col-xl-9">
        <select class="form-control custom-select" name="status" formControlName="status">
          <option value="Active">Active</option>
          <option value="Inactive">Inactive</option>
        </select>
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">
      Cancel
    </button>
    <button class="btn btn-primary" [disabled]="dealerForm.invalid || isLoading" type="submit">
      <app-button-loader [label]="'Create'"></app-button-loader>
    </button>
  </div>
</form>