import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationComponent } from '@app/shared/modals/confirmation';
import { STATES } from '@app/core/constants';
import { DateTime } from 'luxon';
import { CustomerService } from '@app/core/service/customer.service';
import { finalize, tap, map, switchMap, distinctUntilChanged, debounceTime, catchError, filter } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { UtilService, CONFIRMATION_MSGS, LOCAL_VAR } from '@app/core';
import { of as observableOf, Observable, Subject, merge, of } from 'rxjs';
import { NgbTypeaheadConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { ClaimHelpComponent } from '@app/shared/modals/claim-help/claim-help.component';
import { ClaimService } from '@app/core/service/claim.service';
import { environment } from '@env/environment.local';

@Component({
  selector: 'app-create-claim',
  templateUrl: './create-claim.component.html',
  styleUrls: ['./create-claim.component.scss'],
  providers: [NgbTypeaheadConfig]
})
export class CreateClaimComponent implements OnInit {
  leaseCreateForm: FormGroup;
  isLoading = false;
  queryParams: any;
  states: any = STATES;
  roles: string[];
  dealersList: [{ name: 'DOWC'; _id: '0' }]; //selectedPersons: Person[] = <any>[{ name: 'Karyn Wright' }, { name: 'Other' }];
  user: any;
  loggedInUserType: string;
  loggedinuserdetail: any;
  claimData: any;
  isreivewMode = false;
  isEditMode = false;
  claimId: any = null;
  baseUrl = '';
  uploadedImg: any = {
    damangeReport: null,
    contractDoc: null,
    leaseAgreement: null,
    odometerStatement: null,
    physicalDamage: null,
    additionalDocuments: null
  };
  selectedCustomer: any = null;
  filter1_name: any;
  filter1_value: any;
  filter2_name: any;
  filter2_value: any;
  claims_data: any;
  show_claims = false;
  getcontract_loading = false;

  @ViewChild('instance') instance: any;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  //formatter = (result: string) => result.toUpperCase();
  dealers = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term =>
        term.length < 3
          ? []
          : this.dealersList
            .filter(
              v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1
            )
            .slice(0, 10)
      )
    );
  formatter = (x: { name: string }) => x.name;
  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private customerService: CustomerService,
    private toastrService: ToastrService,
    public utilService: UtilService,
    private claimService: ClaimService,
    private authenticationService: AuthenticationService,
  ) {
    this.user = this.authenticationService.credentials.user;
    this.loggedInUserType = this.user.userRoleId.slug;
    this.baseUrl = environment.s3Url;
    this.selectedCustomer = localStorage.getItem(LOCAL_VAR.selectedCustomer);
    this.selectedCustomer = JSON.parse(this.selectedCustomer);
    if (this.router.url.indexOf('review') !== -1) {
      this.isreivewMode = true;
    } else {
      this.isreivewMode = false;
    }
    if (this.router.url.indexOf('edit') !== -1) {
      this.isEditMode = true;
    } else {
      this.isEditMode = false;
    }

    this.route.queryParams.pipe().subscribe(params => {
      this.queryParams = params;
    });

    if (this.isEditMode && this.route.snapshot.params['claimId']) {
      this.claimId = this.route.snapshot.params['claimId'];
    }
    if (this.claimId) {
      this.getLeaseDealbyId(this.claimId);
    }
    this.createForm();
    this.getDealers();
  }

  ngOnInit() {
    if (this.loggedInUserType === 'dowcview' || this.loggedInUserType === 'adminview') {
      this.router.navigate(['/products'], { replaceUrl: true });
    }    
  }

  private createForm() {
    if (this.claimData) {
      this.uploadedImg.damangeReport = this.claimData
        .damangeReport
        ? this.claimData.damangeReport
        : null;
      this.uploadedImg.contractDoc = this.claimData
        .contractDoc
        ? this.claimData.contractDoc
        : null;
      this.uploadedImg.leaseAgreement = this.claimData.leaseAgreement
        ? this.claimData.leaseAgreement
        : null;
      this.uploadedImg.odometerStatement = this.claimData
        .odometerStatement
        ? this.claimData.odometerStatement
        : null;
      this.uploadedImg.physicalDamage = this.claimData.physicalDamage
        ? this.claimData.physicalDamage
        : null;
      this.uploadedImg.additionalDocuments = this.claimData.additionalDocuments
        ? this.claimData.additionalDocuments
        : null;
    }

    this.leaseCreateForm = this.formBuilder.group({
      contractNumber: [
        this.claimData ? this.claimData.contractNumber : '',
        [Validators.required]
      ],
      dateOfLoss_at: [
        this.claimData
          ? this.claimData.dateOfLoss_at
            ? DateTime.fromISO(this.claimData.dateOfLoss_at).toObject()
            : null
          : '',
        [Validators.required]
      ],
      vehiclePurchased: [
        this.claimData ? this.claimData.vehiclePurchased : 'N'
      ],
      // todayDate: [
      //   !this.iscreateMode && this.claimData ? '' : '',
      //   [Validators.required]
      // ],
      customerName: [
        this.claimData ? this.claimData.customerName : '',
        [Validators.required]
      ],
      email: [
        this.claimData ? this.claimData.email : this.user.email,
        [Validators.required, Validators.pattern(/^\d{10}|\w+([\.+-]?\w+)*@\w+([\.-]?\w+)$/)]
      ],
      streetAddress: [
        this.claimData ? this.claimData.streetAddress : '',
        Validators.required
      ],
      city: [this.claimData ? this.claimData.city : '', Validators.required],
      state: [this.claimData ? this.claimData.state : '', Validators.required],
      zipCode: [
        this.claimData ? this.claimData.zipCode : '',
        Validators.compose([
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(5)
        ])
      ],
      phoneNumber: [
        this.claimData ? this.claimData.phoneNumber : '',
        Validators.compose([
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(12)
        ])
      ],
      dealer: [
        this.claimData
          ? this.claimData.dealer == null
            ? ''
            : {
              _id: this.claimData.dealer._id,
              name: this.claimData.dealer.name
            }
          : '',
        /* this.claimData ? this.claimData.dealer : '', */
        [Validators.required]
      ],
      dealershipConatactAtName: [
        this.claimData && this.claimData.dealershipConatactAtName
          ? this.claimData.dealershipConatactAtName
          : null,
        [Validators.required]
      ],
      dealershipEmail: [
        this.claimData ? this.claimData.dealershipEmail : '',
        [Validators.required, Validators.pattern(/^\d{10}|\w+([\.+-]?\w+)*@\w+([\.-]?\w+)$/)]
      ],
      dealershipPhoneNumber: [
        this.claimData ? this.claimData.dealershipPhoneNumber : '',
        Validators.compose([
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(12)
        ])
      ],
      dealershipAddress: [
        this.claimData && this.claimData.dealershipAddress
          ? this.claimData.dealershipAddress
          : null,
        Validators.required
      ],
      dealershipCity: [
        this.claimData && this.claimData.dealershipCity
          ? this.claimData.dealershipCity
          : null,
        Validators.required
      ],
      dealershipState: [
        this.claimData && this.claimData.dealershipState
          ? this.claimData.dealershipState
          : null,
        Validators.required
      ],
      dealershipZipCode: [
        this.claimData && this.claimData.dealershipZipCode
          ? this.claimData.dealershipZipCode
          : null,
        Validators.compose([
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(5)
        ])
      ],
      year: [
        this.claimData && this.claimData.year ? this.claimData.year : null,
        Validators.compose([
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(4)
        ])
      ],
      make: [
        this.claimData && this.claimData.make ? this.claimData.make : null,
        Validators.required
      ],
      model: [
        this.claimData && this.claimData.model ? this.claimData.model : null,
        Validators.required
      ],
      currentMileage: [
        this.claimData && this.claimData.currentMileage
          ? this.claimData.currentMileage
          : null,
        Validators.required
      ],
      vinNumber: [
        this.claimData && this.claimData.vinNumber
          ? this.claimData.vinNumber
          : null,
        Validators.compose([Validators.required, Validators.minLength(17)])
      ],
      isVehicleReturn: [
        this.claimData && this.isEditMode
          ? this.claimData.isVehicleReturn
          : null,
        Validators.required
      ],
      isVehicleInspected: [
        this.claimData && this.isEditMode
          ? this.claimData.isVehicleInspected
          : null,
        Validators.required
      ],
      leaseExpireDate: [
        this.claimData
          ? this.claimData.leaseExpireDate
            ? DateTime.fromISO(this.claimData.leaseExpireDate).toObject()
            : null
          : '',
        [Validators.required]
      ],
      damangeReport: [
        this.claimData ? this.claimData.damangeReport : '',
        [Validators.required]
      ],
      contractDoc: [
        this.claimData ? this.claimData.contractDoc : '',
        [Validators.required]
      ],
      leaseAgreement: [
        this.claimData ? this.claimData.leaseAgreement : '',
        [Validators.required]
      ],
      odometerStatement: [
        this.claimData ? this.claimData.odometerStatement : '',
        [Validators.required]
      ],
      physicalDamage: [
        this.claimData ? this.claimData.physicalDamage : ''
      ],
      additionalDocuments: [
        this.claimData ? this.claimData.additionalDocuments : ''
      ],
      filter1select: [''],
      filter1: [],
      filter2select: [''],
      filter2: []
    });
    this.formControlsValueChanges();

  }

  ngAfterViewInit() {
    $("app-required-indication").each(function (index, element) {
      var text = element.innerHTML;
      element.innerHTML = text.replace('\n<!--', '<!--');
    });
    var domainconfig = JSON.parse(localStorage.getItem(LOCAL_VAR.domainconfig));
    if(domainconfig){
      if(domainconfig.secondaryColor){
        this.utilService.changeCssAttribute('card-header','background-color',domainconfig.secondaryColor);
      }
    } 
  }

  formControlsValueChanges() {
    // this.leaseCreateForm.get('isVehicleReturn').valueChanges.subscribe(value => {
    //   if (this.leaseCreateForm.get('isVehicleReturn').value === false) {
    //     this.toastrService.warning("You must return your vehicle to your selling dealer.  If you have any questions, please call the number listed on your contract.");
    //   }
    // });
    // this.leaseCreateForm.get('isVehicleInspected').valueChanges.subscribe(value => {
    //   if (this.leaseCreateForm.get('isVehicleInspected').value === false) {
    //     this.toastrService.warning("Your vehicle must be inspected prior to turning it in.  If you have any questions, please call the number listed on your contract.");
    //   }
    // });
    this.leaseCreateForm.get('dealer').valueChanges.subscribe(values => {
      this.checkDealer();
    });
  }

  submitData() {
    // this.onSubmitStepChange.emit();
    // if (this.selectedCustomer) {
    this.isLoading = true;
    let payload = this.leaseCreateForm.value;
    payload = this.utilService.removeEmpty(payload);
    payload.userId = this.selectedCustomer
      ? this.selectedCustomer._id
      : this.user._id
        ? this.user._id
        : null;
    if (payload.dateOfLoss_at) {
      payload.dateOfLoss_at = DateTime.fromObject(
        payload.dateOfLoss_at
      ).toISO();

    }
    if (payload.leaseExpireDate) {
      payload.leaseExpireDate = DateTime.fromObject(
        payload.leaseExpireDate
      ).toISO();
    }
    if (this.claimData) {
      this.claimService
        .updateLease(this.claimId, payload)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe((response: any) => {
          this.toastrService.info('Thank you for submitting your claim.  One of our Claims Specialists will contact you shortly.');
          this.router.navigate(['/lease-list']);
        });
    } else {
      this.claimService
        .addLease(payload)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe((response: any) => {
          this.toastrService.info('Thank you for submitting your claim.  One of our Claims Specialists will contact you shortly.');
          this.router.navigate(['/lease-list']);
        });
    }
  }

  getDealers() {
    this.customerService
      .getDealers()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.records) {
          this.dealersList = response.records;
        }
      });
  }

  getLeaseDealbyId(DealId: string) {
    this.claimService
      .getLeaseClaimById(DealId)
      .subscribe((response: any) => {
        if (response.records) {
          this.claimData = response.records;
          if (this.claimData.status == 'voided') {
            localStorage.setItem(LOCAL_VAR.selectedProduct, 'lease');
            this.router.navigate(['/lease-list'], { replaceUrl: true });
          }
          this.createForm();
        } else if (this.isEditMode) {
            localStorage.setItem(LOCAL_VAR.selectedProduct, 'lease');
            this.router.navigate(['/lease-list'], { replaceUrl: true });
          }
      });
  }

  onSelectFile(event: any, type: any) {
    this.isLoading = true;
    if (event.target.files && event.target.files.length) {
      const formData: FormData = new FormData();
      formData.append('file', event.target.files[0]);
      if (this.claimData)
        formData.append('claimId', this.claimData._id);
      formData.append('fieldName', type);
      formData.append('prod', 'lease');
      this.authenticationService.uploadPhoto(formData).subscribe(
        (res: any) => {
          this.isLoading = false;
          this.leaseCreateForm.controls[type].setValue(res.path);
          this.leaseCreateForm.controls[type].markAsDirty();
          //this.leaseCreateForm.controls[type].setValue(null);
          //TODO - change ui
          this.uploadedImg[type] = res.path;
        },
        (error: any) => {
          this.isLoading = false;
          console.log(error);
          this.toastrService.warning('Invalid file');
        }
      );
    }
  }

  async removeImage(type: any) {
    const modalRef = this.modalService.open(ConfirmationComponent);

    modalRef.componentInstance.title = this.utilService.objectResolver(
      'files.deleteFiles.title',
      CONFIRMATION_MSGS
    );
    modalRef.componentInstance.body = this.utilService.objectResolver(
      'files.deleteFiles.content',
      CONFIRMATION_MSGS
    );
    modalRef.componentInstance.okBtn = this.utilService.objectResolver(
      'files.deleteFiles.okBtn',
      CONFIRMATION_MSGS
    );
    modalRef.componentInstance.cancelBtn = this.utilService.objectResolver(
      'files.deleteFiles.cancelBtn',
      CONFIRMATION_MSGS
    );
    modalRef.componentInstance.service = this.utilService.objectResolver(
      'files.deleteFiles.service',
      CONFIRMATION_MSGS
    );
    modalRef.componentInstance.method = this.utilService.objectResolver(
      'files.deleteFiles.method',
      CONFIRMATION_MSGS
    );
    let payload: any;
    if (this.claimData) {
      payload = { claimId: this.claimData._id, fieldName: type, prod: 'lease' };
    } else {
      let filename = this.leaseCreateForm.controls[type].value;
      payload = { fieldName: filename, prod: 'lease' };
    }

    modalRef.componentInstance.payload = [payload];

    const result: any = await modalRef.result.catch(e => console.log(e));
    if (result) {
      this.toastrService.info('Document deleted Successfully!..');
      this.isLoading = false;
      this.uploadedImg[type] = null;
      this.leaseCreateForm.controls[type].setValue(null);
      this.leaseCreateForm.controls[type].markAsDirty();
    }

  }

  cancelClick() {
    this.router.navigate(['/lease-list']);
  }

  async openHelpPopup() {
    const modalRef = await this.modalService.open(ClaimHelpComponent);
  }

  omit_special_char(event: any) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k <= 122) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }


  omit_special_Mailchar(event: any) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k <= 122) || k === 46 || k === 64 || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  getMyContract() {
    this.getcontract_loading = true;
    this.filter1_name = this.leaseCreateForm.controls.filter1select.value;
    this.filter1_value = $.trim(this.leaseCreateForm.controls.filter1.value);
    this.filter2_name = this.leaseCreateForm.controls.filter2select.value;
    this.filter2_value = $.trim(this.leaseCreateForm.controls.filter2.value);
    // if(this.filter1_name == '' || this.filter1_value == '' || this.filter2_name == '' || this.filter2_value =='')
    // {
    //   this.toastrService.warning('please select all fields and values');
    //   return true;
    // }
    // console.log(this.filter1_name,'1',this.filter1_value,'2',this.filter2_name,'3',this.filter2_value,'4')
    // var data = { [filter1_name] : filter1_value, [filter2_name]: filter2_value };
    if (this.filter1_name && this.filter1_value && this.filter2_name && this.filter2_value) {

      var data = {

        "firstname": this.filter1_name == 'firstname' ? $.trim(this.filter1_value) : '',
        "lastname": this.filter1_name == 'lastname' ? $.trim(this.filter1_value) : '',
        "phone": this.filter1_name == 'phone' ? $.trim(this.filter1_value) : (this.filter2_name == 'phone' ? $.trim(this.filter2_value) : ''),
        "vin": this.filter1_name == 'vin' ? $.trim(this.filter1_value) : (this.filter2_name == 'vin' ? $.trim(this.filter2_value) : ''),
        "contract": this.filter2_name == 'contract' ? $.trim(this.filter2_value) : '',
        "ProductType": "ANC",
        "ProductSubType": "",
        "Product": ""
      }
      this.claimService
        .getContractDetails(data)
        .subscribe((res: any) => {
          this.getcontract_loading = false;
          if (res.status === 1) {
            this.toastrService.info('The contract information provided is for reference only. Please edit or make any changes you deem necessary.', 'We have found a contract matching your search!');
            if (res.data.length == 1) {
              // this.show_claims = true;
              this.setContractData(res.data[0]);
              // this.claims_data = res.data;
            }
            else {
              this.claims_data = res.data;
              this.show_claims = true;
            }
          } else {
            this.show_claims = false;
            this.toastrService.warning("Try a different combination or fill in the details manually.", "Sorry! we couldn't find that contract!");
            this.resetContractData();
          }
        });
    }
    else {
      this.toastrService.warning('please select / fill all fields and values');
      this.show_claims = false;
      this.getcontract_loading = false;
      return true;
    }
  }

  getContract() {
      var contactNumber = $.trim(this.leaseCreateForm.controls.contractNumber.value);
    if (contactNumber !== '') {
      var data = { "contractNo": contactNumber, "productType":"ANC" };
      this.claimService
        .getContractInfo(data)
        .subscribe((res: any) => {
          if (res.status === 1) {
            this.toastrService.info(res.msg);
            this.setContractData(res.data);
          } else {
            this.toastrService.warning(res.msg);
            this.resetContractData();
          }
        });
    } else {
      this.toastrService.warning('please fill Contract No');
    }
  }

  claim_select(event: any) {
    // console.log(event.target.value,'valuuuuu')
    this.setContractData(this.claims_data[event.target.value]);
    this.show_claims = false;
  }

  setContractData(data: any) {
    this.leaseCreateForm.controls['contractNumber'].setValue(data.ContractNumber);
    this.leaseCreateForm.controls['vinNumber'].setValue(data.VIN);
    this.leaseCreateForm.controls['customerName'].setValue(data.FirstName ? data.FirstName : '' + ' ' + data.LastName ? data.LastName : '');
    this.leaseCreateForm.controls['phoneNumber'].setValue(data.PhysicalPhone);
    // let address = '';
    // if (data.PhysicalAddressLine1)
    //   address = data.PhysicalAddressLine1
    // if (data.PhysicalAddressLine2)
    //    address = address + data.PhysicalAddressLine1 ? ', ' : ' ' + data.PhysicalAddressLine2
    // this.leaseCreateForm.controls['streetAddress'].setValue(address);
    // this.leaseCreateForm.controls['city'].setValue(data.PhysicalCity);
    // this.leaseCreateForm.controls['state'].setValue(data.PhysicalState);
    // this.leaseCreateForm.controls['zipCode'].setValue(data.PhysicalZipCode);
    this.leaseCreateForm.controls['make'].setValue(data.VehicleMake);
    this.leaseCreateForm.controls['model'].setValue(data.VehicleModel);
    this.leaseCreateForm.controls['year'].setValue(data.VehicleYear);
    this.leaseCreateForm.controls['vehiclePurchased'].setValue((data.NewUsed === 'Used') ? 'U' : 'N');
    this.leaseCreateForm.controls['dealer'].setValue(this.dealersList.find((user: any) => { return (user.name.toLowerCase() == data.DealerName.toLowerCase() /*||  user.dealerNo == data.dealerNo */) }));
  }

  resetContractData(){
    //this.leaseCreateForm.controls['contractNumber'].setValue('');
    //this.leaseCreateForm.controls['vinNumber'].setValue('');
    this.leaseCreateForm.controls['customerName'].setValue('');
    this.leaseCreateForm.controls['phoneNumber'].setValue('');
    // this.leaseCreateForm.controls['streetAddress'].setValue('');
    // this.leaseCreateForm.controls['city'].setValue('');
    // this.leaseCreateForm.controls['state'].setValue('');
    // this.leaseCreateForm.controls['zipCode'].setValue('');
    this.leaseCreateForm.controls['make'].setValue('');
    this.leaseCreateForm.controls['model'].setValue('');
    this.leaseCreateForm.controls['year'].setValue('');
    this.leaseCreateForm.controls['vehiclePurchased'].setValue('N');
  }

  checkDealer(){
    if(typeof this.leaseCreateForm.controls['dealer'].value !=='object'){
      this.leaseCreateForm.controls.dealer.setErrors({ 'incorrect': true});
    }
  }

}
