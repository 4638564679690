import { CurrencyPipe, DatePipe } from '@angular/common';
import { EventEmitter } from '@angular/core';
import { COUNTRIES, DATE_TIME_FORMATE, LOCAL_VAR, TIMEZONES } from '@app/core/constants';
import { ToastrService } from 'ngx-toastr';
var UtilService = /** @class */ (function () {
    function UtilService(currencyPipe, _toastrService) {
        this.currencyPipe = currencyPipe;
        this._toastrService = _toastrService;
        this.datePipe = new DatePipe('en-US');
        this._missedError = '';
        this.missedErrors$ = new EventEmitter();
        this.stepClick = true;
        this.selectedProducts = [];
        this.$onProductChange = new EventEmitter();
        this.clickedOnStep$ = new EventEmitter();
    }
    UtilService.prototype.getCountries = function (available) {
        return COUNTRIES.filter(function (c) {
            return available !== undefined ? c.available === available : true;
        });
    };
    UtilService.prototype.getTimeZones = function () {
        var timezones = [];
        TIMEZONES.map(function (t) {
            return t.utc.map(function (u) {
                if (u.indexOf('America') !== -1) {
                    timezones.push({
                        key: t.abbr,
                        value: u
                    });
                }
            });
        });
        return timezones;
    };
    UtilService.prototype.dollarToCents = function (dollar) {
        return parseInt((parseFloat(dollar.toString()) * 100).toString(), 10);
    };
    UtilService.prototype.centsToDollar = function (cents) {
        return parseFloat(cents.toString()) / 100;
    };
    UtilService.prototype.removeEmpty = function (obj) {
        for (var propName in obj) {
            if (obj[propName] === null ||
                obj[propName] === undefined ||
                (Array.isArray(obj[propName]) && !obj[propName].length) ||
                (typeof obj[propName] === 'object' && this.isEmpty(obj[propName]))) {
                delete obj[propName];
            }
        }
        return obj;
    };
    UtilService.prototype.removeWhiteSpace = function (obj) {
        for (var propName in obj) {
            if (obj[propName] === null ||
                obj[propName] === undefined ||
                $.trim(obj[propName]) === '' ||
                (Array.isArray(obj[propName]) && !obj[propName].length) ||
                (typeof obj[propName] === 'object' && this.isEmpty(obj[propName]))) {
                delete obj[propName];
            }
            else if (typeof obj[propName] != "object") {
                obj[propName] = $.trim(obj[propName]);
            }
        }
        return obj;
    };
    UtilService.prototype.isEmpty = function (obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    };
    UtilService.prototype.copyToClipboard = function (value) {
        var selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    };
    UtilService.prototype.stringKeywordReplacer = function (msg, apiObject) {
        var _this = this;
        try {
            return msg.replace(/{([^}]+)}/g, function (match, m1) {
                if (m1.includes('$')) {
                    return Math.abs(_this.centsToDollar(_this.get(apiObject, m1.slice(1))));
                }
                if (m1.includes('#')) {
                    return _this.datePipe.transform(_this.get(apiObject, m1.slice(1)), DATE_TIME_FORMATE.APIKEY);
                }
                return _this.get(apiObject, m1);
            });
        }
        catch (e) {
            console.log(e);
        }
        return msg;
    };
    UtilService.prototype.objectResolver = function (path, obj) {
        return path.split('.').reduce(function (prev, curr) {
            return prev ? prev[curr] : null;
        }, obj || self);
    };
    UtilService.prototype.get = function (obj, key) {
        return key.split('.').reduce(function (o, x) {
            return o === undefined || o === null ? o : o[x];
        }, obj);
    };
    UtilService.prototype.generateWildcardObject = function (id) {
        try {
            var object = '';
            var prefix = id.split('_')[0];
            object = JSON.stringify({
                $or: [
                    {
                        'data.object._id': id
                    },
                    {
                    // ['data.object.' + INDENTIFIERS[prefix]]: id
                    }
                ]
            });
            return object;
        }
        catch (e) {
            console.log(e);
        }
    };
    UtilService.prototype.createFormData = function (object, form, namespace) {
        var formData = form || new FormData();
        for (var property in object) {
            if (!object.hasOwnProperty(property) ||
                (object[property] === null || object[property] === undefined)) {
                continue;
            }
            var formKey = namespace ? namespace + "[" + property + "]" : property;
            if (object[property] instanceof Date) {
                formData.append(formKey, object[property].toISOString());
            }
            else if (typeof object[property] === 'object' &&
                !(object[property] instanceof File)) {
                this.createFormData(object[property], formData, formKey);
            }
            else {
                formData.append(formKey, object[property]);
            }
        }
        return formData;
    };
    UtilService.prototype.setValidators = function (form, control, validators) {
        form.setValidators(validators);
        form.get(control).updateValueAndValidity();
        return form;
    };
    UtilService.prototype.clearValidators = function (form, control) {
        form.clearValidators();
        form.get(control).updateValueAndValidity();
        return form;
    };
    Object.defineProperty(UtilService.prototype, "missedErrors", {
        // For missed API Errors
        get: function () {
            return this._missedError;
        },
        enumerable: true,
        configurable: true
    });
    UtilService.prototype.setMissedError = function (errMsg) {
        this._missedError = errMsg;
        this.missedErrors$.emit(this._missedError);
        console.log(this.missedErrors, 'this.missedErrors EMIT');
    };
    UtilService.prototype.clearMissedError = function () {
        this._missedError = '';
        this.missedErrors$.emit(this._missedError);
    };
    UtilService.prototype.showPaginationInfoOld = function (data, perPage, currentPage) {
        var lastPage = data.length / perPage;
        if (!this.isInt(lastPage)) {
            lastPage = Math.floor(lastPage) + 1;
        }
        if (data && data.length > 0) {
            var from = perPage * currentPage - perPage + 1;
            var to = perPage * currentPage;
            if (data.length % perPage === 0) {
                return "Showing " + from + " to " + to + " of " + data.length + " entries";
            }
            else {
                if (currentPage === lastPage) {
                    return "Showing " + from + " to " + ((data.length % perPage) + from - 1) + " of " + data.length + " entries";
                }
                else {
                    return "Showing " + from + " to " + to + " of " + data.length + " entries";
                }
            }
        }
        else {
            return '';
        }
    };
    UtilService.prototype.showPaginationInfo = function (total, perPage, currentPage) {
        var lastPage = total / perPage;
        if (!this.isInt(lastPage)) {
            lastPage = Math.floor(lastPage) + 1;
        }
        if (total > 0) {
            var from = perPage * currentPage - perPage + 1;
            var to = perPage * currentPage;
            if (total % perPage === 0) {
                return "Showing " + from + " to " + to + " of " + total + " entries";
            }
            else {
                if (currentPage === lastPage) {
                    return "Showing " + from + " to " + ((total % perPage) + from - 1) + " of " + total + " entries";
                }
                else {
                    return "Showing " + from + " to " + to + " of " + total + " entries";
                }
            }
        }
        else {
            return '';
        }
    };
    UtilService.prototype.pagerData = function (total, perPage, currentPage) {
    };
    UtilService.prototype.isInt = function (n) {
        return n % 1 === 0;
    };
    Object.defineProperty(UtilService.prototype, "getCurrentYear", {
        get: function () {
            var currentDate = new Date();
            return currentDate.getFullYear();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UtilService.prototype, "getCurrentMonth", {
        get: function () {
            var currentDate = new Date();
            return currentDate.getMonth() + 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UtilService.prototype, "getCurrentDay", {
        get: function () {
            var currentDate = new Date();
            return currentDate.getDate();
        },
        enumerable: true,
        configurable: true
    });
    UtilService.prototype.showStepAsDone = function (count) {
        var elementArr = [];
        if (document.getElementsByClassName('default navigable') &&
            document.getElementsByClassName('default navigable').length) {
            var elem = document.getElementsByClassName('default navigable');
            if (elem && elem.length) {
                for (var i = 0; i < elem.length; i++) {
                    if (i < count) {
                        $(elem[i]).addClass('done');
                    }
                    elementArr.push(elem[i]);
                }
            }
        }
        this.notFoundStepAsDone(elementArr, count);
    };
    UtilService.prototype.notFoundStepAsDone = function (elementArr, count) {
        var _this = this;
        if (elementArr && elementArr.length === 0) {
            setTimeout(function () {
                _this.showStepAsDone(count);
            }, 200);
        }
    };
    UtilService.prototype.getHiddenStep = function () {
        var elementArr = [];
        if (document.getElementsByClassName('done') &&
            document.getElementsByClassName('done').length) {
            var elem_done = document.getElementsByClassName('done');
            if (elem_done && elem_done.length) {
                for (var i = 0; i < elem_done.length; i++) {
                    $(elem_done[i]).css('cursor', 'pointer');
                    elementArr.push(elem_done[i]);
                }
            }
        }
        this.hiddenStep(elementArr);
    };
    UtilService.prototype.hiddenStep = function (elementArr) {
        var _this = this;
        if (elementArr && elementArr.length) {
            for (var i = 0; i < elementArr.length; i++) {
                elementArr[i].addEventListener('click', function (e) {
                    _this.clickOnStep(e.target.textContent);
                });
            }
        }
        else {
            setTimeout(function () {
                _this.getHiddenStep();
            }, 200);
        }
    };
    UtilService.prototype.clickOnStep = function (stepTitle) {
        var _this = this;
        if (this.stepClick) {
            this.clickedOnStep$.emit(stepTitle);
            this.stepClick = false;
            setTimeout(function () {
                _this.stepClick = true;
            }, 1000);
        }
    };
    UtilService.prototype.getStepIndexFromStepTitle = function (stepTitle) {
        var stepNumber = 0;
        switch (stepTitle) {
            case 'Customer Information':
                stepNumber = 0;
                break;
            case 'Vehicle Information':
                stepNumber = 1;
                break;
            case 'Insurance Information':
                stepNumber = 2;
                break;
            case 'Customer Documents Needed':
                stepNumber = 3;
                break;
            case 'Agent Review':
                stepNumber = 4;
                break;
            case 'Agent Documents Upload':
                stepNumber = 5;
                break;
            case 'Calculations':
                stepNumber = 6;
                break;
        }
        return stepNumber;
    };
    UtilService.prototype.removeDate = function (formGroup, formControl) {
        formGroup.get(formControl).setValue(null);
        formGroup.get(formControl).markAsDirty();
    };
    UtilService.prototype.inArray = function (needle, haystack) {
        var length = haystack.length;
        for (var i = 0; i < length; i++) {
            if (haystack[i] == needle)
                return true;
        }
        return false;
    };
    UtilService.prototype.getPageList = function (toalPage, currentPage) {
        var from = Math.floor(currentPage / 5) * 5;
        var to = (Math.floor(currentPage / 5) + 1) * 5;
        if (from == currentPage && from != 1) {
            from = from - 1;
            to = to - 1;
        }
        if (to > toalPage) {
            to = toalPage;
            from = to - 5;
        }
        from = (from < 1) ? 1 : from;
        // create an array of pages to ng-repeat in the pager control
        var pages = [];
        if (currentPage > 4 && from > 2) {
            pages.push({ page: 1, label: '1 .. ' });
        }
        else if (from == 2) {
            from = 1;
        }
        for (var i = from; i <= to; i++) {
            pages.push({ page: i, label: i });
        }
        if (toalPage > to) {
            pages.push({ page: toalPage, label: ((to + 1) == toalPage) ? toalPage : ' .. ' + toalPage });
        }
        return pages;
    };
    UtilService.prototype.filterUserRoles = function (roles, role) {
        var data = [];
        var allow = [];
        if (role === 'dowcsuperadmin') {
            allow = ['customer', 'admin', 'superadmin', 'dowcadmin', 'dowcsuperadmin', 'dowcview', 'adminview'];
        }
        else if (role === 'dowcview') {
            allow = ['customer', 'admin', 'superadmin', 'dowcview', 'adminview'];
        }
        else if (role === 'adminview') {
            allow = ['customer', 'admin', 'superadmin', 'adminview'];
        }
        else if (role === 'dowcadmin') {
            allow = ['customer', 'admin', 'dowcadmin'];
        }
        else if (role === 'superadmin') {
            allow = ['customer', 'admin', 'superadmin'];
        }
        else if (role === 'admin') {
            allow = ['customer', 'admin'];
        }
        else {
            allow = ['customer'];
        }
        $.each(roles, function (index, val) {
            allow.forEach(function (rol) {
                if (val.slug === rol) {
                    data.push(val);
                }
            });
        });
        return data;
    };
    UtilService.prototype.toggleDivClass = function (target, cls) {
        if ($("#" + target).hasClass(cls)) {
            $("#" + target).removeClass(cls);
            $(".hidden-xl-up .fa-minus").removeClass("d-none");
            $(".hidden-xl-up .fa-plus").addClass("d-none");
        }
        else {
            $("#" + target).addClass(cls);
            $(".hidden-xl-up .fa-minus").addClass("d-none");
            $(".hidden-xl-up .fa-plus").removeClass("d-none");
        }
    };
    UtilService.prototype.getProduct = function () {
    };
    UtilService.prototype.setProduct = function () {
    };
    UtilService.prototype.updateDomainCss = function () {
        var domainconfig = JSON.parse(localStorage.getItem(LOCAL_VAR.domainconfig));
        // this._toastrService.clear();
        //console.log(domainconfig);
        var favIcon = document.querySelector('#appIcon');
        if (domainconfig) {
            if (domainconfig.primaryColor) {
                //$('.btn-primary').css({"background-color":domainconfig.primaryColor});
                document.documentElement.style.setProperty('--primary-color', domainconfig.primaryColor);
                this.changeCssAttribute('nav-item', 'background-color', domainconfig.primaryColor);
                this.changeCssAttribute('mobile-menu-container', 'background-color', domainconfig.primaryColor);
                this.changeCssAttribute('btn-primary', 'background-color', domainconfig.primaryColor);
                this.changeCssAttribute('btn-primary', 'border-color', domainconfig.primaryColor);
                this.changeCssAttribute('btn-info', 'background', domainconfig.primaryColor);
                this.changeCssAttribute('btn-info', 'background-color', domainconfig.primaryColor);
                this.changeCssAttribute('btn-info', 'border-color', domainconfig.primaryColor);
                this.changeCssAttribute('page-goto', 'background', domainconfig.primaryColor);
                this.changeCssAttribute('btn-link', 'color', domainconfig.primaryColor);
                this.changeCssAttribute('goto-btn', 'background', domainconfig.primaryColor);
                this.changeCssAttribute('logo-container', 'background', domainconfig.primaryColor);
                //this.changeCssAttribute('help-text','border',domainconfig.primaryColor);
                // this.utilService.changeCssAttribute('nav-item','background-color',domainconfig.primaryColor);
                // $(".nav-item .dropdown-menu").css("background-color",domainconfig.primaryColor);
            }
            if (domainconfig.secondaryColor) {
                document.documentElement.style.setProperty('--secondary-color', domainconfig.secondaryColor);
                this.changeCssAttribute('card-header', 'background', domainconfig.secondaryColor);
                this.changeCssAttribute('card-header', 'background-color', domainconfig.secondaryColor);
                this.changeCssAttribute('title-bg', 'background-color', domainconfig.secondaryColor);
                this.changeCssAttribute('table-title', 'background-color', domainconfig.primaryColor);
            }
            // change Favicon      
            if (domainconfig.domain.indexOf("assuredprovider") != -1) {
                favIcon.href = 'assets/images/APS_Favicon.webp';
            }
            else {
                favIcon.href = 'assets/images/favicon.png';
            }
        }
        else {
            favIcon.href = 'assets/images/favicon.png';
            // favIcon.href = 'assets/images/APS_Favicon.webp';
            document.documentElement.style.setProperty('--primary-color', '#009B4C');
            document.documentElement.style.setProperty('--secondary-color', '#A4BB39');
        }
    };
    UtilService.prototype.changeCssAttribute = function (cls, attr, val) {
        $('.' + cls).css(attr, val);
        var elements = document.getElementsByClassName(cls);
        //console.log(" ===> ",cls,val,attr);
        for (var i = 0; i < elements.length; i++) {
            //console.log(cls," => ",elements[i].style[attr]," INTO ",val);
            elements[i].style[attr] = val;
        }
    };
    return UtilService;
}());
export { UtilService };
