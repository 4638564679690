import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ClaimService } from '@app/core/service/claim.service';
import { ToastrService } from 'ngx-toastr';
import { LOCAL_VAR } from './../../../core/constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-exception-accept-claim',
  templateUrl: './exception-accept-claim.component.html',
  styleUrls: ['./exception-accept-claim.component.scss']
})
export class ExceptionAcceptClaimComponent implements OnInit {
  @Input()
  claimId? = '';
  adminId? = '';

  selectedCustomer: any = null;
  acceptForm: FormGroup;
  isLoading = false;

  constructor(
    public activeModal: NgbActiveModal,
    public claimService: ClaimService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    public router: Router
  ) {
    this.selectedCustomer = localStorage.getItem(LOCAL_VAR.selectedCustomer);
    this.selectedCustomer = JSON.parse(this.selectedCustomer);
    this.createForm();
  }

  ngOnInit() {}
  createForm() {
    this.acceptForm = this.formBuilder.group({
      message: ['', [Validators.required]],
      adminId: ['']
    });
  }
  acceptRequest() {
    this.acceptForm.get('adminId').setValue(this.adminId);
    /*  console.log(this.rejectForm.value);
    return false; */
    this.claimService
      .acceptExceptionRequest(this.claimId, this.acceptForm.value)
      .subscribe((response: any) => {
        if (response) {
          this.activeModal.close(response);
        } else {
          this.toastrService.warning('something went Wrong');
        }
      });
  }
}
