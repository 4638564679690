/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./shared/spinner.component.ngfactory";
import * as i4 from "./shared/spinner.component";
import * as i5 from "@angular/common";
import * as i6 from "./app.component";
import * as i7 from "@angular/platform-browser";
import * as i8 from "./core/service/error-message.service";
import * as i9 from "./core/authentication/authentication.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 3, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-spinner", [], null, null, null, i3.View_SpinnerComponent_0, i3.RenderType_SpinnerComponent)), i1.ɵdid(3, 180224, null, 0, i4.SpinnerComponent, [i2.Router, i5.DOCUMENT], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 3, "button", [["class", "btn btn-primary btn-icon scroll-to-top"], ["rippleEffect", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.scrollToTop() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(6, { "show-scroll-to-top": 0 }), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["class", "mdi mdi-arrow-up"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = "btn btn-primary btn-icon scroll-to-top"; var currVal_1 = _ck(_v, 6, 0, _co.windowScrolled); _ck(_v, 5, 0, currVal_0, currVal_1); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onWindowScroll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i6.AppComponent, [i2.Router, i2.ActivatedRoute, i7.Title, i8.ErrorMessageService, i9.AuthenticationService], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i6.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
