<div class="row agent-container">
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        Dealer Groups
        <div class="card-actions">
          <button class="btn btn-white" routerLink="/dealergroup-create">
            Create
          </button>
        </div>
      </div>
      <div class="card-body p-0">
        <div class="card">
          <div class="card-body pl-0 pr-0 pb-0">
            <form [formGroup]="filterUser">
              <div class="row">
                <div class="col-12 d-xl-flex align-items-start">
                  <div class="col-12 col-xl-6">
                    <div class="form-group mb-0">
                      <label class="d-block small">Name</label>
                      <input type="text" name="dealername" class="form-control" formControlName="dealername" />
                    </div>
                  </div>
                  <div class="col-12 col-xl-2">
                    <div class="form-group mb-0">
                      <label class="d-block small">Domain</label>
                      <input type="text" name="domain" class="form-control" formControlName="domain" />
                    </div>
                  </div>
                  <div class="col-12 col-xl-2">
                    <div class="form-group mb-0">
                      <label class="d-block small">Config</label>
                      <select class="form-control custom-select" name="config" formControlName="config">
                        <option value="">All</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-12 col-xl-2">
                    <div class="form-group mb-0">
                      <label class="d-block small">Status</label>
                      <select class="form-control custom-select" name="status" formControlName="status">
                        <option value="">All</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                    </div>
                  </div>
                  <div class="none">
                    <input type="hidden" name="sortFieldFilter" formControlName="sortFieldFilter" #sortFieldFilter />
                    <input type="hidden" name="sortByFilter" formControlName="sortByFilter" #sortByFilter />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="card-body" [ngClass]="{ 'p-0': dealersGroupList && dealersGroupList.length > 0 }"
        *ngIf="!isLoading && dealersGroupList">
        <div class="table table-responsive smart-table" *ngIf="dealersGroupList && dealersGroupList.length > 0">
          <table class="w-100">
            <thead>
              <tr>
                <th class="pointer">
                  <div [ngClass]="{
                      sort: sortBy === 'name',
                      asc: sortDir == 1,
                      desc: sortDir == -1
                    }" (click)="sort('name')">
                    Name
                  </div>
                </th>
                <th>Dealers</th>
                <th>Domain</th>
                <th>Config</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="
                  let dealer of dealersGroupList">
                <td>
                  {{ dealer.name }}
                </td>
                <td>
                  <small *ngFor="
                  let value of dealer.dealers; let i = index"> <span *ngIf="i!=0">, </span> {{ dealersList[value.dealerId].name }}</small>
                </td>
                <td>{{dealer.domain}}</td>
                <td class="text-capitalize">{{dealer.config}}</td>
                <td class="text-capitalize">
                  {{dealer.status}}
                </td>
                <td  class="text-right"><a
                  class="ng2-smart-action ng2-smart-action-edit-edit"
                  routerLink="/dealergroup-edit/{{dealer._id}}"
                  placement="top"
                  ngbTooltip="Update"
                >
                  <i class="ti-pencil text-warning m-r-10"></i>
                </a></td>
              </tr>
            </tbody>
          </table>
        </div>
        <app-no-data-msg [message]="'No Dealer Found'" *ngIf="dealersGroupList && dealersGroupList.length === 0">
        </app-no-data-msg>
        <div class="card-footer" *ngIf="dealersGroupList && dealersGroupList.length > 0">
          <div class="row">
            <div class="col-md">
              <span>{{
                                utilService.showPaginationInfo(pager.Totalrecords, pager.perPage, pager.currentPage)
                              }}</span>
            </div>
            <div class="col-sm">
              <form [formGroup]="filterUser">
                <div class="form-group mb-0 pull-right">
                  <small>Rows:</small>
                  <select class="form-control custom-select rows-button" name="perPage" formControlName="perPage">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  <input *ngIf="pager.TotalPages && pager.TotalPages>1" type="number" #goto name="goto"
                    class="form-control goto-input" placeholder="Page No" min="1" max="{{pager.TotalPages}}" />
                  <span *ngIf="pager.TotalPages && pager.TotalPages>1" class="btn btn-link page-goto goto-btn" (click)="goPage()">
                    Go
                  </span>
                </div>
              </form>
            </div>
            <div class="col-md" *ngIf="pager.TotalPages && pager.TotalPages>1">
              <div class="">
                <ul class="pagination pull-right">
                  <li>
                    <a (click)="setPage(pager.currentPage - 1)"><i class="icon-arrow-left"></i></a>
                  </li>
                  <li *ngFor="let page of PageList" [ngClass]="{active:pager.currentPage === page.page}">
                    <a (click)="setPage(page.page)">{{page.label}}</a>
                  </li>
                  <li>
                    <a (click)="setPage(pager.currentPage + 1)"><i class="icon-arrow-right"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="help-container">
      <div class="help-section">
        <p class="help-text" (click)="openHelpPopup()">
          <i class="hellp-popup-icons"></i>
        </p>
      </div>
    </div>
    <app-table-loader [isLoading]="isLoading"></app-table-loader>
  </div>
</div>