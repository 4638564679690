<div class="row agent-container">
  <div class="col-12">
    <div class="card">
      <form [formGroup]="dealerGroupForm" (ngSubmit)="submitData()" class="form-horizontal form-material"
        id="dealerGroupForm" novalidate>
        <div class="card-header">
          Dealer Group
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12 p-xl-0">
              <div class="col-12 p-0">
                <div class="form-group d-xl-flex align-items-center">
                  <div class="form-group col-12 col-xl-12 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-2 p-0">
                      <app-required-indication [control]="dealerGroupForm.get('name')" [label]="'Group Name'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-6">
                      <app-form-error-wrapper [control]="dealerGroupForm.get('name')" [controlName]="'Name'"
                        [apiErrorType]="'name'">
                        <input type="text" name="name" class="form-control" formControlName="name" />
                      </app-form-error-wrapper>
                    </div>
                  </div>
                </div>
                <div class="form-group d-xl-flex align-items-center">
                  <div class="form-group col-12 col-xl-12 d-xl-flex align-items-center">
                    <label class="col-12 col-xl-2 p-0">
                      <app-required-indication [control]="dealerGroupForm.get('dealers')" [label]="'Dealers'">
                      </app-required-indication>
                    </label>
                    <div class="col-12 col-xl-10">
                      <div *ngFor="let value of dealerGroupList; let i = index">
                        <label class="m-r-10">{{value.name}}</label>
                        <button type="button" class="btn btn-danger m-3" (click)="removeDealer(i)" >
                          <i class="mdi mdi-close" (click)="removeDealer(i)"></i>
                        </button><br />
                      </div>
                      <i *ngIf="dealerGroupList.length===0" class="m-r-10">No dealers added</i>
                    </div>

                  </div>
                </div>
                <div class="form-group col-12 col-xl-12 d-xl-flex align-items-center">
                  <label class="col-12 col-xl-2 p-0">
                  </label>
                  <div class="col-12 col-xl-6">
                    <app-form-error-wrapper [control]="dealerGroupForm.get('dealer')" [controlName]="'Dealer'"
                      [apiErrorType]="'dealer'">
                      <ng-template #rt let-r="result" let-t="term">
                        {{ r.name }}
                      </ng-template>
                      <input class="form-control" type="text" [ngbTypeahead]="dealers" [inputFormatter]="formatter"
                        formControlName="dealer" [resultTemplate]="rt" autocomplete="off"
                        placeholder="Search and select Dealer" name="dealer" />
                    </app-form-error-wrapper>
                  </div>
                  <div class="form-group d-xl-flex align-items-center">
                    <label class="col-12 col-xl-1"></label>
                    <div class="col-12 col-xl-10 pt-3">
                      <button type="button" class="btn btn-primary" (click)="addDealer()" [disabled]="invalidDealer">
                        <i class="mdi mdi-plus"></i> Add
                      </button>
                    </div>
                  </div>
                </div>
                <div class="form-group col-12 col-xl-12 d-xl-flex align-items-center">
                  <label class="col-12 col-xl-2 p-0">
                    <app-required-indication [control]="dealerGroupForm.get('status')" [label]="'Status'">
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-4">
                    <app-form-error-wrapper [control]="dealerGroupForm.get('status')" [controlName]="'status'"
                      [apiErrorType]="'status'">
                      <select class="form-control custom-select" name="status" formControlName="status">
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                    </app-form-error-wrapper>
                  </div>
                </div>
                <div class="form-group col-12 col-xl-12 d-xl-flex align-items-center">
                  <label class="col-12 col-xl-2 p-0">
                    <app-required-indication [control]="dealerGroupForm.get('config')" [label]="'Config'">
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-4">
                    <app-form-error-wrapper [control]="dealerGroupForm.get('config')" [controlName]="'config'"
                      [apiErrorType]="'config'">
                      <select class="form-control custom-select" name="config" formControlName="config">
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                    </app-form-error-wrapper>
                  </div>
                </div>
              </div>
            </div>
            
            <p *ngIf="dealerGroupForm.get('config').value =='active'">Configuration</p>
            <div class="col-12 p-xl-0" *ngIf="dealerGroupForm.get('config').value =='active'">
              <div class="form-group d-xl-flex align-items-center">
                <label class="col-12 col-xl-2">
                  <app-required-indication [control]="dealerGroupForm.controls['appName']" [label]="'App Name'">
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-10">
                  <app-form-error-wrapper [control]="dealerGroupForm.controls['appName']"
                    [controlName]="'appName'" [apiErrorType]="'appName'">
                    <input class="form-control" type="text" formControlName="appName" placeholder="Enter app name test" />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group d-xl-flex align-items-center">
                <label class="col-12 col-xl-2">
                  <app-required-indication [control]="dealerGroupForm.controls['domain']" [label]="'Domain'">
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-10">
                  <app-form-error-wrapper [control]="dealerGroupForm.controls['domain']"
                    [controlName]="'domain'" [apiErrorType]="'domain'">
                    <input class="form-control" type="text" formControlName="domain" placeholder="Enter domain name test.com" />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group d-xl-flex align-items-center">
                <label class="col-12 col-xl-2">
                  <app-required-indication [control]="dealerGroupForm.controls['baseurl']" [label]="'Baseurl'">
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-10">
                  <app-form-error-wrapper [control]="dealerGroupForm.controls['baseurl']"
                    [controlName]="'baseurl'" [apiErrorType]="'baseurl'">
                    <input class="form-control" type="text" formControlName="baseurl" placeholder="Enter baseurl https://test.com/" />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group d-xl-flex align-items-center">
                <label class="col-12 col-xl-2">
                  <app-required-indication [control]="dealerGroupForm.controls['website']" [label]="'Website'">
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-10">
                  <app-form-error-wrapper [control]="dealerGroupForm.controls['website']"
                    [controlName]="'website'" [apiErrorType]="'website'">
                    <input class="form-control" type="text" formControlName="website" placeholder="Enter wesite name (www.test.com)" />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group d-xl-flex align-items-center">
                <label class="col-12 col-xl-2">
                  <app-required-indication [control]="dealerGroupForm.controls['portal']" [label]="'Web portal'">
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-10">
                  <app-form-error-wrapper [control]="dealerGroupForm.controls['portal']"
                    [controlName]="'portal'" [apiErrorType]="'portal'">
                    <input class="form-control" type="text" formControlName="portal" placeholder="Enter site portal address (test.com)" />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group d-xl-flex align-items-center">
                <label class="col-12 col-xl-2">
                  <app-required-indication [control]="dealerGroupForm.controls['appLogo']" [label]="'Logo Url'">
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-10">
                  <app-form-error-wrapper [control]="dealerGroupForm.controls['appLogo']"
                    [controlName]="'Logo Url'" [apiErrorType]="'appLogo'">
                    <input class="form-control" type="text" formControlName="appLogo" placeholder="Enter logo url" />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group d-xl-flex align-items-center">
                <label class="col-12 col-xl-2">
                  <app-required-indication [control]="dealerGroupForm.controls['emailer']" [label]="'Emailer'">
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-10">
                  <app-form-error-wrapper [control]="dealerGroupForm.controls['emailer']"
                    [controlName]="'sender Mail'" [apiErrorType]="'emailer'">
                    <input class="form-control" type="text" formControlName="emailer" placeholder="Enter mailer name" />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group d-xl-flex align-items-center">
                <label class="col-12 col-xl-2">
                  <app-required-indication [control]="dealerGroupForm.controls['mailerKey']" [label]="'Mailer key'">
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-10">
                  <app-form-error-wrapper [control]="dealerGroupForm.controls['mailerKey']"
                    [controlName]="'sender Mail'" [apiErrorType]="'mailerKey'">
                    <input class="form-control" type="text" formControlName="mailerKey" placeholder="Enter mailer key" />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group d-xl-flex align-items-center">
                <label class="col-12 col-xl-2">
                  <app-required-indication [control]="dealerGroupForm.controls['senderMail']" [label]="'Mail sender'">
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-10">
                  <app-form-error-wrapper [control]="dealerGroupForm.controls['senderMail']"
                    [controlName]="'sender Mail'" [apiErrorType]="'senderMail'">
                    <input class="form-control" type="text" formControlName="senderMail" placeholder="Enter Sender Mail (GAP Claim<claim@portal.com>)" />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group d-xl-flex align-items-center">
                <label class="col-12 col-xl-2">
                  <app-required-indication [control]="dealerGroupForm.controls['notificationSenderMail']" [label]="'Notifcation Mail sender'">
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-10">
                  <app-form-error-wrapper [control]="dealerGroupForm.controls['notificationSenderMail']"
                    [controlName]="'Notifcation Mail sender'" [apiErrorType]="'notificationSenderMail'">
                    <input class="form-control" type="text" formControlName="notificationSenderMail" placeholder="Enter Notifcation Sender Mail (GAP Claim<claim@portal.com>)" />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group d-xl-flex align-items-center">
                <label class="col-12 col-xl-2">
                  <app-required-indication [control]="dealerGroupForm.controls['supportMail']" [label]="'Support Mail'">
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-10">
                  <app-form-error-wrapper [control]="dealerGroupForm.controls['supportMail']"
                    [controlName]="'support Mail'" [apiErrorType]="'supportMail'">
                    <input class="form-control" type="text" formControlName="supportMail" />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group d-xl-flex align-items-center">
                <label class="col-12 col-xl-2">
                  <app-required-indication [control]="dealerGroupForm.controls['HelpMail']" [label]="'Help Mail'">
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-10">
                  <app-form-error-wrapper [control]="dealerGroupForm.controls['HelpMail']"
                    [controlName]="'Street Address'" [apiErrorType]="'HelpMail'">
                    <input class="form-control" type="text" formControlName="HelpMail" />
                  </app-form-error-wrapper>
                </div>
              </div>
              
              <div class="form-group d-xl-flex align-items-center">
                <label class="col-12 col-xl-2">
                  <app-required-indication [control]="dealerGroupForm.controls['notifyClaimMail']" [label]="'Claim complete notification'">
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-10">
                  <app-form-error-wrapper [control]="dealerGroupForm.controls['notifyClaimMail']"
                    [controlName]="'Street Address'" [apiErrorType]="'notifyClaimMail'">
                    <input class="form-control" type="text" formControlName="notifyClaimMail" />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group d-xl-flex align-items-center">
                <label class="col-12 col-xl-2">
                  <app-required-indication [control]="dealerGroupForm.controls['dealersNotifyMail']" [label]="'Dealers consolidate reports To'">
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-10">
                  <app-form-error-wrapper [control]="dealerGroupForm.controls['dealersNotifyMail']"
                    [controlName]="'Street Address'" [apiErrorType]="'dealersNotifyMail'">
                    <input class="form-control" type="text" formControlName="dealersNotifyMail" />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group row ml-0 mr-0">
                <div class="form-group m-xl-0 col-12 col-xl-3 d-xl-flex align-items-center p-0">
                  <label class="col-12 col-xl-8">
                    <app-required-indication [control]="dealerGroupForm.get('notifyToDefault')" [label]="'Default Claim Mail'">
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-4">
                    <div class="custom-control custom-checkbox ">
                      <input id="notifyToDefault" class="custom-control-input" formControlName="notifyToDefault"
                        [checked]="dealerGroupForm.get('notifyToDefault').value" type="checkbox" />
                      <label for="notifyToDefault" class="custom-control-label pos-abs"></label>
                    </div>
                  </div>
                </div>
                <div class="form-group m-xl-0 col-12 col-xl-9 d-xl-flex align-items-center p-0">
                  <label class="col-12 col-xl-4 text-xl-center">
                    <app-required-indication [control]="dealerGroupForm.controls['defaultMail']" [label]="'Default Mail Id'">
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-8">
                    <app-form-error-wrapper [control]="dealerGroupForm.controls['defaultMail']" [controlName]="'defaultMail'"
                      [apiErrorType]="'defaultMail'">
                      <input class="form-control" type="text" formControlName="defaultMail" />
                    </app-form-error-wrapper>
                  </div>
                </div>
              </div>
              <div class="form-group row ml-0 mr-0">
                <div class="form-group m-xl-0 col-12 col-xl-3 d-xl-flex align-items-center p-0">
                  <label class="col-12 col-xl-8">
                    <app-required-indication [control]="dealerGroupForm.get('MAIL_FLAG')" [label]="'Mail Flag (Re-submission mail)'">
                    </app-required-indication>
                  </label>
                  <div class="col-12 col-xl-4">
                    <div class="custom-control custom-checkbox ">
                      <input id="MAIL_FLAG" class="custom-control-input" formControlName="MAIL_FLAG"
                        [checked]="dealerGroupForm.get('MAIL_FLAG').value" type="checkbox" />
                      <label for="MAIL_FLAG" class="custom-control-label pos-abs"></label>
                    </div>
                  </div>
                </div>
               
              </div>
              <div class="form-group d-xl-flex align-items-center">
                <label class="col-12 col-xl-2">
                  <app-required-indication [control]="dealerGroupForm.controls['MailFooter']" [label]="'Mail template footer'">
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-10">
                  <app-form-error-wrapper [control]="dealerGroupForm.controls['MailFooter']"
                    [controlName]="'Mail Footer'" [apiErrorType]="'MailFooter'">
                    <input class="form-control" type="text" formControlName="MailFooter" />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group d-xl-flex align-items-center">
                <label class="col-12 col-xl-2">
                  <app-required-indication [control]="dealerGroupForm.controls['stoneEagleAPI']" [label]="'Stone Eagle API'">
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-10">
                  <app-form-error-wrapper [control]="dealerGroupForm.controls['stoneEagleAPI']"
                    [controlName]="'Stone Eagle API'" [apiErrorType]="'stoneEagleAPI'">
                    <input class="form-control" type="text" formControlName="stoneEagleAPI" />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group d-xl-flex align-items-center">
                <label class="col-12 col-xl-2">
                  <app-required-indication [control]="dealerGroupForm.controls['primaryColor']" [label]="'Primary Colour'">
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-10">
                  <app-form-error-wrapper [control]="dealerGroupForm.controls['primaryColor']"
                    [controlName]="'Primary Class'" [apiErrorType]="'primaryColor'">
                    <input class="form-control" type="text" formControlName="primaryColor" />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group d-xl-flex align-items-center">
                <label class="col-12 col-xl-2">
                  <app-required-indication [control]="dealerGroupForm.controls['secondaryColor']" [label]="'Secondary Colour'">
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-10">
                  <app-form-error-wrapper [control]="dealerGroupForm.controls['secondaryColor']"
                    [controlName]="'Secondary Class'" [apiErrorType]="'secondaryColor'">
                    <input class="form-control" type="text" formControlName="secondaryColor" />
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group row ml-0 mr-0">
                <div class="form-group m-xl-0 col-12 d-xl-flex align-items-center p-0">
                  Note: <small>If you want enter two more mail ids use seperation of comma(,).</small>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-12 text-right">
              <button type="button" (click)="cancelClick()" class="btn btn-secondary mr-2">
                Cancel
              </button>
              <button *ngIf="isEditMode" class="btn btn-info" [disabled]="dealerGroupForm.invalid || isLoading">
                <app-button-loader [isLoading]="isLoading" [label]="'Update'"></app-button-loader>
              </button>
              <button *ngIf="isEditMode===false" class="btn btn-info" [disabled]="dealerGroupForm.invalid || isLoading">
                <app-button-loader [isLoading]="isLoading" [label]="'Create'"></app-button-loader>
              </button>
              <!-- <button class="btn btn-primary" routerLink="/agents">Create Agent</button> -->
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="help-container">
      <div class="help-section">
        <p class="help-text" (click)="openHelpPopup()">
          <i class="hellp-popup-icons"></i>
        </p>
      </div>
    </div>
  </div>
</div>