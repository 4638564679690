<form [formGroup]="helpSupport" (ngSubmit)="rejectRequest()">
  <div class="modal-header bg-inprogress">
    <h4 class="modal-title text-white">Help Support</h4>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <label class="col-12">Ticket : {{ticket}}</label>
      <label class="col-12">Name : {{userData}}</label>
      <label class="col-12">Email : {{userEmail}}</label>
      <label class="col-12">Mobile : {{userPhone}}</label>
      <label class="col-12">Date : {{updateTime | date: 'MM/dd/yyyy HH:mm:ss' }}</label>
      <label class="col-12">Help Message : <p>{{message}}</p></label>
    </div>
    <div class="form-group row">
      <app-required-indication class="col-12" [control]="helpSupport.controls['notes']" [label]="'Response'">
        </app-required-indication>
        <div class="col-12">
          <app-form-error-wrapper class="col-3 w-30" [control]="helpSupport.get('notes')" [controlName]="'Enter notes'"
            [apiErrorType]="'notes'">
            <textarea type="text" name="reason" rows="5" class="form-control" formControlName="notes"></textarea>
          </app-form-error-wrapper>
        </div>
    </div>
    <div class="form-group row">
      <label class="col-12">Status</label>
      <div class="col-12">
        <app-form-error-wrapper [control]="helpSupport.get('status')" [controlName]="'Select status'"
          [apiErrorType]="'status'">
          <select class="form-control custom-select rows-button" name="status" formControlName="status">
            <option value="open">Open</option>
            <option value="closed">Closed</option>
          </select>
        </app-form-error-wrapper>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">
      Cancel
    </button>
    <button class="btn btn-primary" type="submit">
      <app-button-loader [label]="'Update'"></app-button-loader>
    </button>
  </div>
</form>