<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        Change Password
      </div>
      <div class="card-body">
        <form
          [formGroup]="changePasswordForm"
          (ngSubmit)="changePassword()"
          class="form-horizontal form-material"
          id="chnagePasswordForm"
          novalidate
        >
          <div class="row">
            <div class="col-12 p-xl-0">
              <div class="form-group d-xl-flex align-items-center">
                <label class="col-12 col-xl-2">
                  <app-required-indication
                    [control]="changePasswordForm.controls['currentpassword']"
                    [label]="'Current Password'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-4">
                  <app-form-error-wrapper
                    [control]="changePasswordForm.controls['currentpassword']"
                    [controlName]="'CurrentPassword'"
                    [apiErrorType]="'oPassword'"
                  >
                  <div class="input-group input-group-merge form-password-toggle">
                    <input
                      class="form-control"
                      [type]="oPasswordTextType ? 'text' : 'password'"
                      placeholder="············"
                      formControlName="currentpassword"
                      autocomplete="off"
                    />
                      <div class="input-group-append">
                        <span class="input-group-text cursor-pointer" 
                        (click)="oPasswordTextType = !oPasswordTextType;"
                          ><i
                            class="fa"
                            [ngClass]="{
                              'fa-eye-slash': oPasswordTextType,
                              'fa-eye': !oPasswordTextType
                            }"
                          ></i
                        ></span>
                      </div>
                    </div>
                  </app-form-error-wrapper>
                </div>
              </div>
              <div class="form-group d-xl-flex align-items-center">
                <label class="col-12 col-xl-2">
                  <app-required-indication
                    [control]="changePasswordForm.controls['newpassword']"
                    [label]="'New Password'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-4">
                  <app-form-error-wrapper
                    [control]="changePasswordForm.controls['newpassword']"
                    [controlName]="'newpassword'"
                    [apiErrorType]="'password'"
                  >
                  <div class="input-group input-group-merge form-password-toggle">
                    <input
                      class="form-control"
                      [type]="newpasswordTextType ? 'text' : 'password'"
                      placeholder="············"
                      formControlName="newpassword"
                      autocomplete="off"
                    />
                    <div class="input-group-append">
                      <span class="input-group-text cursor-pointer"
                      (click)="newpasswordTextType = !newpasswordTextType;"
                        ><i
                          class="fa"
                          [ngClass]="{
                            'fa-eye-slash': newpasswordTextType,
                            'fa-eye': !newpasswordTextType
                          }"
                        ></i
                      ></span>
                    </div>
                  </div>
                  </app-form-error-wrapper>
                  <span
                  class="error help-block text-danger mt-1 d-block small"
                  *ngIf="changePasswordForm.get('newpassword').value && changePasswordForm.get('newpassword').invalid"
                  >Password must have minimum one Lowercase, Uppercase, Numbers, Special characters and 8 characters length
                </span>
                </div>
              </div>
              <div class="form-group d-xl-flex align-items-center">
                <label class="col-12 col-xl-2">
                  <app-required-indication
                    [control]="changePasswordForm.controls['confirmpassword']"
                    [label]="'Confirm Password'"
                  >
                  </app-required-indication>
                </label>
                <div class="col-12 col-xl-4">
                  <app-form-error-wrapper
                    [control]="changePasswordForm.controls['confirmpassword']"
                    [controlName]="'confirmpassword'"
                    [apiErrorType]="'cPassword'"
                  >
                  <div class="input-group input-group-merge form-password-toggle">
                    <input
                      class="form-control"
                      [type]="cPasswordTextType ? 'text' : 'password'"
                      placeholder="············"
                      formControlName="confirmpassword"
                      autocomplete="off"
                    />
                    <div class="input-group-append">
                      <span class="input-group-text cursor-pointer"
                      (click)="cPasswordTextType = !cPasswordTextType;"
                        ><i
                          class="fa"
                          [ngClass]="{
                            'fa-eye-slash': cPasswordTextType,
                            'fa-eye': !cPasswordTextType
                          }"
                        ></i
                      ></span>
                    </div>
                  </div>
                  </app-form-error-wrapper>
                  <span
                    class="error help-block text-danger mt-1 d-block small"
                    *ngIf="
                      changePasswordForm.get('confirmpassword').value &&
                      changePasswordForm.get('newpassword').value !==
                        changePasswordForm.get('confirmpassword').value
                    "
                    >Password doesn't matched.</span
                  >
                </div>
              </div>

              <div class="form-group d-lg-flex align-items-center">
                <label class="col-12 col-lg-2"></label>
                <div class="col-12 col-lg-10">
                  <button
                    class="btn btn-primary"
                    type="submit"
                    [disabled]="changePasswordForm.invalid || isLoading"
                  >
                    <app-button-loader
                      [isLoading]="isLoading"
                      [label]="'Change Password'"
                    ></app-button-loader>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
