import { Component, OnInit } from '@angular/core';
import {
  Router,
  NavigationStart,
  NavigationEnd,
  ActivatedRoute
} from '@angular/router';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit {
  color = 'defaultdark';
  showSettings = false;
  showMinisidebar = false;
  showDarktheme = false;
  // loggedInUserType: any;
  // isuserLoggedIn: any;
  public config: PerfectScrollbarConfigInterface = {};
  public isDealCreatePage = false;
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    // this.loggedInUserType = localStorage.getItem('userType');
    // this.isuserLoggedIn = localStorage.getItem('isLoogdedin');
    // if (this.router.url === '/') {
    //   if (this.loggedInUserType === 'CUSTOMER') {
    //     this.router.navigate(['/customer']);
    //   } else if (this.loggedInUserType === 'ADMIN') {
    //     this.router.navigate(['/customer']);
    //   }
    // }
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe(event => {});
  }

  ngOnInit() {}
}
