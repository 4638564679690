<form [formGroup]="helpForm" (ngSubmit)="helpaction()">
  <div class="modal-header bg-inprogress">
    <h4 class="modal-title text-white">Help</h4>
  </div>
  <div class="modal-body">
    <div class="row mb-2">
      <app-required-indication class="col-4" [control]="helpForm.controls['firstName']" [label]="'First Name'">
      </app-required-indication>
      <div class="col-8">
        <app-form-error-wrapper
        [control]="helpForm.get('firstName')"
        [controlName]="'Enter FirstName'"
        [apiErrorType]="'firstName'"
      >
        <input
          type="text"
          name="firstName"
          class="form-control"
          [readonly]="user?true:false"
          formControlName="firstName"
        />
        </app-form-error-wrapper>
      </div>
    </div>
    <div class="row mb-2">
      <app-required-indication class="col-4" [control]="helpForm.controls['lastName']" [label]="'Last Name'">
      </app-required-indication>
      <div class="col-8">
        <app-form-error-wrapper
        [control]="helpForm.get('lastName')"
        [controlName]="'Enter LastName'"
        [apiErrorType]="'lastName'"
      >
        <input
          type="text"
          name="lastName"
          class="form-control"
          [readonly]="user?true:false"
          formControlName="lastName"
        />
        </app-form-error-wrapper>
      </div>
    </div>
    <div class="row mb-2">
      <app-required-indication class="col-4" [control]="helpForm.controls['phone']" [label]="'Phone'">
      </app-required-indication>
      <div class="col-8">
        <app-form-error-wrapper
        [control]="helpForm.get('phone')"
        [controlName]="'Phone Number'"
        [apiErrorType]="'phone'"
      >
        <input class="form-control" type="tel" name="phone" mask="000-000-0000" [readonly]="user?true:false"
          formControlName="phone" />
        </app-form-error-wrapper>
      </div>
    </div>
    <div class="row mb-2">
      <app-required-indication class="col-4" [control]="helpForm.controls['email']" [label]="'Email'">
      </app-required-indication>
      <div class="col-8">
        <app-form-error-wrapper
        [control]="helpForm.get('email')"
        [controlName]="'Enter Email'"
        [apiErrorType]="'email'"
      >
        <input
          type="text"
          name="email"
          class="form-control"
          [readonly]="user?true:false"
          formControlName="email"
        />
        </app-form-error-wrapper>
      </div>
    </div>
    <div class="row mb-2">
      <app-required-indication class="col-4" [control]="helpForm.controls['request']" [label]="'Request'">
      </app-required-indication>
      <div class="col-8">
        <select class="form-control custom-select" name="request" formControlName="request">
          <option value="gap_claim">GAP Claim</option>
          <option value="Lease_wear_&_tear">Lease Wear & Tear Claim</option>
          <option value="key_protection">Key Protection Claim</option>
          <option value="360_shield">360 Shield Claim</option>
          <option value="debt_cancellation">Debt Cancellation</option>
          <option value="reimbursement">Reimbursement</option>
          <option value="theft_claim">Theft Claim</option>
          <option value="general">General</option>
        </select>
      </div>
    </div>
    <div class="row mb-2">
      <app-required-indication class="col-12" [control]="helpForm.controls['message']" [label]="'Message'">
      </app-required-indication>
      <div class="col-12">
        <app-form-error-wrapper
          class="col-3 w-30"
          [control]="helpForm.get('message')"
          [controlName]="'Enter Message'"
          [apiErrorType]="'message'"
        >
          <textarea
            type="text"
            name="message"
            rows="5"
            class="form-control"
            formControlName="message"
          ></textarea>
        </app-form-error-wrapper>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="activeModal.dismiss()"
    >
      Cancel
    </button>
    <button class="btn btn-primary" [disabled]="helpForm.invalid" type="submit">
      <app-button-loader [label]="'Help'"></app-button-loader>
    </button>
  </div>
</form>
