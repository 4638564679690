<div class="modal-header">
  <h4 class="modal-title">Create User</h4>
</div>
<div class="modal-body">
  <div class="form-group row">
    <label class="col-12 col-xl-3">First Name</label>
    <div class="col-12 col-xl-9">
      <input type="text" name="firstname" class="form-control" />
    </div>
  </div>
  <div class="form-group row">
    <label class="col-12 col-xl-3">Last Name</label>
    <div class="col-12 col-xl-9">
      <input type="text" name="lastname" class="form-control" />
    </div>
  </div>
  <div class="form-group row">
    <label class="col-12 col-xl-3">Street Address</label>
    <div class="col-12 col-xl-9">
      <input type="text" name="streetAddress" class="form-control" />
    </div>
  </div>
  <div class="form-group row">
    <label class="col-12 col-xl-3">City</label>
    <div class="col-12 col-xl-9">
      <input type="text" name="city" class="form-control" />
    </div>
  </div>
  <div class="form-group row">
    <label class="col-12 col-xl-3">State</label>
    <div class="col-12 col-xl-9">
      <select class="custom-select form-control" name="state">
          <option *ngFor="let stat of states" value="{{ stat.code }}">{{ stat.name }}</option>
      </select>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-12 col-xl-3">Zip Code</label>
    <div class="col-12 col-xl-9">
      <input type="text" name="zipcode" class="form-control" />
    </div>
  </div>
  <div class="form-group row">
    <label class="col-12 col-xl-3">Phone Number</label>
    <div class="col-12 col-xl-9">
      <input type="text" name="mobileNo" class="form-control" />
    </div>
  </div>
  <div class="form-group row">
    <label class="col-12 col-xl-3">Email</label>
    <div class="col-12 col-xl-9">
      <input type="email" name="email" class="form-control" />
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Cancel</button>
  <button class="btn btn-primary">
    <app-button-loader [label]="'Create User'"></app-button-loader>
  </button>
</div>
