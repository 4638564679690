import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CustomerService = /** @class */ (function () {
    function CustomerService(httpClient) {
        this.httpClient = httpClient;
    }
    CustomerService.prototype.getAllCustomers = function () {
        return this.httpClient.get("/customer").pipe(map(function (body) {
            return body;
        }));
    };
    CustomerService.prototype.getAllCustomersByFilter = function (payload) {
        var params = payload.status
            ? new HttpParams().set('status', "" + payload.status)
            : new HttpParams();
        params = payload.firstName
            ? params.append('firstName', "" + payload.firstName)
            : params;
        params = payload.lastName
            ? params.append('lastName', "" + payload.lastName)
            : params;
        params = payload.email
            ? params.append('email', "" + payload.email)
            : params;
        params = payload.role ? params.append('role', "" + payload.role) : params;
        params = payload.dealer
            ? params.append('dealerId', "" + payload.dealer)
            : params;
        params = payload.sortFieldFilter
            ? params.append('sortFieldFilter', "" + payload.sortFieldFilter)
            : params;
        params = payload.sortByFilter
            ? params.append('sortByFilter', "" + payload.sortByFilter)
            : params;
        params = params.append('currentPage', "" + payload.currentPage);
        params = params.append('perPage', "" + payload.perPage);
        return this.httpClient.get('/customer', { params: params }).pipe(map(function (body) {
            return body;
        }));
    };
    CustomerService.prototype.getCustomerDetail = function (customerId) {
        return this.httpClient.get("/customer/" + customerId).pipe(map(function (body) {
            return body;
        }));
    };
    CustomerService.prototype.getCustomerRoles = function () {
        return this.httpClient.get("/userroles").pipe(map(function (body) {
            return body;
        }));
    };
    CustomerService.prototype.getConfig = function () {
        return this.httpClient.get("/config").pipe(map(function (body) {
            return body;
        }));
    };
    CustomerService.prototype.updateConfig = function (payload) {
        return this.httpClient.post("/updateconfig", payload).pipe(map(function (body) {
            return body;
        }));
    };
    CustomerService.prototype.createCustomer = function (payload) {
        return this.httpClient.post("/customer", payload).pipe(map(function (body) {
            return body;
        }));
    };
    CustomerService.prototype.updateCustomer = function (customerId, payload) {
        return this.httpClient.put("/customer/" + customerId, payload).pipe(map(function (body) {
            return body;
        }));
    };
    CustomerService.prototype.sendActivationCode = function (customerId) {
        return this.httpClient.get("/customer/" + customerId + "/sendcode").pipe(map(function (body) {
            return body;
        }));
    };
    CustomerService.prototype.deleteCustomer = function (customerId) {
        return this.httpClient.delete("/customer/" + customerId).pipe(map(function (body) {
            return body;
        }));
    };
    CustomerService.prototype.getDealers = function () {
        return this.httpClient.get("/dealerlist").pipe(map(function (body) {
            return body;
        }));
    };
    CustomerService.prototype.getallDealers = function () {
        return this.httpClient.get("/alldealerlist").pipe(map(function (body) {
            return body;
        }));
    };
    //audit
    CustomerService.prototype.getAuditActions = function () {
        return this.httpClient.get("/auditactions").pipe(map(function (body) {
            return body;
        }));
    };
    CustomerService.prototype.getAllAudits = function (payload) {
        var params = new HttpParams();
        params = params.append('currentPage', "" + payload.currentPage);
        params = params.append('perPage', "" + payload.perPage);
        return this.httpClient.get("/audit", { params: params }).pipe(map(function (body) {
            return body;
        }));
    };
    CustomerService.prototype.getAllAuditsByFilter = function (payload) {
        var params = payload.customerId
            ? new HttpParams().set('customerId', "" + payload.customerId)
            : new HttpParams();
        params = payload.datetime_from
            ? params.append('datetime_from', "" + payload.datetime_from)
            : params;
        params = payload.datetime_to
            ? params.append('datetime_to', "" + payload.datetime_to)
            : params;
        params = payload.rid
            ? params.append('rId', "" + payload.rid)
            : params;
        params = payload.type
            ? params.append('type', "" + payload.type)
            : params;
        params = payload.cid
            ? params.append('cid', "" + payload.cid)
            : params;
        params = payload.email
            ? params.append('email', "" + payload.email)
            : params;
        params = payload.domain
            ? params.append('domain', "" + payload.domain)
            : params;
        params = payload.name
            ? params.append('name', "" + payload.name)
            : params;
        params = payload.sortFieldFilter
            ? params.append('sortFieldFilter', "" + payload.sortFieldFilter)
            : params;
        params = payload.sortByFilter
            ? params.append('sortByFilter', "" + payload.sortByFilter)
            : params;
        params = params.append('currentPage', "" + payload.currentPage);
        params = params.append('perPage', "" + payload.perPage);
        return this.httpClient.get('/audit', { params: params }).pipe(map(function (body) {
            return body;
        }));
    };
    CustomerService.prototype.getAllDealers = function (payload) {
        var params = payload.status
            ? new HttpParams().set('status', "" + payload.status)
            : new HttpParams();
        params = payload.dealername
            ? params.append('name', "" + payload.dealername)
            : params;
        params = payload.sortFieldFilter
            ? params.append('sortFieldFilter', "" + payload.sortFieldFilter)
            : params;
        params = payload.sortByFilter
            ? params.append('sortByFilter', "" + payload.sortByFilter)
            : params;
        params = params.append('currentPage', "" + payload.currentPage);
        params = params.append('perPage', "" + payload.perPage);
        return this.httpClient.get('/dealer', { params: params }).pipe(map(function (body) {
            return body;
        }));
    };
    CustomerService.prototype.createDealer = function (payload) {
        return this.httpClient.post("/createdealer", payload).pipe(map(function (body) {
            return body;
        }));
    };
    CustomerService.prototype.updateDealer = function (payload) {
        return this.httpClient.post("/updatedealer", payload).pipe(map(function (body) {
            return body;
        }));
    };
    CustomerService.prototype.getAllDealerGroup = function (payload) {
        var params = payload.status
            ? new HttpParams().set('status', "" + payload.status)
            : new HttpParams();
        params = payload.dealername
            ? params.append('name', "" + payload.dealername)
            : params;
        params = payload.domain
            ? params.append('domain', "" + payload.domain)
            : params;
        params = payload.config
            ? params.append('config', "" + payload.config)
            : params;
        params = payload.sortFieldFilter
            ? params.append('sortFieldFilter', "" + payload.sortFieldFilter)
            : params;
        params = payload.sortByFilter
            ? params.append('sortByFilter', "" + payload.sortByFilter)
            : params;
        params = params.append('currentPage', "" + payload.currentPage);
        params = params.append('perPage', "" + payload.perPage);
        return this.httpClient.get('/dealergroups', { params: params }).pipe(map(function (body) {
            return body;
        }));
    };
    CustomerService.prototype.getDealerGroups = function () {
        return this.httpClient.get("/dealergroup").pipe(map(function (body) {
            return body;
        }));
    };
    CustomerService.prototype.getDealerGroupDetail = function (dealerGroupId) {
        return this.httpClient.get("/dealergroup/" + dealerGroupId).pipe(map(function (body) {
            return body;
        }));
    };
    CustomerService.prototype.checkDealerGroupByDealer = function (dealerId) {
        return this.httpClient.get("/dealergroupcheck/" + dealerId).pipe(map(function (body) {
            return body;
        }));
    };
    CustomerService.prototype.createDealerGroup = function (payload) {
        return this.httpClient.post("/createdealergroup", payload).pipe(map(function (body) {
            return body;
        }));
    };
    CustomerService.prototype.updateDealerGroup = function (dealerGroupId, payload) {
        return this.httpClient.post("/updatedealergroup/" + dealerGroupId, payload).pipe(map(function (body) {
            return body;
        }));
    };
    CustomerService.prototype.getIpdetails = function (ip) {
        return this.httpClient.get("/ipdetails/" + ip).pipe(map(function (body) {
            return body;
        }));
    };
    CustomerService.prototype.getAllHelpByFilter = function (payload) {
        var params = payload.customerId
            ? new HttpParams().set('customerId', "" + payload.customerId)
            : new HttpParams();
        params = payload.datetime_from
            ? params.append('datetime_from', "" + payload.datetime_from)
            : params;
        params = payload.datetime_to
            ? params.append('datetime_to', "" + payload.datetime_to)
            : params;
        params = payload.sequence
            ? params.append('sequence', "" + payload.sequence)
            : params;
        params = payload.message
            ? params.append('message', "" + payload.message)
            : params;
        params = payload.request
            ? params.append('request', "" + payload.request)
            : params;
        params = payload.status
            ? params.append('status', "" + payload.status)
            : params;
        params = payload.email
            ? params.append('email', "" + payload.email)
            : params;
        params = payload.sortFieldFilter
            ? params.append('sortFieldFilter', "" + payload.sortFieldFilter)
            : params;
        params = payload.sortByFilter
            ? params.append('sortByFilter', "" + payload.sortByFilter)
            : params;
        params = params.append('currentPage', "" + payload.currentPage);
        params = params.append('perPage', "" + payload.perPage);
        return this.httpClient.get('/help', { params: params }).pipe(map(function (body) {
            return body;
        }));
    };
    CustomerService.ngInjectableDef = i0.defineInjectable({ factory: function CustomerService_Factory() { return new CustomerService(i0.inject(i1.HttpClient)); }, token: CustomerService, providedIn: "root" });
    return CustomerService;
}());
export { CustomerService };
