import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationComponent } from '@app/shared/modals/confirmation';
import { STATES } from '@app/core/constants';
import { DateTime } from 'luxon';
import { CustomerService } from '@app/core/service/customer.service';
import {
  finalize,
  tap,
  map,
  switchMap,
  distinctUntilChanged,
  debounceTime,
  catchError,
  filter
} from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { UtilService, CONFIRMATION_MSGS, LOCAL_VAR } from '@app/core';
import { of as observableOf, Observable, Subject, merge, of } from 'rxjs';
import { NgbTypeaheadConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { ClaimHelpComponent } from '@app/shared/modals/claim-help/claim-help.component';
import { debitcancelService } from '@app/core/service/debitcancel.service';
import { ClaimService } from '@app/core/service/claim.service';
import { environment } from '@env/environment.local';
import { NgxCurrencyModule } from "ngx-currency";

@Component({
  selector: 'app-debit-cancellation-calculation',
  templateUrl: './debit-cancellation-calculation.component.html',
  styleUrls: ['./debit-cancellation-calculation.component.scss']
})
export class DebitCancellationCalculationComponent implements OnInit {
  debitForm: FormGroup;
  isLoading = false;
  queryParams: any;
  states: any = STATES;
  roles: string[];user: any;
  loggedInUserType: string;
  loggedinuserdetail: any;
  claimData: any;
  isreivewMode = false;
  isEditMode = false;
  isAdmin: boolean = null;
  claimId: any = null;
  baseUrl = '';
  selectedCustomer: any = null;
  claims_data: any;
  show_claims = false;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private customerService: CustomerService,
    private toastrService: ToastrService,
    public utilService: UtilService,
    private claimService: debitcancelService,
    private Claims: ClaimService,
    private authenticationService: AuthenticationService,    
    config: NgbTypeaheadConfig
  ) {
    this.user = this.authenticationService.credentials.user;
    this.loggedInUserType = this.user.userRoleId.slug;
    this.baseUrl = environment.s3Url;
    this.selectedCustomer = localStorage.getItem(LOCAL_VAR.selectedCustomer);
    this.selectedCustomer = JSON.parse(this.selectedCustomer);
 
    if (this.router.url.indexOf('debitcancellation-cal') !== -1) {
      this.isEditMode = true;
    } else {
      this.isEditMode = false;
    }

    this.route.queryParams.pipe().subscribe(params => {
      this.queryParams = params;
    });

    if (this.isEditMode && this.route.snapshot.params['claimId']) {
      this.claimId = this.route.snapshot.params['claimId'];
    }
    if (this.claimId) {
      this.getClaimbyId(this.claimId);
    }
    this.createForm();
    this.handleOnChanges();
  }

  ngOnInit() {
    if (this.loggedInUserType === 'dowcview' || this.loggedInUserType === 'adminview') {
      this.router.navigate(['/products'], { replaceUrl: true });
    }
  }

  private createForm() {

    if (this.claimData && (this.loggedInUserType === 'dowcsuperadmin' || this.loggedInUserType === 'superadmin')) {
      this.isAdmin = true;
    }

    this.debitForm = this.formBuilder.group({
      claimId: [this.claimData ? this.claimData._id : ''],
      contractNumber: [
        {
          value: this.claimData
            ? this.claimData.contractNumber
              ? this.claimData.contractNumber
              : 0
            : null,
          disabled: true
        },
        [Validators.required]
      ],
      claimDate: [
        {
          value:
            this.claimData && this.claimData.claimDate
              ? typeof this.claimData.claimDate === 'object'
                ? this.claimData.claimDate
                : DateTime.fromISO(this.claimData.claimDate).toObject()
              : null,
          disabled: true
        },
        Validators.required
      ],
      gapCalculationApproval: [
        this.claimData
          ? this.claimData.gapCalculationApproval
            ? this.claimData.gapCalculationApproval
            : ''
          : '',
        [Validators.required]
      ],
      agreementPurchasePrice: [
        this.claimData ? this.claimData.agreementPurchasePrice : '',
        [Validators.required]
      ],
      agreementPurchaseDate: [
        this.claimData
          ? this.claimData.agreementPurchaseDate
            ? DateTime.fromISO(this.claimData.agreementPurchaseDate).toObject()
            : null
          : '',
          [Validators.required]
      ],
      maximunBenefitLimit: [
        this.claimData
          ? this.claimData.maximunBenefitLimit
            ? this.claimData.maximunBenefitLimit
            : 0
          : null,
        [Validators.required]
      ],
      maximunBenefitLimit1: [
        this.claimData
          ? this.claimData.maximunBenefitLimit
            ? this.claimData.maximunBenefitLimit
            : 0
          : null,
        [Validators.required]
      ],
      vehiclePurchasePrice: [
        this.claimData ? this.claimData.vehiclePurchasePrice : '',
        [Validators.required]
      ],
      dateOfFirstPayment: [
        this.claimData
          ? this.claimData.dateOfFirstPayment
            ? DateTime.fromISO(this.claimData.dateOfFirstPayment).toObject()
            : null
          : '',
          this.loggedInUserType !=='customer' ? [Validators.required] : ''
      ],
      APR: [
        this.claimData ? this.claimData.APR : 0,
        this.loggedInUserType !=='customer' ? Validators.compose([
          Validators.required,
          Validators.minLength(0),
          Validators.maxLength(3)
        ]) :''
      ],
      // monthlyPaymentAmount: [
      //   this.claimData ? this.claimData.monthlyPaymentAmount : '',
      //   this.loggedInUserType !=='customer' ? [Validators.required] : ''
      // ],
      amountFinanced: [
        this.claimData ? this.claimData.amountFinanced : '',
        this.loggedInUserType !=='customer' ? [Validators.required] : ''
      ],
      financingContractType: this.formBuilder.group({
        retailInstallmentSales: [
          this.claimData ? this.claimData.financingContractType.retailInstallmentSales : false
        ],
        lease: [
          this.claimData ? this.claimData.financingContractType.lease : false
        ]
      }),
      totalOfAllPayement: [
        this.claimData ? this.claimData.totalOfAllPayement : '',
        this.loggedInUserType !=='customer' ? [Validators.required] : ''
      ],
      salvageIncludeValue: [
        {
          value: this.claimData
            ? this.claimData.salvageIncludeValue
              ? this.claimData.salvageIncludeValue
              : 0
            : null,
          disabled: true
        }
      ],
      balloonPayment: [
        this.claimData
          ? this.claimData.balloonPayment
            ? this.claimData.balloonPayment
            : 0
          : null,
        [Validators.required]
      ],
      dateOfFirstDue: [
        this.claimData
          ? this.claimData.dateOfFirstDue
            ? DateTime.fromISO(this.claimData.dateOfFirstDue).toObject()
            : null
          : '',
          [Validators.required]
      ],
      financeAPR: [
        this.claimData
          ? this.claimData.financeAPR
            ? this.claimData.financeAPR
            : 0
          : null,
        [Validators.required]
      ],
      numberOfPayments: [
        this.claimData
          ? this.claimData.numberOfPayments
            ? this.claimData.numberOfPayments
            : 0
          : null,
        [Validators.required]
      ],
      frequencyOfPayments: [
        this.claimData
          ? this.claimData.frequencyOfPayments
            ? this.claimData.frequencyOfPayments
            : 12
          : null,
        [Validators.required]
      ],
      includeSalvage: [
        this.claimData
          ? this.claimData.includeSalvage
            ? this.claimData.includeSalvage
            : 'Yes'
          : 'Yes',
        [Validators.required]
      ],
      accessories: [
        this.claimData
          ? this.claimData.accessories
            ? this.claimData.accessories
            : 0
          : null,
        [Validators.required]
      ],
      serviceContract: [
        this.claimData
          ? this.claimData.serviceContract
            ? this.claimData.serviceContract
            : 0
          : null,
        [Validators.required]
      ],
      closingFee: [
        this.claimData
          ? this.claimData.closingFee
            ? this.claimData.closingFee
            : 0
          : null,
        [Validators.required]
      ],
      titleFee: [
        this.claimData
          ? this.claimData.titleFee
            ? this.claimData.titleFee
            : 0
          : null,
        [Validators.required]
      ],
      salesTax: [
        this.claimData
          ? this.claimData.salesTax
            ? this.claimData.salesTax
            : 0
          : null,
        [Validators.required]
      ],
      license: [
        this.claimData
          ? this.claimData.license
            ? this.claimData.license
            : 0
          : null,
        [Validators.required]
      ],
      accessories1: [
        this.claimData
          ? this.claimData.accessories
            ? this.claimData.accessories
            : 0
          : null,
        [Validators.required]
      ],
      serviceContract1: [
        this.claimData
          ? this.claimData.serviceContract
            ? this.claimData.serviceContract
            : 0
          : null,
        [Validators.required]
      ],
      closingFee1: [
        this.claimData
          ? this.claimData.closingFee
            ? this.claimData.closingFee
            : 0
          : null,
        [Validators.required]
      ],
      titleFee1: [
        this.claimData
          ? this.claimData.titleFee
            ? this.claimData.titleFee
            : 0
          : null,
        [Validators.required]
      ],
      salesTax1: [
        this.claimData
          ? this.claimData.salesTax
            ? this.claimData.salesTax
            : 0
          : null,
        [Validators.required]
      ],
      license1: [
        this.claimData
          ? this.claimData.license
            ? this.claimData.license
            : 0
          : null,
        [Validators.required]
      ],
      actualPayoffAmount: [
        this.claimData
          ? this.claimData.actualPayoffAmount
            ? this.claimData.actualPayoffAmount
            :0
          : null,
        [Validators.required]
      ],      
      conditionAdjustmentA: [
        this.claimData
          ? this.claimData.conditionAdjustmentA
            ? this.claimData.conditionAdjustmentA
            : 0
          : null,
        [Validators.required]
      ],
      conditionAdjustmentB: [
        this.claimData
          ? this.claimData.conditionAdjustmentB
            ? this.claimData.conditionAdjustmentB
            : 0
          : null,
        [Validators.required]
      ],
      conditionAdjustmentC: [
        this.claimData
          ? this.claimData.conditionAdjustmentC
            ? this.claimData.conditionAdjustmentC
            : 0
          : null,
        [Validators.required]
      ],
      conditionAdjustmentA1: [
        {
          value: this.claimData
            ? this.claimData.conditionAdjustmentA
              ? this.claimData.conditionAdjustmentA
              : 0
            : null,
          disabled: true
        },
        [Validators.required]
      ],
      conditionAdjustmentB1: [
        {
          value: this.claimData
            ? this.claimData.conditionAdjustmentB
              ? this.claimData.conditionAdjustmentB
              : 0
            : null,
          disabled: true
        },
        [Validators.required]
      ],
      conditionAdjustmentC1: [
        {
          value: this.claimData
            ? this.claimData.conditionAdjustmentC
              ? this.claimData.conditionAdjustmentC
              : 0
            : null,
          disabled: true
        },
        [Validators.required]
      ] ,
      projectedPrincipalBalance: [
        {
          value: this.claimData
            ? this.claimData.projectedPrincipalBalance
              ? this.claimData.projectedPrincipalBalance
              : 0
            : null,
          disabled: true
        },
        [Validators.required]
      ],
      estimatedPrincipalPaid: [
        {
          value: this.claimData
            ? this.claimData.estimatedPrincipalPaid
              ? this.claimData.estimatedPrincipalPaid
              : 0
            : null,
          disabled: true
        },
        [Validators.required]
      ],
      numberofPaymentsperYear: [
        {
          value: this.claimData
            ? this.claimData.numberofPaymentsperYear
              ? this.claimData.numberofPaymentsperYear
              : 0
            : null,
          disabled: true
        },
        [Validators.required]
      ],
      numberofPaymentsElapsed: [
        {
          value: this.claimData
            ? this.claimData.numberofPaymentsElapsed
              ? this.claimData.numberofPaymentsElapsed
              : 0
            : null,
          disabled: true
        },
        [Validators.required]
      ],
      realizedAPR: [
        {
          value: this.claimData
            ? this.claimData.realizedAPR
              ? this.claimData.realizedAPR
              : 0
            : null,
          disabled: true
        },
        [Validators.required]
      ],
      paymentsPerPeriod: [
        this.claimData
          ? this.claimData.paymentsPerPeriod
            ? this.claimData.paymentsPerPeriod
            : this.claimData.monthlyPaymentAmount
          : null,
        [Validators.required]
      ],
      amountFinancedActual: [
        {
          value: this.claimData
            ? this.claimData.amountFinancedActual
              ? this.claimData.amountFinancedActual
              : 0
            : null,
          disabled: true
        },
        [Validators.required]
      ],
      amountFinancedActual1: [
        {
          value: this.claimData
            ? this.claimData.amountFinancedActual1
              ? this.claimData.amountFinancedActual1
              : 0
            : null,
          disabled: true
        },
        [Validators.required]
      ] ,
      actualPayoffAmount1: [
        {
          value: this.claimData
            ? this.claimData.actualPayoffAmount
              ? this.claimData.actualPayoffAmount
              : 0
            : null,
          disabled: true
        },
        [Validators.required]
      ] ,
      lesserAdjustedAmount: [
        {
          value: this.claimData
            ? this.claimData.lesserAdjustedAmount
              ? this.claimData.lesserAdjustedAmount
              : 0
            : null,
          disabled: true
        },
        [Validators.required]
      ],
      lesserAdjustedAmount1: [
        {
          value: this.claimData
            ? this.claimData.lesserAdjustedAmount
              ? this.claimData.lesserAdjustedAmount
              : 0
            : null,
          disabled: true
        },
        [Validators.required]
      ],
      liabilityInsuranceSettlement: [
        {
          value: this.claimData
            ? this.claimData.liabilityInsuranceSettlement
              ? this.claimData.liabilityInsuranceSettlement
              : 0
            : null,
          disabled: false
        },
        [Validators.required]
      ],
      comprehensiveInsuranceSettlement: [
        {
          value: this.claimData
            ? this.claimData.comprehensiveInsuranceSettlement
              ? this.claimData.comprehensiveInsuranceSettlement
              : 0
            : null,
          disabled: false
        },
        [Validators.required]
      ],
      liabilityInsuranceSettlement1: [
        {
          value: this.claimData
            ? this.claimData.liabilityInsuranceSettlement
              ? this.claimData.liabilityInsuranceSettlement
              : 0
            : null,
          disabled: true
        },
        [Validators.required]
      ],
      comprehensiveInsuranceSettlement1: [
        {
          value: this.claimData
            ? this.claimData.comprehensiveInsuranceSettlement
              ? this.claimData.comprehensiveInsuranceSettlement
              : 0
            : null,
          disabled: true
        },
        [Validators.required]
      ],
      Dedectibles: [
        {
          value: this.claimData
            ? this.claimData.Dedectibles
              ? this.claimData.Dedectibles
              : 0
            : null,
          disabled: false
        },
        [Validators.required]
      ],
      Dedectibles1: [
        {
          value: this.claimData
            ? this.claimData.Dedectibles
              ? this.claimData.Dedectibles
              : 0
            : null,
          disabled: true
        },
        [Validators.required]
      ],
      totalDeduction: [
        {
          value: this.claimData
            ? this.claimData.totalDeduction
              ? this.claimData.totalDeduction
              : 0
            : null,
          disabled: true
        },
        [Validators.required]
      ],
      settlementBeforeSalvage: [
        {
          value: this.claimData
            ? this.claimData.settlementBeforeSalvage
              ? this.claimData.settlementBeforeSalvage
              : 0
            : null,
          disabled: true
        },
        [Validators.required]
      ],
      settlementBeforeSalvage1: [
        {
          value: this.claimData
            ? this.claimData.settlementBeforeSalvage
              ? this.claimData.settlementBeforeSalvage
              : 0
            : null,
          disabled: true
        },
        [Validators.required]
      ],
      lesserPayofforAmortized: [
        {
          value: this.claimData
            ? this.claimData.lesserPayofforAmortized
              ? this.claimData.lesserPayofforAmortized
              : 0
            : null,
          disabled: true
        },
        [Validators.required]
      ],
      gapsettlement: [
        {
          value: this.claimData
            ? this.claimData.gapsettlement
              ? this.claimData.gapsettlement
              : 0
            : null,
          disabled: true
        },
        [Validators.required]
      ]
    });
  

  }


  ngAfterViewInit() {
    $("app-required-indication").each(function (index, element) {
      var text = element.innerHTML;
      element.innerHTML = text.replace('\n<!--', '<!--');
    });
    var domainconfig = JSON.parse(localStorage.getItem(LOCAL_VAR.domainconfig));
    if(domainconfig){
      if(domainconfig.secondaryColor){
        this.utilService.changeCssAttribute('card-header','background-color',domainconfig.secondaryColor);
      }
    } 
  }

  submitData() {
    // this.onSubmitStepChange.emit();
    // if (this.selectedCustomer) {
    // this.isLoading = true;
    let payload = this.debitForm.getRawValue();
    payload = this.utilService.removeEmpty(payload);
  
    const duplicate = [
      'contractNumber',
      'claimDate',
      'gapContractRefund1',
      // 'maximunBenefitLimit',
      // 'vehiclePurchasePrice', 
      // 'amountFinanced',
      /* 'otherProductsRefunds2',
      'otherProductsRefunds3', */
      'conditionAdjustmentA1',
      'conditionAdjustmentB1',
      'conditionAdjustmentC1',
      'comprehensiveInsuranceSettlement1',
      'liabilityInsuranceSettlement1',
      // 'dateOfFirstPayment', 
      'claimId',
      'amountFinancedActual1',
      'amountFinancedTruncated1',
      'ActualvsTruncated1',
      'lessprincipalPayment1',
      'settlementBeforeSalvage1', 
      'adjustedAmountFinancednew1',
      'actualPayoffAmount1',
      /* 'actualPayoffAmount2',
      'actualPayoffAmount3', */
      'lesserAdjustedAmount1',
      'insuranceSettlement1',
      /* 'insuranceSettlement2',
      'insuranceSettlement3', */
      'Dedectibles1',
      'insurenceSettlementAllRefund1',
      'totalConditionAdjustment1'
    ];
    for (const iterator of duplicate) {
      delete payload[iterator];
    }
    if (payload.dateOfFirstDue) {
      payload.dateOfFirstDue = DateTime.fromObject(
        payload.dateOfFirstDue
      ).toISO();
    }
    if (payload.agreementPurchaseDate) {
      payload.agreementPurchaseDate = DateTime.fromObject(
        payload.agreementPurchaseDate
      ).toISO();
    }
    if (payload.dateOfFirstPayment) {
      payload.dateOfFirstPayment = DateTime.fromObject(
        payload.dateOfFirstPayment
      ).toISO();
    }
    
    console.log(payload);
    // return;
    if (this.claimData) {
      this.claimService
        .updateCal(this.claimId, payload)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe((response: any) => {
          this.toastrService.info('Claim Submitted');
          this.router.navigate(['/debitcancellation-list']);
        });
    } 
  }

  checkValidfields() {
    if (this.debitForm.invalid || this.isLoading) {
      this.toastrService.warning('Please fill all required fields.');
      for (const i in this.debitForm.controls) {
        this.debitForm.controls[i].markAsTouched();
        const control = this.debitForm.controls[i] as FormGroup | FormArray;
        if (control instanceof FormGroup) {
          for (const j in control.controls) control.controls[j].markAsTouched();
          // this.debitForm.controls[i]
        }
        if (control instanceof FormArray) {
          const formarraycontrol = control.controls[0] as FormGroup;
          if (formarraycontrol instanceof FormGroup) {
            for (const i in formarraycontrol.controls)
              formarraycontrol.controls[i].markAsTouched();
          }
        }
      }

      console.log(this.debitForm, 'validddd1');
    }
  }

  getClaimbyId(DealId: string) {
    this.claimService
      .getById(DealId)
      .subscribe((response: any) => {
        if (response.records) {
          this.claimData = response.records;
          if (this.claimData.status == 'voided') {
            localStorage.setItem(LOCAL_VAR.selectedProduct, 'debitcancellation');
            this.router.navigate(['/debitcancellation-list']);
          }
          this.createForm();
          this.handleOnChanges();
          this.DebtCalculation();
        } else if (this.isEditMode) {
          localStorage.setItem(LOCAL_VAR.selectedProduct, 'debitcancellation');
          this.router.navigate(['/debitcancellation-list']);
        }
      });
  }

  cancelClick() {
    this.router.navigate(['/debitcancellation-list']);
  }

  backClick() {
    this.router.navigate(['/debitcancellation-edit/'+this.claimId]);
  }

  async openHelpPopup() {
    const modalRef = await this.modalService.open(ClaimHelpComponent);
  }

  handleOnChanges() {
    // return;
    //new calculation - begin
    this.debitForm.get('maximunBenefitLimit').valueChanges.subscribe(res => {
      this.DebtCalculation();
    });
    this.debitForm.get('amountFinanced').valueChanges.subscribe(res => {
      this.DebtCalculation();
    });
    this.debitForm.get('includeSalvage').valueChanges.subscribe(res => {
      this.DebtCalculation();
    });
    this.debitForm.get('financeAPR').valueChanges.subscribe(res => {
      this.DebtCalculation();
    });
    this.debitForm.get('frequencyOfPayments').valueChanges.subscribe(res => {
      this.DebtCalculation();
    });
    this.debitForm.get('balloonPayment').valueChanges.subscribe(res => {
      this.DebtCalculation();
    });
    this.debitForm.get('dateOfFirstDue').valueChanges.subscribe(res => {
      this.DebtCalculation();
    });
    this.debitForm.get('actualPayoffAmount').valueChanges.subscribe(res => {
      this.DebtCalculation();
    });
    this.debitForm.get('accessories').valueChanges.subscribe(res => {
      this.DebtCalculation();
    });
    this.debitForm.get('serviceContract').valueChanges.subscribe(res => {
      this.DebtCalculation();
    });
    this.debitForm.get('closingFee').valueChanges.subscribe(res => {
      this.DebtCalculation();
    });
    this.debitForm.get('titleFee').valueChanges.subscribe(res => {
      this.DebtCalculation();
    });
    this.debitForm.get('salesTax').valueChanges.subscribe(res => {
      this.DebtCalculation();
    });
    this.debitForm.get('license').valueChanges.subscribe(res => {
      this.DebtCalculation();
    });
    this.debitForm.get('liabilityInsuranceSettlement').valueChanges.subscribe(res => {
      this.DebtCalculation();
    });
    this.debitForm.get('comprehensiveInsuranceSettlement').valueChanges.subscribe(res => {
      this.DebtCalculation();
    });
    this.debitForm.get('Dedectibles').valueChanges.subscribe(res => {
      this.DebtCalculation();
    });

    this.debitForm.get('amountFinanced').valueChanges.subscribe(res => {
      this.debitForm
        .get('amountFinancedActual')
        .setValue(this.debitForm.get('amountFinanced').value);
      this.DebtCalculation();
    });
    // this.debitForm.get('gapContractRefund').valueChanges.subscribe(res => {
    //   this.DebtCalculation();
    // });
    // this.debitForm.get('otherProductsRefunds').valueChanges.subscribe(res => {
    //   this.DebtCalculation();
    // });
    this.debitForm.get('conditionAdjustmentA').valueChanges.subscribe(res => {
      this.DebtCalculation();
    });
    this.debitForm.get('conditionAdjustmentB').valueChanges.subscribe(res => {
      this.DebtCalculation();
    });
    this.debitForm.get('conditionAdjustmentC').valueChanges.subscribe(res => {
      this.DebtCalculation();
    });
  }

  DebtCalculation() {
    //Calc - adjustedAmountFinanced
    this.claimData.amountFinanced = this.debitForm.get(
      'amountFinanced'
    ).value;
    //this.claimData.principalPaymentsReduction = this.debitForm.get('principalPaymentsReduction').value;

    this.claimData.conditionAdjustmentA = this.debitForm.get(
      'conditionAdjustmentA'
    ).value;
    this.claimData.conditionAdjustmentB = this.debitForm.get(
      'conditionAdjustmentB'
    ).value;
    this.claimData.conditionAdjustmentC = this.debitForm.get(
      'conditionAdjustmentC'
    ).value;

    //new calculation - begin
    this.claimData.frequencyOfPayments = this.debitForm.get(
      'frequencyOfPayments'
    ).value;
    // console.log('valueee==',this.debitForm.get('dateOfFirstDue').value);
    this.claimData.dateOfFirstDue = this.debitForm.get('dateOfFirstDue').value;
    this.claimData.balloonPayment = this.debitForm.get('balloonPayment').value;
    this.claimData.financeAPR = this.debitForm.get('financeAPR').value;
    this.claimData.maximunBenefitLimit = this.debitForm.get(
      'maximunBenefitLimit'
    ).value;
    this.claimData.includeSalvage = this.debitForm.get(
      'includeSalvage'
    ).value;
    this.claimData.numberOfPayments = this.debitForm.get(
      'numberOfPayments'
    ).value;
    this.claimData.amountFinanced = this.debitForm.get(
      'amountFinanced'
    ).value;
    this.claimData.amountFinancedActual = this.debitForm.get(
      'amountFinancedActual'
    ).value;
    this.claimData.accessories = this.debitForm.get(
      'accessories'
    ).value;
    this.claimData.serviceContract = this.debitForm.get(
      'serviceContract'
    ).value;
    this.claimData.closingFee = this.debitForm.get(
      'closingFee'
    ).value;
    this.claimData.titleFee = this.debitForm.get(
      'titleFee'
    ).value;
    this.claimData.salesTax = this.debitForm.get(
      'salesTax'
    ).value;
    this.claimData.license = this.debitForm.get(
      'license'
    ).value;
    this.claimData.actualPayoffAmount = this.debitForm.get(
      'actualPayoffAmount'
    ).value;
    this.claimData.liabilityInsuranceSettlement = this.debitForm.get(
      'liabilityInsuranceSettlement'
    ).value;
    this.claimData.comprehensiveInsuranceSettlement = this.debitForm.get(
      'comprehensiveInsuranceSettlement'
    ).value;
    // this.claimData.insuranceSettlement = this.debitForm.get(
    //   'insuranceSettlement'
    // ).value;
    this.claimData.Dedectibles = this.debitForm.get('Dedectibles').value;
    this.claimData.settlementBeforeSalvage = this.debitForm.get(
      'settlementBeforeSalvage'
    ).value;
    // this.claimData.gapContractRefund = this.debitForm.get(
    //   'gapContractRefund'
    // ).value;
    this.claimData.salvageIncludeValue = this.debitForm.get(
      'salvageIncludeValue'
    ).value;

    this.claimData.totalDeduction = this.debitForm.get(
      'totalDeduction'
    ).value;
    this.claimData.lesserPayofforAmortized = this.debitForm.get(
      'lesserPayofforAmortized'
    ).value;
    this.claimData.gapCalculationApproval = this.debitForm.get(
      'gapCalculationApproval'
    ).value;
    this.claimData.paymentsPerPeriod = this.debitForm.get(
      'paymentsPerPeriod'
    ).value;
    this.claimData.realizedAPR = this.debitForm.get('realizedAPR').value;
    //new calculation - end
    // console.log(this.claimData.amountFinanced,'this.claimData.amountFinanced form field');
    // console.log(this.claimData.amountFinancedActual,'this.claimData.amountFinancedActual disabled');

    const calculated = this.claimService.DebtCalculation(this.claimData);
      console.log(calculated);
    isNaN(calculated.numberofPaymentsperYear)
      ? this.debitForm.get('numberofPaymentsperYear').setValue(0)
      : this.debitForm
          .get('numberofPaymentsperYear')
          .setValue(calculated.numberofPaymentsperYear);

    //this.debitForm.get('numberofPaymentsElapsed').setValue(calculated.numberofPaymentsElapsed);
    isNaN(calculated.numberofPaymentsElapsed)
      ? this.debitForm.get('numberofPaymentsElapsed').setValue(0)
      : this.debitForm
          .get('numberofPaymentsElapsed')
          .setValue(calculated.numberofPaymentsElapsed);

    isNaN(calculated.realizedAPR)
      ? this.debitForm.get('realizedAPR').setValue(0)
      : this.debitForm.get('realizedAPR').setValue(calculated.realizedAPR);
    isNaN(calculated.paymentsPerPeriod)
      ? this.debitForm.get('paymentsPerPeriod').setValue(0)
      : this.debitForm
          .get('paymentsPerPeriod')
          .setValue(calculated.paymentsPerPeriod);

    // this.debitForm.get('paymentsPerPeriod').setValue(calculated.paymentsPerPeriod);

    isNaN(calculated.projectedPrincipalBalance)
      ? this.debitForm.get('projectedPrincipalBalance').setValue(0)
      : this.debitForm
          .get('projectedPrincipalBalance')
          .setValue(calculated.projectedPrincipalBalance);

    isNaN(calculated.estimatedPrincipalPaid)
      ? this.debitForm.get('estimatedPrincipalPaid').setValue(0)
      : this.debitForm
          .get('estimatedPrincipalPaid')
          .setValue(calculated.estimatedPrincipalPaid);

    isNaN(calculated.lesserAdjustedAmount)
      ? this.debitForm.get('lesserAdjustedAmount').setValue(0)
      : this.debitForm
          .get('lesserAdjustedAmount')
          .setValue(calculated.lesserAdjustedAmount);

    isNaN(calculated.salvageIncludeValue)
      ? this.debitForm.get('salvageIncludeValue').setValue(0)
      : this.debitForm
          .get('salvageIncludeValue')
          .setValue(calculated.salvageIncludeValue);
    isNaN(calculated.totalDeduction)
      ? this.debitForm.get('totalDeduction').setValue(0)
      : this.debitForm
          .get('totalDeduction')
          .setValue(calculated.totalDeduction);

    isNaN(calculated.settlementBeforeSalvage)
      ? this.debitForm.get('settlementBeforeSalvage').setValue(0)
      : this.debitForm
          .get('settlementBeforeSalvage')
          .setValue(calculated.settlementBeforeSalvage);

    isNaN(calculated.gapsettlement)
      ? this.debitForm.get('gapsettlement').setValue(0)
      : this.debitForm.get('gapsettlement').setValue(calculated.gapsettlement);

    isNaN(calculated.lesserPayofforAmortized)
      ? this.debitForm.get('lesserPayofforAmortized').setValue(0)
      : this.debitForm
          .get('lesserPayofforAmortized')
          .setValue(calculated.lesserPayofforAmortized);

    this.debitForm
      .get('amountFinancedActual')
      .setValue(this.debitForm.get('amountFinanced').value);
    this.debitForm
      .get('actualPayoffAmount1')
      .setValue(this.debitForm.get('actualPayoffAmount').value);
    this.debitForm
      .get('salvageIncludeValue')
      .setValue(this.debitForm.get('salvageIncludeValue').value);

    this.debitForm
      .get('conditionAdjustmentA1')
      .setValue(this.debitForm.get('conditionAdjustmentA').value);
    this.debitForm
      .get('conditionAdjustmentB1')
      .setValue(this.debitForm.get('conditionAdjustmentB').value);
    this.debitForm
      .get('conditionAdjustmentC1')
      .setValue(this.debitForm.get('conditionAdjustmentC').value);

    isNaN(calculated.amountFinancedActual1)
      ? this.debitForm.get('amountFinancedActual1').setValue(0)
      : this.debitForm
          .get('amountFinancedActual1')
          .setValue(calculated.amountFinancedActual1);

    this.debitForm
      .get('lesserAdjustedAmount1')
      .setValue(this.debitForm.get('lesserAdjustedAmount').value);
    this.debitForm
      .get('liabilityInsuranceSettlement1')
      .setValue(this.debitForm.get('liabilityInsuranceSettlement').value);
    this.debitForm
      .get('comprehensiveInsuranceSettlement1')
      .setValue(this.debitForm.get('comprehensiveInsuranceSettlement').value);
    this.debitForm
      .get('Dedectibles1')
      .setValue(this.debitForm.get('Dedectibles').value);
    this.debitForm
      .get('accessories1')
      .setValue(this.debitForm.get('accessories').value);
    this.debitForm
      .get('serviceContract1')
      .setValue(this.debitForm.get('serviceContract').value);
    this.debitForm
      .get('closingFee1')
      .setValue(this.debitForm.get('closingFee').value);
    this.debitForm
      .get('titleFee1')
      .setValue(this.debitForm.get('titleFee').value);
    this.debitForm
      .get('salesTax1')
      .setValue(this.debitForm.get('salesTax').value);
    this.debitForm
      .get('license1')
      .setValue(this.debitForm.get('license').value);
    this.debitForm
      .get('settlementBeforeSalvage1')
      .setValue(this.debitForm.get('settlementBeforeSalvage').value);
    this.debitForm
      .get('maximunBenefitLimit1')
      .setValue(this.debitForm.get('maximunBenefitLimit').value);
  }

  omit_char(event: any) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ( (k > 95 && k <= 105) || (k >= 48 && k <= 57));
  }

  selectCheckbox(control: any, e: any, group?: any) {
    if (group) {
      const formGroup: any = this.debitForm.controls[group];
      formGroup.controls[control].setValue(e.target.checked);
      formGroup.controls[control].markAsDirty();
    } else {
      this.debitForm.controls[control].setValue(e.target.checked);
      this.debitForm.controls[control].markAsDirty();
    }
  }

}
