var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RejectClaimComponent } from '@app/shared/modals/reject-claim/reject-claim.component';
import { ConfirmClaimComponent } from '@app/shared/modals/confirm-claim/confirm-claim.component';
import { Subject, Observable } from 'rxjs';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { takeUntil, finalize, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ClaimService } from '@app/core/service/claim.service';
import { FormBuilder, Validators } from '@angular/forms';
import { UtilService, CONFIRMATION_MSGS } from '@app/core';
import { DateTime } from 'luxon';
import { ConfirmationComponent } from '@app/shared/modals/confirmation';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from '@app/core/service/customer.service';
import { ClaimHelpComponent } from '@app/shared/modals/claim-help/claim-help.component';
var ListClaimComponent = /** @class */ (function () {
    function ListClaimComponent(modalService, router, authenticationService, claimService, formBuilder, utilService, toastrService, route, customerService) {
        var _this = this;
        this.modalService = modalService;
        this.router = router;
        this.authenticationService = authenticationService;
        this.claimService = claimService;
        this.formBuilder = formBuilder;
        this.utilService = utilService;
        this.toastrService = toastrService;
        this.route = route;
        this.customerService = customerService;
        this.loggedInUserType = null;
        this.loggedInUserdealer = null;
        this.unsubscribe = new Subject();
        this.selectedCustomer = null;
        this.isLoading = false;
        this.claims = [];
        this.environmentUrl = '';
        this.sortBy = 'createdAt';
        this.sortDir = -1;
        this.perPage = 10;
        this.currentPage = 1;
        this.pager = {
            perPage: 10,
            currentPage: 1,
            Totalrecords: 0,
            TotalPages: 0
        };
        this.hostname = window.location.hostname;
        this.dealers = function (text$) {
            return text$.pipe(debounceTime(200), distinctUntilChanged(), map(function (term) {
                return term.length < 2
                    ? []
                    : _this.dealersList
                        .filter(function (v) { return v.name.toLowerCase().indexOf(term.toLowerCase()) > -1; });
            }));
        };
        this.formatter = function (x) { return x.name; };
        this.user = this.authenticationService.credentials.user;
        this.loggedInUserType = this.user.userRoleId.slug;
        this.loggedInUserdealer = this.user.dealerId;
        this.authenticationService.credentials$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(function (credentials) {
            _this.user = credentials.user;
            _this.loggedInUserType = _this.user.userRoleId.slug;
            _this.loggedInUserdealer = _this.user.dealerId;
        });
        // this.selectedCustomer = localStorage.getItem(LOCAL_VAR.selectedCustomer);
        // this.selectedCustomer = JSON.parse(this.selectedCustomer);
        // localStorage.setItem(LOCAL_VAR.selectedCustomer, null);
        // if (!this.selectedCustomer && this.loggedInUserType === 'admin') {
        //   this.router.navigate(['/customer'], {
        //     replaceUrl: true
        //   });
        // }
        this.createForm();
        this.getClaimsByFilter();
        this.route.queryParams.pipe().subscribe(function (params) {
            if (params && params.page) {
                if (!isNaN(params.page)) {
                    _this.currentPage = +params.page;
                }
            }
        });
    }
    ListClaimComponent.prototype.ngOnInit = function () {
    };
    ListClaimComponent.prototype.ngAfterViewInit = function () {
        this.utilService.updateDomainCss();
    };
    ListClaimComponent.prototype.createForm = function () {
        var _this = this;
        this.fiterForm = this.formBuilder.group({
            addendumNumber: ['', Validators.compose([Validators.maxLength(5)])],
            sequence: ['', Validators.compose([Validators.maxLength(5)])],
            date_from: [''],
            date_to: [''],
            customerName: [''],
            email: [''],
            status: [''],
            sortFieldFilter: ['createdAt'],
            sortByFilter: ['-1'],
            dealer: [''],
            perPage: 10
        });
        this.fiterForm.valueChanges.subscribe(function (values) {
            _this.pager.currentPage = 1;
            _this.getClaimsByFilter();
        });
        this.customerService
            .getDealers()
            /* .pipe(
              finalize(() => {
                this.isLoading = false;
              })
            ) */
            .subscribe(function (response) {
            _this.dealersList = response.records;
        });
    };
    ListClaimComponent.prototype.getClaimsByFilter = function () {
        var _this = this;
        var payload = this.fiterForm.value;
        payload = this.utilService.removeWhiteSpace(payload);
        if (payload.date_from) {
            payload.dateOfLoss_at_from = DateTime.fromObject(payload.date_from).toISO();
        }
        if (payload.date_to) {
            payload.dateOfLoss_at_to = DateTime.fromObject(payload.date_to).toISO();
        }
        if (typeof payload.dealer == "object") {
            payload.dealer = payload.dealer._id;
        }
        else if (typeof payload.dealer == "string" && payload.dealer !== "" && this.currentPage === this.pager.currentPage) {
            return false;
        }
        payload.currentPage = this.pager.currentPage;
        this.pager.perPage = payload.perPage;
        this.isLoading = true;
        this.claimService
            .getLeaseClaimsByFilter(payload)
            .pipe(finalize(function () {
            _this.isLoading = false;
        }))
            .subscribe(function (response) {
            _this.claims = response.records;
            _this.renderPagination(response.page, response.total, response.totalPage);
        });
    };
    ListClaimComponent.prototype.acceptRequest = function (claim) {
        var _this = this;
        var payload = {
            message: "Hi, " + claim.customerName + " your deal has been accepted for claim",
            adminId: this.user._id
        };
        this.claimService
            .acceptLeaseRequest(claim._id, payload)
            .subscribe(function (response) {
            if (response) {
                _this.toastrService.info('Claim accepted Successfully!..');
                _this.getClaimsByFilter();
            }
            else {
                _this.toastrService.warning('something went Wrong');
            }
        });
    };
    ListClaimComponent.prototype.openRejectPopup = function (claim, type) {
        return __awaiter(this, void 0, void 0, function () {
            var modalRef, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalService.open(RejectClaimComponent)];
                    case 1:
                        modalRef = _a.sent();
                        modalRef.componentInstance.claimId = claim._id;
                        modalRef.componentInstance.amountApproved = claim.amountApproved;
                        modalRef.componentInstance.adminId = this.user._id;
                        modalRef.componentInstance.product = 'lease';
                        modalRef.componentInstance.rejectType = type;
                        return [4 /*yield*/, modalRef.result.catch(function (e) { return console.log(e); })];
                    case 2:
                        result = _a.sent();
                        if (result) {
                            if (type == 'void')
                                this.toastrService.info("Your claim has been voided successfully. To view this voided claim, please change the filter status to 'All' or 'Voided.'", '', { toastClass: 'toast ngx-toastr', positionClass: 'toast-top-right', closeButton: true, timeOut: 10000 });
                            else
                                this.toastrService.info('Claim ' + (type == 'void' ? 'Voided' : type == 'reject' ? 'rejected' : 'Denied') + '!');
                            this.getClaimsByFilter();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ListClaimComponent.prototype.openConfirmPopup = function (claim) {
        return __awaiter(this, void 0, void 0, function () {
            var modalRef, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalService.open(ConfirmClaimComponent)];
                    case 1:
                        modalRef = _a.sent();
                        modalRef.componentInstance.message = "Hi, " + claim.customerName + " your deal has been accepted for claim";
                        modalRef.componentInstance.claimId = claim._id;
                        modalRef.componentInstance.amountApproved = claim.amountApproved;
                        modalRef.componentInstance.adminId = this.user._id;
                        modalRef.componentInstance.product = 'lease';
                        return [4 /*yield*/, modalRef.result.catch(function (e) { return console.log(e); })];
                    case 2:
                        result = _a.sent();
                        if (result) {
                            this.getClaimsByFilter();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ListClaimComponent.prototype.openDeleteClaim = function (claimId) {
        return __awaiter(this, void 0, void 0, function () {
            var modalRef, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        modalRef = this.modalService.open(ConfirmationComponent);
                        modalRef.componentInstance.title = this.utilService.objectResolver('claims.deleteLeaseClaim.title', CONFIRMATION_MSGS);
                        modalRef.componentInstance.body = this.utilService.objectResolver('claims.deleteLeaseClaim.content', CONFIRMATION_MSGS);
                        modalRef.componentInstance.okBtn = this.utilService.objectResolver('claims.deleteLeaseClaim.okBtn', CONFIRMATION_MSGS);
                        modalRef.componentInstance.cancelBtn = this.utilService.objectResolver('claims.deleteLeaseClaim.cancelBtn', CONFIRMATION_MSGS);
                        modalRef.componentInstance.service = this.utilService.objectResolver('claims.deleteLeaseClaim.service', CONFIRMATION_MSGS);
                        modalRef.componentInstance.method = this.utilService.objectResolver('claims.deleteLeaseClaim.method', CONFIRMATION_MSGS);
                        modalRef.componentInstance.payload = [claimId];
                        return [4 /*yield*/, modalRef.result.catch(function (e) { return console.log(e); })];
                    case 1:
                        result = _a.sent();
                        if (result) {
                            this.toastrService.info('Claim deleted Successfully!..');
                            this.getClaimsByFilter();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ListClaimComponent.prototype.sort = function (sortByVal) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (this.sortBy === sortByVal) {
                    this.sortDir = this.sortDir === 1 ? -1 : 1;
                }
                else {
                    this.sortDir = 1;
                }
                /*  this.sortFieldFilter.setValue(sort);
                this.sortByFilter.setValue(sortDir); */
                this.fiterForm.value.sortFieldFilter = sortByVal;
                this.fiterForm.value.sortByFilter = this.sortDir;
                this.sortBy = sortByVal;
                this.getClaimsByFilter();
                return [2 /*return*/];
            });
        });
    };
    ListClaimComponent.prototype.goPage = function () {
        var goto = this.nameInput.nativeElement.value;
        this.setPage(goto);
        this.nameInput.nativeElement.value = '';
    };
    ListClaimComponent.prototype.setPage = function (page) {
        if (0 < page && page <= this.pager.TotalPages && page != this.pager.currentPage) {
            this.pager.currentPage = page;
            this.getClaimsByFilter();
        }
    };
    ListClaimComponent.prototype.renderPagination = function (page, total, totalPage) {
        this.pager.currentPage = page;
        this.currentPage = page;
        this.pager.Totalrecords = total;
        this.pager.TotalPages = totalPage;
        this.PageList = this.utilService.getPageList(this.pager.TotalPages, this.pager.currentPage);
    };
    ListClaimComponent.prototype.omit_special_char = function (event) {
        var k;
        k = event.charCode; //         k = event.keyCode;  (Both can be used)
        return ((k > 64 && k < 91) || (k > 96 && k <= 122) || k == 8 || k == 32 || (k >= 48 && k <= 57));
    };
    ListClaimComponent.prototype.omit_special_Mailchar = function (event) {
        var k;
        k = event.charCode; //         k = event.keyCode;  (Both can be used)
        return ((k > 64 && k < 91) || (k > 96 && k <= 122) || k === 46 || k === 64 || k == 8 || k == 32 || (k >= 48 && k <= 57));
    };
    ListClaimComponent.prototype.openHelpPopup = function () {
        return __awaiter(this, void 0, void 0, function () {
            var modalRef;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalService.open(ClaimHelpComponent)];
                    case 1:
                        modalRef = _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ListClaimComponent.prototype.checkAdmindealer = function (dealerId) {
        var adminDealer = false;
        if (this.user.isGroupDealer) {
            var group = this.user.dealerGroupId;
            for (var _i = 0, _a = group.dealers; _i < _a.length; _i++) {
                var grp = _a[_i];
                if (grp.dealerId == dealerId) {
                    adminDealer = true;
                }
            }
        }
        else if (dealerId == this.loggedInUserdealer) {
            adminDealer = true;
        }
        return adminDealer;
    };
    return ListClaimComponent;
}());
export { ListClaimComponent };
